import React, { useState, useEffect } from 'react';
import { useGetUpcomingConsultsMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import moment from 'moment';
import CommonFilterSelect from '../common/FilterDropdown';
import Loader from '../common/Loader';
import SharedTable from '../common/SharedTable';
import { Colorcode } from '../common/utils/Colorcode';

const UpcomingConsultsList = () => {

    const [getList] = useGetUpcomingConsultsMutation();
    const customerColor = Colorcode();
    const [loading, setLoading] = useState(false);
    const [loadOnce, setLoadOnce] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isMoreData, setIsMoreData] = useState(false);
    const [totalPages, setTotalPages] = useState();
    const [pageNumber, setPageNumber] = useState('1');
    const [filterString, setFilterString] = useState('all');
    const [searchString, setSearchString] = useState('');
    const filterOptions = [
        { value: 'all', label: 'All' },
        { value: 'scheduled', label: 'Scheduled' },
        { value: 'cancelled', label: 'Cancelled' }
    ];
    const [sort, setSort] = useState({
        sortType: '',
        headerFiled: ''
    });

    const handleOnClickSort = (header) => {
        setPageNumber(1);
        if (header?.field === sort?.headerFiled) {
            setSort({
                ...sort,
                sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
            });
        } else {
            setSort({
                sortType: 'asc',
                headerFiled: header?.field
            });
        }
    };

    const preSelectedFilter = {
        selectedOption: 'All' // Set the initial value to 'option1'
    };

    const tableHeaders = [
        { field: 'appointment_id', headerName: 'Appointment ID' },
        { field: 'patient_name', headerName: 'Patient Name' },
        { field: 'phone_number', headerName: 'Phone Number' },
        { field: 'doctor', headerName: 'Doctor Name' },
        { field: 'appointment_time', headerName: 'Appointment Time' },
        { field: 'appointment_type', headerName: 'Appointment Type' },
        { field: 'status', headerName: 'Status' }
    ];

    const fetchTableData = (page, status, search) => {
        if (!loadOnce) { setLoading(true); }
        getList({
            endpoint: `${apiConstants.upcomingConsultsList}/${page}/${status}/${search}`,
            method: 'POST',
            data: {
                consult_type: '',
                current_time: '',
                data: {},
                date: '',
                field: sort.headerFiled,
                type: sort?.sortType,
                kiosk_id: ''
            }
        }).then((result) => {
            let upcomingConsultsData = result?.data?.list;
            const modifiedData = upcomingConsultsData.map(item => ({
                ...item,
                appointment_time: moment(item.appointment_time).format('DD MMM YYYY, hh:mm A'),
                appointment_type: item.appointment_type === 1 ? 'OPD' : 'Remote'
            }));
            setTableData(modifiedData);
            setIsMoreData(result?.data?.moreData);
            setTotalPages(result?.data?.total_pages);
            setLoading(false);
            setLoadOnce(true);
        }).catch((error) => {
            setLoading(false);
            console.error('error', error);
        });
    };

    useEffect(() => {
        fetchTableData(pageNumber, filterString, searchString);
    }, [pageNumber, filterString, searchString, sort]);

    const handleOnPageChange = (pageNumber) => {
        setPageNumber(pageNumber);
    };

    const handleFilterSelect = (selectedOption) => {
        setFilterString(selectedOption.value);
    };

    const onSearch = (search) => {
        setPageNumber(1);
        setSearchString(search);
    };

    return (
        <div className="row mt-6 mx-24">
            {loading ? (
                <div className="flex items-center justify-center py-72">
                    <Loader />
                </div>
            ) : (
                <div>
                    <CommonFilterSelect
                        initialValues={preSelectedFilter}
                        options={filterOptions}
                        placeholder={preSelectedFilter.selectedOption}
                        onChange={handleFilterSelect}
                        onSearch={onSearch}
                        showFilter
                    />
                    <div>
                        <h4 style={{ borderColor: customerColor[0] }} className="text-2xl font-semibold border-b-4
                text-[Coolvetica Rg] list-header-text w-max text-[#4f4f4f]">
                            Upcoming Consult List
                        </h4>
                    </div>
                    <div className="mt-10">
                        <SharedTable
                            tableData={tableData}
                            tableHeaders={tableHeaders}
                            handleOnPageChange={handleOnPageChange}
                            isMoreData={isMoreData}
                            totalPages={totalPages}
                            handleOnClickSort={handleOnClickSort}
                            currentPageNumber={pageNumber}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default UpcomingConsultsList;
