import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import {
  useDeletepatientdocumentMutation
} from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import * as yup from 'yup';
import { Colorcode } from '../../common/utils/Colorcode';
const customerColor = Colorcode();
const Schema = yup.object().shape({
  reason: yup.string().required('Reason is required field').nullable()

});
const ScheduleModal = ({
  setDeleteModal,
  documentID,
  fetchPatientdocuments
}) => {

  const [deletepatientdocument] = useDeletepatientdocumentMutation();

  const handleSubmit = (values) => {
    deletepatientdocument({
      endpoint: apiConstants.deletepatientdocument,
      method: 'PUT',
      data: {
        attachment_id: documentID,
        reason: values?.reason
      }
    }).then(() => {
      setDeleteModal(false);
      fetchPatientdocuments();
    }).catch((error) => {
      console.error('error', error);
    });
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white">
              <div className="w-full">
                <div className='p-[20px] border-b-[1px] border-b-[#808080] flex justify-center'>
                  <span className='text-[#757776] text-[20px] fw-500'>
                    Delete Document</span>
                </div>
              </div>
              <div className='w-full flex justify-center text-[16px] pt-[25px] fw-500'>
                Are you sure you want to delete this document?
              </div>
              <div className="w-full py-[30px] px-[40px] text-[#757776]">
                <Formik
                  initialValues={{
                    reason: ''
                  }}
                  validationSchema={Schema}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  <Form className='flex flex-col items-center gap-[10px]'>
                    <Field
                      as="textarea"
                      name="reason"
                      placeholder="Enter reason"
                      autoComplete="off"
                      rows="3"
                      className="border border-[#A9A9A9] opacity-70 rounded-[10px]
                      outline-none w-full py-2 px-3 mb-[20px]"
                    />
                    <ErrorMessage name="reason" component="div" className='text-red-600' />
                    <div className='flex gap-[80px]'>
                      <button
                        type='submit'
                        className="text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                        rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                        style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
                      >
                        Yes
                      </button>
                      <button
                        type='text'
                        className="bg-[#808080] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                        rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                        onClick={() => { setDeleteModal(false); }}
                      >
                        No
                      </button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default ScheduleModal;
