import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import ReactSelectFilters from '../../../common/ReactSelectFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAddAppointmentDataMutation, useDeleteAppointmentDataMutation } from '../../../../reducers/allReducer';
import { apiConstants } from '../../../../constants/api';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeValueFromSelectedData,
  updatFieldValue,
  updateSelectedData
} from '../../../../reducers/selectedDropdownValuesSlice';

const SearchComponent = ({
  placeholder,
  dropdownName,
  appointmentId,
  addAppointmentType,
  saveNoteType,
  endpointForDataSearch,
  hookForApiCall,
  fieldNameForSearchApi
}) => {
  const colorCode = localStorage.getItem('Nav-Color');
  const [showAddNoteSection, setShowAddNoteSection] = useState(false);
  const [isNoteSaved, setIsNoteSaved] = useState(false);
  const [dropdownValues, setDropdownValues] = useState([]);
  const [searchValue, setSearchValue] = useState();

  const [addAppointmentData] = useAddAppointmentDataMutation();
  const [deleteAppointmentData] = useDeleteAppointmentDataMutation();
  const userId = localStorage.getItem('user_id');
  const dispatch = useDispatch();

  const selectedDropdownValues = useSelector((state) => state?.selectedDropdownValues?.[addAppointmentType]);
  const addedNote = useSelector((state) => state?.selectedDropdownValues?.[saveNoteType]);

  const addSelectedDropdownValue = (value) => {
    addAppointmentData({
      endpoint: `${apiConstants?.addAppointmentData}/${userId}`,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        id: value[0]?.id,
        name: value[0]?.name,
        type: addAppointmentType
      }
    }).then(() => {
      setDropdownValues([]);
      dispatch(updateSelectedData({ fieldName: addAppointmentType, fieldValue: value[0] }));
    });
  };

  const handleOnDropdownChange = (value) => {
    addSelectedDropdownValue(value);
  };

  const getOptionLabel = (option) => {
    return option?.display_name || option?.name;
  };

  const handleRemoveSelectedDropdownValue = (value) => {
    deleteAppointmentData({
      endpoint: `${apiConstants.deleteAppointmentData}/${appointmentId}`,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        id: value?.id,
        type: addAppointmentType
      }
    }).then(() => {
      dispatch(removeValueFromSelectedData({ fieldName: addAppointmentType, fieldValue: value }));
    });
  };

  const saveNote = () => {
    addAppointmentData({
      endpoint: `${apiConstants?.addAppointmentData}/${userId}`,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        [saveNoteType]: addedNote,
        type: saveNoteType
      }
    }).then(() => {
      setIsNoteSaved(true);
      setShowAddNoteSection(false);
    });
  };

  const handleDropdownInputChange = (value) => {
    setSearchValue(value);
  };

  const getDropdownData = (value) => {
    hookForApiCall({
      endpoint: `${endpointForDataSearch}/${value}`
    }).then((result) => setDropdownValues(result?.data?.[fieldNameForSearchApi]));
  };

  useEffect(() => {
    if (searchValue?.length >= 2) {
      getDropdownData(searchValue);
    } else if (searchValue?.length === 0) {
      setDropdownValues([]);
    }
  }, [searchValue]);

  const saveAddedNote = (value) => {
    setIsNoteSaved(false);
    dispatch(updatFieldValue({ fieldName: saveNoteType, fieldValue: value }));
  };

  useEffect(() => {
    if (addedNote?.length > 0 && !showAddNoteSection) {
      setIsNoteSaved(true);
      setShowAddNoteSection(true);
    }
  }, [addedNote]);

  return (
    <div>
      <Formik>
        <Form>
          <ReactSelectFilters
            options={dropdownValues}
            placeholder={placeholder}
            name={dropdownName}
            noDropDownIndicator
            handleDropdownInputChange={handleDropdownInputChange}
            onChange={handleOnDropdownChange}
            noOptionMessage='No matches found'
            isMulti={true}
            disableEnterKey
            getOptionLabel={getOptionLabel}
            controlShouldRenderValue={false}
            isSearchable={true}
            displaySearchIcon={true}
          />
        </Form>
      </Formik>
      {selectedDropdownValues?.length > 0 && (
        <div className='flex flex-wrap gap-[10px] pt-[20px]'>
          {selectedDropdownValues?.map((value, index) => (
            <div
              key={index}
              className='bg-[#383678] rounded-[30px] text-[#fff] text-[11px] flex py-[5px] px-[10px] 
                items-center gap-[5px]'
              style={{ background: `${colorCode}` }}
            >
              <p>{value?.display_name || value?.name}</p>
              <div className='cursor-pointer' onClick={() => handleRemoveSelectedDropdownValue(value)}>
                <FontAwesomeIcon icon={faXmark} style={{ color: 'white' }} />
              </div>
            </div>
          ))}
        </div>
      )
      }
      <div className='py-[20px] pl-[10px]'>
        <div className='cursor-pointer' onClick={() => setShowAddNoteSection(true)}>
          <p className='text-[#979797] font-[600] underline text-[12px]'>Add Note</p>
        </div>
        {showAddNoteSection && (
          <div className='grid grid-cols-12 gap-[15px] pt-[5px]'>
            <input
              type='text'
              value={addedNote}
              onChange={(e) => saveAddedNote(e.target.value)}
              className='!border-b-[#ccc] !text-[#737575] col-span-10 text-[14px]'
            />
            <div className='col-span-2'>
              <span className={`${(addedNote?.length > 0 && !isNoteSaved) ? 'text-[#FF6302]' : 'text-[#808080]'} 
                font-[600] underline text-[15px] text-end cursor-pointer`}
                onClick={() => !isNoteSaved && saveNote()}>
                Save
              </span>
            </div>
          </div>
        )}
      </div>
    </div >
  );
};

export default SearchComponent;