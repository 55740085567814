import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SharedButton from '../common/SharedButton';
import EditImg from '../../images/editIcon.png';
import DefaultImage from '../../images/general_pic.png';
import {
  useLazyGetDoctorDetailsViewQuery,
  useUpdatedoctorMutation,
  useGetSignedUrlForDoctorCertificateMutation,
  useUploadPhotoMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import Loader from '../common/Loader';
import Mail from '../../images/mail.png';
import Phone from '../../images/phone.png';
import Register from '../../images/registerIcon.png';
import Doctor from '../../images/doctorIcon.png';
import { Formik, Form, Field } from 'formik';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import PhoneInput from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import AssociateKioskModal from './AssociateKioskModal';
import ConfirmModal from '../common/ConfirmModal';
import DoctorScheduleSlotsModal from './DoctorScheduleSlotsModal';

const DoctorDetailsView = () => {
  const navigate = useNavigate();

  const [doctorsDetailView] = useLazyGetDoctorDetailsViewQuery();
  const [updatedoctor] = useUpdatedoctorMutation();
  const [getSignedUrlForDoctorCertificate] = useGetSignedUrlForDoctorCertificateMutation();
  const [uploadPhoto] = useUploadPhotoMutation();

  const [loading, setLoading] = useState(false);
  const [detailsCardData, setDetailsCardData] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [phoneNo, setPhoneNo] = useState(detailsCardData.phone_number || '');
  const [imgUrl, setImgUrl] = useState('');
  const [certificate, setCertificate] = useState();
  const [fileName, setFileName] = useState('');
  const [associateModal, setAssociateModal] = useState(false);
  const [msg, setMsg] = useState('');
  const [isConfirm, setIsConfirm] = useState();
  const [isScheduleSots, setIsScheduleSots] = useState(false);

  const { id } = useParams();
  const hiddenUserFileInput = useRef(null);
  const hiddenUserPic = useRef(null);

  const handleClickDoc = () => {
    hiddenUserFileInput.current.click();
  };

  const handleClickPic = () => {
    hiddenUserPic.current.click();
  };

  useEffect(() => {
    setPhoneNo(detailsCardData?.phone_number);
    setIsToggle(detailsCardData?.status === 'active' ? false : true);
  }, [detailsCardData]);

  const handleUploadDoc = (e) => {
    let file = e.target.files[0];
    setFileName(file.name);
    getSignedUrlForDoctorCertificate({
      endpoint: apiConstants.getSignedUrlForDoctorCertificate,
      method: 'POST',
      data: {
        fileName: file.name
      }
    }).then((result) => {
      if (result?.data) {
        fetch(result?.data.signedUrl, {
          method: 'PUT',
          body: file
        }).then(() => {
        });
        setCertificate(result?.data?.publicUrl);
      }
    }).catch((error) => {
      console.error('error', error);
    });
    hiddenUserFileInput.current.value = '';
  };

  const handleUploadPic = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append('image', e.target.files[0], e.target.files[0].name);
      uploadPhoto({
        endpoint: apiConstants.uploadPhoto,
        method: 'POST',
        data: formData || null
      }).then((result) => {
        setImgUrl(result?.data);
      }).catch((error) => {
        console.error('error', error);
      });
    }
    hiddenUserPic.current.value = '';
  };

  const cardData = () => {
    setLoading(true);
    doctorsDetailView({
      endpoint: `${apiConstants.doctorsDetailView}/${id}`
    }).then((result) => {
      let doctorViewData = result?.data;
      const modifiedData = {
        ...doctorViewData,
        id: `#DR${id}`
      };
      setDetailsCardData(modifiedData);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.error('error', error);
    });
  };
  useEffect(() => {
    cardData();
  }, []);

  const handleSubmit = (values) => {
    updatedoctor({
      endpoint: apiConstants.updatedoctor,
      method: 'PUT',
      // data: {
      data: {
        certificate: certificate || detailsCardData?.certificate,
        credential: values.credential,
        doctor_status: detailsCardData.doctor_status,
        email_id: values.email_id,
        first_name: values.first_name,
        id: parseInt(id),
        last_name: values.last_name,
        phone_number: values.phone_number,
        profile_photo: imgUrl || detailsCardData.profile_photo,
        registration_no: values.registration_no,
        status: isToggle ? 'inactive' : 'active'
      }
      // }
    }).then(() => {
      cardData();
    }).catch(() => {
    });
    setIsEdit(false);
    setFileName('');
  };

  const toggleBar = () => {
    setIsToggle(!isToggle);
  };

  const handleCertificate = (link) => {
    window.open(link);
  };

  return (
    <div className="row mt-16 mx-28">
      {isScheduleSots &&
        <DoctorScheduleSlotsModal
          setIsScheduleSots={setIsScheduleSots}
          detailsCardData={detailsCardData}
        />
      }
      {associateModal &&
        <AssociateKioskModal
          setAssociateModal={setAssociateModal}
          setIsConfirm={setIsConfirm}
          setMsg={setMsg}
        />}
      {isConfirm &&
        <ConfirmModal
          msg={msg}
        />}
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div className='flex flex-col mt-16'>
          <input
            id="uploadDoc"
            type="file"
            accept=".xlsx, .png, .pdf, .jpg"
            ref={hiddenUserFileInput}
            style={{ display: 'none' }}
            onChange={(e) => handleUploadDoc(e)}
          />
          <input
            id="uploadPic"
            type="file"
            accept=".png, .jpg"
            ref={hiddenUserPic}
            style={{ display: 'none' }}
            onChange={(e) => handleUploadPic(e)}
          />
          <div>
            <SharedButton
              className="ml-0 rounded-[20px] float-left text-[18px]
            bg-[#383678] text-white px-[30px] py-[7px] font-bold
              mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
              title='Back' onClick={() => navigate(-1)}
            />
          </div>
          <div className='bg-[#f4f4f4] rounded-lg mt-4 p-2 h-[575px] relative'>
            <div className='flex justify-center mt-6 w-full h-full'>
              <Formik
                initialValues={{
                  first_name: detailsCardData.first_name,
                  last_name: detailsCardData.last_name,
                  email_id: detailsCardData.email_id,
                  phone_number: detailsCardData.phone_number,
                  registration_no: detailsCardData.registration_no,
                  credential: detailsCardData.credential
                }}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({ setFieldValue }) => (
                  <Form className='w-full flex gap-[5%]'>
                    {isEdit ? <div className='flex gap-[20px] absolute top-[20px] right-[10px]'>
                      <SharedButton
                        className="ml-0 rounded-[20px] float-left text-[18px]
                      bg-[#383678] text-white px-[30px] py-[7px] font-bold
                        mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        type='text'
                        title='Schedule Slots'
                        onClick={() => setIsScheduleSots(true)}
                      />
                      <div className='flex items-center detailView'>
                        <div className='doctor-status'>Disable Doctor</div>
                        <Toggle
                          className='status-tog'
                          checked={isToggle}
                          onChange={() => toggleBar()}
                          icons={{
                            checked: <span style={{ fontFamily: 'Poppins' }}></span>,
                            unchecked: <span style={{ fontFamily: 'Poppins' }}></span>
                          }}
                        />
                      </div>
                      <SharedButton
                        className="ml-0 rounded-[20px] float-left text-[18px]
                      bg-[#383678] text-white px-[30px] py-[7px] font-bold
                        mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        type='submit'
                        title='Save'
                      />
                    </div> :
                      <div className='absolute right-[20px]'>
                        <SharedButton
                          className="ml-0 rounded-[20px] float-left 
                          bg-[#383678] text-white px-[30px] py-[5px] text-sm font-bold
                            mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                          title='Schedule Slots' onClick={() => setIsScheduleSots(true)}
                        />
                        <SharedButton
                          className="ml-0 rounded-[20px] float-left 
                          bg-[#383678] text-white px-[30px] py-[5px] text-sm font-bold
                            mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                          type='text'
                          title='Assosiate Kiosk' onClick={() => setAssociateModal(true)}
                        />
                        <img className='ml-3 cursor-pointer' src={EditImg} onClick={() => setIsEdit(true)} />
                      </div>}
                    <div className='w-[45%] flex flex-col gap-[20px] items-center justify-center'>
                      {isEdit ?
                        <img className='mt-6 block w-24 h-24 mx-auto border border-solid border-black rounded-xl
                          cursor-pointer'
                          src={imgUrl ? imgUrl :
                            (detailsCardData.profile_photo ? detailsCardData.profile_photo : DefaultImage)}
                          onClick={handleClickPic}
                          alt="Profile"
                        /> :
                        <img className='mt-6 block w-24 h-24 mx-auto border border-solid border-black rounded-xl'
                          src={detailsCardData.profile_photo ? detailsCardData.profile_photo : DefaultImage}
                          alt="Profile"
                        />}
                      {isEdit ?
                        (<><Field
                          name="first_name"
                          type='text'
                          placeholder="First Name"
                          autoComplete="off"
                          className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                          outline-none w-[50%] py-2 px-3 h-[50px]"
                        />
                          <Field
                            name="last_name"
                            type='text'
                            placeholder="Last Name"
                            autoComplete="off"
                            className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                            outline-none w-[50%] py-2 px-3 h-[50px]"
                          /></>) :
                        <div className='mt-[10%]'>
                          {detailsCardData.first_name && (
                            <label className='text-[#737575] text-5xl font-semibold'>
                              {detailsCardData.first_name} {detailsCardData.last_name}
                            </label>
                          )}
                        </div>}
                      <div className='flex gap-[20px] items-center'>
                        <div className='text-center'>
                          {detailsCardData.first_name && (
                            <label className='text-[#737575] text-[20px] font-semibold'>
                              {'#DR'}{id}
                            </label>
                          )}
                        </div>
                        <div className='h-[22px] border-l-2 border-solid border-black'></div>
                        <div className=''>
                          {detailsCardData.status}
                        </div>
                      </div>
                      {isEdit ? <div className='cursor-pointer flex items-center gap-[5px]'
                        onClick={() => handleClickDoc()}>
                        <FontAwesomeIcon icon={faPaperclip}
                          className='text-white right-[10px] top-0 text-[20px] h-[20px] w-[20px] bg-[#383678] p-[10px] 
                          rounded-[50%]' />
                        <div>{fileName ? fileName : 'certificate'}</div>
                      </div> : <div className='underline cursor-pointer'
                        onClick={() => handleCertificate(detailsCardData.certificate)}>
                        Degree Certificate
                      </div>
                      }
                    </div>
                    <div className='h-[80%] border-l-2 border-solid border-[#d3d3d3] my-8'></div>
                    <div className='w-[45%] flex flex-col justify-center items-start ml-[5%] gap-[30px]'>
                      <div className='flex items-center gap-3'>
                        <div className='w-[65px] h-[65px] rounded-[50%] bg-[#d1d1d166] 
                        flex justify-center items-center'>
                          <img className='cursor-pointer' src={Mail} width={32} height={23} />
                        </div>
                        {isEdit ?
                          <Field
                            name="email_id"
                            type='text'
                            placeholder="First Name"
                            autoComplete="off"
                            className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                            outline-none w-full py-2 px-3 h-[50px]"
                          /> :
                          <div className='text-[18px] text-[#999999]'>{detailsCardData.email_id}</div>}
                      </div>
                      <div className='flex items-center w-[60%]'>
                        <div className='w-[65px] h-[65px] rounded-[50%] bg-[#d1d1d166]  
                        flex justify-center items-center'>
                          <img className='cursor-pointer' src={Phone} width={37} height={30} />
                        </div>
                        {isEdit ?
                          <Field name="phone_number" className='w-[60%]'>
                            {({ field }) => (
                              <PhoneInput
                                {...field}
                                name="phone_number"
                                type="tel"
                                placeholder="Phone Number*"
                                country={'in'}
                                onlyCountries={['in']}
                                autoFormat={false}
                                value={phoneNo}
                                onChange={(data) => {
                                  const phoneNumber = '+' + data;
                                  setPhoneNo(phoneNumber);
                                  setFieldValue('phone_number', phoneNumber);
                                }}
                              />
                            )}
                          </Field> :
                          <div className='text-[18px] text-[#999999]'>{detailsCardData.phone_number}</div>}
                      </div>
                      <div className='flex items-center gap-3'>
                        <div className='w-[65px] h-[65px] rounded-[50%] bg-[#d1d1d166] 
                        flex justify-center items-center'>
                          <img className='cursor-pointer' src={Register} width={32} height={23} />
                        </div>
                        {isEdit ?
                          <Field
                            name="registration_no"
                            type='text'
                            placeholder="Registration No"
                            autoComplete="off"
                            className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                            outline-none w-full py-2 px-3 h-[50px]"
                          /> :
                          <div className='text-[18px] text-[#999999]'>{detailsCardData.registration_no}</div>}
                      </div>
                      <div className='flex items-center gap-3'>
                        <div className='w-[65px] h-[65px] rounded-[50%] bg-[#d1d1d166] 
                        flex justify-center items-center'>
                          <img className='cursor-pointer' src={Doctor} width={32} height={23} />
                        </div>
                        {isEdit ?
                          <Field
                            name="credential"
                            type='text'
                            placeholder="Credential"
                            autoComplete="off"
                            className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                            outline-none w-full py-2 px-3 h-[50px]"
                          /> :
                          <div className='text-[18px] text-[#999999]'>{detailsCardData.credential}</div>}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DoctorDetailsView;