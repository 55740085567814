import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { awsdetails } from '../../../constants/awsdetails';

const AttachmentView = () => {
  const attachments = useSelector((state) => state?.medicalHistoryData?.attachmentDetailsPrescription);

  const handleOnClickViewAttachment = (data) => {
    // eslint-disable-next-line max-len
    const winFeature = 'toolbar=no,titlebar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no';
    window.open(`${awsdetails.awsUrl}/${data.attachment_name}`, 'Image/*', winFeature);
  };

  return (
    <>
      {attachments && attachments?.length > 0 ? (
        <div className='flex flex-col gap-[10px]'>
          {attachments?.map((data, index) => (
            <div key={index} className='grid grid-cols-12 gap-[20px] border-b-[1px] border-b-[#d9d9d9] p-[10px]'>
              <div className='col-span-7 flex flex-col gap-[5px] break-all'>
                <p className='text-[17px] text-[#888] font-[700]'>{data?.original_name}</p>
                <p className='text-[12px] text-[#999]'>{data?.attachment_description}</p>
              </div>
              <div className='col-span-3'>
                <p className='text-[14px] text-[#999]'>
                  Uploaded on - {moment(data?.date_created).format('DD MMM YYYY')}
                </p>
              </div>
              <div className='col-span-2 cursor-pointer' onClick={() => handleOnClickViewAttachment(data)} >
                <p className='text-[14px] text-[#337ab7] underline'>Preview</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className='p-[30px] text-center'>
          <p className='text-[#737575] text-[14px]'>No attachments available</p>
        </div>
      )}
    </>
  );
};

export default AttachmentView;