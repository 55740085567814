import React, { useState, useEffect } from 'react';
import { useGettabulardashboardanalyticsMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import moment from 'moment';
import SharedTable from '../common/SharedTable';
import { Colorcode } from '../common/utils/Colorcode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClose } from '@fortawesome/free-solid-svg-icons';
import SharedButton from '../common/SharedButton';
import AnalyticsModal from './AnalyticsModal';

const TabConsultByDoctor = () => {

  const [gettabulardashboardanalytics] = useGettabulardashboardanalyticsMutation();
  const customerColor = Colorcode();
  const [tableData, setTableData] = useState([]);
  const [filterApplied, setfilterApplied] = useState(false);
  const [filterPopup, setfilterPopup] = useState(false);
  const [objectData, setObjectData] = useState({
    start_date: '',
    end_date: ''
  });

  const tableHeaders = [
    { field: 'doctor', headerName: 'Doctor' },
    { field: 'email', headerName: 'Email' },
    { field: 'phone', headerName: 'Phone' },
    { field: 'degree', headerName: 'Degree' },
    { field: 'doctor_consults', headerName: 'Doctor Consults' }
  ];

  const fetchTableData = () => {
    gettabulardashboardanalytics({
      endpoint: apiConstants.gettabulardashboardanalytics,
      method: 'POST',
      data: {
        end_date: objectData?.end_date ? moment(objectData?.end_date).format('YYYY-MM-DD') : '',
        start_date: objectData?.start_date ? moment(objectData?.start_date).format('YYYY-MM-DD') : '',
        search: '',
        type: 'consults_by_doctor'
      }
    }).then((result) => {
      let upcomingConsultsData = result?.data?.list;
      setTableData(upcomingConsultsData);
    }).catch((error) => {
      console.error('error', error);
    });
  };

  const handleClear = () => {
    setfilterApplied(false);
    setObjectData({
      start_date: '',
      end_date: ''
    });
    fetchTableData();
  };

  useEffect(() => {
    fetchTableData();
  }, [objectData]);

  const export_csv = () => {
    let fileTitle = 'consults_by_doctor';
    if (!tableData) {
      alert('Data is not available.');
      return;
    }
    const items = tableData;
    const replacer = (key, value) => value === null ? '' : value;
    const header = Object.keys(items[0]);
    const csv = [
      header.join(','),
      ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
    ].join('\r\n');

    var exportedFilenmae = fileTitle + '.csv' || 'export.csv';

    var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  return (
    <div className="mt-6 mx-24">
      {filterPopup &&
        <AnalyticsModal
          setfilterPopup={setfilterPopup}
          setObjectData={setObjectData}
          objectData={objectData}
          getAnalytics={fetchTableData}
          setfilterApplied={setfilterApplied}
        />}
      <div className="col-span-12 flex justify-between relative">
        <div className='self-center'>
          <SharedButton
            className="ml-0 rounded-[20px] float-right bg-[#383678] text-white px-[25px] py-[10px] font-semibold
            text-sm mt-1.5 mx-auto flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
            type="text"
            title="Export as CSV"
            onClick={() => export_csv()}
          />
        </div>
        <div className='flex items-center gap-[50px]'>
          <h6 className="data-filter-selected">
            {filterApplied ? `Data is shown for dates from ${moment(objectData.start_date).format('Do MMM YYYY')} to 
            ${moment(objectData.end_date).format('Do MMM YYYY')}` : 'Data is shown for last 30 days'}
          </h6>
          <div className="graph-tabular-btn flex gap-2 w-[155px] h-[35px] items-center cursor-pointer"
            onClick={() => setfilterPopup(true)}>
            <div>Filter by Date</div>
            <FontAwesomeIcon icon={faCalendarAlt}
              className='text-[#fff] right-[40px] top-[25px] text-[20px] cursor-pointer'
            />
          </div>
        </div>
        {filterApplied && <div className="flex gap-2 w-[155px] h-[35px] items-center justify-center
          top-[55px] right-0 absolute"
          onClick={() => handleClear()}
        >
          <div>Clear Filter</div>
          <FontAwesomeIcon icon={faClose}
            className='text-[#000] right-[40px] top-[25px] text-[20px] cursor-pointer'
          />
        </div>}
      </div>
      <div>
        <h4 style={{ borderColor: customerColor[0] }}
          className="text-2xl font-semibold border-b-4 text-[Coolvetica Rg] list-header-text w-max text-[#4f4f4f]
          mt-[30px]">
          Analytics
        </h4>
      </div>
      <div className="mt-10 mb-[50px]">
        <SharedTable
          tableData={tableData}
          tableHeaders={tableHeaders}
          isPagination
        />
      </div>
    </div>
  );
};

export default TabConsultByDoctor;
