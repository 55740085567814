import React, { useState } from 'react';
import {
  useAddDoctorVitalsMutation,
  useGetSignedUrlForAttachmentsDoctorMutation,
  useSaveAttachmentDoctorMutation
} from '../../../../reducers/allReducer';
import { apiConstants } from '../../../../constants/api';
import ReactLoading from 'react-loading';

const CaptureImageModal = ({
  handleModalClose,
  imageUrl,
  appointmentId,
  userId,
  appointmentType
}) => {
  const [description, setDescription] = useState();
  const [isDescriptionError, setIsDescriptionError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [getSignedUrlForAttachementsDoctor] = useGetSignedUrlForAttachmentsDoctorMutation();
  const [saveAttachmentDoctor] = useSaveAttachmentDoctorMutation();
  const [addDoctorVitals] = useAddDoctorVitalsMutation();

  const handleOnChangeDescription = (value) => {
    setDescription(value);
    if (value?.length < 3) {
      setIsDescriptionError(true);
    } else {
      setIsDescriptionError(false);
    }
  };

  const dataURItoBlob = (dataURI) => {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const saveImage = (params) => {
    saveAttachmentDoctor({
      endpoint: `${apiConstants?.saveAttachmentDoctor}/${userId}`,
      method: 'POST',
      data: params
    }).then((result) => {
      if (result?.data?.attachment_id) {
        let param = {};
        if (appointmentType === 2) {
          param = {
            appointment_id: appointmentId,
            device_name: 'Screenshot',
            user_attachment_id: result?.data?.attachment_id
          };
        } else {
          param = {
            appointment_id: appointmentId,
            device_name: 'Otoscope',
            user_attachment_id: result?.data?.attachment_id
          };
        }
        addDoctorVitals({
          endpoint: apiConstants?.addDoctorVitals,
          method: 'POST',
          data: param
        }).then((result1) => {
          if (result1?.data?.success === true) {
            setIsUploading(false);
            setDescription();
            handleModalClose();
          }
        });
      }
    });
  };

  const handleUploadImage = () => {
    if (description?.length < 3) {
      setIsDescriptionError(true);
    } else {
      setIsDescriptionError(false);
      setIsUploading(true);
      const dateTime = Date.now();
      const timestamp = Math.floor(dateTime / 1000);
      let filename1 = '';
      if (appointmentType === 2) {
          filename1 = timestamp + '_Screenshot_' + appointmentId + '.png';
      } else {
          filename1 = timestamp + '_Otoscope_' + appointmentId + '.png';
      }
      let sendFile = dataURItoBlob(imageUrl);
      getSignedUrlForAttachementsDoctor({
        endpoint: `${apiConstants?.getSignedUrlForAttachmentsDoctor}/${userId}`,
        method: 'POST',
        data: {
          objectName: filename1,
          attachment_description: description
        }
      }).then((result) => {
        let params = {
          filename: result?.data?.filename,
          originalFileName: result?.data?.originalFileName,
          appointment_id: appointmentId,
          attachment_description: description
        };
        if (result?.data) {
          let signedUrl = result?.data?.signedUrl;
          fetch(signedUrl, {
            method: 'PUT',
            body: sendFile
          }).then(() => saveImage(params));
        }
      });
    }
  };

  return (
    <div
      className='relative z-50'
      aria-labelledby='modal-title'
      role='dialog'
      aria-modal='true'
    >
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>
      <div className='fixed inset-0 z-50 overflow-y-auto'>
        <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
          <div
            className='relative transform overflow-hidden rounded-[30px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl'
          >
            <div className='bg-white p-[50px]'>
              <div className='flex flex-col gap-[10px]'>
                <div className='flex justify-center'>
                  <img src={imageUrl} alt='' className='h-[230px] w-[40%]' />
                </div>
                <p className='text-center'>Description</p>
                <input type='text' placeholder='Enter Description*' value={description} 
                  onChange={(e) => handleOnChangeDescription(e?.target?.value)} />
                {isDescriptionError && (
                  <div className='text-center'>
                    <p className='text-[15px] text-red-600'>Min. 3 characters!</p>
                  </div>
                )}
                <div className='flex gap-[10px] justify-center pt-[20px]'>
                  {!isUploading ? (
                    <button 
                      className='text-white px-[20px] py-[10px] rounded-[50px] text-[12px] text-nowrap'
                      style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)'}}
                      onClick={() => handleUploadImage()}
                    >
                      Upload
                    </button>
                  ) : (
                    <ReactLoading bsClass="end-appointment-btn" type={'spinningBubbles'} 
                      style={{ width: '6%', fill: '#383678' }} />
                  )}
                  <button 
                    className='text-white px-[20px] py-[10px] rounded-[50px] text-[12px] text-nowrap'
                    style={{ background: 'linear-gradient(40.66deg,#737575,#737375)'}}
                    onClick={() => handleModalClose()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaptureImageModal;
