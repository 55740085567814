 export const getPatientHeaders = (setShowPatientHeaderDetail) => {
    const handleSetShowPatientHeaderDetail = (showSymptoms, showVitals, showTeleExamination,
      showInvestigation,  showDiagnosis, showPrescription) => {
      setShowPatientHeaderDetail({
        showSymptoms: showSymptoms,
        showVitals: showVitals,
        showTeleExamination: showTeleExamination,
        showInvestigation: showInvestigation,
        showDiagnosis: showDiagnosis,
        showPrescription: showPrescription
      });
    };
    const headers = [
      {
        title: 'Symptoms',
        handleClick: () => {
          handleSetShowPatientHeaderDetail(true, false, false, false, false, false);
        }
      },
      {
        title: 'Vitals',
        handleClick: () => {
          handleSetShowPatientHeaderDetail(false, true, false, false, false, false);
        }
      },
      {
        title: 'Tele Examination',
        handleClick: () => {
          handleSetShowPatientHeaderDetail(false, false, true, false, false, false);
        }
      },
      {
        title: 'Investigation',
        handleClick: () => {
          handleSetShowPatientHeaderDetail(false, false, false, true, false, false);
        }
      },
      {
        title: 'Diagnosis',
        handleClick: () => {
          handleSetShowPatientHeaderDetail(false, false, false, false, true, false);
        }
      },
      {
        title: 'Prescription',
        handleClick: () => {
          handleSetShowPatientHeaderDetail(false, false, false, false, false, true);
        }
      }
    ];
    return headers;
  };