import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AgentsLandingPage from './index';
import Header from '../common/Header';
import Register from './components/Register';
import VerifySlots from './components/VerifySlots';
import TodaysConsults from './components/TodaysConsults';
import Doctor from './components/Doctor';
import Upload from './components/Upload';
import PastConsults from './components/PastConsults';
import ViewDoctorSchedule from './components/ViewDoctorSchedule';

const AgentsRoutes = () => {
  const user_type = 'doctor';
  let authentication = localStorage.getItem('access_token');
  //   let UserType = localStorage.getItem('user_type');
  //   if ((authentication && authentication.length < 5) && window.location.pathname === `/${user_type}`) {
  //     window.location.replace('/login');
  //   } else if ((authentication && authentication.length < 5) && window.location.pathname.length >= 56) {
  //     setTimeout(() => {
  //       window.location.replace('/login');
  //     }, 10);
  //   } else if ((authentication && authentication.length > 5) && UserType !== user_type) {
  //     setTimeout(() => {
  //       window.location.replace('/login');
  //     }, 10);
  //   }
  return (
    <div className='w-full h-full'>
      <div className='grid grid-cols-12'>
        <div className='col-span-12 h-full'>
          <Header />
          <Routes>
            {authentication === null && window.location.pathname === `/${user_type}` &&
              window.location.replace('/login')
            }
            <Route path='/' element={<AgentsLandingPage />} />
            <Route path='/create' element={<Register />} />
            <Route path='/verifypatients' element={<VerifySlots />} />
            <Route path='/todays-consults' element={<TodaysConsults />} />
            <Route path='past-consults' element={<PastConsults />} />
            <Route path='/doctors' element={<Doctor />} />
            <Route path='upload/:appointment_id' element={<Upload />} />
            <Route path='view-schedule/:doctorId' element={<ViewDoctorSchedule />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AgentsRoutes;