export const daysTypeOptions = [
  { label: 'Every Day', value: 'everyday' },
  { label: 'Selected Days', value: 'selected_days' },
  { label: 'Alternate Days', value: 'alternatedays' },
  { label: 'SOS', value: 'sos' },
  { label: 'Only Once', value: 'onlyonce' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'In two weeks', value: 'intwoweeks' }
];

export const routesOptions = [
  { label: 'Oral', value: 'Oral' },
  { label: 'Eye Drops', value: 'Eye Drops' },
  { label: 'Ear Drops', value: 'Ear Drops' },
  { label: 'Nasal Drops', value: 'Nasal Drops' },
  { label: 'Topical', value: 'Topical' },
  { label: 'Inhalational', value: 'Inhalational' },
  { label: 'Gargles', value: 'Gargles' },
  { label: 'Intravenous', value: 'Intravenous' },
  { label: 'Intramuscular', value: 'Intramuscular' },
  { label: 'Intradermal', value: 'Intradermal' },
  { label: 'Mouthwash', value: 'Mouthwash' },
  { label: 'Subcutaneous', value: 'Subcutaneous' },
  { label: 'Sublingual', value: 'Sublingual' },
  { label: 'Vaginal', value: 'Vaginal' },
  { label: 'Rectal', value: 'Rectal' }
];

export const timesPerDayOptions = [
  { label: 'Morning', value: 'Morning', selected: false },
  { label: 'Afternoon', value: 'Afternoon', selected: false },
  { label: 'Evening', value: 'Evening', selected: false},
  { label: 'Night', value: 'Night', selected: false}
];

export const selectedDaysOptions = [
  { label: 'Monday', value: 'Monday' },
  { label: 'Tuesday', value: 'Tuesday' },
  { label: 'Wednesday', value: 'Wednesday' },
  { label: 'Thursday', value: 'Thursday' },
  { label: 'Friday', value: 'Friday' },
  { label: 'Saturday', value: 'Saturday' },
  { label: 'Sunday', value: 'Sunday' }
];

export const dosageTypes = [
  { label: 'mg', value: 'mg' },
  { label: 'mcg', value: 'mcg' },
  { label: 'ml', value: 'ml' },
  { label: 'I.U', value: 'I.U' }
];

export const specialities = [
  {label: 'General surgery', value: 'General surgery'},
  {label: 'Internal Medicine', value: 'Internal Medicine'},
  {label: 'Dental Medicine', value: 'Dental Medicine'},
  {label: 'Gastroenterology', value: 'Gastroenterology'},
  {label: 'Paediatric Medicine', value: 'Paediatric Medicine'},
  {label: 'Allergy and immunology', value: 'Allergy and immunology'},
  {label: 'Ophthalmology', value: 'Ophthalmology'},
  {label: 'Orthopaedics', value: 'Orthopaedics'},
  {label: 'Obstetric medicine', value: 'Obstetric medicine'},
  {label: 'Gynaecology', value: 'Gynaecology'},
  {label: 'Pulmonology', value: 'Pulmonology'},
  {label: 'Diabetology', value: 'Diabetology'},
  {label: 'Family Medicine', value: 'Family Medicine'},
  {label: 'Ear, nose and throat', value: 'General surgery'},
  {label: 'Cardiology', value: 'General surgery'},
  {label: 'psychiatry', value: 'General surgery'},
  {label: 'Nephrology', value: 'General surgery'},
  {label: 'Radiology', value: 'General surgery'},
  {label: 'General Dentist', value: 'General Dentist'},
  {label: 'Dermatology', value: 'General surgery'},
  {label: 'Endocrinology', value: 'Endocrinology'},
  {label: 'ENT- Head and Neck surgery', value: 'General surgery'},
  {label: 'Urology', value: 'Urology'},
  {label: 'orthodontics', value: 'orthodontics'},
  {label: 'weight management', value: 'weight management'},
  {label: 'occupational medicine', value: 'occupational medicine'},
  {label: 'Physical medicine and rehab', value: 'Physical medicine and rehab'},
  {label: 'Paediatric Infectious ds', value: 'Paediatric Infectious ds'},
  {label: 'Rheumatology', value: 'Rheumatology'},
  {label: 'Bariatric medicine', value: 'Bariatric medicine'},
  {label: 'Geriatric medicine', value: 'Geriatric medicine'},
  {label: 'Wound care', value: 'Wound care'},
  {label: 'Neurosurgery', value: 'Neurosurgery'},
  {label: 'Plastic and reconstructive surgery', value: 'Plastic and reconstructive surgery'}
];