import React from 'react';

const CommonDisplayBox = ({ dataList }) => {
  return (
    <div className='p-[20px] flex flex-wrap gap-[10px]'>
      {dataList && dataList?.map((data, index) => (
        <div key={index} className='text-[12px] bg-[#838383] text-white font-[400] rounded-[18px] p-[10px]'>
          {data?.name || data}
        </div>
      ))}
      {!dataList && <div className='text-sm text-[#777] fw-500'>
        No records found
      </div>
      }
    </div>
  );
};

export default CommonDisplayBox;
