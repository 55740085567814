import React from 'react';
import CommonPrescriptionView from '../../common/CommonPrescriptionView';

const PastConsultsModal = ({
  appointmentData,
  coordinates
}) => {
  const getDuration = () => {
    const data = appointmentData?.appointment_details;
    if (data?.duration_number !== null && data?.duration_type !== null) {
      const duration = data?.duration_number + ' ' + data?.duration_type;
      return duration?.split('\n');
    }
  };

  return (
    <div>
      <CommonPrescriptionView
        patientDetails={appointmentData?.appointment_details}
        symptomsList={appointmentData?.details?.symptoms}
        presentingIllness={appointmentData?.appointment_details?.presenting_illness?.split('\n')}
        diagnosis={appointmentData?.details?.diagnosis}
        differentialDiagnosis={appointmentData?.details?.differential_diagnosis}
        provisionalDiagnosis={appointmentData?.details?.provisional_diagnosis}
        investigation={appointmentData?.details?.tests}
        referredTo={appointmentData?.appointment_details?.referred_to?.split('\n')}
        selfCare={appointmentData?.appointment_details?.prescription_instructions}
        followUpDate={appointmentData?.appointment_details?.followup_date}
        duration={getDuration()}
        drugs={appointmentData?.details?.drugs_prescription}
        teleExamination={appointmentData?.appointment_details?.tele_examination}
        patientReports={appointmentData?.details?.reports}
        vitals={appointmentData?.details?.vitals}
        pacs={appointmentData?.details?.pacs}
        isPastConsultModal
        coordinates={coordinates}
      />
    </div>
  );
};

export default PastConsultsModal;