import React from 'react';

const PatientDetailsInPrescription = ({
  patientDetails
}) => {
  return (
    <div className='p-[10px] border-[1px] border-black text-[12px]'>
      <div className='flex flex-col gap-[15px]'>
        <div className='grid grid-cols-12'>
          <div className='col-span-4'>
            <span>Name: </span>
            <span>{patientDetails?.title + '. ' + patientDetails?.first_name + ' ' + patientDetails?.last_name}</span>
          </div>
          <div className='col-span-4'>
            <span>Age: </span>
            <span>{patientDetails?.dob}</span>
          </div>
          <div className='col-span-4'>
            <span>Gender: </span>
            <span>{patientDetails?.gender}</span>
          </div>
        </div>
        <div className='grid grid-cols-12'>
          <div className='col-span-4'>
            <span>Address: </span>
            <span>{patientDetails?.address_line_1 + ' ' + patientDetails?.address_line_2}</span>
          </div>
          <div className='col-span-4'>
            <span>Date: </span>
            <span>{patientDetails?.appointment_date}</span>
          </div>
          <div className='col-span-4'>
            <span>Marital Status: </span>
            <span>{patientDetails?.marital_status}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetailsInPrescription;