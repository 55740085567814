
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Axon_Logo from '../../images/Sakha-logo.png';
// import InputElement from '../common/InputElement';
import SharedButton from '../common/SharedButton';
import { useGetLoggedInMutation, useLazyGetRolePrivilegeAccessQuery, useLazyGetSettingsQuery }
  from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactSelectFilters from '../common/ReactSelectFilters';

const LoginPage = () => {
  let optionsData = [];
  const navigate = useNavigate();
  const [getLogin] = useGetLoggedInMutation();
  const [getRolePrivilegeAccessData] = useLazyGetRolePrivilegeAccessQuery();
  const [getSettings] = useLazyGetSettingsQuery();
  const [passwordEnabled] = useState(true);
  const [multipleUserType, setMultipleUserType] = useState([]);
  const [options, setOptions] = useState([]);
  const [fieldValue, setFieldValue] = useState();
  const [formData, setFormData] = useState({
    phone_number: {
      number: '', errorMessage: 'Please enter valid Phone Number', value: '', required: true, is_dirty: false
    },
    password: { value: '', errorMessage: 'Please enter correct Password', required: true, is_dirty: false },
    otp: { value: '', errorMessage: 'Please enter 6 Digit OTP', required: false, is_dirty: false },
    customer_id: { value: null, errorMessage: 'Please select Customer', required: false, is_dirty: false },
    user_type: { value: null, errorMessage: 'Please select User Type', required: false, is_dirty: false }
  });
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [phoneNumberData, setPhoneNumberData] = useState('');
  const [customerData, setCustomerData] = useState();
  const [passwordData, setPasswordData] = useState('');
  const [errorData, setErrorData] = useState({});
  const [passwordtype, setPasswordType] = useState('password');
  const [showLoginCredintial, setShowLoginCredintial] = useState(true);
  const [customersList, setCustomersList] = useState([]);

  const showPassword = () => {
    if (passwordtype === 'password') {
      setPasswordType('text');
    } else if (passwordtype === 'text') {
      setPasswordType('password');
    }
  };

  useEffect(() => {

  }, []);

  const login = (phoneNumber, password, otp, e, customer_id) => {
    setPhoneNumberData(phoneNumber);
    setPasswordData(password);
    e.preventDefault();
    let FormData = formData;
    getLogin({
      endpoint: apiConstants.login,
      method: 'POST',
      data: {
        phone_number: phoneNumberData ? phoneNumberData.number : phoneNumber.number,
        password: passwordData ? passwordData.value : password.value,
        customer_id: customerData ? customerData : customer_id ? customer_id : formData.customer_id.value,
        user_type: fieldValue ? fieldValue.value : formData.user_type.value
      }
    }).then((result) => {
      if (result?.data?.usertypes) {
        setMultipleUserType(result?.data?.usertypes);
        setCustomerData(result?.data?.customer_id);
        result?.data?.usertypes.map((user_data) => {
          optionsData.push({
            value: user_data.user_type,
            label: user_data.user_type
          });
        });
        setOptions(optionsData);
      }
      if (result?.data.user_type)
        if (result.data) {
          if (result?.data?.input_required !== 1) {
            if (result.data.user_type === 'admin') {
              localStorage.setItem('paramedic_slot_required', result.data.paramedic_slot_required);
            }
            if (result.data.user_type === 'agent') {
              localStorage.setItem('paramedic_slot_required', result.data.paramedic_slot_required);
            }
            if (result.data.consult_types) {
              localStorage.setItem('Consult_Type', result.data.consult_types);
            }
            result.data.user_type && localStorage.setItem('user_type', result.data.user_type);
            result.data.kiosk_id && localStorage.setItem('kiosk_id', result.data.kiosk_id);
            result.data.privileges && localStorage.setItem('privileges', result.data.privileges);
            result.data.customer_id && localStorage.setItem('customer_id', result.data.customer_id);
            result.data.name && localStorage.setItem('username', result.data.name);
            result?.data?.id && localStorage.setItem('user_id', result?.data?.id);
            setTimeout(() => {
              if (result.data.user_type === 'client') {
                navigate('/patient');
              } else {
                navigate(`/${result.data.user_type}`);
              }
            }, 1000);
            //RoleBased Access
            if (result.data.user_type === 'paramedic') {
              localStorage.setItem('Kiosk', result.data.kiosk_location.charAt(0).toUpperCase() +
                result.data.kiosk_location.slice(1));
            } else if (result.data.user_type === 'agent' && result.data.kiosk_name) {
              localStorage.setItem('is_patient_otp_require', result.data.is_patient_otp_require);
              localStorage.setItem('Kiosk', result.data.kiosk_name.charAt(0).toUpperCase() +
                result.data.kiosk_name.slice(1));
            }
            if (result.data.profile_photo) {
              localStorage.setItem('ProfilePic', result.data.profile_photo);
            }
            result.data.auth_token && localStorage.setItem('access_token', result.data.auth_token);
            if (result.data.user_type === 'admin') {
              setTimeout(() => {
                getRolePrivilegeAccessData({
                  endpoint: `${apiConstants.getRolePreviliges}/${result.data.privileges[0]}`
                }).then((privilageResult) => {
                  let Read_Access_URLS = [], Write_Access_URLS = [];
                  if (privilageResult) {
                    if (privilageResult.read_access_urls && privilageResult.read_access_urls.length > 0) {
                      for (let i = 0; i < privilageResult.read_access_urls.length; i++) {
                        Read_Access_URLS.push(privilageResult.read_access_urls[i].url);
                      }
                    }
                    if (privilageResult.read_write_access_urls && privilageResult.read_write_access_urls.length > 0) {
                      for (let i = 0; i < privilageResult.read_write_access_urls.length; i++) {
                        Write_Access_URLS.push(privilageResult.read_write_access_urls[i].url);
                      }
                    }

                    localStorage.setItem('Read_Access_URLS', JSON.stringify(Read_Access_URLS));
                    localStorage.setItem('Write_Access_URLS', JSON.stringify(Write_Access_URLS));
                  }
                });
              }, 1000);
            }
            result.data.auth_token &&
              setTimeout(() => {
                getSettings({
                  endpoint: apiConstants.getSettings
                }).then((settingsResult) => {
                  if (settingsResult) {
                    if (settingsResult.data.color_code) {
                      localStorage.setItem('Nav-Color', settingsResult.data.color_code);
                    }
                    if (settingsResult.data.logo) {
                      localStorage.setItem('Nav-Icon', settingsResult.data.logo);
                    }
                  }
                });
              });
          } else if (result.data.input_required === 1 && result.data.customers && result.data.customers.length > 0) {
            FormData['customer_id'].required = true;
            FormData['user_type'].required = false;
            setShowLoginCredintial(false);
            setFormData(FormData);
          } else if (result.data.input_required === 1 && result.data.usertypes && result.data.usertypes.length > 0) {
            FormData['customer_id'].required = true;
            FormData['user_type'].required = true;
            setShowLoginCredintial(false);
            setFormData(FormData);
            handleChange({
              target: { id: 'customer_id', value: result.data.customer_id }
            });
          }
        } else {
          // navigate('/frontDeskList');
        }
      if (result?.data.input_required) {
        let tempArray = [];
        result.data.customers?.map((resultItem) => tempArray.push({
          label: resultItem.customer_name,
          value: resultItem.customer_id
        }));
        setCustomersList(tempArray);
      }

    });

  };

  const callLoginApi = (event) => {
    login(phoneNumberData.number, passwordData.value, null, event, null);
    // getLogin({
    //   endpoint: apiConstants.login,
    //   method: 'POST',
    //   data: {
    //     phone_number: phoneNumberData.number,
    //     password: passwordData.value,
    //     customer_id: customerData,
    //     user_type: fieldValue.value
    //   }
    // }).then((result) => {
    //   // console.log('result', result.data.user_type);
    //   if (result.data.user_type === 'client') {
    //     navigate('/patient');
    //   } else {
    //     navigate(`/${result.data.user_type}`);
    //   }
    // });
  };

  const onChangeOfInput = (data) => {
    setFieldValue(data);
  };
  const handleOnPhoneChange = (inputValue) => {
    const id = 'phone_number';
    const value = inputValue.replace(/[^\d]/g, '');
    const number = `+${value}`;

    if ((!value.match('^[0-9]*$')) || (!value.match(/^\d{12}$/))) {
      setPhoneNumberError(true);
      validatePhoneNumber(true);
    } else {
      setPhoneNumberError(false);
      validatePhoneNumber(false);
    }

    setFormData((prevState) => {
      const updatedData = { ...prevState };
      updatedData[id].value = value;
      updatedData[id].number = number;
      return updatedData;
    });
  };

  const validatePhoneNumber = (status) => {
    return status;
  };

  const handleChange = (e) => {
    if (e) {
      const { name, value } = e.target;
      setFormData(prevFormData => {
        const updatedFormData = { ...prevFormData };
        updatedFormData[name].value = value;
        return updatedFormData;
      });
      validate();
    }
  };

  const validate = (onSubmit = false) => {
    let result = true;
    const newErrorData = { ...errorData };

    Object.keys(formData).forEach((key) => {
      const { required, is_dirty, errorMessage } = formData[key];

      if (required === false) return;
      if (!onSubmit && !is_dirty) return;

      const validateValue = true;

      if ((passwordEnabled && key === 'otp') || (!passwordEnabled && key === 'password')) {
        newErrorData[key] = null;
      } else {
        if (validateValue !== true) {
          result = false;
          newErrorData[key] = errorMessage ? errorMessage : validateValue;
        } else {
          newErrorData[key] = null;
        }
      }
    });

    if (result === true) {
      // setError(false);
    } else {
      // setError(true);
      setErrorData(newErrorData);
    }

    return result;
  };

  return (
    <div className='bg-cover bg-center bg-no-repeat mt-[7%]'>
      <div className="block mx-auto col-span-1/3" style={{ marginLeft: '33.33%' }}>
        <div className='login-parent-div w-[38%] row bg-[#fefefe] items-center justify-center fixed top-1/2 left-1/2
        transform -translate-x-1/2 -translate-y-1/2 rounded-md p-[20px] shadow-lg hover:shadow-xl'>
          <img onClick={() => { navigate('/'); }}
            className='block mx-auto p-5 w-2/5 mt-5' src={Axon_Logo} alt="Axon Logo" />
          {showLoginCredintial && multipleUserType.length === 0 &&
            <div className='w-[90%] col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12'>
              <Formik initialValues={{}}>
                {({ values, setFieldValue }) => {
                  return <Form onSubmit={(e) => login(formData.phone_number,
                    formData.password, formData.otp, e, values?.customer_id?.value)}>
                    <div className="row login">
                      <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12"
                        style={{ marginBottom: '5%', display: 'grid' }}>
                        <span className="login-span">Phone Number*</span>
                        <PhoneInput
                          name="phone"
                          type="tel"
                          placeholder="Phone Number"
                          country={'in'}
                          onlyCountries={['in']}
                          autoFormat={false}
                          value={formData.phone_number.value}
                          onChange={(value) =>
                            handleOnPhoneChange(value)
                          }
                        />
                        {(phoneNumberError || errorData.phone_number) &&
                          <p className="text-red-500 text-xs leading-3 font-poppins">
                            Please enter a valid Phone Number
                          </p>
                        }
                      </div>
                    </div>
                    <div className='grid grid-cols-12' >
                      <div className='col-span-12 md:col-span-12 sm:col-span-12 xs:col-span-12 lg:col-span-12'>
                        {passwordEnabled && <span className="login-span">Password*</span>}
                        {passwordEnabled && (
                          <div className='relative'>
                            <Field
                              name="password"
                              type={passwordtype === 'password' ? 'password' : 'text'}
                              onChange={(e) => handleChange(e)}
                              placeholder="Password"
                              autoComplete="off"
                              value={formData.password.value}
                              className="border border-[#A9A9A9] opacity-70
                          outline-none w-full py-2 px-3 rounded-[5px]"
                            />
                            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                              <button
                                type="button"
                                onClick={showPassword}
                                className="focus:outline-none"
                              >
                                {passwordtype === 'password' ? (
                                  // Eye icon when password is visible
                                  <FontAwesomeIcon icon={faEye} className="text-gray-500" />
                                ) : (
                                  // Eye-slash icon when password is hidden
                                  <FontAwesomeIcon icon={faEyeSlash} className="text-gray-500" />
                                )}
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {customersList?.length > 0 &&
                      <div className='w-[100%] scheduleFilter' style={{ marginTop: '5%' }} >
                        <span className="login-span">Select Customer*</span>
                        <Field name="doctor">
                          {({ field }) => (
                            <ReactSelectFilters
                              {...field}
                              customStyles={{
                                valueContainer: provided => ({
                                  ...provided,
                                  backgroundColor: 'white',
                                  padding: '8px',
                                  height: '50px'
                                })
                              }}
                              options={customersList}
                              placeholder='Select Customer*'
                              name='doctor'
                              noDropDownIndicator={false}
                              // handleDropdownInputChange={handleOnDropdownChange}
                              onChange={(data) => {
                                console.log('data', data);
                                setFieldValue('customer_id', data);
                              }}
                              selectFieldValues={values.customer_id}
                              isMulti={false}
                              disableEnterKey
                            />
                          )}
                        </Field>
                      </div>}

                    <SharedButton className="bg-[#383678] text-white px-[50px] py-[5px]
                  rounded focus:outline-none text-xl mt-[58px] mx-auto flex" type="submit" title="LOGIN" />
                    <br />
                    <br />
                    {passwordEnabled &&
                      <Link to={'/forgotpassword'}
                        className="block text-sm text-red-500 hover:text-red-700 text-center">
                        Forgot Password
                      </Link>}
                  </Form>;
                }}
              </Formik>
            </div>}
          {multipleUserType.length > 0 &&
            <><div className='grid grid-cols-12'>
              <div className='col-span-12 md:col-span-12 sm:col-span-12 
              xs:col-span-12 lg:col-span-12 justify-items-center'>
                <ReactSelectFilters
                  // className='w-[50%]'
                  options={options}
                  placeholder='Select user type*'
                  name='user_type'
                  // value={fieldValue}
                  noDropDownIndicator={false}
                  // handleDropdownInputChange={ }
                  onChange={onChangeOfInput}
                  selectFieldValues={fieldValue}
                  isMulti={false}
                  disableEnterKey
                />
                {!fieldValue && <p className='mt-4 grid justify-items-center text-[#e90909]'>
                  Please select user type</p>}
                <div className='flex gap-[40px] w-[55%] mt-12'>
                  <button
                    type='text'
                    className="bg-[#b1adadbd] text-white px-[50px] py-[5px] w-[150px] h-[48px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center"
                    onClick={() => window.open('/login', '_self')}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    className="bg-[#383678] text-white px-[50px] py-[5px] w-[150px] h-[48px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center"
                    onClick={callLoginApi}>
                    Login
                  </button>
                </div>
                {/* <SharedButton className="bg-[#383678] text-white px-[50px] py-[5px]
                  rounded focus:outline-none text-xl mt-[58px] mx-auto flex" type="submit" title="LOGIN" /> */}
              </div>
            </div></>
          }
        </div>
      </div>
    </div >
  );
};

export default LoginPage;
