import React, { useState } from 'react';
import {Gallery} from 'react-grid-gallery';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import Captions from 'yet-another-react-lightbox/plugins/captions';
import 'yet-another-react-lightbox/plugins/captions.css';

const PacsModal = ({
  handleModalClose,
  data
}) => {
  const [index, setIndex] = useState(-1);

  const handleClick = (index) => setIndex(index);

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white p-3">
              <div className='flex flex-col gap-[10px]'>
                {data?.length > 0 && (
                  <div className="w-full p-[10px]">
                    <Gallery
                      images={data}
                      enableLightbox={true}
                      enableImageSelection={false}
                      rowHeight={80}
                      onClick={handleClick}
                    />
                     <Lightbox
                      slides={data}
                      open={index >= 0}
                      index={index}
                      close={() => setIndex(-1)}
                      plugins={[Captions]}
                    />
                  </div>
                )}
                <div className='flex justify-end'>
                  <button 
                    className='bg-gray-500 text-white px-[15px] py-[5px] rounded-[50px] 
                      text-[13px] text-nowrap mr-[10px]'
                    onClick={() => handleModalClose()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PacsModal;
