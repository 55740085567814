import React from 'react';
import PatientDetailBox from '../PatientDetailBox';
import CommonDisplayBox from './CommonDisplayBox';

const DisplaySymptoms = ({
  symptomsList,
  presentingIllness,
  duration
}) => {
  return (
    <div className='grid grid-cols-12 gap-[30px]'>
      <div className='col-span-6'>
        <PatientDetailBox title='Symptoms'>
          <CommonDisplayBox dataList={symptomsList} />
        </PatientDetailBox>
      </div>
      <div className='col-span-6'>
        <PatientDetailBox title='Presenting Illness'>
          <CommonDisplayBox dataList={presentingIllness} />
        </PatientDetailBox>
      </div>
      <div className='col-span-6'>
        <PatientDetailBox title='Duration'>
          <CommonDisplayBox dataList={duration} />
        </PatientDetailBox>
      </div>
    </div>
  );
};

export default DisplaySymptoms;