import React from 'react';

const EditReportDetailsModal = ({
  handleModalClose,
  data,
  setDataToEdit,
  handleSaveEditDetails
}) => {
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-lg"
          >
            <div className="bg-white p-3 flex flex-col">
              <div className='p-[20px] border-b-[1px] border-b-[#757776]'>
                <p className='text-[23px] text-[#757776] text-center'>Edit Report Details</p>
              </div>
              <div className='p-[30px]'>
                <div className='flex flex-col gap-[20px] items-center'>
                  <textarea
                    placeholder='Please enter the description of this report'
                    className='text-[13px] rounded-[12px] border-[1px] border-[#383678] w-full h-[90px] 
                      focus-visible:outline-none p-[10px] resize-none'
                    onChange={(e) => setDataToEdit({ ...data, description: e.target.value })}
                    rows={2}
                    value={data?.description}
                  ></textarea>
                  <div className='flex gap-[10px]'>
                    <button 
                      type='button'
                      className='bg-[#383678] text-white px-[15px] py-[5px] rounded-[20px]' 
                      style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)'}}
                      onClick={() => handleSaveEditDetails()}
                    >
                      Save
                    </button>
                    <button 
                      type='button'
                      className='bg-[#8c8c8c] text-white px-[15px] py-[5px] rounded-[20px]' 
                      onClick={() => handleModalClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditReportDetailsModal;
