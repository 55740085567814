/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { awsdetails } from '../../../../constants/awsdetails';

const Stethoscope = ({ appointmentId, handleShowStethoscope }) => {
  const [streaming, setStreaming] = useState();
  const [webSocket, setWebSocket] = useState();

  const listenToSocket = () => {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    let source;
    let ws = new WebSocket(`${awsdetails.wsUrl}?appointmentId=${appointmentId}`);
    setWebSocket(ws);
    ws.binaryType = 'arraybuffer';
    ws.onopen = function () {
      ws.send(JSON.stringify({ type: 'get_info' }));
    };
    let totalDuration = 0;
    ws.onmessage = (message) => {
      if (message.data instanceof ArrayBuffer) {
        setStreaming(true);
        audioCtx.decodeAudioData((message.data),
          (buffered) => {
            totalDuration += buffered.duration;
            source = audioCtx.createBufferSource();
            source.buffer = buffered;
            source.playbackRate.value = 0.6;
            source.connect(audioCtx.destination);
            source.start(totalDuration);
            }, () => {
          }).catch(exception => { console.log(exception); });

        } else if (typeof message.data === 'string') {
            let data = JSON.parse(message.data);
            if (data.type === 'close') {
              setTimeout(() => {
                handleShowStethoscope();
                // this.props.buttonHide();
               setStreaming(false);
              }, 50);

            } else if (data.type === 'get_info') {
                if (data.message !== null) {
                  setTimeout(() => {
                   setStreaming(true);
                  }, 50);
                }
            }
        }
    };
    ws.onclose = () => {
      handleShowStethoscope();
      // this.props.buttonHide();
      setStreaming(false);
    };
  };

  useEffect(() => {
    listenToSocket();
    return () => {
      if (webSocket) {
        webSocket?.onclose();
        handleShowStethoscope();
      }
    };
  }, []);

  return (
    <div>
      {streaming !== false && (
        <div className='w-full'>
          <div style={{ textAlign: 'center', float: 'left', height: '100%', width: '80%' }}>
            {
              streaming ?
                <div style={{ textAlign: 'center', fontFamily: 'Poppins', color: '#737575'}}>
                  <div id = 'svg1 '></div>
                  <svg 
                    xmlns='http://www.w3.org/2000/svg' 
                    version='1.1' width='400px' height='100px' viewBox='0 0 400 100'
                  >
                    <svg className=' svg1 '> 
                      <defs>
                        <linearGradient id='grad1' gradientUnits='userSpaceOnUse' x1='0' y1='0' x2='0' y2='90'>
                          <stop offset='10%' style={{'stop-color':'#383678'}} />
                          <stop offset='25%' style={{'stop-color':'#383678'}} />
                          <stop offset='40%' style={{'stop-color':'#383678'}} />
                          <stop offset='50%' style={{'stop-color':'#383678'}} />
                          <stop offset='60%' style={{'stop-color':'#383678'}} />
                          <stop offset='75%' style={{'stop-color':'#383678'}} />
                          <stop offset='110%' style={{'stop-color':'#383678'}} />
                        </linearGradient>
                      </defs>
                      <polyline points= ' 5,45 10,45 15,45 18,42 20,47 22,45 24,45 27,44 29,49 32,45 34,45 37,39 39,52 40,45 44,28 46,58 48,38 50,45 53,40 55,50 57,45 60,45 63,30 65,60 67,45 70,45 73,35 75,60 77,45 80,45 83,25 85,55 87,15 89,70 91,45 93,45 97,35 99,65 101,10 103,80 105,30 107,45 110,45 112,45 113,5 115,85 117,35 119,60 121,45 123,10 125,75 127,40 129,55 131,45 133,15 135,70 137,35 139,60 141,45 143,20 145,75 147,42 149,58 151,45 153,45 155,40 157,65 159,35 161,55 163,45 165,45 171,45 173,35 175,65 177,40 180,45 183,45 198,45 200,45 203,40 205,50 207,45 210,45 213,35 215,55 217,45 220,45 223,30 225,60 227,45 230,45 233,45 235,75 237,25 240,65 243,45 247,45 249,45 250,10 253,80 257,30 260,45 262,45 263,5 265,88 267,30 269,60 270,45 273,15 275,75 277,35 279,65 280,45 283,20 285,70 287,40 289,58 290,45 293,25 295,65 297,42 299,52 302,45 303,45 305,35 307,65 309,35 310,55 312,45 315,45 320,45 323,40 325,55 327,37 330,45 332,45 334,38 336,53 337,45 355,45 357,45 360,45 '
                        stroke='url(#grad1)' fill='none' />
                    </svg>
                  </svg>
                </div> 
              :
              <div style={{ textAlign: 'center', color: '#737575' }}>
                Waiting for the paramedic to plugin the device
              </div>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default Stethoscope;