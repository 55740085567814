import React, { useState, useEffect, useRef, createRef } from 'react';
import { Constants, useMeeting, usePubSub } from '@videosdk.live/react-sdk';
import { BottomBar } from './components/BottomBar';
import { SidebarConatiner } from '../components/sidebar/SidebarContainer';
import MemorizedParticipantView from './components/ParticipantView';
import { PresenterView } from '../components/PresenterView';
import { nameTructed, trimSnackBarText } from '../utils/helper';
import WaitingToJoinScreen from '../components/screens/WaitingToJoinScreen';
import ConfirmBox from '../components/ConfirmBox';
// import useIsMobile from '../hooks/useIsMobile';
// import useIsTab from '../hooks/useIsTab';
// import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { useMeetingAppContext } from '../MeetingAppContextDef';
import useMediaStream from '../hooks/useMediaStream';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import { awsdetails } from '../../../../constants/awsdetails';
import { useNavigate, useParams } from 'react-router-dom';
import { useChangeAvailableStatusMutation, useLoggerMutation } from '../../../../reducers/allReducer';
import { apiConstants } from '../../../../constants/api';
import MeetingEndModal from './MeetingEndModal';
import CaptureImageModal from './CaptureImageModal';

export function MeetingContainer({
  onMeetingLeave,
  setIsMeetingLeft,
  selectedMic,
  selectedWebcam,
  selectWebcamDeviceId,
  setSelectWebcamDeviceId,
  selectMicDeviceId,
  setSelectMicDeviceId,
  micEnabled,
  webcamEnabled
}) {
  const { useRaisedHandParticipants } = useMeetingAppContext();
  const { getVideoTrack } = useMediaStream();

  const bottomBarHeight = 60;

  const [containerHeight, setContainerHeight] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [localParticipantAllowedJoin, setLocalParticipantAllowedJoin] =
    useState(null);
  const [meetingErrorVisible, setMeetingErrorVisible] = useState(false);
  const [meetingError, setMeetingError] = useState(false);
  const [participant, setParticipant] = useState();

  const mMeetingRef = useRef();
  const containerRef = createRef();
  const containerHeightRef = useRef();
  const containerWidthRef = useRef();
  const { leave } = useMeeting();

  const pdfId = useSelector((state) => state?.selectedDropdownValues?.pdfId);
  const { appointmentId } = useParams();
  const [logger] = useLoggerMutation();
  const navigate = useNavigate();
  const [changeAvailableStatus] = useChangeAvailableStatusMutation();
  const [showEndingModal, setShowEndingModal] = useState(false);
  const [showCaptureModal, setShowCaptureModal] = useState(false);
  const [capturedImage, setCapturedImage] = useState();
  const userId = localStorage.getItem('user_id');
  const appointmentType = useSelector((state) => state?.selectedDropdownValues?.appointmentType);
  const { sideBarMode } = useMeetingAppContext();

  useEffect(() => {
    containerHeightRef.current = containerHeight;
    containerWidthRef.current = containerWidth;
  }, [containerHeight, containerWidth]);

  // const isMobile = useIsMobile();
  // const isTab = useIsTab();
  // const isLGDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1439 });
  // const isXLDesktop = useMediaQuery({ minWidth: 1440 });

  // const sideBarContainerWidth = isXLDesktop
  //   ? 400
  //   : isLGDesktop
  //     ? 360
  //     : isTab
  //       ? 320
  //       : isMobile
  //         ? 280
  //         : 240;

  useEffect(() => {
    containerRef.current?.offsetHeight &&
      setContainerHeight(containerRef.current.offsetHeight);
    containerRef.current?.offsetWidth &&
      setContainerWidth(containerRef.current.offsetWidth);

    window.addEventListener('resize', () => {
      containerRef.current?.offsetHeight &&
        setContainerHeight(containerRef.current.offsetHeight);
      containerRef.current?.offsetWidth &&
        setContainerWidth(containerRef.current.offsetWidth);
    });
  }, [containerRef]);

  const { participantRaisedHand } = useRaisedHandParticipants();

  const _handleMeetingLeft = () => {
    setIsMeetingLeft(true);
  };

  const _handleOnRecordingStateChanged = ({ status }) => {
    if (
      status === Constants.recordingEvents.RECORDING_STARTED ||
      status === Constants.recordingEvents.RECORDING_STOPPED
    ) {
      toast(
        `${
          status === Constants.recordingEvents.RECORDING_STARTED
          ? 'Meeting recording is started'
          : 'Meeting recording is stopped.'
        }`,
        {
          position: 'bottom-left',
          autoClose: 4000,
          hideProgressBar: true,
          closeButton: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        }
      );
    }
  };

  function onParticipantJoined(participant) {
    // Change quality to low, med or high based on resolution
    participant && participant.setQuality('high');
    setParticipant(participant);
    setIsParamedic(false);
  }

  function onEntryResponded(participantId, name) {
    if (mMeetingRef.current?.localParticipant?.id === participantId) {
      if (name === 'allowed') {
        setLocalParticipantAllowedJoin(true);
      } else {
        setLocalParticipantAllowedJoin(false);
        setTimeout(() => {
          _handleMeetingLeft();
        }, 3000);
      }
    }
  }

  async function onMeetingJoined() {
    const { changeWebcam, changeMic, muteMic, disableWebcam } =
      mMeetingRef.current;

    if (webcamEnabled && selectedWebcam.id) {
      await new Promise((resolve) => {
        let track;
        disableWebcam();
        setTimeout(async () => {
          track = await getVideoTrack({
            webcamId: selectedWebcam.id,
            encoderConfig: 'h540p_w960p'
          });
          changeWebcam(track);
          resolve();
        }, 500);
      });
    }

    if (micEnabled && selectedMic.id) {
      await new Promise((resolve) => {
        muteMic();
        setTimeout(() => {
          changeMic(selectedMic.id);
          resolve();
        }, 500);
      });
    }
  }
  function onMeetingLeft() {
    // console.log('onMeetingLeft');
    onMeetingLeave();
    setIsParamedic(true);
  }

  const _handleOnError = (data) => {
    const { code, message } = data;

    const joiningErrCodes = [
      4001, 4002, 4003, 4004, 4005, 4006, 4007, 4008, 4009, 4010
    ];

    const isJoiningError = joiningErrCodes.findIndex((c) => c === code) !== -1;
    const isCriticalError = `${code}`.startsWith('500');

    new Audio(
      isCriticalError
        ? 'https://static.videosdk.live/prebuilt/notification_critical_err.mp3'
        : 'https://static.videosdk.live/prebuilt/notification_err.mp3'
    )?.play();

    setMeetingErrorVisible(true);
    setMeetingError({
      code,
      message: isJoiningError ? 'Unable to join meeting!' : message
    });
  };

  const mMeeting = useMeeting({
    onParticipantJoined,
    onEntryResponded,
    onMeetingJoined,
    onMeetingLeft,
    onError: _handleOnError,
    onRecordingStateChanged: _handleOnRecordingStateChanged,
    onParticipantLeft
  });

  function onParticipantLeft() {
    setIsParamedic(true);
    setParticipant();
  }
  const isPresenting = mMeeting.presenterId ? true : false;

  useEffect(() => {
    mMeetingRef.current = mMeeting;
  }, [mMeeting]);

  usePubSub('RAISE_HAND', {
    onMessageReceived: (data) => {
      const localParticipantId = mMeeting?.localParticipant?.id;

      const { senderId, senderName } = data;

      const isLocal = senderId === localParticipantId;

      new Audio(
        'https://static.videosdk.live/prebuilt/notification.mp3'
      ).play();

      toast(`${isLocal ? 'You' : nameTructed(senderName, 15)} raised hand 🖐🏼`, {
        position: 'bottom-left',
        autoClose: 4000,
        hideProgressBar: true,
        closeButton: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light'
      });

      participantRaisedHand(senderId);
    }
  });

  usePubSub('CHAT', {
    onMessageReceived: (data) => {
      const localParticipantId = mMeeting?.localParticipant?.id;

      const { senderId, senderName, message } = data;

      const isLocal = senderId === localParticipantId;

      if (!isLocal) {
        new Audio(
          'https://static.videosdk.live/prebuilt/notification.mp3'
        ).play();

        toast(
          `${trimSnackBarText(
            `${nameTructed(senderName, 15)} says: ${message}`
          )}`,
          {
            position: 'bottom-left',
            autoClose: 4000,
            hideProgressBar: true,
            closeButton: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light'
          }
        );
      }
    }
  });
  
  const handleChangeDoctorStatus = (status) => {
    leave();
    navigate('/doctor');
    changeAvailableStatus({
      endpoint: apiConstants?.changeAvailableStatus,
      method: 'PUT',
      data: {
        available: status
      }
    });
  };

  const onEndCall = () => {
    let pdfName = pdfId || '';
    const url1 = awsdetails.serverUrl;
    let fileName = url1 + pdfName + '.pdf';
    fetch(fileName, {
        method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        logger({
          endpoint: apiConstants?.logger,
          method: 'POST',
          data: {
            appointment_id: appointmentId,
            msg: 'Doctor left the call.'
          }
        });
        handleChangeDoctorStatus(true);
      } else {
        setShowEndingModal(true);
      }
    });
  };

  const handleCapture = (url) => {
    setCapturedImage(url);
    setShowCaptureModal(true);
  };

  const [isParamedic, setIsParamedic] = useState(true);
  useEffect(() => {
    return () => {
      leave();
      onMeetingLeave();
    };
  }, []);

  return (
    <>
      {showEndingModal && (
        <MeetingEndModal
          handleModalClose={() => setShowEndingModal(false)}
          appointmentId={appointmentId}
          handleChangeDoctorStatus={handleChangeDoctorStatus}
          userId={userId}
        />
      )}
      {showCaptureModal && (
        <CaptureImageModal
          handleModalClose={() => setShowCaptureModal(false)}
          imageUrl={capturedImage}
          appointmentId={appointmentId}
          userId={userId}
          appointmentType={appointmentType}
        />
      )}
      <div className='pt-[20px]'>
        {isParamedic &&
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p style={{ fontSize: 24, fontWeight: 'bold' }}>
              Connecting to paramedic
            </p>
            <ReactLoading type={'bubbles'} style={{ fill: 'black', width: '9%', display: 'inline-block' }} />
          </div>
        }
        <div className='h-[500px] w-full'>
          <div ref={containerRef} className='h-full flex flex-col bg-black'>
            {typeof localParticipantAllowedJoin === 'boolean' ? (
              localParticipantAllowedJoin ? (
                <>
                  <div className='flex flex-1 flex-row bg-black'>
                    {!sideBarMode && (
                      <div className='flex flex-1'>
                        {isPresenting ? (
                          <PresenterView height={containerHeight - bottomBarHeight} />
                        ) : null}
                        {!isPresenting && (
                          <MemorizedParticipantView isPresenting={isPresenting} />
                        )}
                      </div>
                    )}

                    <SidebarConatiner
                      height={containerHeight - bottomBarHeight}
                      sideBarContainerWidth='100%'
                    />
                  </div>
                  {!sideBarMode && (
                    <BottomBar
                      bottomBarHeight={bottomBarHeight}
                      setIsMeetingLeft={setIsMeetingLeft}
                      selectWebcamDeviceId={selectWebcamDeviceId}
                      setSelectWebcamDeviceId={setSelectWebcamDeviceId}
                      selectMicDeviceId={selectMicDeviceId}
                      setSelectMicDeviceId={setSelectMicDeviceId}
                      participant={participant}
                      onEndCall={onEndCall}
                      handleCapture={handleCapture}
                    />
                  )}
                </>
              ) : (
                <></>
              )
            ) : (
              !mMeeting.isMeetingJoined && <WaitingToJoinScreen />
            )}
            <ConfirmBox
              open={meetingErrorVisible}
              successText='OKAY'
              onSuccess={() => {
                setMeetingErrorVisible(false);
              }}
              title={`Error Code: ${meetingError.code}`}
              subTitle={meetingError.message}
            />
          </div>
        </div>
      </div>
    </>
  );
}
