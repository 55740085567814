import React, { useState } from 'react';
import SakhaLogo from '../../images/Sakha-Global.png';
import Toggle from 'react-toggle';
import 'react-toggle/style.css'; // for ES6 modules
import Profile from '../../images/Male.png';
import moment from 'moment';
import { useChangeStatusMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import { useSelector } from 'react-redux';
import { getDifference } from './OpdAppointment/PatientDetails/PatientProfle';
import { useNavigate } from 'react-router-dom';
import { Colorcode } from '../common/utils/Colorcode';

const Sidebar = () => {
  const customerColor = Colorcode();
  const [changeStatus] = useChangeStatusMutation();
  const [isToggle, setIsToggle] = useState(true);
  const isPrescription = window?.location?.pathname?.includes('prescription');
  const patientDetails = useSelector((state) => state?.appointmentDetails?.patientDetails);
  const navigate = useNavigate();
  const userName = localStorage.getItem('username') || '';
  const toggleBar = () => {
    changeStatus({
      endpoint: apiConstants.changeDoctorStatus,
      method: 'PUT',
      data:
      {
        available: isToggle ? false : true
      }
    }).then((res) => {
      console.log('res', res);
    }).catch();
    setIsToggle(!isToggle);
  };

  let currentHour = moment().hour();
  let hourLabel;
  if (currentHour >= 4 && currentHour < 12) {
    hourLabel = 'Morning';
  } else if (currentHour >= 12 && currentHour < 16) {
    hourLabel = 'AfterNoon';
  } else if (currentHour >= 16 && currentHour < 20) {
    hourLabel = 'Evening';
  } else if (
    (currentHour >= 20 && currentHour <= 24) ||
    (currentHour > 0 && currentHour < 4)
  ) {
    hourLabel = 'Night';
  }

  const handleOnClickMedicalHistory = () => {
    const url = `/doctor/prescription/${patientDetails?.appointment_id}/medical-history/view`;
    navigate(url);
  };

  return (
    <div className='sidebar-box bg-[#fff] h-[100vh] flex flex-col p-[30px] gap-[20px]'>
      <div className='ml-[28px]'>
        <img src={SakhaLogo} alt='' width={170} height={65} />
      </div>
      {!isPrescription ? (
        <div>
          <div className='flex mt-[60px] gap-[10px] items-center'>
            <div className='text-sm' style={{ color: customerColor[0] }}>
              {'Welcome'}
            </div>
            <hr className='w-[60%]' />
          </div>
          <div className='text-sm fw-500 pt-[30px] text-[#4D4D4D]'>
            {'Good' + ' ' + hourLabel + '!'}
          </div>
          <div>
            <img src={Profile} style={{ width: '60px', height: '0px', marginLeft: '30%', marginTop: '0px' }} />
          </div>
          <div className='doctor-name text-[#383678] text-[26px] font-bold mt-[10px]'>{'Dr.' + ' ' + userName}</div>
          <div className='doctor-status-outline flex items-center mt-[10px]'>
            <div className='doctor-status'>{isToggle ? 'Online' : 'Offline'}</div>
            <Toggle
              className='status-tog w-[80px] ml-[10px]'
              checked={isToggle}
              onChange={() => toggleBar()}
              icons={{
                checked: <span style={{ fontFamily: 'Poppins' }}></span>,
                unchecked: <span style={{ fontFamily: 'Poppins' }}></span>
              }}
            />
          </div>
        </div>
      ) : (
        <div className='rounded-[20px] bg-[#f6f5f59e] pt-[30px] p-[20px]' style={{ boxShadow: '2px 2px 2px #e6e6e6' }}>
          <div className='flex flex-col gap-[5px]'>
            <p className='text-[14px] text-[#383678] border-b-[1px] border-b-[#ddd] p-[10px]'>Patient Details</p>
            <div className='flex gap-[10px] flex-wrap pt-[10px]'>
              <img src={Profile} alt='' className='w-[90px] h-[90px] rounded-[50px]' />
              <p className='fw-500 text-[20px] text-[#737575]'>
                {patientDetails?.first_name + ' ' + patientDetails?.last_name}
              </p>
            </div>
            <p className='text-[14px] text-[#383678]'>
              Patient ID:
              <span className='text-[#808080] fw-500'> {patientDetails?.client_id}</span>
            </p>
            <p className='text-[14px] text-[#808080] fw-500'>
              {patientDetails?.gender} | {getDifference(patientDetails?.dob, null, 'years')}
            </p>
            <p className='text-[14px] text-[#808080] fw-500'>{patientDetails?.email_id}</p>
            {!window.location?.pathname?.includes('medical-history') && (
              <button
                className='bg-blue-600 text-white px-[15px] py-[5px] mt-[10px] fw-500
				w-[140px] rounded-[50px] text-[12px] text-nowrap'
                style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                onClick={() => handleOnClickMedicalHistory()}
              >
                Medical History
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
