export const apiConstants = {
  adminCount: '/api/admin/count',
  changeDoctorStatus: '/api/doctor/changestatus',
  login: '/api/common/login',
  getSettings: '/api/common/getSettings',
  getAgents: '/api/admin/getallagents',
  getRolePreviliges: '/api/admin/getroleprivilegeaccess',
  upcomingConsultsList: '/api/admin/getallfutureappointments',
  departmentsList: '/api/admin/getallmedicaldepartments',
  medicalHistoryList: '/api/admin/getallhistoryquestions',
  adminFrontDeskList: '/api/admin/getallagents',
  adminParamedicList: '/api/admin/getallparamedics',
  adminDoctorsList: '/api/admin/getalldoctors',
  adminPatientList: '/api/admin/getallpatients',
  adminKiosksList: '/api/admin/getallkiosks',
  addkiosk: '/api/admin/addkiosk',
  updateKiosk: '/api/admin/updatekiosk', // updatekiosk
  getUsersForAssociation: '/api/admin/getusersforassociation', // getusersforassociation
  associateUsersWithKiosk: '/api/admin/associateuserswithkiosk',
  getVitalsAnalytics: '/api/admin/getvitalsanalytics',
  getKioskListById: '/api/admin/getkiosk',
  adminSettings: '/api/common/logo',
  doctorsDetailView: '/api/admin/getdoctor',
  getallclients: '/api/agent/getallclients',
  getAvaialbleDoctors: '/api/agent/getavailabledoctorsfortoday',
  getAdminVerifySlots: '/api/admin/getdoctortoverify',
  verifyDoctorSlots: '/api/admin/verifydoctorslots',
  verifyAgentSlots: '/api/agent/getpatientsforverify',
  createPatient: '/api/customer/createUser',
  getUpcomingConsultants: '/api/doctor/getdoctordashboarddetails',
  getCount: '/api/admin/count',
  getDoctorSlotsForDate: '/api/agent/getdoctorandslotsfordate',
  schedulekioskappointment: '/api/agent/schedulekioskappointment',
  getAllAgentDoctor: '/api/agent/getalldoctors',
  updatekioskappointment: '/api/agent/updatekioskappointment',
  getConceptBloodGroup: '/api/common/getConceptBloodGroup',
  getallmedicaldepartments: '/api/agent/getallmedicaldepartments',
  getpatientswithsamephone: '/api/agent/getpatientswithsamephone',
  getconsultsfortoday: '/api/agent/getconsultsfortoday',
  getappointmentusers: '/api/paramedic/getappointmentusers',
  getpatientdocuments: '/api/paramedic/getpatientdocuments',
  uploadpatientdocuments: '/api/paramedic/uploadpatientdocuments',
  deletepatientdocument: '/api/paramedic/deletepatientdocument',
  updatepatientdocument: '/api/paramedic/updatepatientdocument',
  getallpastconsults: '/api/agent/getallpastconsults',
  getAppointmentType: '/api/doctor/getappointmenttype',
  getMedicalCertificateDetails: '/api/doctor/getmedicalcertificatedetails',
  saveMedicalCertificateDetails: '/api/doctor/medicalcertificatedetails',
  sendMedicalCertificate: '/api/doctor/sendmedicalcertificate',
  getPatientDetailsByAppointmentId: '/api/doctor/getpatientdetailsbyappointmentid',
  searchSymptomList: '/api/doctor/searchsymptomlist',
  deleteAppointmentData: '/api/appointment/deleteappointmentdata',
  addAppointmentData: '/api/appointment/addappointmentdata',
  listAppointmentData: '/api/appointment/listappointmentdata',
  searchInvestigationList: '/api/doctor/searchinvestigationlist',
  searchDiagnosisList: '/api/doctor/searchdiagnosislist',
  getPastConsultants: '/api/doctor/getallpastconsults',
  uploadPhoto: '/api/common/upload_photo',
  getAllAdminList: '/api/admin/getalladmins',
  getAllCustomerList: '/api/admin/getallcustomers',
  getAllAppointments: '/api/admin/getallconsultdetails',
  getDrugTypes: '/api/doctor/getdrugtypes',
  getMedicines: '/api/doctor/medicines',
  getPrescriptionData: '/api/doctor/getprescriptiondata',
  sendEmailToPatient: '/api/appointment/sendmailtopatient',
  getPatientMedicalHistory: '/api/doctor/getpatientmedicalhistory',
  addPatientMedicalHistory: '/api/agent/addpatientmedicalhistory',
  addCustomer: '/api/admin/addcustomer',
  createAdmin: '/api/admin/CreateUser/admin',
  adminCreateParamedic: '/api/admin/CreateUser/paramedic/',
  adminCreateFrontDesk: '/api/admin/CreateUser/agent/',
  adminCreateDoctor: '/api/admin/CreateUser/doctor/',
  admicDoctorCertificateUpload: '/api/doctor/uploadDoctorDocument',
  getcustomers: '/api/admin/getcustomers',
  getAppointmentDetails: '/api/doctor/getAppointmentDetials',
  getPatientReports: '/api/doctor/getpatientreports',
  getCoordinates: '/api/common/ecg/getcoordinates',
  getPatientPastConsults: '/api/doctor/getpatientpastconsults',
  getFrontDeskView: '/api/admin/getagent',
  getParamedicView: '/api/admin/getparamedic',
  getcustomerView: '/api/admin/getcustomer',
  getadminView: '/api/admin/getadmin',
  updateagent: '/api/admin/updateagent',
  updateparamedic: '/api/admin/updateparamedic',
  updatedoctor: '/api/admin/updatedoctor',
  getSignedUrlForDoctorCertificate: '/api/admin/getSignedUrlForDoctorCertificate',
  updateadmin: '/api/admin/updateadmin',
  updatecustomer: '/api/admin/updatecustomer',
  getPatientVitals: '/api/doctor/getpatientvitals',
  getPatientVitalHistoryInConsult: '/api/doctor/getvitalhistoryinconsult',
  getkiosksforassociation: '/api/admin/getkiosksforassociation',
  updatekioskforuser: '/api/admin/updatekioskforuser',
  getkiosksfordoctorassociation: '/api/admin/getkiosksfordoctorassociation',
  getDoctorSlots: '/api/doctor/getdoctorschedule',
  addSlot: '/api/doctor/adddoctorschedule',
  updateSlot: '/api/doctor/updatedoctorschedule',
  deleteSlot: '/api/doctor/deletedoctorschedule',
  addBodyPainDetails: '/api/agent/addbodypaindetails',
  onAttachmentUpdate: '/api/agent/onAttachmentUpdate',
  onAttachmentDelete: '/api/agent/onAttachmentDelete',
  onAttachmentView: '/api/agent/onAttachmentView',
  getSignedUrlForAttachments: '/api/agent/getSignedUrlForAttachments',
  saveAttachment: '/api/agent/saveAttachment',
  doctorsbulkupload: '/api/admin/doctorsbulkupload',
  getdoctorlistforslots: '/api/admin/getdoctorlistforslots',
  getsamplecsvdoctor: '/api/admin/getsamplecsvdoctor',
  doctorschedulebulkupload: '/api/admin/doctorschedulebulkupload',
  verifydoctor: '/api/admin/verifydoctor',
  saveLogo: '/api/common/save_logo',
  getAllKioskUsersList: '/api/admin/getallkioskusers',
  getFutureConsults: '/api/doctor/getfutureconsults',
  getDoctorDocuments: '/api/doctor/getdoctordocuments',
  uploadDoctorDocument: '/api/doctor/uploadDoctorDocument',
  getAllAppearance: '/api/common/getAppearance',
  getDeviceOrderStatus: '/api/admin/getdeviceorderstatus',
  getAllSettings: '/api/common/getSettings',
  appearance: '/api/common/appearance',
  getdashboardanalytics: '/api/admin/getdashboardanalytics',
  changeAvailableStatus: '/api/doctor/changestatus',
  logger: '/api/common/logger',
  reasonForDisconnect: '/api/appointment/reasonfordisconnect',
  getdoctorschedule: '/api/admin/getdoctorschedule',
  adminAddDoctorSlot: '/api/admin/adddoctorschedule',
  adminUpdateDoctorSlot: '/api/admin/updatedoctorschedule',
  adminDeleteDoctorSlot: '/api/admin/deletedoctorschedule',
  getAgentSlots: '/api/agent/getdoctorschedule',
  getSignedUrlForAttachmentsDoctor: '/api/doctor/getSignedUrlForAttachments',
  saveAttachmentDoctor: '/api/doctor/saveAttachment',
  addDoctorVitals: '/api/doctor/adddoctorvitals',
  updatedeviceorderstatus: '/api/admin/updatedeviceorderstatus',
  associatedoctorswithkiosks: '/api/admin/associatedoctorswithkiosks',
  getOtpDeatils: '/api/common/generateOTP',
  verifyOtp: '/api/common/forgotPassword/verify',
  resetPassword: '/api/common/forgotPassword/resetPassword',
  gettabulardashboardanalytics: '/api/admin/gettabulardashboardanalytics',
  getvideosdkcreds: '/api/common/getvideosdkcreds',
  updaterecordingdetails: '/api/common/updaterecordingdetails',
  addHistoryQuestion: '/api/admin/addhistoryquestion',
  getSampleCsv: '/api/admin/getsamplecsvforscheduling',
  getHistoryQuestion: '/api/admin/gethistoryquestion',
  editHistoryQuestion: '/api/admin/edithistoryquestion'
};