import React from 'react';
import AppointmentType from './AppointmentType';
import PatientDetails from './PatientDetails';
import VideoSdk from '../VideoSdk';

const OpdAppointment = () => {
  return (
    <div className='grid grid-cols-12'>
      <div className='sm:col-span-6 md:col-span-5 p-[20px]'>
        <AppointmentType />
        <div className='text-center'>
          <div className='h-full w-full relative overflow-hidden text-center'>
            <div className='relative'>
              {/* <div className='w-full h-[500px] pt-[20px]'> */}
                <VideoSdk />
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className='sm:col-span-12 md:col-span-7 px-[20px] py-[20px]'>
        <PatientDetails />
      </div>
    </div>
  );
};

export default OpdAppointment;
