import React from 'react';
import PatientDetailBox from '../PatientDetailBox';
import CommonDisplayBox from './CommonDisplayBox';
import { awsdetails } from '../../../../../constants/awsdetails';

const DisplayDiagnosis = ({
  diagnosis,
  differentialDiagnosis,
  provisionalDiagnosis,
  patientReports,
  isPastConsultModal
}) => {
  const maxLengthForName = isPastConsultModal ? 10 : 20;
  const viewReport = (name) => {
    const awsUrl = awsdetails?.awsUrl;
    window.open(`${awsUrl}/${name}`);
  };

  return (
    <div className='grid grid-cols-12 gap-[30px]'>
      <div className='col-span-6 min-h-35'>
        <PatientDetailBox title='Diagnosis'>
          <CommonDisplayBox dataList={diagnosis} />
        </PatientDetailBox>
      </div>
      <div className='col-span-6 min-h-35'>
        <PatientDetailBox title='Differential Diagnosis'>
          <CommonDisplayBox dataList={differentialDiagnosis} />
        </PatientDetailBox>
      </div>
      <div className='col-span-6 min-h-35'>
        <PatientDetailBox title='Provisional Diagnosis'>
          <CommonDisplayBox dataList={provisionalDiagnosis} />
        </PatientDetailBox>
      </div>
      <div className='col-span-6 min-h-35'>
        <PatientDetailBox title='Reports'>
          {patientReports && patientReports?.length > 0 && (
            <div className='flex flex-col gap-[10px]'>
              {patientReports?.map((report, index) => (
                <div key={index} className='grid grid-cols-12 border-b-[1px] border-b-[#eeebeb] p-[5px]'>
                  <div className='col-span-6'>
                    <p className='pl-[20px]'>
                      {report?.original_name.length > maxLengthForName ?
                        report?.original_name?.substring(0, (maxLengthForName - 1))
                        +'..' : report?.original_name}
                    </p>
                  </div>
                  <div className='col-span-6'>
                    <p className='cursor-pointer' onClick={() => viewReport(report?.attachment_name)}>View</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </PatientDetailBox>
      </div>
    </div>
  );
};

export default DisplayDiagnosis;
