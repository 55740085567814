import { createSlice } from '@reduxjs/toolkit';

export const medicalHistorySlice = createSlice({
  name: 'medicalHistory',
  initialState: {
    medicalHistory: [],
    bodyPainDetails: [],
    attachmentDetails: [],
    medicalHistoryDetailsToDisplay: [],
    selectedTabTitle: '',
    medicalHistoryPrescription: [],
    bodyPainDetailsPrescription: [],
    attachmentDetailsPrescription: [],
    selectedTabTitlePrescription: '',
    medicalHistoryPrescriptionToDisplay: []
  },
  reducers: {
    saveMedicalHistoryData: (state, action) => {
      const { fieldValue, fieldName } = action.payload;
      state[fieldName] = fieldValue;
    },
    updateMedicalHistoryDetails: (state, action) => {
      const { depth, dataToReplace, parentId } = action.payload;
      switch(depth) {
        case 1:
          state.medicalHistoryDetailsToDisplay.questions[parentId].value = dataToReplace;
          return;
        case 2:
          state.medicalHistoryDetailsToDisplay.questions[parentId] = dataToReplace;
          return;
        default:
          return;
      }
    },
    updateMedicalHistoryData: (state, action) => {
      const index = state.medicalHistory.findIndex((d) => d.medical_history_section_name === state.selectedTabTitle);
      if (index >= 0 ) {
        state.medicalHistory[index].json_value = action.payload;
      } 
    },
    updateBodyPainDetails: (state, action) => {
      const { id } = action.payload;
      const index = state.bodyPainDetails.findIndex((d) => d.body_part_number === id);
      if (index >= 0) {
        if (state.bodyPainDetails[index]?.client_selected_pain_id) {
          state.bodyPainDetails[index].client_selected_pain_id = null;
        } else {
          state.bodyPainDetails[index].client_selected_pain_id = id;
        }
      }
    },
    updateAttachmentDetails: (state, action) => {
      const { id, description } = action.payload;
      const index = state.attachmentDetails.findIndex((d) => d.attachment_id === id);
      state.attachmentDetails[index].attachment_description = description;
    },
    deleteAttachment: (state, action) => {
      const { id } = action.payload;
      state.attachmentDetails = state.attachmentDetails.filter((d) => d.attachment_id !== id);
    },
    addNewAttachment: (state, action) => {
      state.attachmentDetails.push(action.payload);
    }
  }
});

export const {
  updateMedicalHistoryDetails,
  saveMedicalHistoryData,
  updateMedicalHistoryData,
  updateBodyPainDetails,
  updateAttachmentDetails,
  deleteAttachment,
  addNewAttachment
} = medicalHistorySlice.actions;
export default medicalHistorySlice.reducer;
