import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import AdminImage from '../../images/admin_icon.png';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import {
  useCreateKioskMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import ConfirmModal from '../common/ConfirmModal';

const Schema = Yup.object().shape({
  customer_name: Yup.string().required('Please Enter Kiosk Name'),
  code: Yup.string().max(10).required('Please Enter Kiosk Code'),
  address: Yup.string().required('Please Enter Address')
});

const CreateKiosk = () => {
  const [createKiosk] = useCreateKioskMutation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    let timeoutId;
    if (showModal) {
      timeoutId = setTimeout(() => {
        setShowModal(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showModal]);

  const handleSubmit = (values) => {
    createKiosk({
      endpoint: `${apiConstants.addkiosk}`,
      method: 'POST',
      data: {
        data: {
          name: values.customer_name,
          code: values.code,
          address: values.address,
          status: values.status
        }
      }
    }).then((data) => {
      setShowModal(true);
      if (data?.error) {
        setMessage(data?.error?.data?.error);
        setError(true);
      } else {
        setMessage('Created Successfully');
        navigate('/admin/kioskslist');
      }
    }).catch(() => {
    });
  };

  return (
    <>
      {showModal &&
        <ConfirmModal msg={message} isError={isError} />
      }
      <div className='my-[2%] mx-[5%]'>
        <div className='bg-[#f4f4f4] grid grid-cols-12 mt-28 rounded-[16px] p-[20px]'>
          <div className='col-span-6'>
            <div className='text-[#737575] text-[40px] mt-[25px] ml-48 font-[700]'>Create Kiosk</div>
            <img className="h-auto max-w-lg mx-auto ml-36 mt-10" src={AdminImage} alt="image description" />
          </div>
          <div className='col-span-6'>
            <Formik
              initialValues={{
                customer_name: '',
                code: '',
                address: '',
                status: 'Active'
              }}
              validationSchema={Schema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form className='flex flex-col items-center gap-[30px] mt-[30px]'>
                  <div className='relative w-[55%]'>
                    <Field
                      name="customer_name"
                      type='text'
                      placeholder="Kiosk Name*"
                      autoComplete="off"
                      className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2"
                    />
                    <ErrorMessage
                      name="customer_name" component="div" className='text-[red] text-[12px]' />
                  </div>
                  <div className='relative w-[55%]'>
                    <Field
                      name="code"
                      type='text'
                      placeholder="Kiosk Code*"
                      autoComplete="off"
                      className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                                        outline-none w-full py-2"
                    />
                    <ErrorMessage name="code" component="div" className='text-[red] text-[12px]' />
                  </div>

                  <div className='w-[55%]'>
                    <Field
                      name="address"
                      type='text'
                      placeholder="Address*"
                      autoComplete="off"
                      className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                                        outline-none w-full py-2"
                    />
                    <ErrorMessage name="address" component="div"
                      className='text-[red] text-[12px]' />
                  </div>
                  <div className='w-[55%]'>
                    <span className='mr-40'>Kiosk Status*</span>
                    <div className='flex gap-4 mt-[16px]'>
                      <div className='flex-[1]'>
                        <div className='flex'>
                          <Field
                            name="status"
                            type='radio'
                            value={'Active'}
                            placeholder="Active"
                            className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                                                    outline-none mr-2 cursor-pointer"
                          />
                          <div className='opacity-60'>Active</div>
                        </div>
                        <ErrorMessage
                          name="status" component="div" className='text-[red] text-[12px]' />
                      </div>
                      <div className='flex-[1]'>
                        <div className='flex'>
                          <Field
                            name="status"
                            type='radio'
                            value={'Inactive'}
                            placeholder="InActive"
                            className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                                                     outline-none mr-2 cursor-pointer"
                          />
                          <div className='opacity-60'>InActive</div>
                        </div>
                        <ErrorMessage
                          name="status" component="div" className='text-[red] text-[12px]' />
                      </div>
                    </div>
                  </div>

                  <div className='flex gap-[40px] w-[55%]'>
                    <button
                      type='submit'
                      className="bg-[#383678] text-white w-[150px] h-[40px] items-center
                                        rounded-[20px] focus:outline-none text-[16px] flex justify-center"
                      style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                    >
                      Submit
                    </button>
                    <button
                      type='text'
                      className="bg-[#383678] text-white w-[150px] h-[40px] items-center
                                        rounded-[20px] focus:outline-none text-[16px] flex justify-center"
                      style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                      onClick={() => navigate('/admin/kioskslist')}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

          </div>
        </div>
      </div >
    </>
  );
};

export default CreateKiosk;