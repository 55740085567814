import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import {
  faXmark, faUser, faEnvelope, faCreditCard
} from '@fortawesome/free-solid-svg-icons';
import {
  useLazyGetAllAppearanceQuery,
  useLazyGetDeviceOrderStatusDataQuery,
  useLazyGetAllSettingsQuery,
  useAppearanceMutation,
  useUpdatedeviceorderstatusMutation,
  useLazyGetvideosdkcredsQuery,
  useUpdaterecordingdetailsMutation
} from '../../reducers/allReducer';
import SharedButton from '../common/SharedButton';
import { apiConstants } from '../../constants/api';
import { awsdetails } from '../../constants/awsdetails';
import { SketchPicker } from 'react-color';
import Clock from 'react-clock';
import 'react-clock/dist/Clock.css';
import moment from 'moment';
import bp from '../../images/bp.png';
import digitalThermometer from '../../images/digitalThermometer.png';
import glucose from '../../images/glucose.png';
import oxygen from '../../images/oxygen.png';
import SettingsModal from './SettingsModal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReactSelectFilters from '../common/ReactSelectFilters';
import editIcon from '../../images/editIcon.png';

const Data = [
  {
    name: 'Logo/Image for PDF',
    tabName: 'Logo_Image',
    modalName: 'Logo_Image'
  },
  {
    name: 'Nav Color',
    tabName: 'nav_color',
    modalName: 'nav_color'
  },
  {
    name: 'Account Info.',
    tabName: 'account_info',
    modalName: 'account_info'
  },
  {
    name: 'Time Zone',
    tabName: 'time_zone',
    modalName: 'time_zone'
  },
  {
    name: 'Prescription Header',
    tabName: 'Prescription_Header',
    modalName: 'Prescription_Header'
  },
  {
    name: 'Prescription Footer',
    tabName: 'Prescription_Footer',
    modalName: 'Prescription_Footer'
  },
  {
    name: 'Consult Types',
    tabName: 'Consult_Types',
    modalName: 'Consult_Types'
  },
  {
    name: 'Device Manager',
    tabName: 'Device_Manager',
    modalName: 'Device_Manager'
  },
  // {
  //   name: 'Manage Concepts',
  //   tabName: 'Manage_Concepts',
  //   modalName: 'Manage_Concepts'
  // },
  {
    name: 'General Settings',
    tabName: 'General_Settings',
    modalName: 'General_Settings'
  },
  {
    name: 'Device Order Status',
    tabName: 'Device_Order',
    modalName: 'Device_Order'
  },
  {
    name: 'Video SDK',
    tabName: 'Video_SDK',
    modalName: 'Video_SDK'
  }
];

const ActiveTabName = (activeTab) => {
  const data = Data.find((data) => data?.tabName === activeTab);
  return data?.name;
};

const timezones = [
  {
    name: 'India Standard Time', value: 330, timeHr: 5, timeMin: 30
  },
  {
    name: 'Greenwich Mean Time/Universal Coordinated Time', value: 0, timeHr: 0, timeMin: 0
  }
];

const CustomerConfiguration = () => {
  const { id } = useParams();

  const [getAllAppearance] = useLazyGetAllAppearanceQuery();
  const [getDeviceOrderStatusData] = useLazyGetDeviceOrderStatusDataQuery();
  const [getAllSettingsData] = useLazyGetAllSettingsQuery();
  const [updatedeviceorderstatus] = useUpdatedeviceorderstatusMutation();
  const [getvideosdkcreds] = useLazyGetvideosdkcredsQuery();
  const [updaterecordingdetails] = useUpdaterecordingdetailsMutation();

  const [activeTab, setActiveTab] = useState('Logo_Image');
  const [activeModal, setActiveModal] = useState(null);
  const [appearanceData, setAppearanceData] = useState({});
  const [logoTabDetails, setLogoTabDetails] = useState({});
  const [deviceOrderStatus, setDeviceOrderStatus] = useState({});
  const [customerColorCode, setCustomerColorCode] = useState('');
  const [headerContent, setHeaderContent] = useState('');
  const [footerContent, setFooterContent] = useState('');
  const [consultType, setConsultType] = useState({
    opd: false,
    remote: false
  });
  const [deviceData, setDeviceData] = useState([]);
  const [opdType, setOpdType] = useState('');
  const [minuteTimeZone, setMinuteTimeZone] = useState();
  const [CurrentTZ, setCurrentTZ] = useState();
  const [date, setDate] = useState(new Date());
  const [timeZone, setTimeZone] = useState();
  const [genSettings, setGenSettings] = useState({
    doctorSlot: '',
    isPatientOTP: '',
    isDoctorOTP: '',
    isParamedicSlot: '',
    isStethPoint: '',
    isAdvanceTele: ''
  });
  const [deviceManager, setDeviceManager] = useState({
    thermo: '',
    glucose: '',
    oxygen: '',
    pressure: ''
  });
  const [videoSDKData, setVideoSDKData] = useState([]);
  const [isRecording, setIsRecording] = useState(true);
  const [tokenData, setTokenData] = useState(videoSDKData?.token);
  const [secretKey, setSecretKey] = useState(videoSDKData?.secret_key);
  const [apiKey, setApiKey] = useState(videoSDKData?.api_key);

  const timeOption = [
    { value: 'IN', label: 'India Standard Time' },
    { value: 'GMT', label: 'Greenwich Mean Time/Universal Coordinated Time' }
  ];

  const deviceOption = [
    // { value: 'BOLT', label: 'BOLT' },
    { value: 'MINTTI', label: 'MINTTI' }
  ];

  const [appearance] = useAppearanceMutation();

  const handleAction = (tabName) => {
    setActiveModal(tabName);
  };

  const handleDeviceOrderStatus = () => {
    updatedeviceorderstatus({
      endpoint: apiConstants.updatedeviceorderstatus,
      method: 'PUT',
      data: {
        customer_id: id,
        device_data: deviceData
      }
    }).then(() => {
      handleData();
    });
    setActiveModal(null);
  };
  const handleSave = (description, name) => {
    appearance({
      endpoint: apiConstants.appearance,
      method: 'POST',
      data: {
        customer_id: id,
        description: description,
        name: name
      }
    }).then((result) => {
      console.log('resultdata', result?.data);
      handleData();
    });
    setActiveModal(null);
    setCustomerColorCode('');
  };

  const handleVideoRecodData = () => {
    getvideosdkcreds({
      endpoint: `${apiConstants.getvideosdkcreds}/${id}`
    }).then((result) => {
      setVideoSDKData(result.data);
      setTokenData(result.data?.token);
      setSecretKey(result.data?.secret_key);
      setApiKey(result.data?.api_key);
      setIsRecording(result?.data?.is_recording);
    }).catch();
  };

  const updateVideoRecordData = () => {
    let methodResponse = (videoSDKData?.token === undefined
      && videoSDKData?.secret_key === undefined
      && videoSDKData?.api_key === undefined);

    updaterecordingdetails({
      endpoint: apiConstants.updaterecordingdetails,
      method: 'POST',
      data: {
        customer_id: id,
        is_recording: isRecording === 1 ? true : false,
        token: tokenData,
        secret_key: secretKey,
        api_key: apiKey,
        method: methodResponse === true ? 'insert' : 'update'
      }
    }).then(() => {
      handleVideoRecodData();
    });
    setActiveModal(null);
  };

  const handleData = () => {
    getAllAppearance({
      endpoint: `${apiConstants.getAllAppearance}/${id}`,
      method: 'GET'
    }).then((result) => {
      setAppearanceData(result?.data);
      setLogoTabDetails(result?.data[0]);
      let Hr, Min;
      if (result?.data.length > 0) {
        Object.keys(result?.data).map(keys => {
          if (result?.data[keys].name === 'timezone_hours') {
            Hr = result?.data[keys].description * 60;
          } else if (result?.data[keys].name === 'timezone_minutes') {
            Min = result?.data[keys].description;
          }
        });

        if (Hr && Min) {
          setMinuteTimeZone(parseInt(Hr) + parseInt(Min));
          setTimeZone(timeOption[0]);
        } else {
          setMinuteTimeZone(0);
          setTimeZone(timeOption[1]);
        }
        for (let i = 0; i < timezones.length; i++) {
          if (timezones[i].value === (parseInt(Hr) + parseInt(Min))) {
            setCurrentTZ(timezones[i].name);
          }
        }
        setHeaderContent(result?.data[7]?.description);
        setFooterContent(result?.data[8]?.description);

        if (result?.data[9]?.description === '1,2') {
          setConsultType({
            opd: true,
            remote: true
          });
        } else if (result?.data[9]?.description === '1') {
          setConsultType({
            opd: true,
            remote: false
          });
        } else {
          setConsultType({
            opd: false,
            remote: true
          });
        }

        setOpdType(result?.data[21]?.description);

        setGenSettings({
          doctorSlot: result?.data[15]?.description,
          isPatientOTP: result?.data[16]?.description,
          isDoctorOTP: result?.data[17]?.description,
          isParamedicSlot: result?.data[18]?.description,
          isStethPoint: result?.data[20]?.description,
          isAdvanceTele: result?.data[19]?.description
        });

        setDeviceManager({
          thermo: { value: result?.data[11]?.description, label: result?.data[11]?.description },
          glucose: { value: result?.data[14]?.description, label: result?.data[14]?.description },
          oxygen: { value: result?.data[13]?.description, label: result?.data[13]?.description },
          pressure: { value: result?.data[12]?.description, label: result?.data[12]?.description }
        });
      }
    });

    // settings call
    getAllSettingsData({
      endpoint: `${apiConstants.getAllSettings}`,
      method: 'GET'
    }).then((result) => {
      console.log('result', result);
    });

    // device status call
    getDeviceOrderStatusData({
      endpoint: `${apiConstants.getDeviceOrderStatus}/${id}`,
      method: 'GET'
    }).then((result) => {
      setDeviceOrderStatus(result?.data?.data);
      let originalArray = result?.data?.data;
      const transformedArray = originalArray.map(item => {
        return {
          device_id: item.device_id,
          order_status: item.order_status
        };
      });
      setDeviceData(transformedArray);
    });
  };

  useEffect(() => {
    handleData();
    handleVideoRecodData();
  }, []);

  setInterval(() => {
    setDate(new Date());
  }, 1000);

  let CurrentSelectedTZDate = moment.utc(date).format('dddd, MMMM DD, YYYY hh:mm:ss A');
  CurrentSelectedTZDate =
    moment(CurrentSelectedTZDate).add(minuteTimeZone, 'minutes').format('dddd, MMMM DD, YYYY hh:mm:ss A');

  useEffect(() => {
    if (timeZone?.value === 'IN') {
      setCurrentTZ(timezones[0].name);
      setMinuteTimeZone(330);
    }
    if (timeZone?.value === 'GMT') {
      setCurrentTZ(timezones[1].name);
      setMinuteTimeZone(0);
    }
  }, [timeZone]);

  const handleAccountInfo = (values) => {
    handleSave(values.accountUser, 'from_email_name');
    handleSave(values.accountMail, 'from_email_id');
    handleSave(values.accountPay, 'payment_mode_id');
  };

  const handleConsultType = () => {
    if (consultType.opd && consultType.remote) {
      handleSave('1,2', 'consult_types');
    } else if (consultType.opd) {
      handleSave('1', 'consult_types');
    } else {
      handleSave('2', 'consult_types');
    }
    handleSave(opdType, 'opd_consult_type');
  };

  const handleGenSettings = () => {
    handleSave(genSettings.doctorSlot, 'doctor_slot_minutes');
    handleSave(genSettings.isPatientOTP, 'is_patient_otp_require');
    handleSave(genSettings.isDoctorOTP, 'is_doctor_otp_require');
    handleSave(genSettings.isParamedicSlot, 'paramedic_slot_required');
    handleSave(genSettings.isStethPoint, 'is_steth_points_required');
    handleSave(genSettings.isAdvanceTele, 'is_advanced_tele_examination_required');
  };

  const handleTimeZone = () => {
    if (timeZone?.value === 'IN') {
      handleSave(5, 'timezone_hours');
      handleSave(30, 'timezone_minutes');
    }
    if (timeZone?.value === 'GMT') {
      handleSave(0, 'timezone_hours');
      handleSave(0, 'timezone_minutes');
    }
  };

  const handleDeviceManager = () => {
    handleSave(deviceManager.thermo?.label, 'thermometer');
    handleSave(deviceManager.glucose?.label, 'blood_glucose');
    handleSave(deviceManager.oxygen?.label, 'blood_oxygen');
    handleSave(deviceManager.pressure?.label, 'blood_pressure');
  };

  const handleOnDropdownChange = () => {

  };

  const setTokenHandler = (e) => {
    setTokenData(e.target.value);
  };

  const setSecretHandler = (e) => {
    setSecretKey(e.target.value);
  };

  const setApiHandler = (e) => {
    setApiKey(e.target.value);
  };

  return (
    <div className='row mt-16 mx-28'>
      <div className='flex flex-col mt-16'>
        <div className='bg-[#f4f4f4] rounded-lg mt-4 mb-4 p-6 min-h-[500px]'>
          <div className='grid grid-cols-12 gap-4'>
            <div className='col-span-3'>
              {Data?.map((data, index) => (
                <div key={index} className='col-span-3 p-[20px] text-[15px] text-[#737575] bg-[white] 
                                    mb-4 cursor-pointer '
                  onClick={() => { setActiveTab(data?.tabName); setActiveModal((null)); }}
                  style={activeTab === data?.tabName ?
                    { borderLeft: '5px solid #383678', borderRadius: '5px' } :
                    { borderRadius: '5px' }} >
                  {index + 1}. {data?.name}
                </div>
              ))}
            </div>
            <div className='bg-[white] rounded-sm col-span-9 p-4'>
              <div className='flex justify-between border-b border-black'>
                <div className='text-[18px] font-[1000] text-[#737575]'>{ActiveTabName(activeTab)}</div>
                <div>
                  {activeModal ?
                    <FontAwesomeIcon icon={faXmark}
                      className={'text-[#383678] text-[30px] mb-2 cursor-pointer'}
                      onClick={() => setActiveModal((null))}
                    /> :
                    <img className='pb-[10px] cursor-pointer'
                      src={editIcon} alt='' onClick={() => handleAction((activeTab))} />}
                </div>
              </div>
              {!activeModal &&
                <div className="mt-4">
                  {/* Logo Tab */}
                  {activeTab === 'Logo_Image' &&
                    <div className='logoTab'>
                      <div className='grid justify-items-center'>Logo for Header and PDF</div>
                      <div className='mt-4 grid justify-items-center'>
                        (Change By Clicking Edit)</div>
                      {/* image should come here */}
                      <div className="grid justify-items-center">
                        <img className='mt-12'
                          src={`${awsdetails.awsUrl}/${logoTabDetails.image}`}
                          alt="Logo image" />
                      </div>
                    </div>
                  }
                  {/* Nav Color */}
                  {activeTab === 'nav_color' &&
                    <div className='flex flex-col items-center mt-[10%]'>
                      <div>Color Code: <span style={{ color: `${appearanceData[4]?.description}` }}>
                        {appearanceData[1]?.description}</span></div>
                      <div>Preview</div>
                      <div
                        style={{
                          width: '100%', height: '90px', marginTop: '40px',
                          background: `${appearanceData[1]?.description}`
                        }}>
                      </div>
                    </div>}

                  {activeTab === 'account_info' &&
                    <div className="flex flex-col items-center gap-4 text-[#737575] font-[1000] mt-[10%]">
                      <div className="flex justify-center items-center gap-4">
                        <FontAwesomeIcon icon={faUser}
                          className={'text-[#383678] text-[24px] cursor-pointer'}
                          style={{
                            border: '2px solid rgb(51, 207, 168)',
                            color: 'rgb(56, 54, 120)',
                            padding: '8px',
                            borderRadius: '50%'
                          }}
                        />
                        <div className="flex-[4]">
                          {appearanceData[5]?.description}
                        </div>
                      </div>
                      <div className="flex justify-center items-center gap-4">
                        <FontAwesomeIcon icon={faEnvelope}
                          className={'text-[#383678] text-[24px] cursor-pointer'}
                          style={{
                            border: '2px solid rgb(51, 207, 168)',
                            color: 'rgb(56, 54, 120)',
                            padding: '8px',
                            borderRadius: '50%'
                          }}
                        />
                        <div className="flex-[4]">
                          {appearanceData[4]?.description}
                        </div>
                      </div>
                      <div className="flex justify-center items-center gap-4">
                        <FontAwesomeIcon icon={faCreditCard}
                          className={'text-[#383678] text-[24px] cursor-pointer'}
                          style={{
                            border: '2px solid rgb(51, 207, 168)',
                            color: 'rgb(56, 54, 120)',
                            padding: '8px',
                            borderRadius: '50%'
                          }}
                        />
                        <div className="flex-[4]">
                          {appearanceData[6]?.description}
                        </div>
                      </div>
                    </div>}

                  {activeTab === 'time_zone' && <div className='grid grid-cols-12'>
                    <div className="px-5 md:col-span-12" style={{ marginTop: '13vh' }}>
                      <div className="md:grid md:grid-cols-8 mt-15">
                        <div className="md:col-span-6">
                          <h5 className="text-gray-500 font-light">Selected Time Zone: <b>{CurrentTZ}</b></h5>
                          <h5 className="text-gray-500 font-light">Selected Time: <b>{CurrentSelectedTZDate}</b></h5>
                          <h5 className="text-gray-500 font-light">UTC Time :
                            <b>{moment.utc(new Date()).format('dddd, MMMM DD, YYYY hh:mm:ss A')}</b></h5>
                        </div>
                        <div className="col-span-2">
                          <Clock value={new Date(CurrentSelectedTZDate)} />
                        </div>
                      </div>
                    </div>
                  </div>}

                  {activeTab === 'Prescription_Header' &&
                    <div className='mt-[10%]'>
                      <div className="text-center">Preview</div>
                      <div dangerouslySetInnerHTML={{ __html: appearanceData[7].description }} />
                    </div>
                  }
                  {activeTab === 'Prescription_Footer' &&
                    <div className='mt-[10%]'>
                      <div>
                        <div className="text-center">Preview</div>
                        <div dangerouslySetInnerHTML={{ __html: appearanceData[8].description }} />
                      </div>
                    </div>}

                  {activeTab === 'Consult_Types' &&
                    <div className="text-center">
                      <div className="font-[1000] text-[#737575]">Active Consult Type</div>
                      {appearanceData[9]?.description === '1' &&
                        <div>1. OPD Consult</div>}
                      {appearanceData[9]?.description === '2' &&
                        <div>1. Remote Consult</div>}
                      {appearanceData[9]?.description === '1,2' && <>
                        <div>1. OPD Consult</div>
                        <div>2. Remote Consult</div>
                      </>}
                    </div>
                  }
                  {activeTab === 'Device_Manager' &&
                    <div className="flex justify-center flex-col items-center gap-[30px]">
                      <div className='flex gap-2 justify-center items-center'>
                        <div className='w-[45px] h-[45px] rounded-[50%] border-[3px] border-[#2bd398]'>
                          <img src={digitalThermometer} />
                        </div>
                        <div>Thermometer: {appearanceData[11]?.description}</div>
                      </div>
                      <div className='flex gap-2 justify-center items-center'>
                        <div className='w-[45px] h-[45px] rounded-[50%] border-[3px] border-[#2bd398]'>
                          <img src={glucose} />
                        </div>
                        <div>Blood Glucose: {appearanceData[14]?.description}</div>
                      </div>
                      <div className='flex gap-2 justify-center items-center'>
                        <div className='w-[45px] h-[45px] rounded-[50%] border-[3px] border-[#2bd398]'>
                          <img src={oxygen} />
                        </div>
                        <div>Blood Oxygen: {appearanceData[13]?.description}</div>
                      </div>
                      <div className='flex gap-2 justify-center items-center'>
                        <div className='w-[45px] h-[45px] rounded-[50%] border-[3px] border-[#2bd398]'>
                          <img src={bp} />
                        </div>
                        <div>Blood Pressure: {appearanceData[12]?.description}</div>
                      </div>
                    </div>
                  }

                  {activeTab === 'Manage_Concepts' &&
                    <div className='flex flex-col gap-5 text-[#737575] font-semibold items-center mt-[10%]'>
                      <div className='underline cursor-pointer'>Manage Concept Classes </div>
                      <div className='underline cursor-pointer'>Manage Concept Sources </div>
                      <div className='underline cursor-pointer'>Manage Encounter Types </div>
                      <div className='underline cursor-pointer'>Manage Encounter Roles </div>
                    </div>}

                  {activeTab === 'General_Settings' &&
                    <div className='flex flex-col gap-5 text-[#737575] font-semibold'>
                      <div>1.Doctor Slot Minutes: {genSettings.doctorSlot}</div>
                      <div>2.Patient OTP Require: {genSettings.isPatientOTP === '1' ? 'Yes' : 'No'}</div>
                      <div>3.Doctor OTP Require: {genSettings.isDoctorOTP === '1' ? 'Yes' : 'No'}</div>
                      <div>4.Paramedic slot required: {genSettings.isParamedicSlot === '1' ? 'Yes' : 'No'}</div>
                      <div>5.Stethoscope points required: {genSettings.isStethPoint === '1' ? 'Yes' : 'No'}</div>
                      <div>6.Advance tele-examination required: {genSettings.isAdvanceTele === '1' ? 'Yes' : 'No'}</div>
                    </div>}

                  {activeTab === 'Device_Order' &&
                    <div className='flex flex-col gap-5 text-[#737575] font-semibold'>
                      {deviceOrderStatus && deviceOrderStatus.map((item, index) => {
                        return (
                          <div key={index}>{index + 1}.{item?.device_name}:
                            {item?.order_status === 1 ? 'Free' : 'Paid'}</div>);
                      })}
                    </div>}
                  {activeTab === 'Video_SDK' &&
                    <div className='flex flex-col gap-5 text-[#737575] font-semibold'>
                      <div className="token-container"><b>Token:</b> {videoSDKData?.token}</div>
                      <div><b>Secret Key:</b> {videoSDKData?.secret_key}</div>
                      <div><b>API Key:</b> {videoSDKData?.api_key}</div>
                      <div><b>Recording:</b> {videoSDKData?.is_recording === 1 ? 'Yes' : 'No'}</div>
                    </div>}
                </div>
              }

              <div className="mt-4">
                {activeModal === 'Logo_Image' &&
                  <div>
                    <SettingsModal
                      setShowModal={setActiveModal}
                      settingsData={appearanceData[0]}
                    />
                  </div>}
                {activeModal === 'nav_color' &&
                  <div className='grid grid-cols-12 gap-4 mt-[10%]'>
                    <div className='col-span-8'>
                      <div>Color Code: <span style={{
                        color: `${customerColorCode}` ? `${customerColorCode}`
                          : `${appearanceData[1]?.description}`
                      }}>
                        {appearanceData[1]?.description}</span></div>
                      <div>Preview</div>
                      <div
                        style={{
                          width: '100%', height: '90px', marginTop: '40px',
                          background: `${customerColorCode}` ? `${customerColorCode}`
                            : `${appearanceData[1]?.description}`
                        }}>
                      </div>
                    </div>
                    <div className='col-span-3'>
                      <SketchPicker
                        color={`${customerColorCode}` ? `${customerColorCode}`
                          : `${appearanceData[4]?.description}`}
                        onChangeComplete={(e) => {
                          setCustomerColorCode(e.hex);
                        }}
                      />
                    </div>
                    <button
                      type='submit'
                      className="bg-[#383678] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                          rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center col-span-12"
                      style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                      onClick={() => handleSave(customerColorCode, 'customer_color_code')}
                    >
                      Save
                    </button>
                  </div>
                }
                {activeModal === 'account_info' &&
                  <div>
                    <Formik
                      initialValues={{
                        accountUser: appearanceData[5]?.description,
                        accountMail: appearanceData[4]?.description,
                        accountPay: appearanceData[6]?.description
                      }}
                      enableReinitialize
                      onSubmit={handleAccountInfo}
                    >
                      <Form className='flex flex-col items-center gap-[30px] mt-[30px] createUser'>
                        <div className="flex flex-col items-center gap-4 text-[#737575] font-[1000] mt-[10%]">
                          <div className="flex justify-center items-center gap-4">
                            <FontAwesomeIcon icon={faUser}
                              className={'text-[#383678] text-[24px] cursor-pointer'}
                              style={{
                                border: '2px solid rgb(51, 207, 168)',
                                color: 'rgb(56, 54, 120)',
                                padding: '8px',
                                borderRadius: '50%'
                              }}
                            />
                            <Field
                              name="accountUser"
                              type='text'
                              placeholder="Account User"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] opacity-70 bg-[#fff]
                              outline-none w-full py-2 px-3"
                            />
                            <ErrorMessage name="accountUser" component="div" className='text-red-600 absolute' />
                          </div>
                          <div className="flex justify-center items-center gap-4">
                            <FontAwesomeIcon icon={faEnvelope}
                              className={'text-[#383678] text-[24px] cursor-pointer'}
                              style={{
                                border: '2px solid rgb(51, 207, 168)',
                                color: 'rgb(56, 54, 120)',
                                padding: '8px',
                                borderRadius: '50%'
                              }}
                            />
                            <Field
                              name="accountMail"
                              type='text'
                              placeholder="Account Mail"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] opacity-70 bg-[#fff]
                              outline-none w-full py-2 px-3"
                            />
                            <ErrorMessage name="accountMail" component="div" className='text-red-600 absolute' />
                          </div>
                          <div className="flex justify-center items-center gap-4">
                            <FontAwesomeIcon icon={faCreditCard}
                              className={'text-[#383678] text-[24px] cursor-pointer'}
                              style={{
                                border: '2px solid rgb(51, 207, 168)',
                                color: 'rgb(56, 54, 120)',
                                padding: '8px',
                                borderRadius: '50%'
                              }}
                            />
                            <Field
                              name="accountPay"
                              type='text'
                              placeholder="Account Pay"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] opacity-70 bg-[#fff]
                              outline-none w-full py-2 px-3"
                            />
                            <ErrorMessage name="accountPay" component="div" className='text-red-600 absolute' />
                          </div>
                        </div>
                        <div className='flex gap-[80px]'>
                          <button
                            type='submit'
                            className='bg-[#383678] text-white px-[50px] py-[5px] w-[120px] h-[40px] leading-[30px]
                            rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center'
                            style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                          >
                            Save
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  </div>}
                {activeModal === 'time_zone' &&
                  <div className='flex flex-col items-center'>
                    <div className='grid grid-cols-12 '>
                      <div className="col-start-3 col-span-6 flex justify-center">
                        <ReactSelectFilters
                          className='w-[50%]'
                          options={timeOption}
                          placeholder='Time Zone'
                          name='timeZone'
                          noDropDownIndicator={false}
                          handleDropdownInputChange={handleOnDropdownChange}
                          onChange={(data) => {
                            setTimeZone(data);
                          }}
                          selectFieldValues={timeZone}
                          isMulti={false}
                          disableEnterKey
                        />
                      </div>
                      <div className="col-start-9 col-span-2"> {/* Offset of 2 columns from the left */}
                        <Clock value={new Date(CurrentSelectedTZDate)} />
                      </div>
                    </div>
                    <div className="flex flex-col items-center mt-[50px]">
                      <h5 className="text-gray-500 font-light">Selected Time Zone: <b>{CurrentTZ}</b></h5>
                      <h5 className="text-gray-500 font-light">Selected Time: <b>{CurrentSelectedTZDate}</b></h5>
                      <h5 className="text-gray-500 font-light">UTC Time :
                        <b>{moment.utc(new Date()).format('dddd, MMMM DD, YYYY hh:mm:ss A')}</b></h5>
                    </div>
                    <div>
                      <SharedButton
                        className="ml-0 rounded-[20px] float-left bg-[#383678] text-white px-[30px] py-[5px] mt-[50px]
                        text-sm font-bold mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        title='Save'
                        onClick={() => handleTimeZone()}
                      />
                    </div>

                  </div>}

                {activeModal === 'Prescription_Header' &&
                  <div>
                    <div>Prescription Header</div>
                    <div>
                      <textarea className='w-full p-4 my-4 border-b border-[#7c7c7c]'
                        value={`${headerContent}`}
                        onChange={(e) => setHeaderContent(e.target.value)}
                        // readOnly={true}
                        rows={10}
                      />
                    </div>
                    <div className="flex justify-center">
                      <SharedButton
                        className="ml-0 rounded-[20px] float-left bg-[#383678] text-white 
                        px-[30px] py-[5px] text-sm font-bold
                        mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        title='Save'
                        onClick={() => handleSave(headerContent, 'prescription_header_content')}
                      />
                    </div>
                  </div>
                }
                {activeModal === 'Prescription_Footer' && <div>
                  <div>Prescription Footer</div>
                  <div>
                    <textarea className='w-full p-4 my-4 border border-black'
                      value={footerContent}
                      onChange={(e) => setFooterContent(e.target.value)}
                      // readOnly={true}
                      rows={10}
                    />
                  </div>
                  <div className="flex justify-center">
                    <SharedButton
                      className="ml-0 rounded-[20px] float-left bg-[#383678] text-white 
                      px-[30px] py-[5px] text-sm font-bold
                      mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                      title='Save'
                      onClick={() => handleSave(footerContent, 'prescription_footer_content')}
                    />
                  </div>
                </div>}
                {activeModal === 'Consult_Types' &&
                  <div className='flex flex-col gap-5 mt-[5%] items-center'>
                    <div>Active Consult Type</div>
                    <div className='flex gap-3'>
                      <input type='checkbox' checked={consultType.opd}
                        onChange={() => setConsultType({ ...consultType, opd: !consultType.opd })} />
                      <div>OPD Consult</div>
                    </div>
                    {consultType.opd && <div className='flex gap-5'>
                      <div className='flex'>
                        <input type='radio' checked={opdType === '1'} onChange={() => setOpdType('1')} />
                        <div>New</div>
                      </div>
                      <div className='flex'>
                        <input type='radio' checked={opdType === '2'} onChange={() => setOpdType('2')} />
                        <div>Old</div>
                      </div>
                    </div>}
                    <div className='flex gap-3'>
                      <input type='checkbox' checked={consultType.remote}
                        onChange={() => setConsultType({ ...consultType, remote: !consultType.remote })} />
                      <div>Remote Consult</div>
                    </div>
                    <SharedButton
                      className="ml-0 rounded-[20px] float-left bg-[#383678] text-white px-[30px] py-[5px]
                      text-sm font-bold mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                      title='Save'
                      onClick={() => handleConsultType()}
                    />
                  </div>}
                {activeModal === 'Device_Manager' &&
                  <div className="flex justify-center flex-col items-center gap-[30px]">
                    <div className='flex gap-2 justify-center items-center'>
                      <div className='w-[45px] h-[45px] rounded-[50%] border-[3px] border-[#2bd398]'>
                        <img src={digitalThermometer} />
                      </div>
                      <div>Thermometer: {deviceManager.thermo?.label}</div>
                      <div className='w-[120px]'>
                        <ReactSelectFilters
                          className='w-[50%]'
                          options={deviceOption}
                          placeholder='Thermometer'
                          name='thermometer'
                          noDropDownIndicator={false}
                          handleDropdownInputChange={handleOnDropdownChange}
                          onChange={(data) => {
                            setDeviceManager({ ...deviceManager, thermo: data });
                          }}
                          selectFieldValues={deviceManager?.thermo}
                          isMulti={false}
                          disableEnterKey
                        />
                      </div>
                    </div>
                    <div className='flex gap-2 justify-center items-center'>
                      <div className='w-[45px] h-[45px] rounded-[50%] border-[3px] border-[#2bd398]'>
                        <img src={glucose} />
                      </div>
                      <div>Blood Glucose: {deviceManager.glucose?.label}</div>
                      <div className='w-[120px]'>
                        <ReactSelectFilters
                          className='w-[50%]'
                          options={deviceOption}
                          placeholder='Glucose'
                          name='glucose'
                          noDropDownIndicator={false}
                          handleDropdownInputChange={handleOnDropdownChange}
                          onChange={(data) => {
                            setDeviceManager({ ...deviceManager, glucose: data });
                          }}
                          selectFieldValues={deviceManager?.glucose}
                          isMulti={false}
                          disableEnterKey
                        />
                      </div>
                    </div>
                    <div className='flex gap-2 justify-center items-center'>
                      <div className='w-[45px] h-[45px] rounded-[50%] border-[3px] border-[#2bd398]'>
                        <img src={oxygen} />
                      </div>
                      <div>Blood Oxygen: {deviceManager.oxygen?.label}</div>
                      <div className='w-[120px]'>
                        <ReactSelectFilters
                          className='w-[50%]'
                          options={deviceOption}
                          placeholder='Oxygen'
                          name='oxygen'
                          noDropDownIndicator={false}
                          handleDropdownInputChange={handleOnDropdownChange}
                          onChange={(data) => {
                            setDeviceManager({ ...deviceManager, oxygen: data });
                          }}
                          selectFieldValues={deviceManager?.oxygen}
                          isMulti={false}
                          disableEnterKey
                        />
                      </div>
                    </div>
                    <div className='flex gap-2 justify-center items-center'>
                      <div className='w-[45px] h-[45px] rounded-[50%] border-[3px] border-[#2bd398]'>
                        <img src={bp} />
                      </div>
                      <div>Blood Pressure: {deviceManager.pressure?.label}</div>
                      <div className='w-[120px]'>
                        <ReactSelectFilters
                          className='w-[120px]'
                          options={deviceOption}
                          placeholder='Pressure'
                          name='pressure'
                          noDropDownIndicator={false}
                          handleDropdownInputChange={handleOnDropdownChange}
                          onChange={(data) => {
                            setDeviceManager({ ...deviceManager, pressure: data });
                          }}
                          selectFieldValues={deviceManager?.pressure}
                          isMulti={false}
                          disableEnterKey
                        />
                      </div>
                    </div>
                    <div>
                      <SharedButton
                        className="ml-0 rounded-[20px] float-left bg-[#383678] text-white px-[30px] py-[5px] mt-[30px]
                        text-sm font-bold mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        title='Save'
                        onClick={() => handleDeviceManager()}
                      />
                    </div>
                  </div>}
                {activeModal === 'Manage_Concepts' && <div> activeModalManage_Concepts</div>}
                {activeModal === 'General_Settings' &&
                  <div>
                    <div className='flex flex-col gap-5 text-[#737575] font-semibold'>
                      <div>
                        <div>1.Doctor Slot Minutes*</div>
                        <div className='flex'>
                          <input type='number' value={genSettings.doctorSlot}
                            style={{ borderBottom: '1px solid grey' }}
                            onChange={(e) => setGenSettings({ ...genSettings, doctorSlot: e.target.value })} />
                        </div>
                      </div>
                      <div>
                        <div>2.Patient OTP Require*</div>
                        <div className='flex gap-[100px]'>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={genSettings.isPatientOTP === '1'}
                              onChange={() => setGenSettings({ ...genSettings, isPatientOTP: '1' })} />
                            <div>Yes</div>
                          </div>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={genSettings.isPatientOTP === '0'}
                              onChange={() => setGenSettings({ ...genSettings, isPatientOTP: '0' })} />
                            <div>No</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>3.Doctor OTP Require*</div>
                        <div className='flex gap-[100px]'>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={genSettings.isDoctorOTP === '1'}
                              onChange={() => setGenSettings({ ...genSettings, isDoctorOTP: '1' })} />
                            <div>Yes</div>
                          </div>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={genSettings.isDoctorOTP === '0'}
                              onChange={() => setGenSettings({ ...genSettings, isDoctorOTP: '0' })} />
                            <div>No</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>4.Paramedic slot required*</div>
                        <div className='flex gap-[100px]'>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={genSettings.isParamedicSlot === '1'}
                              onChange={() => setGenSettings({ ...genSettings, isParamedicSlot: '1' })} />
                            <div>Yes</div>
                          </div>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={genSettings.isParamedicSlot === '0'}
                              onChange={() => setGenSettings({ ...genSettings, isParamedicSlot: '0' })} />
                            <div>No</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>5.Stethoscope points required*</div>
                        <div className='flex gap-[100px]'>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={genSettings.isStethPoint === '1'}
                              onChange={() => setGenSettings({ ...genSettings, isStethPoint: '1' })} />
                            <div>Yes</div>
                          </div>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={genSettings.isStethPoint === '0'}
                              onChange={() => setGenSettings({ ...genSettings, isStethPoint: '0' })} />
                            <div>No</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>6.Advance tele-examination required*</div>
                        <div className='flex gap-[100px]'>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={genSettings.isAdvanceTele === '1'}
                              onChange={() => setGenSettings({ ...genSettings, isAdvanceTele: '1' })} />
                            <div>Yes</div>
                          </div>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={genSettings.isAdvanceTele === '0'}
                              onChange={() => setGenSettings({ ...genSettings, isAdvanceTele: '0' })} />
                            <div>No</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <SharedButton
                        className="ml-0 rounded-[20px] float-left bg-[#383678] text-white px-[30px] py-[5px]
                      text-sm font-bold mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        title='Save'
                        onClick={() => handleGenSettings()}
                      />
                    </div>
                  </div>}
                {activeModal === 'Device_Order' &&
                  <div>
                    <div className='flex flex-col gap-5 text-[#737575] font-semibold'>
                      {deviceOrderStatus && deviceOrderStatus.map((item, index) => {
                        return (
                          <div key={index}>
                            <div>{index + 1}.{item?.device_name}</div>
                            <div className='flex gap-[100px]'>
                              <div className='flex gap-[10px]'>
                                <input type='radio' checked={deviceData[index].order_status === 1}
                                  onChange={() =>
                                    setDeviceData(prevDeviceData => {
                                      const updatedDeviceData = [...prevDeviceData]; // Create a copy of the array
                                      updatedDeviceData[index] = { ...updatedDeviceData[index], order_status: 1 };
                                      return updatedDeviceData; // Return the updated array
                                    })}
                                /><div>Free</div>
                              </div>
                              <div className='flex gap-[10px]'>
                                <input type='radio' checked={deviceData[index].order_status === 2}
                                  onChange={() =>
                                    setDeviceData(prevDeviceData => {
                                      const updatedDeviceData = [...prevDeviceData]; // Create a copy of the array
                                      updatedDeviceData[index] = { ...updatedDeviceData[index], order_status: 2 };
                                      return updatedDeviceData; // Return the updated array
                                    })}
                                /><div>Paid</div>
                              </div>
                            </div>
                          </div>);
                      })}
                    </div>
                    <div>
                      <SharedButton
                        className="ml-0 rounded-[20px] float-left bg-[#383678] text-white px-[30px] py-[5px]
                      text-sm font-bold mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        title='Save'
                        onClick={() => handleDeviceOrderStatus()}
                      /></div>
                  </div>}
                {activeModal === 'Video_SDK' &&
                  <div>
                    <div className='flex flex-col gap-5 text-[#737575] font-semibold'>
                      <input
                        name="token"
                        type='text'
                        placeholder="Token"
                        autoComplete="off"
                        value={tokenData}
                        onChange={setTokenHandler}
                        className="border-b border-[#A9A9A9] opacity-70 bg-[#fff]
                              outline-none w-full py-2 px-3"
                      />
                      <input
                        name="secretkey"
                        type='text'
                        placeholder="secret Key"
                        autoComplete="off"
                        value={secretKey}
                        onChange={setSecretHandler}
                        className="border-b border-[#A9A9A9] opacity-70 bg-[#fff]
                              outline-none w-full py-2 px-3"
                      />
                      <input
                        name="apikey"
                        type='text'
                        placeholder="Api Key"
                        autoComplete="off"
                        value={apiKey}
                        onChange={setApiHandler}
                        className="border-b border-[#A9A9A9] opacity-70 bg-[#fff]
                              outline-none w-full py-2 px-3"
                      />
                      {/* <div className="token-container">1.Token: {videoSDKData?.token}</div>
                      <div>2.Secret Key: {videoSDKData?.secret_key}</div>
                      <div>3.API Key: {videoSDKData?.api_key}</div> */}
                      <div>
                        <div>Recording*</div>
                        <div className='flex gap-[100px]'>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={isRecording === 1}
                              onChange={() => setIsRecording(1)} />
                            <div>Yes</div>
                          </div>
                          <div className='flex gap-[10px]'>
                            <input type='radio' checked={isRecording === 0}
                              onChange={() => setIsRecording(0)} />
                            <div>No</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <SharedButton
                        className="ml-0 rounded-[20px] float-left bg-[#383678] text-white px-[30px] py-[5px]
                        text-sm font-bold mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        title='Save'
                        onClick={() => { updateVideoRecordData(); }}
                      />
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default CustomerConfiguration;