import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const ConfirmModal = ({
  msg,
  isError // boolean
}) => {

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white flex flex-col gap-[10px] items-center pt-[20px] pb-[60px]">
              <FontAwesomeIcon icon={isError ? faExclamationCircle : faCheckCircle}
                className={`${isError ? 'text-[red]' : 'text-[#383678]'}  text-[120px]`}
              />
              <div>{msg}</div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default ConfirmModal;
