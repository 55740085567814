import React, { useState } from 'react';
import ProfilePic from '../../../images/general_pic.png';
import NewLogo from '../../../images/Sakha-Global.png';
import { useNavigate } from 'react-router-dom';
import { HeaderLinks } from '../utils/HeaderConstants';
import { Colorcode } from '../utils/Colorcode';

const Header = () => {
  const navigate = useNavigate();
  const user_type = localStorage.getItem('user_type');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [menuShow, setMenuShow] = useState(false);
  const customerColor = Colorcode();
  const NavIcon = localStorage.getItem('Nav-Icon');
  let hideMenuTimeout;
  let dropMenu = HeaderLinks();
  let user_name = localStorage.getItem('username') ?
    localStorage.getItem('username') : '';
  let ProfilePicForUser = localStorage.getItem('ProfilePic') ?
    localStorage.getItem('ProfilePic') : ProfilePic;

  let kioskName = '';
  if (user_type === 'paramedic') {
    kioskName = localStorage.getItem('Kiosk');
  } else if (user_type === 'agent') {
    kioskName = localStorage.getItem('Kiosk');
  }
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const logoutUser = () => {
    setIsDropdownOpen(!isDropdownOpen);
    navigate('/login');
    localStorage.removeItem('ProfilePic');
    localStorage.removeItem('Nav-Color');
    localStorage.clear();
  };

  const redirectTo = (url) => {
    setMenuShow(false);
    if (url) {
      navigate(url);
    }
  };

  const gotoDashboard = () => {
    navigate(`/${user_type}`);
  };

  return (
    <div className='gap-filler col-span-9 lg:col-span-9 md:col-span-9'>
      <nav style={{ backgroundColor: customerColor[0] }}
        className='rounded navbar rectangle-1 flex justify-between p-[10px] h-[82px]'>
        <div className='flex list-none'>
          {(window?.location?.pathname !== '/admin' && window?.location?.pathname !== '/doctor' &&
            window?.location?.pathname.indexOf('doctor/prescription') <= 0) && (
              <img
                onClick={() => gotoDashboard()}
                // src={NewLogo}
                src={user_type === 'superadmin' ?
                  NewLogo : 'https://sakha-health.s3-ap-southeast-1.amazonaws.com/' + NavIcon
                }
                className="cursor-pointer w-auto h-[56px] mr-[60px] p-1 ml-0 bg-white"
              />
            )}
          {!window?.location?.pathname.includes('/opdappointment') && (
            <>
              {dropMenu.map((item, index) => (
                <div key={index} className='py-[15px]'>
                  {item.isVisible &&
                    item.sub_menu === undefined && (
                      <label
                        className="fw-500 text-white cursor-pointer px-4 py-1 font-[700]"
                        style={{
                          fontSize: (window?.location?.pathname !== '/admin' &&
                            window?.location?.pathname !== '/doctor') ? '21px' : '21px'
                        }}
                        onClick={() => redirectTo(item.url)}
                      >
                        {item.headerOption}
                      </label>
                    )
                  }
                  {item.isVisible && item.sub_menu && (
                    <label
                      className="fw-500 text-white cursor-pointer font-semibold px-4 py-1 relative"
                      style={{
                        fontSize: (window?.location?.pathname !== '/admin' &&
                          window?.location?.pathname !== '/doctor') ? '21px' : '21px'
                      }}
                      onMouseEnter={() => {
                        clearTimeout(hideMenuTimeout);
                        setMenuShow(true);
                      }}
                      onMouseLeave={() => {
                        hideMenuTimeout = setTimeout(() => {
                          setMenuShow(false);
                        }, 300); // Adjust the delay (in milliseconds) based on your needs
                      }}
                    >
                      {item.headerOption}
                      {menuShow && (
                        <ul className="min-w-28 absolute bg-white
                                            text-[#555] mt-2 w-full z-50 shadow-2xl">
                          {Object(item.sub_menu).map((key, subIndex) => (
                            key.isVisible && (<li key={subIndex} onClick={() => redirectTo(key.url)}
                              className="show-menu px-4 py-1 hover:bg-gray-200 text-sm cursor-pointer">
                              {key.subHeaderOption}
                            </li>
                            )
                          ))}
                        </ul>
                      )}
                    </label>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
        {kioskName &&
          <div className='text-[#fff] font-semibold'>
            <div className='text-[14px]'>Clinic:</div>
            <div className='text-[17px] font-[600]'>{kioskName}</div>
          </div>}
        <ul className='flex items-center mt-1'>
          <li className={'relative mr-8'}>
            <div
              className={'nav-title mt-[-4px] pr-2'}
              onClick={toggleDropdown}
            >
              <img className='w-16 h-16 rounded-2xl cursor-pointer'
                src={ProfilePicForUser} alt="Profile Pic"></img>
            </div>
            {isDropdownOpen && (
              <div className="absolute top-[75px] right-0 bg-white rounded-md z-[10]
                            p-4 shadow-2xl w-max mr-[-40px]">
                <label className='font-Poppins text-black text-xl font-semibold'>
                  {user_name}
                </label>
                <label
                  onClick={() => logoutUser()}
                  className='block font-Poppins text-black text-sm font-extrabold
                                    cursor-pointer text-end mt-5'
                >
                  Logout
                </label>
              </div>
            )}
          </li>
        </ul>
      </nav >
    </div >
  );
};

export default Header;
