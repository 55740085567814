import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SharedButton from '../common/SharedButton';
import EditImg from '../../images/editIcon.png';
import {
  useLazyGetcustomerViewQuery,
  useUpdatecustomerMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import Loader from '../common/Loader';
import User from '../../images/userIcon.png';
import Mail from '../../images/mail.png';
import Phone from '../../images/phone.png';
import { Formik, Form, Field } from 'formik';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import PhoneInput from 'react-phone-input-2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';

const CustomerDetailView = () => {
  const navigate = useNavigate();

  const [getcustomerView] = useLazyGetcustomerViewQuery();
  const [updatecustomer] = useUpdatecustomerMutation();

  const [loading, setLoading] = useState(false);
  const [detailsCardData, setDetailsCardData] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [phoneNo, setPhoneNo] = useState('');

  const { id } = useParams();

  useEffect(() => {
    setPhoneNo(detailsCardData?.primary_contact_phone);
    setIsToggle(detailsCardData?.status === 0 ? true : false);
  }, [detailsCardData]);

  const cardData = () => {
    setLoading(true);
    getcustomerView({
      endpoint: `${apiConstants.getcustomerView}/${id}`
    }).then((result) => {
      let doctorViewData = result?.data;
      const modifiedData = {
        ...doctorViewData,
        id: `#DR${id}`
      };
      setDetailsCardData(modifiedData);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.error('error', error);
    });
  };
  useEffect(() => {
    cardData();
  }, []);

  const handleSubmit = (values) => {
    updatecustomer({
      endpoint: apiConstants.updatecustomer,
      method: 'PUT',
      data: {
        customer_id: id,
        customer_name: values?.customer_name,
        primary_contact_email: values?.primary_contact_email,
        primary_contact_name: values?.primary_contact_name,
        primary_contact_phone: values?.primary_contact_phone,
        status: isToggle ? 0 : 1
      }
    }).then(() => {
      cardData();
    }).catch(() => {
    });
    setIsEdit(false);
  };

  const toggleBar = () => {
    setIsToggle(!isToggle);
  };

  return (
    <div className="row mt-16 mx-28">
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div className='flex flex-col mt-16'>
          <div>
            <SharedButton
              className="ml-0 rounded-[20px] float-left text-[18px]
            bg-[#383678] text-white px-[30px] py-[7px] font-bold
              mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
              title='Back' onClick={() => navigate(-1)}
            />
          </div>
          <div className='bg-[#f4f4f4] rounded-lg mt-4 p-2 h-[575px] relative'>
            <div className='flex justify-center mt-6 w-full h-full'>
              <Formik
                initialValues={{
                  customer_name: detailsCardData.customer_name,
                  primary_contact_email: detailsCardData.primary_contact_email,
                  primary_contact_phone: detailsCardData.primary_contact_phone,
                  primary_contact_name: detailsCardData.primary_contact_name
                }}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({ setFieldValue }) => (
                  <Form className='w-full flex gap-[5%]'>
                    {isEdit ? <div className='flex gap-[20px] absolute top-[20px] right-[10px]'>
                      <div className='flex items-center detailView'>
                        <div className='doctor-status'>Disable Customer</div>
                        <Toggle
                          className='status-tog'
                          checked={isToggle}
                          onChange={() => toggleBar()}
                          icons={{
                            checked: <span style={{ fontFamily: 'Poppins' }}></span>,
                            unchecked: <span style={{ fontFamily: 'Poppins' }}></span>
                          }}
                        />
                      </div>
                      <SharedButton
                        className="ml-0 rounded-[20px] float-left text-[18px]
                      bg-[#383678] text-white px-[30px] py-[7px] font-bold
                        mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        type='submit'
                        title='Save'
                      />
                    </div> :
                      <div className='absolute right-[20px]'>
                        <div className="ml-0 rounded-[20px] float-left gap-[10px] items-center
                        bg-[#383678] text-white px-[30px] py-[10px] text-[16px] cursor-pointer
                          mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                          title="Configuration" onClick={() => navigate(`../customer/${id}/appearance`)}>
                          <FontAwesomeIcon icon={faGlobeAmericas}
                          />
                          <div>Configuration</div>
                        </div>
                        <img className='ml-3 mt-[10px] cursor-pointer' src={EditImg} onClick={() => setIsEdit(true)} />
                      </div>}
                    <div className='w-[45%] flex flex-col gap-[20px] items-center justify-center'>
                      {isEdit ?
                        (<Field
                          name="customer_name"
                          type='text'
                          placeholder="customer_name"
                          autoComplete="off"
                          className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                          outline-none w-[50%] py-2 px-3 h-[50px]"
                        />) :
                        <div className='mt-[10%]'>
                          {detailsCardData.customer_name && (
                            <label className='text-[#737575] text-5xl font-semibold'>
                              {detailsCardData.customer_name}
                            </label>
                          )}
                        </div>}
                      <div className='flex gap-[20px] items-center'>
                        <div className='text-center'>
                          <label className='text-[#737575] text-[20px] font-semibold'>
                            {'#CU'}{id}
                          </label>
                        </div>
                        <div className='h-[22px] border-l-2 border-solid border-black'></div>
                        <div className=''>
                          {detailsCardData.status}
                        </div>
                      </div>
                    </div>
                    <div className='h-[80%] border-l-2 border-solid border-[#d3d3d3] my-8'></div>
                    <div className='w-[45%] flex flex-col justify-center items-start ml-[5%] gap-[30px]'>
                      <div className='flex items-center gap-3'>
                        <div className='w-[65px] h-[65px] rounded-[50%] bg-[#d1d1d166] 
                        flex justify-center items-center'>
                          <img className='cursor-pointer' src={User} width={32} height={23} />
                        </div>
                        {isEdit ?
                          <Field
                            name="primary_contact_name"
                            type='text'
                            placeholder="Contact Name"
                            autoComplete="off"
                            className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                            outline-none w-full py-2 px-3 h-[50px]"
                          /> :
                          <div className='text-[18px] text-[#999999]'>{detailsCardData.primary_contact_name}</div>}
                      </div>
                      <div className='flex items-center gap-3'>
                        <div className='w-[65px] h-[65px] rounded-[50%] bg-[#d1d1d166] 
                        flex justify-center items-center'>
                          <img className='cursor-pointer' src={Mail} width={32} height={23} />
                        </div>
                        {isEdit ?
                          <Field
                            name="primary_contact_email"
                            type='text'
                            placeholder="First Name"
                            autoComplete="off"
                            className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                            outline-none w-full py-2 px-3 h-[50px]"
                          /> :
                          <div className='text-[18px] text-[#999999]'>{detailsCardData.primary_contact_email}</div>}
                      </div>
                      <div className='flex items-center w-[60%]'>
                        <div className='w-[65px] h-[65px] rounded-[50%] bg-[#d1d1d166]  
                        flex justify-center items-center'>
                          <img className='cursor-pointer' src={Phone} width={37} height={30} />
                        </div>
                        {isEdit ?
                          <Field name="primary_contact_phone" className='w-[60%]'>
                            {({ field }) => (
                              <PhoneInput
                                {...field}
                                name="primary_contact_phone"
                                type="tel"
                                placeholder="Phone Number*"
                                country={'in'}
                                onlyCountries={['in']}
                                autoFormat={false}
                                value={phoneNo}
                                onChange={(data) => {
                                  const phoneNumber = '+' + data;
                                  setPhoneNo(phoneNumber);
                                  setFieldValue('primary_contact_phone', phoneNumber);
                                }}
                              />
                            )}
                          </Field> :
                          <div className='text-[18px] text-[#999999]'>{detailsCardData.primary_contact_phone}</div>}
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDetailView;