import moment from 'moment';
import React from 'react';

const MedicalCertificatePreview = ({
  certificateDetails
}) => {
  return (
    <>
      <div className='flex flex-col gap-[20px]'>
        <p>Signature of Patient or thumb impression …………………………………………………………………………….</p>
        <p>To be filled by the applicant in the presence of registered medical practitioner. Identification Marks:-</p>
        <p>1) {certificateDetails?.id_marks1}</p>
        <p>2) {certificateDetails?.id_marks2}</p>
        <p>I, Dr {certificateDetails?.doctor_name || '…………………………………………………………………………….'} after careful examination 
          of the case certify hereby that 
          {certificateDetails?.doctor_name ? ((certificateDetails?.title ? 
          (certificateDetails?.title + '. ' ) : ' ') + certificateDetails?.patient_name) : 
          '…………………………………………………………………………….'}
          &nbsp;whose signature is given is suffering from&nbsp;
          {(certificateDetails?.suffering_from + '. ') || '…………………………………………………………………………….'}
          And I consider that a period of absence from the duty of&nbsp;
          {certificateDetails?.duty || '……………………………………………………………………………'}
          &nbsp;with effect from&nbsp;
          {certificateDetails?.effective_from ? moment(certificateDetails?.effective_from).format('DD MMM YYYY') : 
            '……………………………………………………………………………}'}
            &nbsp;is absolutely necessary for the restoration of&nbsp;
            {certificateDetails?.gender === 'Male' ? 'his' : 'her'} health.
        </p>
      </div>
      <div className='flex pt-[40px] justify-end'>
        <p>Signature of Medical Practitioner</p>
      </div>
      <div className='grid grid-cols-12 pt-[30px]'>
        <div className='col-span-6 flex flex-col'>
          <p>Place: {certificateDetails?.kiosk_location}</p>
          <p>Date: {moment().format('DD MMM YYYY')}</p>
        </div>
        <div className='col-span-6 flex flex-col'>
          <p>Name of Doctor: {certificateDetails?.doctor_name}</p>
          <p>Registration No: {certificateDetails?.registration_no}</p>
        </div>
      </div>
    </>
  );
};

export default MedicalCertificatePreview;