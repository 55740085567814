import React from 'react';
import { useSelector } from 'react-redux';
import { awsdetails } from '../../../../constants/awsdetails';

const Reports = () => {
  const reportsData = useSelector((state) => state?.selectedDropdownValues?.vitals?.reports);

  const handleOpenReport = (data) => {
    const url = `${awsdetails?.awsUrl}/${data?.attachment_name}`;
    window.open(url);
  };

  return (
    <div>
      {reportsData?.length > 0 ? (
        <table className='prescriptionTable w-full h-full'>
          <thead>
            <tr className='bg-[#f2f2f2]'>
              <th className='text-[12px] p-[10px] text-[#555] text-start'>Document</th>
              <th className='text-[12px] p-[10px] text-[#555] text-start'>Action</th>
              <th></th>
            </tr>
          </thead>
          <tbody className='text-[12px] text-[#333]'>
            {reportsData?.map((data, index) => (
              <tr key={index}>
                <td className='capitalize'>
                  {data.original_name.length > 24 ? data.original_name.substring(0,23)+'..' : data.original_name}
                </td>
                <td className='cursor-pointer' onClick={() => handleOpenReport(data)}>
                  View
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className='p-[10px]'>
          <p className='text-[#777777]'>No Data Sent</p>
        </div>
      )}
    </div>
  );
};

export default Reports;