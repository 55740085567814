import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Colorcode } from '../../../common/utils/Colorcode';

const customerColor = Colorcode();
const PatientDetailBox = ({
  title,
  children,
  showCloseIcon,
  handleCloseIcon
}) => {
  return (
    <div className='w-full h-full' style={title === 'Patient Details' ? { maxHeight: '27%' } : { minHeight: '1%' }}>
      <div
        className='h-full bg-white rounded-[25px] flex flex-col border-[1px] border-[#ddd]'
        style={{ boxShadow: '0 2px 5px 0 rgba(0,0,0,.25)' }}
      >
        <div className='pt-[20px]'>
          <div className='lineAroundText flex relative justify-between z-[1]'>
            <span className='ml-[20px] px-[5px] text-[#383678] bg-white fw-500 text-sm'>{title}</span>
            {showCloseIcon && (
              <div className='cursor-pointer mr-[20px] px-[10px] bg-white' onClick={() => handleCloseIcon()}>
                <FontAwesomeIcon icon={faXmark} style={{ color: customerColor[0] }} />
              </div>
            )}
          </div>
        </div>
        <div className='p-[10px]'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PatientDetailBox;
