import React, { useState, useEffect } from 'react';
import {
  useGetAdminPatientsListMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import CommonFilterSelect from '../common/FilterDropdown';
import Loader from '../common/Loader';
import SharedTable from '../common/SharedTable';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Colorcode } from '../common/utils/Colorcode';

const PatientList = () => {

  const isAdmin = localStorage.getItem('privileges') === 'admin' ? true : false;
  const navigate = useNavigate();
  const customerColor = Colorcode();
  const [getList] = useGetAdminPatientsListMutation();
  const [loading, setLoading] = useState(false);
  const [loadOnce, setLoadOnce] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [pageNumber, setPageNumber] = useState('1');
  const [filterString, setFilterString] = useState('all');
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });

  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Verified' },
    { value: 'inactive', label: 'Not Verified' }
  ];

  const preSelectedFilter = {
    selectedOption: 'all' // Set the initial value to 'option1'
  };

  const tableHeaders = [
    { field: 'client_id', headerName: 'ID' },
    { field: 'first_name', headerName: 'Name' },
    { field: 'email_id', headerName: 'E-Mail' },
    { field: 'phone_number', headerName: 'Phone No' },
    { field: 'dob', headerName: 'DOB' },
    { field: 'address', headerName: 'Address' },
    { field: 'customer_name', headerName: 'Customer' },
    { field: 'patient_list_status', headerName: 'Status' },
    { field: 'total_consults', headerName: 'Consults' }
  ];

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header.field === 'address') {
      header.field = 'full_address';
    }
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const fetchTableData = (page, status, search) => {
    if (!loadOnce) { setLoading(true); }
    getList({
      endpoint: `${apiConstants.adminPatientList}/${page}/${status}/${search}`,
      method: 'POST',
      data: {
        consult_type: '',
        current_time: '',
        data: {},
        date: '',
        field: sort.headerFiled,
        type: sort?.sortType,
        kiosk_id: ''
      }
    }).then((result) => {
      let patientListData = result?.data?.list;
      const modifiedData = patientListData.map(item => ({
        ...item,
        first_name: item.first_name + ' ' + item.last_name,
        dob: moment(item.dov).format('YYYY-MM-DD'),
        address: item.address_line_1 + ',' + item.address_line_2 + ',' +
          item.city + ',' + item.state + ',' + item.country + ',' + item.pincode,
        patient_list_status: (item.status === 'active' ? <div className='flex justify-start items-center gap-[5px]'>
          <div className="w-[10px] h-[10px] bg-[red] rounded-full"></div>
          <div>Offline</div>
        </div> : <div className='flex justify-start items-center gap-[5px]'>
          <div className="w-[10px] h-[10px] bg-[#808080] rounded-full"></div>
          <div>Disabled</div>
        </div>)
      }));
      setTableData(modifiedData);
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setPageNumber(parseInt(result?.data?.current_page));
      setLoading(false);
      setLoadOnce(true);
    }).catch((error) => {
      setLoading(false);
      console.error('error', error);
    });
  };

  useEffect(() => {
    fetchTableData(pageNumber, filterString, searchString);
  }, [pageNumber, searchString, sort]);

  useEffect(() => {
    fetchTableData(1, filterString, searchString);
  }, [filterString]);

  const handleOnPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleFilterSelect = (selectedOption) => {
    setFilterString(selectedOption.value);
  };

  const onSearch = (search) => {
    setPageNumber(1);
    setSearchString(search);
  };

  const onRowSelected = (event) => {
    if (isAdmin) {
      navigate(`/admin/patient-view/${event.client_id}`);
    }
  };

  return (
    <div className="row mt-6 mx-24">
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div>
          <CommonFilterSelect
            initialValues={preSelectedFilter}
            options={filterOptions}
            onSearch={onSearch}
            onChange={handleFilterSelect}
            showFilter
          />
          <div className="mt-7">
            <h4 style={{ borderColor: customerColor[0] }} className="text-2xl font-semibold border-b-4
                            text-[Coolvetica Rg] list-header-text w-[140px] text-[#4f4f4f]">
              Patient List
            </h4>
          </div>
          <div className="mt-10">
            <SharedTable
              tableData={tableData}
              tableHeaders={tableHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              onRowSelected={onRowSelected}
              handleOnClickSort={handleOnClickSort}
              currentPageNumber={pageNumber}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PatientList;
