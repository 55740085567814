import React, { useState, useEffect } from 'react';
import {
  useGetconsultsfortodayMutation
} from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import SharedTable from '../../common/SharedTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Colorcode } from '../../common/utils/Colorcode';

const TodaysConsults = () => {
  const navigate = useNavigate();
  const customerColor = Colorcode();
  const [getconsultsfortoday] = useGetconsultsfortodayMutation();
  var [pageNumber, setPageNumber] = useState('1');
  const [search, setSearch] = useState('');
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [todaysConsultList, setTodaysConsultList] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });

  const handleRowData = () => {
  };

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const handleSearch = (e) => {
    pageNumber = 1;
    setSearch(e.target.value);
  };

  const fetchTodaysConsultList = (pageNumber) => {
    const pageNum = pageNumber || 1;
    getconsultsfortoday({
      endpoint: search ? `${apiConstants.getconsultsfortoday}/${pageNum}/all/${search}` :
        `${apiConstants.getconsultsfortoday}/${pageNum}/all`,
      method: 'POST',
      data: {
        consult_type: '',
        current_time: '',
        data: {},
        date: '',
        field: sort.headerFiled,
        type: sort?.sortType,
        kiosk_id: ''
      }
    }).then((result) => {
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setPageNumber(parseInt(result?.data?.current_page));

      let todaysConsultList = result?.data?.list;
      const modifiedData = todaysConsultList.map(item => ({
        ...item,
        status: item.status[0].toUpperCase() + item.status.slice(1),
        doctor_joined: item.doctor_joined === 0 ? 'No' : 'Yes',
        patient_joined: item.patient_joined === 0 ? 'No' : 'Yes',
        appointment_time: item.appointment_time && moment(item.appointment_time).format('DD MMM YYYY'),
        appointment_type: item.appointment_type === 1 ? 'OPD' : '',
        upload:
          (<button className="ml-0 rounded-[20px] float-right bg-[#383678] text-white px-[25px] py-[5px]
            text-sm mt-1.5 mx-auto flex cursor-pointer"
            style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
            onClick={() => navigate(`/agent/upload/${item.appointment_id}`)}>
            Upload
          </button>)
      }));
      setTodaysConsultList(modifiedData);
    }).catch(error => {
      console.log('error', error);
    });
  };

  useEffect(() => {
    fetchTodaysConsultList(1);
  }, [search, sort]);

  const todaysConsultHeaders = [
    { field: 'appointment_id', headerName: 'ID' },
    { field: 'appointment_time', headerName: 'Time' },
    { field: 'doctor_name', headerName: 'Doctor' },
    { field: 'patient_name', headerName: 'Patient' },
    { field: 'phone_number', headerName: 'Patient Phone' },
    // { field: 'doctor_joined', headerName: 'Doctor Joined' },
    // { field: 'patient_joined', headerName: 'Patient Joined' },
    { field: 'status', headerName: 'Status' },
    // { field: 'appointment_type', headerName: 'Consult Type' },
    { field: 'upload', headerName: 'Upload' }
  ];

  const handleOnPageChange = (pageNumber) => {
    fetchTodaysConsultList(pageNumber);
  };
  return (
    <div className='w-full h-full'>
      <div className='grid grid-cols-12 px-[6%] pt-[3%]'>
        <div className='col-span-12'>
          <div className="" style={{ float: 'right', marginTop: 10 }}>
            <div className=''>

              <div className="container-list-header">

                <div className="search relative">
                  <input
                    type="text"
                    placeholder=" "
                    value={search}
                    autoComplete="off"
                    id="patientSearch"
                    name="patientSearch"
                    onChange={(e) => handleSearch(e)}
                    onFocus={() => setShowSearchIcon(false)}
                    onBlur={() => setShowSearchIcon(true)} />
                  <FontAwesomeIcon icon={faSearch}
                    className={`text-gray-500 absolute right-[10px] top-0 text-[20px]
                    search-icon ${showSearchIcon ? 'search-visible' : 'search-hidden'}`} />
                  {/* <div>
                    <svg>
                      <use xlinkHref="#path" />
                    </svg>
                  </div> */}
                </div>
                {/* <svg xmlns="axon" style={{ display: 'none' }}>
                  <symbol xmlns="axon" viewBox="0 0 160 28" id="path">
                    <path d="M32.9418651,-20.6880772 C37.9418651,-20.6880772 40.9418651,-16.6880772 40.9418651,
                    -12.6880772 C40.9418651,-8.68807717 37.9418651,-4.68807717 32.9418651,-4.68807717 C27.9418651,
                    -4.68807717 24.9418651,-8.68807717 24.9418651,-12.6880772 C24.9418651,-16.6880772 27.9418651,
                    -20.6880772 32.9418651,-20.6880772 L32.9418651,-29.870624 C32.9418651,-30.3676803 33.3448089,
                    -30.770624 33.8418651,-30.770624 C34.08056,-30.770624 34.3094785,-30.6758029 34.4782612,
                    -30.5070201 L141.371843,76.386562"
                      transform="translate(83.156854, 22.171573) rotate(-225.000000) translate(-83.156854, -22.171573)">
                    </path>
                  </symbol>
                </svg> */}

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-12 pl-[6.5%] pr-[30px] pt-[30px]'>
        <div className='col-span-12 pb-[15px]'>
          <span className='underline-heading' style={{ borderColor: customerColor[0] }}>
            Today&apos;s Consults List
          </span>
        </div>
      </div>
      <div className="grid grid-cols-12 px-[6%] pb-[30px]">
        <div className='col-span-12 mt-[20px]'>
          <SharedTable
            tableData={todaysConsultList}
            tableHeaders={todaysConsultHeaders}
            handleOnPageChange={handleOnPageChange}
            isMoreData={isMoreData}
            totalPages={totalPages}
            onRowSelected={handleRowData}
            handleOnClickSort={handleOnClickSort}
            currentPageNumber={pageNumber}
          />
        </div>
      </div>
    </div>
  );
};

export default TodaysConsults;
