import React, { useState, useRef } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import DoctorImage from '../../images/icons/Doctor_icon.svg';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import {
	useAdminCreateDoctorMutation,
	useUploadPhotoMutation,
	useAdminUploadDoctorCertificateMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import ProfilePic from '../../images/general_pic.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { Colorcode } from '../common/utils/Colorcode';

const Schema = yup.object().shape({
	profile_pic: yup.string().required('Please upload Profile Pic'),
	fname: yup.string()
		.matches(/^[A-Za-z ]*$/, 'Please enter valid first name')
		.max(40).required('Please Enter First Name Without Empty Spaces & Numbers'),
	lname: yup.string()
		.matches(/^[A-Za-z ]*$/, 'Please enter valid last name')
		.max(40).required('Please Enter Last Name Without Empty Spaces & Numbers'),
	phone_number: yup.string().min(13, 'Please enter a valid phone number')
		.max(13, 'Please enter a valid phone number').required('Please Enter a Valid Phone Number'),
	email_id: yup.string().email('Must be valid email').required('Please Enter Valid Email'),
	registration_no: yup.string().required('Please Enter Registration no'),
	credential: yup.string().required('Please Enter credential')
	// email: yup.string().email('Must be valid email').required('Please Enter Valid Email')
});

const ParamedicCreate = () => {
	const customerColor = Colorcode();
	const navigate = useNavigate();
	const user_id = localStorage.getItem('user_)id');

	const [phoneNumber, setPhoneNumber] = useState('');
	const [createDoctor] = useAdminCreateDoctorMutation();
	const [uploadPic] = useUploadPhotoMutation();

	const [uploadCertificate] = useAdminUploadDoctorCertificateMutation();

	const [fileName, setFileName] = useState();
	const [doc, setDoc] = useState('');
	const [imgUrl, setImgUrl] = useState('');
	const hiddenUserFileInput = useRef(null);
	const hiddenUserPic = useRef(null);

	const handleClickDoc = () => {
		hiddenUserFileInput.current.click();
	};

	const handleClickPic = () => {
		hiddenUserPic.current.click();
	};
	const handleUploadPic = (e, setFieldValue) => {
		if (e.target.files && e.target.files.length > 0) {
			const formData = new FormData();
			formData.append('image', e.target.files[0], e.target.files[0].name);
			uploadPic({
				endpoint: apiConstants.uploadPhoto,
				method: 'POST',
				data: formData || null
			}).then((result) => {
				setImgUrl(result?.data);
				setFieldValue('profile_pic', result?.data);
			}).catch((error) => {
				console.error('error', error);
			});
		}
		hiddenUserPic.current.value = '';
	};

	const certificateFileUpload = (e) => {
		if (e.target.files && e.target.files.length > 0) {
			setFileName(e.target.files[0].name);
			uploadCertificate({
				endpoint: apiConstants.admicDoctorCertificateUpload,
				method: 'POST',
				data: {
					doctor_id: user_id,
					fileName: e.target.files[0].name,
					type: 'certificate'
				}
			}).then((result) => {
				if (result) {
					let signedUrl = result.data.signedUrl;
					setDoc(result.data.publicUrl);
					fetch(signedUrl, {
						method: 'PUT',
						body: e.target.files[0]
					}).then((res) => {
						console.log(res);
					});
				}
			}).catch((error) => {
				console.error('error', error);
			});
		}
	};

	const privacypolicy = () => {
		window.open('https://smarthealth.sakhaglobal.com/privacypolicy');
	};

	const termsandconditions = () => {
		window.open('https://smarthealth.sakhaglobal.com/termsandconditions');
	};
	const handleSubmit = (values) => {
		const transformedData = {
			fname: values.fname,
			lname: values.lname,
			email_id: values.email_id,
			phone_number: phoneNumber,
			isPublic: 0,
			certificate: doc || '',
			registration_no: values.registration_no,
			credential: values.credential,
			profile_photo: imgUrl || ''
		};
		createDoctor({
			endpoint: apiConstants.adminCreateDoctor,
			method: 'POST',
			data: { data: transformedData }
		}).then(() => {
			navigate('/admin/doctorlist');
		}).catch((error) => {
			console.error('error', error);
		});
	};

	return (
		<div className='my-[2%] mx-[5%]'>
			<input
				id="uploadDoc"
				type="file"
				accept=".xlsx, .png, .pdf, .jpg"
				ref={hiddenUserFileInput}
				style={{ display: 'none' }}
				onChange={(e) => certificateFileUpload(e)}
			/>

			<div className='bg-[#f4f4f4] grid grid-cols-12 mt-28'>
				<div className='col-span-6'>
					<div className='text-[#737575] text-[40px] mt-[25px] ml-48'>Create Doctor</div>
					<img className="h-auto max-w-lg mx-auto ml-36 mt-10" src={DoctorImage} alt="image description" />
				</div>
				<div className='col-span-6'>
					{/* form here */}
					<Formik
						initialValues={{
							profile_pic: '',
							fname: '',
							lname: '',
							phone_number: '',
							email_id: '',
							registration_no: '',
							credential: ''
						}}
						validationSchema={Schema}
						onSubmit={handleSubmit}
					>
						{({ setFieldValue }) => (
							<Form className='flex flex-col items-center gap-[30px] mt-[30px]'>
								<input
									id="uploadPic"
									type="file"
									accept=".png, .jpg"
									ref={hiddenUserPic}
									style={{ display: 'none' }}
									onChange={(e) => handleUploadPic(e, setFieldValue)}
								/>
								<div className='relative w-[55%] flex gap-[15px]'>
									<div className='relative'>
										<Field name="profile_pic">
											{({ field }) => (
												<img
													{...field}
													title="Click to change profile pic"
													className="reg-user-icon profile_pic"
													src={imgUrl ? imgUrl : ProfilePic}
													onClick={() => handleClickPic()}
												/>
											)}
										</Field>
										<ErrorMessage name="profile_pic" component="div"
											className='text-red-600 absolute top-[62px]' />
									</div>
									<div className='flex flex-col gap-[30px] w-[75%]'>
										<div className='flex items-baseline relative'>
											<Field
												name="fname"
												type='text'
												placeholder="First name*"
												autoComplete="off"
												className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
											/>
											<ErrorMessage name="fname" component="div"
												className='text-red-600 absolute top-[40px]' />
										</div>
										<div className='relative'>
											<Field
												name="lname"
												type='text'
												placeholder="Last name*"
												autoComplete="off"
												className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full"
											/>
											<ErrorMessage name="lname" component="div"
												className='text-red-600 absolute top-[25px]' />
										</div>
									</div>
								</div>
								{/*  phone number*/}
								<div className="relative w-[55%]"
									style={{ display: 'grid', background: '#f4f4f4' }}>
									<Field name="phone_number"
										className='createCustomer border-b border-[#A9A9A9] opacity-70
								 bg-[#f4f4f4] outline-none w-full py-2 px-3'>
										{({ field }) => (
											<PhoneInput
												{...field}
												name="phone_number"
												type="tel"
												placeholder="Phone Number*"
												country={'in'}
												onlyCountries={['in']}
												autoFormat={false}
												onChange={(data) => {
													const phoneNumber = '+' + data;
													setPhoneNumber(phoneNumber);
													setFieldValue('phone_number', phoneNumber);
												}}
											/>
										)}
									</Field>
									<ErrorMessage name="phone_number" component="div"
										className='text-red-600 absolute top-[40px]' />
								</div>
								{/* email field */}
								<div className='relative w-[55%]'>
									<Field
										name="email_id"
										type='text'
										placeholder="Email Id*"
										autoComplete="off"
										className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
									/>
									<ErrorMessage name="email_id" component="div" className='text-red-600 absolute' />
								</div>
								<div className='relative w-[55%]'>
									<Field
										name="registration_no"
										type='text'
										placeholder="Registration No*"
										autoComplete="off"
										className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
									/>
									<ErrorMessage name="registration_no"
										component="div" className='text-red-600 absolute' />
								</div>
								{/* Contact number */}
								<div className='relative w-[55%]'>
									<Field
										name="credential"
										type='text'
										placeholder="Professional Degree*"
										autoComplete="off"
										className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
									/>
									<ErrorMessage name="credential" component="div" className='text-red-600 absolute' />
								</div>

								<div className='flex-col mt-6'>
									<label className="mb-2 m-4 font-medium">
										Professional Degree Certificate</label>
									<div className='cursor-pointer flex items-center gap-[5px]'
										onClick={() => handleClickDoc()}
									>
										<FontAwesomeIcon icon={faPaperclip}
											className='text-white right-[10px] top-0 text-[20px] h-[20px] w-[20px] 
										bg-[#383678] p-[10px] rounded-[50%]' />
										<div>{fileName ? fileName : 'certificate'}</div>
									</div>
								</div>
								{/* Buttons */}
								<div className='flex gap-[40px] w-[55%]'>
									<button
										type='submit'
										className="bg-[#383678] text-white px-[50px] py-[5px] w-[150px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center h-[48px]"
										style={{
											background: `linear-gradient(90deg, 
											${customerColor[0]}, ${customerColor[1]})`
										}}
									>
										Submit
									</button>
									<button
										type='text'
										className="bg-[#383678] text-white px-[50px] py-[5px] w-[150px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center h-[48px]"
										style={{
											background: `linear-gradient(90deg, 
											${customerColor[0]}, ${customerColor[1]})`
										}}
										onClick={() => navigate(-1)}
									>
										Cancel
									</button>
								</div>
							</Form>
						)}
					</Formik>
					<div>
					</div>
					<div className='text-[#737575] ml-32 mt-10 mb-10'>By clicking on Submit you accept our
						<span className='underline cursor-pointer hover:font-semibold'
							onClick={privacypolicy}> Privacy Policy</span> and
						<span className='underline cursor-pointer hover:font-semibold'
							onClick={termsandconditions}> Terms and Conditions.</span>
					</div>
				</div>
			</div>
		</div >
	);
};

export default ParamedicCreate;