import React, { useEffect, useState } from 'react';
import { deepCopy } from '../../Doctor/OpdAppointment/PatientDetails/MedicalHistory';
import DynamicInputElements from '../../Doctor/OpdAppointment/PatientDetails/MedicalHistory/DynamicInputElements';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DeleteAttachmentModal from '../../Doctor/OpdAppointment/PatientDetails/MedicalHistory/DeleteAttachmentModal';
import RepeaterComponent2 from './RepeaterComponent2';
import { capitalizeFirstLetter } from './index';

const RepeaterComponent = ({
  errorData,
  jsonData,
  customerColor,
  dropdownOptions,
  updateSectionData
}) => {

  const [historyData, setHistoryData] = useState(jsonData);
  const [isQuestionDeleteModalOpen, setIsQuestionDeleteModalOpen] = useState(false);
  const [isOptionDeleteModalOpen, setIsOptionDeleteModalOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState({ item: '', question: '' });
  const createQuestion = () => {
    let Data = deepCopy(historyData);
    let QuestionCount = Object.keys(Data.questions).length;
    Data.questions[`question-${QuestionCount + 1}`] = {
        title: '',
        value: '',
        type: '',
        data: {},
        children: {},
        priority: QuestionCount + 1
    };
    setHistoryData(Data);
  };

  useEffect(() => {
    updateSectionData(historyData);
  }, [historyData]);

  const handleSectionNameChange = (e) => {
    let data = deepCopy(historyData);
    const { id, value } = e.target;
    const idSplit = id.split('_');
    if (idSplit.length === 1) {
      data[idSplit[0]].title = value;
    }
    else if (idSplit.length === 2) {
      data.questions[idSplit[1]].title = value;
    }
    setHistoryData(data);
  };

  const handleDropdownChangeType = (e, id) => {
    let data = deepCopy(historyData);
    const { value } = e;
    const idSplit = id.split('_');
    if (idSplit.length === 1) {
      data[idSplit[0]].handleChangeType = value;
      setHistoryData(data);
    }
    else if (idSplit.length === 2) {
      data.questions[idSplit[1]].type = value;
      setHistoryData(data);
      if (value === 'radio') {
        createRadioOptions(idSplit[1], 2, true, value);
      } else {
        data.questions[idSplit[1]].data = {};
        setHistoryData(data);
      }
    }
  };

  const createRadioOptions = (dataOption, numberOfOptions, isValue, value) => {
    let Data = deepCopy(historyData);
    let QuestionCount = Object.keys(Data.questions[dataOption].data).length;
    for (let i = 0; i < numberOfOptions; i++) {
      Data.questions[`${dataOption}`].data[`option-${QuestionCount + (i+1)}`] = {
        title: '',
        isChild: false,
        children: { questions: {} }
      };
    }
    if (isValue) {
      Data.questions[`${dataOption}`].type = value;
    }
    setHistoryData(Data);
  };

  const handleChangeIsChild = (e) => {
    let data = deepCopy(historyData);
    const { id, checked } = e.target;
    const idSplit = id.split('_');
    if (idSplit.length === 1) {
      data[idSplit[0]].isChild = checked;
    }
    else if (idSplit.length === 2) {
      data.questions[idSplit[1]].data['i'] = checked;
    } else if (idSplit.length === 3) {
      data.questions[idSplit[1]].data[idSplit[2]].isChild = checked;
    }
   setHistoryData(data);
  };

  const handleOnDefaultOptionChange = (question, option) => {
    let Data = deepCopy(historyData);
    Data.questions[question].value = Data.questions[question].data[option].title;
    setHistoryData(Data);
  };

  const handleChangeDataOptions = (e) => {
    let data = deepCopy(historyData);
    const { id, value } = e.target;
    const idSplit = id.split('_');
    if (idSplit.length === 1) {
      data[idSplit[0]].title = value;
    }
    else if (idSplit.length === 2) {
      data.questions[idSplit[1]].data['i'] = value;
    } else if (idSplit.length === 3) {
      if (data.questions[idSplit[1]].data[idSplit[2]].title === data.questions[idSplit[1]].value) {
        data.questions[idSplit[1]].data[idSplit[2]].title = value;
        data.questions[idSplit[1]].value = value;
      } else if (data.questions[idSplit[1]].data[idSplit[2]].title !== data.questions[idSplit[1]].value) {
        data.questions[idSplit[1]].data[idSplit[2]].title = value;
      }
    }
    setHistoryData(data);
  };

  const handleQuestionDelete = () => {
    let Data = deepCopy(historyData);
    delete Data.questions[dataToDelete?.item];
    setIsQuestionDeleteModalOpen(false);
    setHistoryData(Data);
  };

  const handleOptionDelete = () => {
    let Data = deepCopy(historyData);
    delete Data.questions[dataToDelete?.question].data[dataToDelete?.item];
    setIsOptionDeleteModalOpen(false);
    setHistoryData(Data);
  };

  const updateHistoryData = (childData, question, item) => {
    let Data = deepCopy(historyData);
    Data.questions[question].data[item].children = childData;
    setHistoryData(Data);
  };

  return (
    <>
     {isQuestionDeleteModalOpen && (
        <DeleteAttachmentModal
          heading='Are you sure you want to delete this question?'
          handleModalClose={() => setIsQuestionDeleteModalOpen(false)}
          handleDeleteAttachment={handleQuestionDelete}
        />
      )}
       {isOptionDeleteModalOpen && (
        <DeleteAttachmentModal
          heading='Are you sure you want to delete this option?'
          handleModalClose={() => setIsOptionDeleteModalOpen(false)}
          handleDeleteAttachment={handleOptionDelete}
        />
      )}
      <div className='pt-[20px]'>
        <button
          type='submit'
          className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px]'
          style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
          onClick={() => createQuestion()}
        >
          + Add Question
        </button>
        {historyData && Object.keys(historyData?.questions)?.length > 0 && (
          <div className='pl-[100px] pt-[30px]'>
            {Object.keys(historyData?.questions)?.map((item, index) => {
              const question = item;
              return (
                <div key={index} className='flex flex-col gap-[10px] pt-[10px]'>
                  <div className='text-[12px]'>
                    <p>{(`sub-question - ${index + 1}`).toUpperCase()}</p>
                  </div>
                  <div className='grid grid-cols-12 gap-[30px]'>
                    <div className='col-span-6'>
                      <DynamicInputElements type='text' id={`section_${item}`} title='Question Title*'
                        value={historyData?.questions[`${item}`]?.title} 
                        handleOnInputElementChange={handleSectionNameChange} 
                        error={errorData && errorData[question] && errorData[question]?.title || ''}
                      />
                    </div>
                    <div className='col-span-2'>
                      <DynamicInputElements type='dropdown' options={dropdownOptions}
                        value={{ label: capitalizeFirstLetter(historyData?.questions[`${item}`]?.type), 
                        value: historyData?.questions[`${item}`]?.type}} id={`section_${item}`}
                        handleOnInputElementChange={handleDropdownChangeType} 
                        error={errorData && errorData[question] && errorData[question]?.type || ''}
                      />
                    </div>
                    <div className='col-span-1 flex self-center cursor-pointer'
                      onClick={() => { setDataToDelete({item: item }); setIsQuestionDeleteModalOpen(true); }}
                    >
                      <FontAwesomeIcon icon={faTrash} color='red' />
                    </div>
                  </div>
                  {historyData && historyData?.questions[`${item}`]?.type === 'radio' && (
                    <div className='pt-[30px]'>
                      <button
                        type='submit'
                        className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px]'
                        style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                        onClick={() => createRadioOptions(item, 1)}
                      >
                        + Add Options
                      </button>
                      {historyData?.questions[`${item}`] && 
                        Object.keys(historyData?.questions[`${item}`]?.data)?.map((item1, index) => {
                          return (
                            <div key={index} className='pl-[100px] py-[10px]'>
                              <div className='grid grid-cols-12 gap-[30px] items-end'>
                                <div className='col-span-5'>
                                  <DynamicInputElements type='text' id={`section_${question}_${item1}`}
                                    value={historyData.questions[`${question}`].data[item1].title} title='Option Title*'
                                    handleOnInputElementChange={handleChangeDataOptions}
                                    error={errorData && errorData[question] && errorData[question]?.options &&
                                      errorData[question]?.options[item1] || ''}
                                  />
                                </div>
                                <div className='col-span-3 flex gap-[10px] items-baseline'>
                                  <input id={`section_${question}_${item1}`} type="checkbox"
                                    checked={historyData.questions[`${question}`].data[item1].isChild}
                                    onChange={handleChangeIsChild}
                                  />
                                  <p>Are there sub questions on select?</p>
                                </div>
                                <div className='col-span-2 flex gap-[10px] items-center'>
                                  <input id={`section_${question}_${item1}`} type="radio"
                                    onChange={() => handleOnDefaultOptionChange(question, item1)}
                                    checked={historyData.questions[`${question}`].data[item1].title ===
                                      historyData.questions[`${question}`].value}
                                  />
                                  <p>Is Default</p>
                                </div>
                                {index >= 2 && (
                                  <div className='col-span-1 flex self-center cursor-pointer'
                                    onClick={() => { setDataToDelete({ item: item1, question: question}); 
                                    setIsOptionDeleteModalOpen(true);}}
                                  >
                                    <FontAwesomeIcon icon={faTrash} color='red' />
                                  </div>
                                )}
                              </div>
                              {historyData.questions[`${question}`].data[item1].isChild && 
                                <div>
                                  <RepeaterComponent2
                                    errorData={errorData && errorData[question] && errorData[question]?.children[item1]}
                                    jsonData={historyData.questions[`${question}`].data[item1].children}
                                    customerColor={customerColor}
                                    dropdownOptions={dropdownOptions}
                                    updateSectionData={updateSectionData}
                                    updateHistoryData={(data) => updateHistoryData(data, question, item1)}
                                  />
                                </div>
                              }
                            </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default RepeaterComponent;