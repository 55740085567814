import { Formik, Form, Field } from 'formik';
import React from 'react';
import ReactSelectFilters from '../../../../common/ReactSelectFilters';
import { specialities } from './constantDropdownOptions';
import { useAddAppointmentDataMutation } from '../../../../../reducers/allReducer';
import { apiConstants } from '../../../../../constants/api';

const ReferComponent = ({ userId, appointmentId, referredInfo }) => {
  const [addAppointmentData] = useAddAppointmentDataMutation();
  
  const handleReferSpecialist = (specialist) => {
    addAppointmentData({
      endpoint: `${apiConstants?.addAppointmentData}/${userId}`,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        referred_to: specialist?.value || null,
        type: 'referred_to'
      }
    });
  };

  const handleReferHigherCenter = (value) => {
    addAppointmentData({
      endpoint: `${apiConstants?.addAppointmentData}/${userId}`,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        refer_to_higher_center: value,
        type: 'refer_to_higher_center'
      }
    });
  };

  return (
    <Formik
      initialValues={{ 
        refer_specialist: referredInfo?.referred_to !== null ?  'yes' : 'no', 
        refer_higher_center: referredInfo?.refer_to_higher_center === 1 ? 'yes' : 'no',
        speciality: {value: referredInfo?.referred_to, label: referredInfo?.referred_to}
      }}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div className='flex flex-col gap-[10px]'>
            <div className='grid grid-cols-12 gap-[20px] items-end'>
              <div className='col-span-6 flex gap-[20px] items-center'>
                <span className='text-[#5b5b5b] font-[600] text-[12px]'>Refer to specialist?</span>
                <div className='flex gap-[20px]'>
                  <div className='flex gap-[10px] items-center'>
                    <Field 
                      type='radio' 
                      name='refer_specialist' 
                      value='yes' 
                      checked={values?.refer_specialist === 'yes'}
                      onChange={() => {
                        setFieldValue('speciality', '');
                        setFieldValue('refer_specialist', 'yes');
                      }}
                    />
                    Yes
                  </div>
                  <div className='flex gap-[10px] items-center'>
                    <Field
                      type='radio' 
                      name='refer_specialist' 
                      value='no' 
                      checked={values?.refer_specialist === 'no'}
                      onChange={() => {
                        setFieldValue('speciality', '');
                        setFieldValue('refer_specialist', 'no'); 
                        handleReferSpecialist();
                      }}
                    />
                    No
                  </div>
                </div>
              </div>
              {values?.refer_specialist === 'yes' && (
                <div className='col-span-6 w-8/12'>
                  <Field name='speciality'>
                    {({ field }) => (
                      <ReactSelectFilters 
                        {...field}
                        options={specialities}
                        placeholder='Select Speciality'
                        name='specialityDropdown'
                        onChange={(option) => {
                          setFieldValue('speciality', option);
                          handleReferSpecialist(option);
                        }}
                        disableEnterKey
                        selectFieldValues={values?.speciality}
                        isSearchable={false}
                      />
                    )}
                  </Field>
                </div>
              )}
            </div>
            <div className='flex gap-[20px] items-center'>
              <span className='text-[#5b5b5b] font-[600] text-[12px]'>
                Refer to higher center for furthe advice and management?
              </span>
              <div className='flex gap-[10px]'>
                <Field 
                  type='radio' 
                  name='refer_higher_center' 
                  value='yes' 
                  checked={values?.refer_higher_center === 'yes'}
                  onChange={() => {setFieldValue('refer_higher_center', 'yes'); handleReferHigherCenter(1);}}
                />
                Yes
              </div>
              <div className='flex gap-[10px]'>
                <Field
                  type='radio' 
                  name='refer_higher_center' 
                  value='no' 
                  checked={values?.refer_higher_center === 'no'}
                  onChange={() => {setFieldValue('refer_higher_center', 'no'); handleReferHigherCenter(0);}}
                />
                No
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ReferComponent;