import React, { useState, useEffect } from 'react';
import Box from '../common/Box';
import {
  useLazyGetUpcomingConsultsForDoctorQuery,
  useLazyGetvideosdkcredsQuery
} from '../../reducers/allReducer';
import SharedTable from '../common/SharedTable';
import { apiConstants } from '../../constants/api';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Colorcode } from '../common/utils/Colorcode';

const Doctor = () => {
  const customerColor = Colorcode();
  const [getUpcomingConsultList] = useLazyGetUpcomingConsultsForDoctorQuery();
  const [getVideoCreds] = useLazyGetvideosdkcredsQuery();
  const [tableData, setTableData] = useState([]);
  const [weeklyCount, setWeeklyCount] = useState(0);
  const [monthlyCount, setMonthlyCount] = useState(0);
  const [todayCount, setTodayCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [originalData, setOriginalData] = useState([]);
  const navigate = useNavigate();

  // table headers
  const tableHeaders = [
    { field: 'appointment_id', headerName: 'Appointment Id' },
    { field: 'name', headerName: 'Patient Name' },
    // { field: 'phone_number', headerName: 'Phone No' },
    { field: 'kiosk_name', headerName: 'Kiosk' },
    { field: 'appointment_time_updated', headerName: 'Appointment' },
    { field: 'statusUpdated', headerName: 'Status' },
    { field: 'action', headerName: '' }
  ];

  const joinMeeting = (appointmentId, meetingId) => {
    const url = `/doctor/opdappointment/${appointmentId}/${meetingId}`;
    navigate(url);
  };

  const getUpcomingConsultsDetails = () => {
    let userId = localStorage.getItem('user_id');
    getUpcomingConsultList({
      endpoint: `${apiConstants.getUpcomingConsultants}/${userId}`
    }).then((result) => {
      let opdList = result?.data?.data?.opdconsults;
      let modifiedList = opdList.map(consult => ({
        ...consult,
        // appointment_time_updated: new Date(consult.appointment_time).toUTCString(),
        appointment_time_updated: moment(consult.appointment_time).format('DD MMM YY, HH:mm'),
        statusUpdated: consult.status[0].toUpperCase() + consult.status.slice(1),
        action: consult?.status === 'scheduled' || consult?.status === 'Scheduled' ?
          (<button className="rounded-[20px] text-white px-[25px]
        py-[5px] text-sm mt-1.5 mx-auto flex cursor-pointer"
            style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
            onClick={() => joinMeeting(consult.appointment_id, consult.meetingId)}>Join</button>) : (<div>-</div>)
      })
      );
      setOriginalData(result?.data?.data?.opdconsults);
      setTableData(modifiedList?.splice(0, 5));
      setTodayCount(result?.data?.data?.TodaysConsults);
      setWeeklyCount(result?.data?.data?.WeeklyConsults);
      setMonthlyCount(result?.data?.data?.MonthlyConsults);
      setTotalCount(result?.data?.data?.totalConsults);
    });
  };

  useEffect(() => {
    getUpcomingConsultsDetails();
  }, []);

  useEffect(() => {
    getVideoCreds({
      endpoint: `${apiConstants.getvideosdkcreds}/3`,
      method: 'GET'
    }).then((result) => {
      localStorage.setItem('videoSdkToken', result.data.token);
    });
  }, []);

  const handleOnClickViewAll = () => {
    const url = '/doctor/upcoming-appointments';
    navigate(url);
  };

  return (
    <div className="p-[80px] pt-[30px] flex flex-col gap-[40px]">
      <div className="grid grid-cols-12 gap-[40px] mt-[30px] h-[100px]">
        <Box name="Today's Consults" className="text-sm" value={todayCount} color={'bg-orange-500'}></Box>
        <Box name="Weekly Consults" value={weeklyCount} color={'bg-blue-500'}></Box>
        <Box name="Monthly Consults" value={monthlyCount} color={'bg-blue-900'}></Box>
        <Box name="Total Consults" value={totalCount} color={'bg-stone-400'}></Box>
      </div>
      {/* upcoming consults part */}
      <div className="flex gap-[10px] items-center mb-[-30px]">
        <div className="text-l" style={{ color: customerColor[0] }}>{'Upcoming consults'}</div>
        <hr className="w-[80%]" />
      </div>
      <div className='text-end'>
        <button
          className='bg-blue-600 text-white px-[20px] py-[8px] rounded-[50px] text-[13px] text-nowrap'
          style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
          onClick={() => getUpcomingConsultsDetails()}
        >
          Refresh
        </button>
      </div>
      {/* Grid view */}
      <div className="gridview">
        <SharedTable
          tableData={tableData}
          tableHeaders={tableHeaders}
          isPagination
        />
      </div>
      {originalData?.length > 5 && (
        <div className='p-[20px] text-center'>
          <button
            className='bg-blue-600 text-white px-[30px] py-[10px] rounded-[50px] text-[18px] text-nowrap'
            style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
            onClick={() => handleOnClickViewAll()}
          >
            View All
          </button>
        </div>
      )}
    </div>
  );
};

export default Doctor;
