import React, { useEffect, useState } from 'react';
import SakhaLogo from '../../images/Sakha-Global.png';
import { useLazyGetAdminCountQuery } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import { Colorcode } from '../common/utils/Colorcode';

const Sidebar = () => {
  const customerColor = Colorcode();
  const [adminCount] = useLazyGetAdminCountQuery();
  const userType = localStorage.getItem('user_type');
  const NavIcon = localStorage.getItem('Nav-Icon');

  const [count, setCount] = useState();
  useEffect(() => {
    adminCount({
      endpoint: apiConstants.adminCount
    }).then((res) => {
      setCount(res.data);
    }).catch((error) => {
      console.log('error', error);
    });
  }, []);

  const ActiveStatus = [
    {
      name: 'Doctors',
      total: count?.total_doctors,
      active: count?.active_doctors
    },
    {
      name: 'Paramedics',
      total: count?.total_paramedics,
      active: count?.active_paramedics
    },
    {
      name: 'Kiosks',
      total: count?.total_kiosks,
      active: count?.active_kiosks
    }
  ];

  const Appointment = [
    {
      name: 'Total Appointments',
      count: count?.appointment_statistics?.total_appointments
    },
    {
      name: 'Completed Appointments',
      count: count?.appointment_statistics?.completed_appointments
    },
    {
      name: 'Pending Appointments',
      count: count?.appointment_statistics?.pending_appointments
    }
  ];

  const RedFlagAppointment = [
    {
      name: 'Cancelled Appointments',
      count: count?.appointment_statistics?.cancelled_appointments
    },
    {
      name: 'Over Delayed Appointments',
      count: count?.appointment_statistics?.over_delayed_appointments
    }
  ];

  return (
    <div className='sidebar-box bg-[#fff] h-[100vh]'>
      <div className='pt-[20px] pl-[60px]'>
        <img src={userType === 'superadmin' ?
          SakhaLogo : 'https://sakha-health.s3-ap-southeast-1.amazonaws.com/' + NavIcon
        } alt='' width={150} height={60} />
      </div>
      <div className='sideNav-card w-[75%] h-[305px] rounded-[28px] bg-[#f7f7f7] m-[10%] mx-auto mb-0
      py-[30px] px-[20px]'>
        <p className='fw-500 text-[15px]' style={{ color: customerColor[0] }}>Active Status</p>
        {ActiveStatus?.map((items, index) => (
          <div className='' key={index}>
            <p className='spadmin-sidenav-rolename'>{items?.name}</p>
            <p className='spadmin-sidenav-actorcount'>
              <b>{items?.active}</b>
            </p>
            <div className='grid grid-cols-12'>
              <div className='col-span-11'>
                <div className="w-full border-[2px] border-[#ccc] h-[22px] mb-4 p-[2px] rounded-[4px]
					admin-progress-indicator-bar">
                  <div className='h-[14px] rounded-full'
                    style={{ background: customerColor[0], width: `${(items?.active / items?.total) * 100}%` }}></div>
                </div>
              </div>
              <div className='col-span-1'>
                <p className='fw-500 lh-10 text-[#A6A8A7] text-[17px]'>
                  {items?.total}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='sideNav-card w-[75%] h-[190px] rounded-[25px] bg-[#f7f7f7] m-[8%] mx-auto mb-10px
      py-[30px] px-[20px]'>
        <p className='fw-500 text-[15px] pb-[10px]' style={{ color: customerColor[0] }}>Appointment Statistics</p>
        <div className='text-[#737578] text-[12px] pl-[5px]'>
          {Appointment?.map((items, index) => (
            <div className='pb-1 grid grid-cols-12 py-[10px]' key={index}>
              <div className='col-span-10'>
                <h6 className='flag-text'>{items.name}</h6>
              </div>
              <div className='col-span-2 text-center'>
                <h6 className='flag-text'>{items.count}</h6>
              </div>
            </div>
          ))}
          <br />
        </div>

      </div>
      {/* red flag */}
      <div className='sideNav-card w-[75%] rounded-[25px] bg-[#f7f7f7] m-[8%] mx-auto mb-10px
      py-[30px] px-[20px]'>
        <p className='fw-500 text-[15px] pb-[10px]' style={{ color: customerColor[0] }}>Red Flags</p>
        <div className='text-[#737578] text-[12px] pl-[5px]'>
          {RedFlagAppointment?.map((items, index) => (
            <div className='pb-1 list-disc text-orange grid grid-cols-12 py-[10px]' key={index}>
              <div className='col-span-10'>
                <h6 className='flag-text'>{items.name}</h6>
              </div>
              <div className='col-span-2 text-center'>
                <h6 className='flag-text'>{items.count}</h6>
              </div>
            </div>
          ))}
          <br />
        </div>

      </div>
    </div>
  );
};

export default Sidebar;
