import React, { useState, useEffect } from 'react';
import {
  useGetAllAgentDoctorMutation
} from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import SharedTable from '../../common/SharedTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ScheduleModal from '../components/ScheduleModal';
import { Colorcode } from '../../common/utils/Colorcode';
// import AdvanceSearchModal from './AdvanceSearchModal';
// import moment from 'moment';

const Doctor = () => {
  const [getAllAgentDoctor] = useGetAllAgentDoctorMutation();
  const customerColor = Colorcode();
  const [pageNumber, setPageNumber] = useState('1');
  // const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [search, setSearch] = useState('');
  const [patientList, setPatientList] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  // const [filter, setFilter] = useState('all');
  const [scheduleModal, setScheduleModal] = useState(false);
  const [rowData, setRowData] = useState();
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });
  // const [searchFilter, setSearchFilter] = useState({
  //   patient: '',
  //   startDate: '',
  //   phone: '',
  //   doctor: '',
  //   endDate: ''
  // });

  const handleRowData = (data) => {
    setRowData(data);
  };

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const handleSearch = (e) => {
    setPageNumber(1);
    setSearch(e.target.value);
  };

  // const clearSearchData = () => {
  //   setFilter('all');
  //   setSearch('');
  //   setSearchFilter({
  //     patient: '',
  //     startDate: '',
  //     phone: '',
  //     doctor: '',
  //     endDate: ''
  //   });
  //   fetchPatientList(1, 'all');
  // };

  const fetchPatientList = (pageNumber, filter) => {
    // if(search !== ''){
    // 	pageNumber = 1;
    // 	setPageNumber(1);
    // }
    const pageNum = pageNumber || 1;
    const type = filter || 'all';
    getAllAgentDoctor({
      endpoint: search ? `${apiConstants.getAllAgentDoctor}/${pageNum}/${type}/${search}` :
        `${apiConstants.getAllAgentDoctor}/${pageNum}/${type}`,
      method: 'POST',
      data: {
        consult_type: '',
        current_time: '',
        data: {
          field: sort.headerFiled,
          type: sort?.sortType,
          patient: '',
          doctor: '',
          phone_number: '',
          email_id: '',
          start_date: '',
          end_date: ''
        },
        field: sort.headerFiled,
        type: sort?.sortType,
        date: '',
        kiosk_id: ''
      }
    }).then((result) => {
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setPageNumber(parseInt(result?.data?.current_page));
      let patientList = result?.data?.list;
      setPatientList(patientList);
    }).catch(error => {
      console.log('error', error);
    });
  };

  useEffect(() => {
    fetchPatientList(1);
  }, [search, sort]);

  const handlePatient = () => {
    fetchPatientList(1);
  };

  const patientHeaders = [
    { field: 'name', headerName: 'Name' },
    { field: 'phone_number', headerName: 'Phone' },
    { field: 'email_id', headerName: 'Email' },
    { field: 'specialization', headerName: 'Qualification' },
    { field: 'registration_no', headerName: 'Registration No' }
  ];

  const handleOnPageChange = (pageNumber) => {
    fetchPatientList(pageNumber);
  };
  return (
    <>
      {scheduleModal &&
        <ScheduleModal
          setScheduleModal={setScheduleModal}
          handlePatient={handlePatient}
          rowData={rowData}
        />}
      {/* {isAdvanceSearch &&
        <AdvanceSearchModal
          setIsAdvanceSearch={setIsAdvanceSearch}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          fetchPatientList={fetchPatientList}
        />} */}
      <div className='w-full h-full'>
        <div className='grid grid-cols-12 px-[6%] pt-[3%]'>
          <div className='col-span-12'>
            <div className="" style={{ float: 'right', marginTop: 10 }}>
              <div className=''>

                <div className="container-list-header">

                  <div className="search relative">
                    <input
                      type="text"
                      placeholder=" "
                      value={search}
                      autoComplete="off"
                      id="patientSearch"
                      name="patientSearch"
                      onChange={(e) => handleSearch(e)}
                      onFocus={() => setShowSearchIcon(false)}
                      onBlur={() => setShowSearchIcon(true)} />
                    <FontAwesomeIcon icon={faSearch}
                      className={`text-gray-500 absolute right-[10px] top-0 text-[20px]
                      search-icon ${showSearchIcon ? 'search-visible' : 'search-hidden'}`} />
                    {/* <div>
                    <svg>
                      <use xlinkHref="#path" />
                    </svg>
                  </div> */}
                  </div>
                  {/* <svg xmlns="axon" style={{ display: 'none' }}>
                  <symbol xmlns="axon" viewBox="0 0 160 28" id="path">
                    <path d="M32.9418651,-20.6880772 C37.9418651,-20.6880772 40.9418651,-16.6880772 40.9418651,
                    -12.6880772 C40.9418651,-8.68807717 37.9418651,-4.68807717 32.9418651,-4.68807717 C27.9418651,
                    -4.68807717 24.9418651,-8.68807717 24.9418651,-12.6880772 C24.9418651,-16.6880772 27.9418651,
                    -20.6880772 32.9418651,-20.6880772 L32.9418651,-29.870624 C32.9418651,-30.3676803 33.3448089,
                    -30.770624 33.8418651,-30.770624 C34.08056,-30.770624 34.3094785,-30.6758029 34.4782612,
                    -30.5070201 L141.371843,76.386562"
                      transform="translate(83.156854, 22.171573) rotate(-225.000000) translate(-83.156854, -22.171573)">
                    </path>
                  </symbol>
                </svg> */}

                </div>
              </div>
              {/* {Consult_Type.includes('1') && */}
              {/* <label
                style={{ fontFamily: 'Poppins', fontSize: 15, color: '#737575', marginTop: 15, fontWeight: 600 }} >
                Need more specific results?<br />
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setIsAdvanceSearch(true)}>
                  Try Advanced Search
                </span>
                <br />
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => clearSearchData()}>Clear Filter</span>
              </label> */}
              {/* } */}
            </div>
          </div>
        </div>

        <div className='grid grid-cols-12 pl-[6.5%] pr-[30px] pt-[30px]'>
          <div className='col-span-12 pb-[15px]'>
            <span className='underline-heading' style={{ borderColor: customerColor[0] }}>
              Doctor List
            </span>
          </div>
        </div>
        <div className="grid grid-cols-12 px-[6%] pb-[30px]">
          <div className='col-span-12 mt-[20px]'>
            <SharedTable
              tableData={patientList}
              tableHeaders={patientHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              onRowSelected={handleRowData}
              handleOnClickSort={handleOnClickSort}
              currentPageNumber={pageNumber}
              location="doctor_list"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Doctor;
