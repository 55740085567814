import React, { useEffect, useState } from 'react';
import SharedButton from '../common/SharedButton';
import { useGetAdminVerifySlotsMutation, useGetDoctorSlotsVerifiedMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import moment from 'moment';
import Loader from '../common/Loader';
import SharedTable from '../common/SharedTable';

const VerifySlots = () => {

    const [verifyAdminSlots] = useGetAdminVerifySlotsMutation();
    const [verifyDoctorSlots] = useGetDoctorSlotsVerifiedMutation();
    const [tableData, setTableData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isMoreData, setIsMoreData] = useState(false);
    const [totalPages, setTotalPages] = useState();
    const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState('1');
    const [sort, setSort] = useState({
        sortType: '',
        headerFiled: ''
    });
    const tableHeaders = [
        { field: 'id', headerName: 'ID' },
        { field: 'date', headerName: 'Date' },
        { field: 'doctor_name', headerName: 'Doctor' },
        { field: 'start_time', headerName: 'Start Time' },
        { field: 'end_time', headerName: 'End Time' },
        { field: 'is_request_for_delete', headerName: 'Delete Request' }
    ];

    const handleOnClickSort = (header) => {
		setPageNumber(1);
        if (header?.field === sort?.headerFiled) {
            setSort({
                ...sort,
                sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
            });
        } else {
            setSort({
                sortType: 'asc',
                headerFiled: header?.field
            });
        }
    };

    const fetchTableData = (page) => {
        verifyAdminSlots({
            endpoint: `${apiConstants.getAdminVerifySlots}/${page}/all`,
            method: 'POST',
            data: {
                consult_type: '',
                current_time: '',
                data: {},
                date: '',
                field: sort.headerFiled,
                type: sort?.sortType,
                kiosk_id: ''
            }
        }).then((result) => {
            let verifySlotsData = result?.data?.list;
            const modifiedData = verifySlotsData.map(item => ({
                ...item,
                start_time: moment(item.start_time, 'hh:mm a').format('hh:mm a'),
                end_time: moment(item.end_time, 'hh:mm a').format('hh:mm a'),
                is_request_for_delete: item.is_request_for_delete === 1 ? 'Yes' : 'No'
            }));
            setTableData(modifiedData);
            setIsMoreData(result?.data?.moreData);
            setTotalPages(result?.data?.total_pages);
			setPageNumber(parseInt(result?.data?.current_page));
            setLoading(false);
        }).catch((error) => {
            setLoading(false);
            console.error('error', error);
        });
    };

    useEffect(() => {
        fetchTableData(1);
    }, [sort]);

    const handleOnPageChange = (pageNumber) => {
        fetchTableData(pageNumber);
    };

    const verify = () => {
        if (selectedRows.length > 0) {
            setLoading(true);
            verifyDoctorSlots({
                endpoint: apiConstants.verifyDoctorSlots,
                method: 'PUT',
                data: {
                    slot_ids: selectedRows
                }
            }).then(() => {
                setLoading(false);
                setSelectedRows([]);
                fetchTableData(1);
            }).catch((error) => {
                setLoading(false);
                console.error('error', error);
            });
        }
    };

    const addSelection = (id) => {
        const index = selectedRows.findIndex(row => row.id === id);
        if (index !== -1) {
            const newSelectedRows = [...selectedRows];
            newSelectedRows.splice(index, 1);
            setSelectedRows(newSelectedRows);
        } else {
            setSelectedRows(prevSelectedRows => [...prevSelectedRows, { id }]);
        }
    };

    const onCheckboxSelected = (event) => {
        if (Array.isArray(event)) {
            const newSelectedRows = event.map((id) => ({ id }));
            setSelectedRows(newSelectedRows);
        } else {
            addSelection(event.id);
        }
    };

    return (
        <div className="row mt-12 mx-24">
            {loading ? (
                <div className="flex items-center justify-center py-72">
                    <Loader />
                </div>
            ) : (
                <div>
                    <div>
                        <h4 className="text-2xl font-semibold border-b-4 border-[#383678]
                                text-[Coolvetica Rg] list-header-text w-max text-[#4f4f4f]">
                            Verify Slots
                        </h4>
                        <SharedButton className="ml-0 rounded-[20px] float-right bg-[#383678] text-white
                            px-[50px] py-[5px] text-sm mt-1.5 mx-auto flex bg-gradient-to-r from-[#383678]
                            via-[#5f59b3] to-[#7976c9]"
                            title="Verify" onClick={verify} />
                    </div>
                    <div className="mt-14">
                        <SharedTable
                            tableData={tableData}
                            tableHeaders={tableHeaders}
                            handleOnPageChange={handleOnPageChange}
                            isMoreData={isMoreData}
                            totalPages={totalPages}
                            showCheckbox
                            onCheckboxSelected={onCheckboxSelected}
                            handleOnClickSort={handleOnClickSort}
currentPageNumber={pageNumber}
                        />
                    </div>
                </div>
            )}

        </div>
    );
};

export default VerifySlots;
