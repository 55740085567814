import { configureStore } from '@reduxjs/toolkit';
import counterSlice from './reducers/counterSlice';
import emptySplitApi from './reducers/middleware/mainReducer';
import { setupListeners } from '@reduxjs/toolkit/query';
import selectedDropdownValuesSlice from './reducers/selectedDropdownValuesSlice';
import medicalHistorySlice from './reducers/medicalHistorySlice';
import appointmentDetailsSlice from './reducers/appointmentDetailsSlice';

const store = configureStore({
  reducer: {
    counter: counterSlice,
    selectedDropdownValues: selectedDropdownValuesSlice,
    medicalHistoryData: medicalHistorySlice,
    appointmentDetails: appointmentDetailsSlice,
    [emptySplitApi.reducerPath]: emptySplitApi.reducer
  },
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false
    }),
    emptySplitApi.middleware
  ]
});
setupListeners(store.dispatch);

export default store;
