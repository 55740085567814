import React, { useState } from 'react';
import ReactSelectFilters from '../ReactSelectFilters';

function TestPage() {
  const options = [
    { value: 'All', label: 'All' },
    { value: 'active', label: 'active' },
    { value: 'online', label: 'online' },
    { value: 'disabled', label: 'disabled' }
  ];
  const [selectedVal, setSelectedVal] = useState(options[0]);

  const handleOnDropdownChange = (data) => {
    setSelectedVal(data);
  };

  return (
    <div className='flex'>
      <div className='p-[20px] w-[200px]'>
        <ReactSelectFilters
          options={options}
          placeholder={'All'}
          name='FilterDropdown'
          noDropDownIndicator={false}
          handleDropdownInputChange={handleOnDropdownChange}
          onChange={handleOnDropdownChange}
          selectFieldValues={selectedVal}
          isMulti={false}
          disableEnterKey
        />
      </div>
    </div>
  );
}

export default TestPage;