import React, { useEffect, useState } from 'react';
import DynamicInputElements from '../../Doctor/OpdAppointment/PatientDetails/MedicalHistory/DynamicInputElements';
import { deepCopy } from '../../Doctor/OpdAppointment/PatientDetails/MedicalHistory';

export const SecondChildComponent = ({ questionData, updateFirstChildData }) => {
  const [secondChildData, setSecondChildData] = useState(questionData);

  const handleOnSecondChildInputElementChange = (e) => {
    let data = deepCopy(secondChildData);
    const { id, value } = e.target;
    data.questions[id].value = value;
    setSecondChildData(data);
  };

  useEffect(() => {
    if (secondChildData) {
      updateFirstChildData(secondChildData);
    }
  }, [secondChildData]);

  return (
    <div className='pt-[5px]'>
      {secondChildData?.questions && Object.keys(secondChildData?.questions)?.map((item, index) => {
        const questionItem = secondChildData?.questions[item];
        return (
          <div key={index} className='flex flex-col gap-[5px]'>
            <div className='grid grid-cols-12 items-center'>
              <div className='col-span-6 text-[14px] text-[#888]'>
                <p className='pl-[100px]'>{questionItem?.title}</p>
              </div>
              <div className='col-span-6 pl-[100px] text-[14px] text-[#4e4f51]'>
                <DynamicInputElements
                  type={questionItem?.type}
                  title={questionItem?.title}
                  value={questionItem?.value}
                  data={questionItem?.data}
                  depth={1}
                  handleOnInputElementChange={handleOnSecondChildInputElementChange}
                  id={item}
                />
              </div>
            </div>
            {Object.keys(questionItem?.data)?.map((childItem, index) => {
              return (
                <div key={index}>
                  {questionItem?.data[childItem]?.isChild && 
                    (questionItem?.data[childItem].title === questionItem?.value) && (
                    <FirstChildComponent
                      questionData={questionItem?.data[childItem]?.children}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export const FirstChildComponent = ({ questionData, updateSectionData }) => {
  const [firstChildData, setFirstChildData] = useState(questionData);

  const handleOnFirstChildInputElementChange = (e) => {
    let data = deepCopy(firstChildData);
    const { id, value } = e.target;
    data.questions[id].value = value;
    setFirstChildData(data);
  };

  useEffect(() => {
    if (firstChildData) {
      updateSectionData(firstChildData);
    }
  }, [firstChildData]);

  const updateFirstChildData = (childData, item, childItem) => {
    let Data = deepCopy(firstChildData);
    Data.questions[item].data[childItem].children = childData;
    setFirstChildData(Data);
  };

  return (
    <div className='pt-[5px]'>
      {firstChildData?.questions && Object.keys(firstChildData?.questions)?.map((item, index) => {
        const questionItem = firstChildData?.questions[item];
        return (
          <div key={index} className='flex flex-col gap-[5px]'>
            <div className='grid grid-cols-12 items-center'>
              <div className='col-span-6 text-[14px] text-[#888]'>
                <p className='pl-[60px]'>{questionItem?.title}</p>
              </div>
              <div className='col-span-6 pl-[60px] text-[14px] text-[#4e4f51]'>
                <DynamicInputElements
                  type={questionItem?.type}
                  title={questionItem?.title}
                  value={questionItem?.value}
                  data={questionItem?.data}
                  depth={1}
                  handleOnInputElementChange={handleOnFirstChildInputElementChange}
                  id={item}
                />
              </div>
            </div>
            {Object.keys(questionItem?.data)?.map((childItem, index) => {
              return (
                <div key={index}>
                  {questionItem?.data[childItem]?.isChild && 
                    (questionItem?.data[childItem].title === questionItem?.value) && (
                    <SecondChildComponent
                      questionData={questionItem?.data[childItem]?.children}
                      updateFirstChildData={(data) => updateFirstChildData(data, item, childItem)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const RecursivePreviewComponent = ({ questionData }) => {
  const [parentData, setParentData] = useState(questionData);

  const handleOnParentInputElementChange = (e) => {
    let data = deepCopy(parentData);
    const { id, value } = e.target;
    data.questions[id].value = value;
    setParentData(data);
  };

  const updateSectionData = (childData, item, childItem) => {
    let Data = deepCopy(parentData);
    Data.questions[item].data[childItem].children = childData;
    setParentData(Data);
  };

  return (
    <div className='pt-[5px] pl-[100px]'>
      {parentData?.questions && Object.keys(parentData?.questions)?.map((item, index) => {
        const questionItem = parentData?.questions[item];
        return (
          <div key={index} className='flex flex-col gap-[5px]'>
            <div className='grid grid-cols-12 items-center'>
              <div className='col-span-6 text-[14px] text-[#888]'>
                <p className='pl-[20px]'>{questionItem?.title}</p>
              </div>
              <div className='col-span-6 pl-[20px] text-[14px] text-[#4e4f51]'>
                <DynamicInputElements
                  type={questionItem?.type}
                  title={questionItem?.title}
                  value={questionItem?.value}
                  data={questionItem?.data}
                  depth={1}
                  handleOnInputElementChange={handleOnParentInputElementChange}
                  id={item}
                />
              </div>
            </div>
            {Object.keys(questionItem?.data)?.map((childItem, index) => {
              return (
                <div key={index}>
                  {questionItem?.data[childItem]?.isChild && 
                    (questionItem?.data[childItem].title === questionItem?.value) && (
                    <FirstChildComponent
                      questionData={questionItem?.data[childItem]?.children}
                      updateSectionData={(data) => updateSectionData(data, item, childItem)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default RecursivePreviewComponent;