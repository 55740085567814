import React, { useState, useEffect } from 'react';
import { useGetAllCustomerListMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import CommonFilterSelect from '../common/FilterDropdown';
import Loader from '../common/Loader';
import SharedTable from '../common/SharedTable';
import { useNavigate } from 'react-router-dom';

const AppointmentList = () => {
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('privileges') === 'admin' ? true : false;
    const [getList] = useGetAllCustomerListMutation();
    const [loading, setLoading] = useState(false);
    const [loadOnce, setLoadOnce] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isMoreData, setIsMoreData] = useState(false);
    const [totalPages, setTotalPages] = useState();
    const [pageNumber, setPageNumber] = useState('1');
    const [filterString, setFilterString] = useState('all');
    const [searchString, setSearchString] = useState('');
    const [sort, setSort] = useState({
        sortType: '',
        headerField: ''
    });
    const filterOptions = [
        { value: 'all', label: 'All' },
        { value: 'scheduled', label: 'Scheduled' },
        { value: 'cancelled', label: 'Cancelled' },
        { value: 'successful', label: 'Successful' }
    ];
    const toggleButtons = isAdmin ? [
        { buttonName: 'Create', url: '/admin/create/customer' }
    ] : [];

    const preSelectedFilter = {
        selectedOption: 'all' // Set the initial value to 'option1'
    };

    const tableHeaders = [
        { field: 'appointment_id', headerName: 'Appointment Id' },
        { field: 'appointment_type_modified', headerName: 'Appointment Type' },
        { field: 'patient_name', headerName: 'Patient Name' },
        { field: 'appointment_time', headerName: 'Appointment Date' },
        { field: 'customer_name', headerName: 'Customer' },
        { field: 'status', headerName: 'Status' }
    ];

    const fetchTableData = (page, status, search) => {
        if (!loadOnce) { setLoading(true); }
        getList({
            endpoint: `${apiConstants.getAllAppointments}/${page}/${status}/${search}`,
            method: 'POST',
            data: {
                field: sort?.headerFiled,
                type: sort?.sortType
            }
        }).then((result) => {
            let customerListData = result?.data?.list;
            const modifiedData = customerListData.map(item => ({
                ...item,
                appointment_type_modified: item.appointment_type === 1 ? 'OPD' : '-',
                status: (item.status === 'scheduled' ?
                    'Scheduled' : (item.status === 'cancelled' ?
                        'Cancelled' : (item.status === 'successful' ?
                            'Successful' : '')))
            }));
            setTableData(modifiedData);
            setIsMoreData(result?.data?.moreData);
            setTotalPages(result?.data?.total_pages);
			setPageNumber(parseInt(result?.data?.current_page));
            setLoading(false);
            setLoadOnce(true);
        }).catch((error) => {
            setLoading(false);
            console.error('error', error);
        });
    };

    const handleOnClickSort = (header) => {
        setPageNumber(1);
        if (header.field === 'appointment_type_modified') {
            header.field = 'appointment_type';
        }

        if (header?.field === sort?.headerFiled) {
            setSort({
                ...sort,
                sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
            });
        } else {
            setSort({
                sortType: 'asc',
                headerFiled: header?.field
            });
        }
    };

    useEffect(() => {
        fetchTableData(pageNumber, filterString, searchString);
    }, [pageNumber, filterString, searchString, sort]);

    const handleOnPageChange = (pageNumber) => {
        setPageNumber(pageNumber);
    };

    const handleFilterSelect = (selectedOption) => {
        setFilterString(selectedOption.value);
    };

    const onSearch = (search) => {
        setPageNumber(1);
        setSearchString(search);
    };

    const onRowSelected = (event) => {
        if (isAdmin) {
            navigate(`/admin/frontdesk-detail-view/${event.id}`);
        }
    };

    const onToggleButtonClick = (url) => {
        navigate(url);
    };

    return (
        <div className="row mt-6 mx-24">
            {loading ? (
                <div className="flex items-center justify-center py-72">
                    <Loader />
                </div>
            ) : (
                <div>
                    <CommonFilterSelect
                        initialValues={preSelectedFilter}
                        options={filterOptions}
                        onSearch={onSearch}
                        toggleButtons={toggleButtons}
                        toggleButtonClick={onToggleButtonClick}
                        onChange={handleFilterSelect}
                        showFilter
                    />
                    <div className="mt-7">
                        <h4 className="text-2xl font-semibold border-b-4 border-[#383678]
                            text-[Coolvetica Rg] list-header-text w-[210px] text-[#4f4f4f]">
                            Appointment List
                        </h4>
                    </div>
                    <div className="mt-10">
                        <SharedTable
                            tableData={tableData}
                            tableHeaders={tableHeaders}
                            handleOnPageChange={handleOnPageChange}
                            isMoreData={isMoreData}
                            totalPages={totalPages}
                            handleOnClickSort={handleOnClickSort}
                            onRowSelected={onRowSelected}
currentPageNumber={pageNumber}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AppointmentList;
