import React from 'react';

const PrescriptionTableInPreview = ({ data }) => {
  const tableHeaders = ['Rx', 'Dosage', 'Frequency', 'Duration', 'Quantity'];

  return (
    <table className='prescriptionPreviewTable h-full w-full border-[1px] border-black border-collapse'>
      <thead>
        <tr>
          {tableHeaders?.map((header, index) => (
            <th key={index}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.map((drug, index) => (
          <tr key={index}>
            <td>{drug?.drug_name}</td>
            <td>{drug?.dosage + drug?.dosage_unit}</td>
            <td>
              <span>{drug?.interval_days}</span><br/>
              <span>{drug?.instructions}</span>
            </td>
            <td>{drug?.duration + ' days'}</td>
            <td>{drug?.quantity}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PrescriptionTableInPreview;