import React, { useState, useEffect } from 'react';
import { useGetAllCustomerListMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import CommonFilterSelect from '../common/FilterDropdown';
import Loader from '../common/Loader';
import SharedTable from '../common/SharedTable';
import { useNavigate } from 'react-router-dom';

const CustomerList = () => {
  const navigate = useNavigate();
  const [getList] = useGetAllCustomerListMutation();
  const [loading, setLoading] = useState(false);
  const [loadOnce, setLoadOnce] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [pageNumber, setPageNumber] = useState('1');
  const [filterString, setFilterString] = useState('all');
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });
  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Disabled' }
  ];
  const toggleButtons = [
    { buttonName: 'Create', url: '/admin/create/customer' }
  ];

  const preSelectedFilter = {
    selectedOption: 'all' // Set the initial value to 'option1'
  };

  const tableHeaders = [
    { field: 'customer_id', headerName: 'ID' },
    { field: 'customer_name', headerName: 'Customer' },
    { field: 'primary_contact_name', headerName: 'Contact Name' },
    { field: 'primary_contact_phone', headerName: 'Phone No' },
    { field: 'primary_contact_email', headerName: 'E-Mail' },
    { field: 'customer_list_status', headerName: 'Status' }
  ];

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const fetchTableData = (page, status, search) => {
    // if(search !== '') {
    //   page = 1;
    //   setPageNumber(1);
    // }
    if (!loadOnce) { setLoading(true); }
    getList({
      endpoint: `${apiConstants.getAllCustomerList}/${page}/${status}/${search}`,
      method: 'POST',
      data: {
        consult_type: '',
        current_time: '',
        data: {},
        date: '',
        field: sort.headerFiled,
        type: sort?.sortType,
        kiosk_id: ''
      }
    }).then((result) => {
      let customerListData = result?.data?.list;
      const modifiedData = customerListData.map(item => ({
        ...item,
        name: item.first_name + ' ' + item.last_name,
        customer_list_status:
          (item.status === 1) ?
            (<div className='flex justify-start items-center gap-[5px]'>
              <div className="w-[10px] h-[10px] bg-[#808080] rounded-full"></div>
              <div>Disabled</div>
            </div>) : <div className='flex justify-start items-center gap-[5px]'>
              <div className="w-[10px] h-[10px] bg-[green] rounded-full"></div>
              <div>Active</div>
            </div>
      }));
      setTableData(modifiedData);
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setPageNumber(parseInt(result?.data?.current_page));
      setLoading(false);
      setLoadOnce(true);
    }).catch(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchTableData(pageNumber, filterString, searchString);
  }, [pageNumber, searchString, sort]);

  useEffect(() => {
    fetchTableData(1, filterString, searchString);
  }, [filterString]);

  const handleOnPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleFilterSelect = (selectedOption) => {
    setFilterString(selectedOption.value);
  };

  const onSearch = (search) => {
    setPageNumber(1);
    setSearchString(search);
  };

  const onRowSelected = (event) => {
    navigate(`/admin/customer/${event.customer_id}/view`);
  };

  const onToggleButtonClick = (url) => {
    navigate(url);
  };

  return (
    <div className="row mt-6 mx-24">
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div>
          <CommonFilterSelect
            initialValues={preSelectedFilter}
            options={filterOptions}
            onSearch={onSearch}
            toggleButtons={toggleButtons}
            toggleButtonClick={onToggleButtonClick}
            onChange={handleFilterSelect}
            showFilter
          />
          <div className="mt-7">
            <h4 className="text-2xl font-semibold border-b-4 border-[#383678]
                            text-[Coolvetica Rg] list-header-text w-44 text-[#4f4f4f]">
              Customer List
            </h4>
          </div>
          <div className="mt-10">
            <SharedTable
              tableData={tableData}
              tableHeaders={tableHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              onRowSelected={onRowSelected}
              handleOnClickSort={handleOnClickSort}
              currentPageNumber={pageNumber}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerList;
