import React, { useState, useEffect } from 'react';
import DetailsView from '../common/DetailView';
import { useLazyGetFrontDeskViewQuery } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import { useParams } from 'react-router-dom';
import Loader from '../common/Loader';

const FrontDeskDetailView = () => {
  const [getFrontDeskView] = useLazyGetFrontDeskViewQuery();
  const [loading, setLoading] = useState(false);
  const [detailsCardData, setDetailsCardData] = useState([]);
  const { id } = useParams();

  const handleCardData = () => {
    setLoading(true);
    getFrontDeskView({
      endpoint: `${apiConstants.getFrontDeskView}/${id}`,
      method: 'GET'
    }).then((result) => {
      let doctorViewData = result?.data;
      const modifiedData = {
        ...doctorViewData,
        id: `#DR${id}`
      };
      setDetailsCardData(modifiedData);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.error('error', error);
    });
  };
  useEffect(() => {
    handleCardData();
  }, []);

  return (
    <div className="row mt-16 mx-28">
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div>
          <DetailsView
            cardData={detailsCardData}
            handleCardData={handleCardData}
          />
        </div>
      )}
    </div>
  );
};

export default FrontDeskDetailView;