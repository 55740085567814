import React from 'react';
import { Colorcode } from '../../../common/utils/Colorcode';

const MedicalCertificateGeneratedModal = ({
  handleButtonClick
}) => {
  const customerColor = Colorcode();
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-sm"
          >
            <div className="bg-white p-[40px]">
              <div className="flex flex-col gap-[30px] justify-center items-center">
                <p>Medical Certificate Generated</p>
                <button
                  className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px] text-[12px] text-nowrap'
                  style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                  onClick={() => handleButtonClick()}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalCertificateGeneratedModal;
