import React from 'react';
import PatientDetailBox from '../PatientDetailBox';
import CommonDisplayBox from './CommonDisplayBox';

const DisplayInvestigation = ({
  investigation,
  referredTo
}) => {
  return (
    <div className='grid grid-cols-12 gap-[30px]'>
      <div className='col-span-6'>
        <PatientDetailBox title='Tests'>
          <CommonDisplayBox dataList={investigation} />
        </PatientDetailBox>
      </div>
      <div className='col-span-6'>
        <PatientDetailBox title='Refer to specialist'>
          <CommonDisplayBox dataList={referredTo} />
        </PatientDetailBox>
      </div>
    </div>
  );
};

export default DisplayInvestigation;