import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { saveMedicalHistoryData } from '../../../reducers/medicalHistorySlice';
import { deepCopy } from '../OpdAppointment/PatientDetails/MedicalHistory';
import BodyPainDiagram from '../OpdAppointment/PatientDetails/MedicalHistory/BodyPainDiagram';
import AttachmentView from './AttachmentView';
import RecursiveComponentView from './RecursiveComponentPrescription';
import { useLazyGetAppointmentDetailsQuery, useLazyGetPatientMedicalHistoryQuery } from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import { savePatientDetails } from '../../../reducers/appointmentDetailsSlice';

const MedicalHistoryView = () => {
  const navigate = useNavigate();
  const { appointmentId } = useParams();
  const [medicalHistoryData, setMedicalHistoryData] = useState([]);
  const selectedTitle = useSelector((state) => state?.medicalHistoryData?.selectedTabTitlePrescription);
  const dispatch = useDispatch();
  const [headersToShow, setHeadersToShow] = useState();
  const [showBodyPainDiagram, setShowBodyPainDiagram] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const medicalHistoryPrescription = useSelector((state) => state?.medicalHistoryData?.medicalHistoryPrescription);
  const dataToDisplay = useSelector((state) => state?.medicalHistoryData?.medicalHistoryPrescriptionToDisplay);
  const [getPatientMedicalHistory] = useLazyGetPatientMedicalHistoryQuery();
  const [getAppointmentDetails] = useLazyGetAppointmentDetailsQuery();

  const handleSetHeaders = (data) => {
    let tempHeaders = [];
    for (let i = 0; i < data?.length; i++) {
      tempHeaders.push(data[i]?.medical_history_section_name);
    }
    tempHeaders.push('Body Pain Diagram', 'Attachments');
    setHeadersToShow(tempHeaders);
  };

  const dispatchToSave = (medicalHistory, bodyPainDetails, userAttachments, selectedTabTitle) => {
    dispatch(saveMedicalHistoryData({ fieldName: 'medicalHistoryPrescription', fieldValue: medicalHistory }));
    dispatch(saveMedicalHistoryData({ fieldName: 'bodyPainDetailsPrescription', fieldValue: bodyPainDetails }));
    dispatch(saveMedicalHistoryData({ fieldName: 'attachmentDetailsPrescription', fieldValue: userAttachments }));
    dispatch(saveMedicalHistoryData({
      fieldName: 'selectedTabTitlePrescription',
      fieldValue: selectedTabTitle
    }));
  };

  useEffect(() => {
    const data = deepCopy(medicalHistoryData);
    if (data?.medical_history_details?.length <= 0) {
      for (let i = 0; i < data?.medical_history_questions?.length; i++) {
        data.medical_history_questions[i].json_value = data?.medical_history_questions[i] &&
          data?.medical_history_questions[i]?.questions_json &&
          JSON.parse(data?.medical_history_questions[i]?.questions_json);
        data.medical_history_questions[i].medical_history_section_name = data?.medical_history_questions[i]?.name;
      }
      handleSetHeaders(data?.medical_history_questions);
      dispatchToSave(data?.medical_history_questions, data?.body_pain_details, data?.user_attachments,
        data?.medical_history_questions && data?.medical_history_questions[0]?.medical_history_section_name);
    } else if (data?.medical_history_questions?.length === data?.medical_history_details?.length) {
      for (let i = 0; i < data?.medical_history_details?.length; i++) {
        data.medical_history_details[i].json_value = data?.medical_history_details[i] &&
          data?.medical_history_details[i]?.json_value && JSON.parse(data?.medical_history_details[i]?.json_value);
      }
      handleSetHeaders(data?.medical_history_details);
      dispatchToSave(data?.medical_history_details, data?.body_pain_details, data?.user_attachments,
        data?.medical_history_details && data?.medical_history_details[0]?.medical_history_section_name);
    } else if (data?.medical_history_details?.length < data?.medical_history_questions?.length) {
      for (let i = 0; i < data?.medical_history_questions?.length; i++) {
        data.medical_history_details[i] = {
          json_value: data?.medical_history_details[i] &&
            data?.medical_history_details[i]?.json_value && JSON.parse(data?.medical_history_details[i]?.json_value)
            || ''
        };
        if (!data?.medical_history_details[i]?.medical_history_section_name) {
          data.medical_history_details[i].medical_history_section_name = data?.medical_history_questions[i]?.name;
        }
      }
      handleSetHeaders(data?.medical_history_details);
      dispatchToSave(data?.medical_history_details, data?.body_pain_details, data?.user_attachments,
        data?.medical_history_details && data?.medical_history_details[0]?.medical_history_section_name);
    } else if (data?.medical_history_questions?.length <= 0) {
      dispatchToSave(data?.medical_history_details, data?.body_pain_details, data?.user_attachments,
        'Body Pain Diagram');
    }
  }, [medicalHistoryData]);

  useEffect(() => {
    getAppointmentDetails({
      endpoint: `${apiConstants?.getAppointmentDetails}/${appointmentId}`
    }).then((result) => {
      if (result && result?.data) {
        dispatch(savePatientDetails({
          fieldName: 'patientDetails',
          fieldValue: result?.data?.appointment_detials?.appointment_detials?.patient_details
        }));
        const clientId = result?.data?.appointment_detials.appointment_detials.patient_details.client_id;
        getPatientMedicalHistory({
          endpoint: `${apiConstants?.getPatientMedicalHistory}/${clientId}`
        }).then((result) => {
          setMedicalHistoryData(result?.data);
        });
      }
    });
  }, []);

  const handleOnTabClick = (value) => {
    dispatch(saveMedicalHistoryData({
      fieldName: 'selectedTabTitlePrescription',
      fieldValue: value
    }));
  };

  useEffect(() => {
    if (selectedTitle === 'Body Pain Diagram') {
      setShowAttachments(false);
      setShowBodyPainDiagram(true);
    } else if (selectedTitle === 'Attachments') {
      setShowBodyPainDiagram(false);
      setShowAttachments(true);
    } else if (selectedTitle) {
      setShowBodyPainDiagram(false);
      setShowAttachments(false);
      const index = medicalHistoryPrescription?.findIndex((d) =>
        d?.medical_history_section_name === selectedTitle);
      const value = medicalHistoryPrescription[index].json_value;
      dispatch(saveMedicalHistoryData({ fieldName: 'medicalHistoryPrescriptionToDisplay', fieldValue: value }));
    }
  }, [selectedTitle]);

  return (
    <div className='p-[20px]'>
      <div className='flex flex-col'>
        <div className='flex justify-between p-[20px]'>
          <p className='text-[30px] text-[#777] fw-500'>Medical History</p>
          <div className='flex items-center gap-[5px] cursor-pointer' onClick={() => navigate(-1)}>
            <FontAwesomeIcon icon={faAngleLeft} color='#383678' />
            <p className='underline text-[#383678] text-sm fw-500'>Back</p>
          </div>
        </div>
        <div className='flex overflow-auto p-[10px] whitespace-nowrap border-y-[1px] border-t-[#d9d9d9]'>
          {headersToShow?.map((header, index) => (
            <div
              key={index}
              className='px-[10px] cursor-pointer border-r-[#aaa]'
              onClick={() => handleOnTabClick(header)}
            >
              <span className={`text-[#333] text-sm fw-500 ${selectedTitle === header
                && 'border-b-[#383678] border-b-[2px]'}`}>{header}</span>
            </div>
          ))}
        </div>
        {dataToDisplay && Object.keys(dataToDisplay)?.length > 0 ? (
          <>
            {dataToDisplay && !showBodyPainDiagram && !showAttachments && (
              <div className='p-[20px] max-h-[63.5vh] overflow-y-auto overflow-x-hidden'>
                <RecursiveComponentView
                  displayData={dataToDisplay}
                  depth={1}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {!showAttachments && !showBodyPainDiagram && (
              <div className='p-[30px] text-center'>
                <p className='text-[#737575] text-[14px]'>No information available</p>
              </div>
            )}
          </>
        )}
        {showBodyPainDiagram && !showAttachments && (
          <div className='p-[20px] max-h-[63.5vh] overflow-y-auto overflow-x-hidden'>
            <BodyPainDiagram onlyDisplay />
          </div>
        )}
        {showAttachments && (
          <div className='p-[20px] max-h-[63.5vh] overflow-y-auto overflow-x-hidden'>
            <AttachmentView />
          </div>
        )}
      </div>
    </div>
  );
};

export default MedicalHistoryView;
