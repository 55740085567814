import React, { useEffect, useState } from 'react';
import PatientDetailBox from '../PatientDetailBox';
import PatientInfoBox from './PatientInfoBox';
import { getPatientHeaders } from '../PatientHeaderOptions';
import DisplaySymptoms from './DisplaySymptoms';
import DisplayVitals from './DisplayVitals';
import DisplayTeleExamination from './DisplayTeleExamination';
import DisplayInvestigation from './DisplayInvestigation';
import DisplayDiagnosis from './DisplayDiagnosis';
import DisplayPrescription from './DisplayPrescription';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const CommonPrescriptionView = ({
  patientDetails,
  symptomsList,
  presentingIllness,
  diagnosis,
  differentialDiagnosis,
  provisionalDiagnosis,
  investigation,
  referredTo,
  selfCare,
  followUpDate,
  duration,
  drugs,
  teleExamination,
  patientReports,
  vitals,
  pacs,
  coordinates,
  isPastConsultModal
}) => {
  const [showPatientHeaderDetail, setShowPatientHeaderDetail] = useState({
    showSymptoms: true,
    showVitals: false,
    showTeleExamination: false,
    showInvestigation: false,
    showDiagnosis: false,
    showPrescription: false
  });
  const [selectedTitle, setSelectedTitle] = useState('Symptoms');
  const [patientInfoHeaderOptions, setPatientInfoHeaderOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const headerOptions = getPatientHeaders(setShowPatientHeaderDetail);
    setPatientInfoHeaderOptions(headerOptions);
  }, []);

  return (
    <div className='p-[30px] flex flex-col gap-[20px] mt-[20px]'>
      <div className='grid grid-cols-12 gap-[10px]'>
        <div className={`${isPastConsultModal ? 'col-span-12' : 'col-span-10'}`}>
          <PatientDetailBox title='Appointment Details'>
            <PatientInfoBox patientDetails={patientDetails} />
          </PatientDetailBox>
        </div>
        {!isPastConsultModal && (
          <div className='col-span-2 flex justify-end items-baseline'>
            <div className='flex items-center gap-[5px] cursor-pointer' onClick={() => navigate(-1)}>
              <FontAwesomeIcon icon={faAngleLeft} color='#383678' />
              <p className='underline text-[#383678] text-sm fw-500'>Back</p>
            </div>
          </div>
        )}
      </div>
      <div className='flex gap-[20px] border-t-[1px] border-b-[1px] p-[10px] pr-[20px] mt-[20px]'>
        {patientInfoHeaderOptions.map((option, index) => (
          <div key={index} className={`cursor-pointer mr-[30px] ${option?.title === selectedTitle && 'underline'}`}
            onClick={() => {setSelectedTitle(option?.title); option.handleClick();}}
          >
            <p className='fw-500 text-sm text-[#000000]'>{option?.title}</p>
          </div>
        ))}
      </div>
      <div className={`py-[20px] ${isPastConsultModal ? 'h-[40vh]' : 'h-[50vh]'} overflow-auto px-[10px]`}>
        {showPatientHeaderDetail?.showSymptoms && (
          <DisplaySymptoms
            symptomsList={symptomsList}
            presentingIllness={presentingIllness}
            duration={duration}
          />
        )}
        {showPatientHeaderDetail?.showVitals && (
          <DisplayVitals
            vitals={vitals}
            pacs={pacs}
            coordinates={coordinates}
          />
        )}
        {showPatientHeaderDetail?.showTeleExamination && (
          <DisplayTeleExamination
            teleExamination={teleExamination}
          />
        )}
        {showPatientHeaderDetail?.showInvestigation && (
          <DisplayInvestigation
            investigation={investigation}
            referredTo={referredTo}
          />
        )}
        {showPatientHeaderDetail?.showDiagnosis && (
          <DisplayDiagnosis
            diagnosis={diagnosis}
            differentialDiagnosis={differentialDiagnosis}
            provisionalDiagnosis={provisionalDiagnosis}
            patientReports={patientReports}
            isPastConsultModal={isPastConsultModal}
          />
        )}
        {showPatientHeaderDetail?.showPrescription && (
          <DisplayPrescription
            selfCare={selfCare}
            followUpDate={followUpDate}
            drugs={drugs}
          />
        )}
      </div>
    </div>
  );
};

export default CommonPrescriptionView;
