import React, { useEffect, useState } from 'react';
import DynamicInputElements from '../../Doctor/OpdAppointment/PatientDetails/MedicalHistory/DynamicInputElements';
import { deepCopy } from '../../Doctor/OpdAppointment/PatientDetails/MedicalHistory';
import { Colorcode } from '../../common/utils/Colorcode';
import RepeaterComponent from './RepeaterComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DeleteAttachmentModal from '../../Doctor/OpdAppointment/PatientDetails/MedicalHistory/DeleteAttachmentModal';
import { useAddHistoryQuestionMutation, useEditHistoryQuestionMutation } from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import { useNavigate, useParams } from 'react-router-dom';
import SectionSuccessModal from './SectionSuccessModal';
import Toggle from 'react-toggle';

export function validateEmptySpaceOnly(string) {
  const namePattern = /^(?!\s*$).+/;
  if (string && string.match(namePattern)) {
    return true;
  }
  return 'No Empty Spaces Allowed';
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const CreateMedicalHistory = ({
  isEdit,
  historyData,
  setIsPreview,
  handleGetHistoryQuestion
}) => {
  const customerColor = Colorcode();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isQuestionDeleteModalOpen, setIsQuestionDeleteModalOpen] = useState(false);
  const [isOptionDeleteModalOpen, setIsOptionDeleteModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [addHistoryQuestion] = useAddHistoryQuestionMutation();
  const [editHistoryQuestion] = useEditHistoryQuestionMutation();
  const [dataToDelete, setDataToDelete] = useState({ item: '', question: '' });
  const [sectionData, setSectionData] = useState({
    sectionName: { title: '' },
    questions: {

    },
    priority: '',
    status: 1
  });
  const [errorData, setErrorData] = useState({});

  const dropdownOptions = [
    { label: 'Text', value: 'text' },
    { label: 'Radio', value: 'radio' },
    { label: 'Textarea', value: 'textarea' }
  ];

  useEffect(() => {
    if (isEdit && historyData) {
      setSectionData(historyData);
    }
  }, [isEdit]);

  const handleSectionNameChange = (e) => {
    let data = deepCopy(sectionData);
    const { id, value } = e.target;
    const idSplit = id.split('_');
    if (idSplit.length === 1) {
      data[idSplit[0]].title = value;
    }
    else if (idSplit.length === 2) {
      data.questions[idSplit[1]].title = value;
    }

    setSectionData(data);
  };

  const handleChangePriority = (e) => {
    let data = deepCopy(sectionData);
    const { value } = e.target;
    data['priority'] = value;
    setSectionData(data);
  };

  const validate = () => {
    let result = true;
    let error_data = errorData, childValidationStatus = true;
    const {
      sectionName,
      questions,
      priority
    } = sectionData;

    const validateSectionName = validateEmptySpaceOnly(sectionName.title);
    if (validateSectionName !== true) {
      result = false;
      // error_data['sectionName'] = 'Please enter section name';
    } else {
      error_data['sectionName'] = null;
    }
    const validateSectionPriority = validateEmptySpaceOnly(priority);
    if (validateSectionPriority !== true) {
      result = false;
      // error_data['sortOrder'] = 'Please enter sort order';
    } else {
      error_data['sortOrder'] = null;
    }
    if (questions && Object.keys(questions).length > 0) {
      if (isEdit) {
        Object.keys(sectionData.questions).map(key => {
          error_data[key] = { title: '', type: '', options: {}, isChild: false, children: {} };
        });
      }
      Object.keys(sectionData.questions).map(key => {
        const { title, type, data } = sectionData.questions[key];
        const validate_title = validateEmptySpaceOnly(title);
        const validate_type = validateEmptySpaceOnly(type);
        let question = key;
        if (validate_title !== true) {
          result = false;
          error_data[key].title = 'Please fill the question title';
        } else {
          error_data[key].title = null;
        }
        if (validate_type !== true) {
          result = false;
          error_data[key].type = 'Please fill the question type';
        } else {
          error_data[key].type = null;
        }

        if (type === 'radio') {
          Object.keys(data).map((item) => {
            let { title } = sectionData.questions[question].data[item];
            // let { validator } = sectionData.questions[question];
            const validate_child_title = validateEmptySpaceOnly(title);
            if (validate_child_title !== true) {
              result = false;
              error_data[key].options[item] = 'Please fill option title';
            } else {
              error_data[key].options[item] = null;
            }
            let validateRepeatorChildData = validateRepeatorChild(sectionData.questions[question].data[item].children);
            error_data[key].children[item] = validateRepeatorChildData.error_data;
            childValidationStatus = (childValidationStatus) ? validateRepeatorChildData.status : false;
          });
        }
      });
    }

    if (result === false) {
      setErrorData(error_data);
    }

    if (childValidationStatus === false) {
      result = false;
    } else if (childValidationStatus === true && result === false) {
      result = false;
    } else if (childValidationStatus === true && result === true) {
      result = true;
    } else {
      result = false;
    }
    return result;
  };

  const validateRepeatorChild = (Data) => {
    let result = {
      status: true,
      error_data: {

      }
    };
    let validators = validateEmptySpaceOnly, childValidationStatus = true;

    Object.keys(Data.questions).map(key => {
      result.error_data[key] = { title: '', type: '', options: {}, isChild: false, children: {} };
    });

    if (Data && Data.questions && Object.keys(Data.questions).length > 0) {
      Object.keys(Data.questions).map(key => {
        const { title, type, data } = Data.questions[key];
        const validate_title = validators(title);
        const validate_type = validators(type);
        if (validate_title !== true) {
          result.status = false;
          result.error_data[key].title = 'Please fill the question title';
        } else {
          result.error_data[key].title = null;
        }
        if (validate_type !== true) {
          result.status = false;
          result.error_data[key].type = 'Please fill the question type';
        } else {
          result.error_data[key].type = null;
        }

        if (type === 'radio') {
          Object.keys(data).map((item) => {
            let { title } = Data.questions[key].data[item];
            const validate_child_title = validators(title);
            if (validate_child_title !== true) {
              result.status = false;
              result.error_data[key].options[item] = 'Please fill option title';
            } else {
              result.error_data[key].options[item] = null;
            }
            let validateRepeatorSubChildData = validateRepeatorSubChild(Data.questions[key].data[item].children);
            result.error_data[key].children[item] = validateRepeatorSubChildData.error_data;
            childValidationStatus = (childValidationStatus) ? validateRepeatorSubChildData.status : false;
          });
        }
      });
    }

    if (childValidationStatus === false) {
      result.status = false;
    } else if (childValidationStatus === true && result.status === false) {
      result.status = false;
    }
    return result;
  };

  const validateRepeatorSubChild = (Data) => {
    let result = {
      status: true,
      error_data: {

      }
    };
    let validators = validateEmptySpaceOnly, childValidationStatus = true;

    Object.keys(Data.questions).map(key => {
      result.error_data[key] = { title: '', type: '', options: {}, isChild: false, children: {} };
    });

    if (Data && Data.questions && Object.keys(Data.questions).length > 0) {
      Object.keys(Data.questions).map(key => {
        const { title, type, data } = Data.questions[key];
        const validate_title = validators(title);
        const validate_type = validators(type);
        if (validate_title !== true) {
          result.status = false;
          result.error_data[key].title = 'Please fill the question title';
        } else {
          result.error_data[key].title = null;
        }
        if (validate_type !== true) {
          result.status = false;
          result.error_data[key].type = 'Please fill the question type';
        } else {
          result.error_data[key].type = null;
        }

        if (type === 'radio') {
          Object.keys(data).map((item) => {
            let { title } = Data.questions[key].data[item];
            const validate_child_title = validators(title);
            if (validate_child_title !== true) {
              result.status = false;
              result.error_data[key].options[item] = 'Please fill option title';
            } else {
              result.error_data[key].options[item] = null;
            }
            let validateRepeatorChildData = validateRepeatorChild(Data.questions[key].data[item].children);
            result.error_data[key].children[item] = validateRepeatorChildData.error_data;
            childValidationStatus = (childValidationStatus) ? validateRepeatorChildData.status : false;
          });
        }
      });
    }

    if (childValidationStatus === false) {
      result.status = false;
    } else if (childValidationStatus === true && result.status === false) {
      result.status = false;
    }
    return result;
  };

  const createQuestion = () => {
    let Data = deepCopy(sectionData);
    let error_data = deepCopy(errorData);
    let QuestionCount = Object.keys(Data.questions).length;
    Data.questions[`question-${QuestionCount + 1}`] = {
      title: '',
      value: '',
      type: '',
      data: {},
      children: {},
      priority: QuestionCount + 1
    };
    error_data[`question-${QuestionCount + 1}`] = { title: '', type: '', options: {}, isChild: false, children: {} };
    setSectionData(Data);
    setErrorData(error_data);
  };

  const handleDropdownChangeType = (e, id) => {
    let data = deepCopy(sectionData);
    const { value } = e;
    const idSplit = id.split('_');
    if (idSplit.length === 1) {
      data[idSplit[0]].handleChangeType = value;
      setSectionData(data);
    }
    else if (idSplit.length === 2) {
      data.questions[idSplit[1]].type = value;
      setSectionData(data);
      if (value === 'radio') {
        createRadioOptions(idSplit[1], 2, true, value);
      } else {
        data.questions[idSplit[1]].data = {};
        setSectionData(data);
      }
    }
  };

  const createRadioOptions = (dataOption, numberOfOptions, isValue, value) => {
    let Data = deepCopy(sectionData);
    let QuestionCount = Object.keys(Data.questions[dataOption].data).length;
    for (let i = 0; i < numberOfOptions; i++) {
      Data.questions[`${dataOption}`].data[`option-${QuestionCount + (i + 1)}`] = {
        title: '',
        isChild: false,
        children: { questions: {} }
      };
    }
    if (isValue) {
      Data.questions[`${dataOption}`].type = value;
    }
    setSectionData(Data);
  };

  const handleChangeIsChild = (e) => {
    let data = deepCopy(sectionData);
    const { id, checked } = e.target;
    const idSplit = id.split('_');
    if (idSplit.length === 1) {
      data[idSplit[0]].isChild = checked;
    }
    else if (idSplit.length === 2) {
      data.questions[idSplit[1]].data['i'] = checked;
    } else if (idSplit.length === 3) {
      data.questions[idSplit[1]].data[idSplit[2]].isChild = checked;
    }
    setSectionData(data);
  };

  const handleOnDefaultOptionChange = (question, option) => {
    let Data = deepCopy(sectionData);
    Data.questions[question].value = Data.questions[question].data[option].title;
    setSectionData(Data);
  };

  const handleChangeDataOptions = (e) => {
    let data = deepCopy(sectionData);
    const { id, value } = e.target;
    const idSplit = id.split('_');
    if (idSplit.length === 1) {
      data[idSplit[0]].title = value;
    }
    else if (idSplit.length === 2) {
      data.questions[idSplit[1]].data['i'] = value;
    } else if (idSplit.length === 3) {
      if (data.questions[idSplit[1]].data[idSplit[2]].title === data.questions[idSplit[1]].value) {
        data.questions[idSplit[1]].data[idSplit[2]].title = value;
        data.questions[idSplit[1]].value = value;
      } else if (data.questions[idSplit[1]].data[idSplit[2]].title !== data.questions[idSplit[1]].value) {
        data.questions[idSplit[1]].data[idSplit[2]].title = value;
      }
    }
    setSectionData(data);
  };

  const handleQuestionDelete = () => {
    let Data = deepCopy(sectionData);
    delete Data.questions[dataToDelete?.item];
    setIsQuestionDeleteModalOpen(false);
    setSectionData(Data);
  };

  const handleOptionDelete = () => {
    let Data = deepCopy(sectionData);
    delete Data.questions[dataToDelete?.question].data[dataToDelete?.item];
    setIsOptionDeleteModalOpen(false);
    setSectionData(Data);
  };

  const updateSectionData = (childData, question, item) => {
    let Data = deepCopy(sectionData);
    Data.questions[question].data[item].children = childData;
    setSectionData(Data);
  };

  const handleAddHistoryQuestion = () => {
    addHistoryQuestion({
      endpoint: apiConstants?.addHistoryQuestion,
      method: 'POST',
      data: {
        name: sectionData?.sectionName?.title,
        sort_order: sectionData?.priority,
        questions_json: JSON.stringify(sectionData),
        status: 1,
        department_id: id
      }
    }).then(() => {
      setIsSuccessModalOpen(true);
      setTimeout(() => {
        navigate(-1);
      }, 1900);
      setTimeout(() => {
        setIsSuccessModalOpen(false);
      }, 2000);
    });
  };

  const handleEditHistoryQuestion = () => {
    editHistoryQuestion({
      endpoint: apiConstants?.editHistoryQuestion,
      method: 'PUT',
      data: {
        name: sectionData?.sectionName?.title,
        sort_order: sectionData?.priority,
        questions_json: JSON.stringify(sectionData),
        status: (sectionData.status === false || sectionData.status === 2) ? 2 : 1,
        id: id
      }
    }).then(() => {
      setIsSuccessModalOpen(true);
      handleGetHistoryQuestion();
      setTimeout(() => {
        setIsPreview(true);
      }, 1900);
      setTimeout(() => {
        setIsSuccessModalOpen(false);
      }, 2000);
    });
  };

  const handleSave = () => {
    if (validate() === false) {
      return;
    }
    if (isEdit) {
      handleEditHistoryQuestion();
    } else {
      handleAddHistoryQuestion();
    }
  };

  useEffect(() => {
    if (sectionData) {
      validate();
    }
  }, [sectionData]);

  const handleToggleChange = () => {
    let data = deepCopy(sectionData);
    if (data['status'] === true || data['status'] === 1) {
      data['status'] = 2;
    } else if (data['status'] === false || data['status'] === 2) {
      data['status'] = 1;
    } else {
      data['status'] = 1;
    }
    setSectionData(data);
  };

  return (
    <>
      {isQuestionDeleteModalOpen && (
        <DeleteAttachmentModal
          heading='Are you sure you want to delete this question?'
          handleModalClose={() => setIsQuestionDeleteModalOpen(false)}
          handleDeleteAttachment={handleQuestionDelete}
        />
      )}
      {isOptionDeleteModalOpen && (
        <DeleteAttachmentModal
          heading='Are you sure you want to delete this option?'
          handleModalClose={() => setIsOptionDeleteModalOpen(false)}
          handleDeleteAttachment={handleOptionDelete}
        />
      )}
      {isSuccessModalOpen && (
        <SectionSuccessModal isEdit={isEdit} />
      )}
      <div className='p-[30px]'>
        <div className={`rounded-[8px] ${!isEdit && 'bg-[#fafafa]'} p-[20px] flex flex-col gap-[10px]`}
          style={{ boxShadow: !isEdit && '1px 1px 10px 0 rgba(0,0,0,.2)' }}
        >
          <p className='text-[#737575] text-[30px] font-[700] text-center'>
            {isEdit ? 'Update' : 'Create'} Medical History Section
          </p>
          <div className='flex gap-[30px] justify-center'>
            <div>
              <DynamicInputElements type='text' name='sectionName' id='sectionName' title='Section Name*'
                value={sectionData?.sectionName?.title} handleOnInputElementChange={handleSectionNameChange}
                error={errorData?.sectionName}
              />
            </div>
            <div>
              <DynamicInputElements type='number' name='sortOrder' id='sortOrder' title='Sort Order*'
                value={sectionData?.priority} handleOnInputElementChange={handleChangePriority}
                error={errorData?.sortOrder}
              />
            </div>
          </div>
          <div className='pt-[20px]'>
            <div className='grid grid-cols-12'>
              <div className='col-span-6'>
                <button
                  type='submit'
                  className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px]'
                  style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                  onClick={() => createQuestion()}
                >
                  + Add Question
                </button>
              </div>
              {isEdit && (
                <div className='col-span-6 flex gap-[10px]'>
                  <p>Disable this section</p>
                  <Toggle
                    className='status-tog w-[80px] ml-[10px]'
                    checked={sectionData?.status === 2 || sectionData?.status === false}
                    onChange={() => handleToggleChange()}
                    icons={{
                      checked: <span style={{ fontFamily: 'Poppins' }}></span>,
                      unchecked: <span style={{ fontFamily: 'Poppins' }}></span>
                    }}
                  />
                </div>
              )}
            </div>
            {sectionData && Object.keys(sectionData?.questions)?.length > 0 && (
              <div className='pl-[100px] pt-[30px]'>
                {Object.keys(sectionData?.questions)?.map((item, index) => {
                  const question = item;
                  return (
                    <div key={index} className='flex flex-col gap-[10px] pt-[10px]'>
                      <div className='text-[12px]'>
                        <p>{(`question - ${index + 1}`).toUpperCase()}</p>
                      </div>
                      <div className='grid grid-cols-12 gap-[30px]'>
                        <div className='col-span-6'>
                          <DynamicInputElements type='text' id={`section_${item}`} title='Question Title*'
                            value={sectionData?.questions[`${item}`]?.title}
                            handleOnInputElementChange={handleSectionNameChange} error={errorData[question]?.title}
                          />
                        </div>
                        <div className='col-span-2'>
                          <DynamicInputElements type='dropdown' options={dropdownOptions}
                            value={{
                              label: capitalizeFirstLetter(sectionData?.questions[`${item}`]?.type),
                              value: sectionData?.questions[`${item}`]?.type
                            }} id={`section_${item}`}
                            handleOnInputElementChange={handleDropdownChangeType} error={errorData[question]?.type}
                          />
                        </div>
                        <div className='col-span-1 flex self-center cursor-pointer'
                          onClick={() => { setDataToDelete({ item: item }); setIsQuestionDeleteModalOpen(true); }}
                        >
                          <FontAwesomeIcon icon={faTrash} color='red' />
                        </div>
                      </div>
                      {sectionData && sectionData?.questions[`${item}`]?.type === 'radio' && (
                        <div className='pt-[30px]'>
                          <button
                            type='submit'
                            className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px]'
                            style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                            onClick={() => createRadioOptions(item, 1)}
                          >
                            + Add Options
                          </button>
                          {sectionData?.questions[`${item}`] &&
                            Object.keys(sectionData?.questions[`${item}`]?.data)?.map((item1, index) => {
                              return (
                                <div key={index} className='pl-[100px] py-[10px]'>
                                  <div className='grid grid-cols-12 gap-[30px] items-end'>
                                    <div className='col-span-5'>
                                      <DynamicInputElements type='text' id={`section_${question}_${item1}`}
                                        value={sectionData.questions[`${question}`]?.data[item1]?.title}
                                        handleOnInputElementChange={handleChangeDataOptions} title='Option Title*'
                                        error={errorData[question]?.options[item1]}
                                      />
                                    </div>
                                    <div className='col-span-3 flex gap-[10px] items-baseline'>
                                      <input id={`section_${question}_${item1}`} type="checkbox"
                                        checked={sectionData.questions[`${question}`].data[item1].isChild}
                                        onChange={handleChangeIsChild}
                                      />
                                      <div>
                                        <p>Are there sub questions on select?</p>
                                      </div>
                                    </div>
                                    <div className='col-span-2 flex gap-[10px] items-center'>
                                      <input id={`section_${question}_${item1}`} type="radio"
                                        onChange={() => handleOnDefaultOptionChange(question, item1)}
                                        checked={sectionData.questions[`${question}`].data[item1].title &&
                                          (sectionData.questions[`${question}`].data[item1].title ===
                                            sectionData.questions[`${question}`].value)}
                                      />
                                      <p>Is Default</p>
                                    </div>
                                    {index >= 2 && (
                                      <div className='col-span-1 flex self-center cursor-pointer'
                                        onClick={() => {
                                          setDataToDelete({ item: item1, question: question });
                                          setIsOptionDeleteModalOpen(true);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faTrash} color='red' />
                                      </div>
                                    )}
                                  </div>
                                  {sectionData.questions[`${question}`].data[item1].isChild &&
                                    <div>
                                      <RepeaterComponent
                                        errorData={errorData && errorData[question] &&
                                          errorData[question].children[item1]}
                                        jsonData={sectionData.questions[`${question}`].data[item1].children}
                                        customerColor={customerColor}
                                        dropdownOptions={dropdownOptions}
                                        updateSectionData={(data) => updateSectionData(data, question, item1)}
                                      />
                                    </div>
                                  }
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          {sectionData && Object?.keys(sectionData?.questions)?.length > 0 && (
            <div className='text-center p-[20px]'>
              <button
                type='button'
                className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px]'
                style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                onClick={() => handleSave()}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateMedicalHistory;