import React, { useState } from 'react';
import PatientDetailBox from '../common/PatientDetailBox';
import {
  useAddAppointmentDataMutation,
  useLazySearchSymtomListQuery
} from '../../../../reducers/allReducer';
import { apiConstants } from '../../../../constants/api';
import SearchComponent from '../common/SearchComponent';
import ReactSelectFilters from '../../../common/ReactSelectFilters';
import { useDispatch, useSelector } from 'react-redux';
import { updatFieldValue } from '../../../../reducers/selectedDropdownValuesSlice';

const Symptoms = ({ appointmentId }) => {
  const [searchSymptomList] = useLazySearchSymtomListQuery();
  const [addAppointmentData] = useAddAppointmentDataMutation();
  const [isPresentingIllnessSaved, setIsPresentingIllnessSaved] = useState(false);
  const userId = localStorage.getItem('user_id');
  const addedPresentingIllness = useSelector((state) => state?.selectedDropdownValues?.presenting_illness);
  const selectedDurationNumber = useSelector((state) => state?.selectedDropdownValues?.duration_number);
  const selectedDurationType = useSelector((state) => state?.selectedDropdownValues?.duration_type);
  const dispatch = useDispatch();

  const durationTypeOptions = [
    { label: 'Days', value: 'Days' },
    { label: 'Month', value: 'Month' }
  ];

  const getDurationNumbers = () => {
    let tempDurationNumbers = [];
    for (let i = 1; i <= 31; i++) {
      tempDurationNumbers.push({ value: i, label: i });
    }
    return tempDurationNumbers;
  };

  const handleOnDurationNumberChange = (option) => {
    addAppointmentData({
      endpoint: `${apiConstants?.addAppointmentData}/${userId}`,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        duration_number: option?.value,
        type: 'duration_number'
      }
    }).then(() => {
      dispatch(updatFieldValue({ fieldName: 'duration_number', fieldValue: option }));
    });
  };

  const handleOnDurationTypeChange = (option) => {
    addAppointmentData({
      endpoint: `${apiConstants?.addAppointmentData}/${userId}`,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        duration_type: option?.value,
        type: 'duration_type'
      }
    }).then(() => {
      dispatch(updatFieldValue({ fieldName: 'duration_type', fieldValue: option }));
    });
  };

  const savePresentingIllness = () => {
    addAppointmentData({
      endpoint: `${apiConstants?.addAppointmentData}/${userId}`,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        symptoms_add_note: addedPresentingIllness,
        type: 'presenting_illness'
      }
    }).then(() => setIsPresentingIllnessSaved(true));
  };

  const addPresentingIllness = (value) => {
    dispatch(updatFieldValue({ fieldName: 'presenting_illness', fieldValue: value }));
  };

  return (
    <div className='grid grid-cols-12 gap-[30px]'>
      <div className='col-span-6'>
        <PatientDetailBox title='Symptoms'>
          <SearchComponent
            placeholder='Symptom Search'
            dropdownName='symptomDropdown'
            appointmentId={appointmentId}
            addAppointmentType='symptoms'
            saveNoteType='symptoms_add_note'
            endpointForDataSearch={apiConstants.searchSymptomList}
            hookForApiCall={searchSymptomList}
            fieldNameForSearchApi='symptomList'
          />
        </PatientDetailBox>
      </div>
      <div className='col-span-6'>
        <PatientDetailBox title='Presenting Illness'>
          <div className='flex flex-col gap-[5px] p-[10px]'>
            <textarea
              placeholder='Please enter presenting illness'
              className='text-[13px] rounded-[12px] border-[1px] border-[#383678] w-full h-[90px] 
                focus-visible:outline-none p-[10px] resize-none'
              onChange={(e) => addPresentingIllness(e.target.value)}
              rows={2}
              value={addedPresentingIllness}
            ></textarea>
            <p className={`${(addedPresentingIllness?.length > 0 &&
              !isPresentingIllnessSaved) ? 'text-[#FF6302]' : 'text-[#808080]'} 
              font-bold underline text-[15px] text-end cursor-pointer`} onClick={() => savePresentingIllness()}>Save</p>
          </div>
        </PatientDetailBox>
      </div>
      <div className='col-span-6'>
        <PatientDetailBox title='Duration'>
          <div className='grid grid-cols-12 gap-[20px] py-[5px] px-[20px]'>
            <div className='col-span-6'>
              <ReactSelectFilters
                options={getDurationNumbers()}
                placeholder='Duration'
                name='durationNumbersDropdown'
                onChange={(option) => {
                  handleOnDurationNumberChange(option);
                }}
                disableEnterKey
                selectFieldValues={selectedDurationNumber}
                fullBorder
              />
            </div>
            <div className='col-span-6'>
              <ReactSelectFilters
                options={durationTypeOptions}
                placeholder='Select Type'
                name='durationTypeDropdown'
                onChange={(option) => {
                  handleOnDurationTypeChange(option);
                }}
                disableEnterKey
                selectFieldValues={selectedDurationType}
                fullBorder
              />
            </div>
          </div>
        </PatientDetailBox>
      </div>
    </div>
  );
};

export default Symptoms;