import React, { useEffect, useState } from 'react';
import PatientDetailBox from '../common/PatientDetailBox';
import MaleImage from '../../../../images/Male.png';
import FemaleImage from '../../../../images/Female.png';
import {
  useLazyGetCoordinatesQuery,
  useLazyGetPatientDetailsByAppointmentIdQuery,
  useLazyGetPatientMedicalHistoryQuery,
  useLazyGetPatientPastConsultsQuery
} from '../../../../reducers/allReducer';
import { apiConstants } from '../../../../constants/api';
import moment from 'moment';
import MedicalHistoryModal from '../common/MedicalHistoryModal';
import MedicalHistory from './MedicalHistory';
import PastConsultsModal from './PastConsultsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { saveMedicalHistoryData } from '../../../../reducers/medicalHistorySlice';
import { Colorcode } from '../../../common/utils/Colorcode';

export const getDifference = (date1, date2) => {
  if (!date2) {
    date2 = moment();
  }
  date1 = moment(date1);
  var diffDuration = moment.duration(date2.diff(date1));
  return ((diffDuration.years() > 0 ? diffDuration.years() + ' ' + 'Years' : '') +
    ' ' + (diffDuration.months() > 0 ? diffDuration.months() + ' ' + 'Months' : '') +
    ' ' + (diffDuration.days() > 0 ? diffDuration.days() + ' ' + 'Days' : ''));
};

const PatientProfile = ({
  appointmentId,
  showPatientDetailCard,
  setShowPatientDetailCard
}) => {
  const customerColor = Colorcode();
  const [getPatientDetailsByAppointmentId] = useLazyGetPatientDetailsByAppointmentIdQuery();
  const [getPatientMedicalHistory] = useLazyGetPatientMedicalHistoryQuery();
  const [getPatientPastConsults] = useLazyGetPatientPastConsultsQuery();
  const [getCoordinates] = useLazyGetCoordinatesQuery();

  const [patientDetails, setPatientDetails] = useState([]);
  const [isMedicalHistoryModalOpen, setIsMedicalHistoryModalOpen] = useState(false);
  const [medicalHistoryData, setMedicalHistoryData] = useState([]);
  const [isPastConsultsModalOpen, setIsPastConsultsModalOpen] = useState(false);
  const [pastConsultsData, setPastConsultsData] = useState();
  const [pastConsultAppointmentId, setPastConstultAppointmentId] = useState();
  const [coordinates, setCoordinates] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    getPatientDetailsByAppointmentId({
      endpoint: `${apiConstants?.getPatientDetailsByAppointmentId}/${appointmentId}`
    }).then((result) => setPatientDetails(result?.data?.patient_details[0]));
  }, []);

  const handleOnClickMedicalHistory = () => {
    getPatientMedicalHistory({
      endpoint: `${apiConstants?.getPatientMedicalHistory}/${patientDetails?.client_id}`
    }).then((result) => {
      setMedicalHistoryData(result?.data);
      setIsMedicalHistoryModalOpen(true);
    });
  };

  const handleGetCoordinates = (appointmentId) => {
    getCoordinates({
      endpoint: `${apiConstants?.getCoordinates}?appointment_id=${appointmentId}`
    }).then((result) => {
      setCoordinates(result?.data[0]?.totalPoints1);
    });
  };

  const constructQuery = (value) => {
    let query = '';
    if (value) {
      query = value;
    } else {
      query = 'getallresults';
    }
    return query;
  };

  const handleOnClickPastConsults = (value) => {
    const query = constructQuery(value);
    getPatientPastConsults({
      endpoint: `${apiConstants?.getPatientPastConsults}/${patientDetails?.client_id}/${query}`
    }).then((result) => {
      handleGetCoordinates(result?.data?.appointment_details?.appointment_id);
      setPastConsultsData(result?.data);
      setPastConstultAppointmentId(result?.data?.appointment_details?.appointment_id);
      if (!value) {
        setIsPastConsultsModalOpen(true);
      }
    });
  };

  const handleOnClickAppointment = (value) => {
    handleOnClickPastConsults(value);
  };

  const handleOnCloseMedicalHistoryModal = () => {
    dispatch(saveMedicalHistoryData({
      fieldName: 'selectedTabTitle',
      fieldValue: ''
    }));
    dispatch(saveMedicalHistoryData({
      fieldName: 'medicalHistoryDetailsToDisplay',
      fieldValue: []
    }));
    setIsMedicalHistoryModalOpen(false);
  };

  return (
    <>
      {isMedicalHistoryModalOpen && (
        <MedicalHistoryModal
          heading='Medical History'
          handleModalClose={() => handleOnCloseMedicalHistoryModal()}
          isDisplayHeader={true}
        >
          <MedicalHistory medicalHistoryData={medicalHistoryData} clientId={patientDetails?.client_id} />
        </MedicalHistoryModal>
      )}
      {isPastConsultsModalOpen && (
        <MedicalHistoryModal
          heading='Past Consults'
          handleModalClose={() => setIsPastConsultsModalOpen(false)}
          isDisplayHeader={false}
          showAppointments
          appointmentList={pastConsultsData?.appointments}
          pastConsultAppointmentId={pastConsultAppointmentId}
          handleOnClickAppointment={handleOnClickAppointment}
        >
          <>
            {pastConsultsData?.appointments?.length > 0 ? (
              <PastConsultsModal appointmentData={pastConsultsData} coordinates={coordinates} />
            ) : (
              <div className='p-[50px] text-center text-[#777]'>
                <p>No past consults found</p>
              </div>
            )}
          </>
        </MedicalHistoryModal>
      )}
      {showPatientDetailCard ? (
        <PatientDetailBox
          title="Patient Details"
          showCloseIcon={true}
          handleCloseIcon={() => setShowPatientDetailCard(!showPatientDetailCard)}
        >
          <div className='grid grid-cols-12'>
            <div className='col-span-2'>
              <div className='flex flex-col justify-center ml-[20px]'>
                <img
                  src={patientDetails?.gender === 'Male' ? MaleImage : FemaleImage}
                  className='w-[80px] h-[80px] rounded-[50%]' alt=''
                />

              </div>
            </div>
            <div className='col-span-10'>
              <div className='flex flex-col'>
                <p className='text-[17px] text-[#737575] font-semibold'>
                  {patientDetails?.first_name + ' ' + patientDetails?.last_name}
                </p>
                <div className='flex gap-[10px] flex-wrap text-[12px] text-[#999999] font-medium'>
                  <p>{patientDetails?.gender}</p>|
                  <p>{getDifference(patientDetails?.dob, null, 'years')}</p>|
                  <p>{patientDetails?.phone_number}</p>|
                  <p>Blood Group: {patientDetails?.blood_group}</p>
                </div>
                {patientDetails?.allergies && (
                  <p className='text-[11px] text-[#999] pt-[5px]'>Allergies: {patientDetails?.allergies}</p>
                )}
                <div className='flex gap-[5px] pt-[10px]'>
                  <button
                    className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px] text-[12px]
                      whitespace-normal'
                    style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                    onClick={() => handleOnClickMedicalHistory()}
                  >
                    Medical History
                  </button>
                  <button
                    className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px] text-[12px]
                      whitespace-normal'
                    style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                    onClick={() => handleOnClickPastConsults()}
                  >
                    Past Consults
                  </button>
                </div>
              </div>
            </div>
          </div>
        </PatientDetailBox>
      ) : (
        <div className='flex justify-between bg-[#383678] p-[10px] text-[14px]'
          style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
        >
          <div className='flex gap-[10px] flex-wrap text-white'>
            <span>{patientDetails?.first_name + ' ' + patientDetails?.last_name}</span>|
            <span>{patientDetails?.gender}</span>|
            <span>{getDifference(patientDetails?.dob, null, 'years')}</span>|
            <span>{patientDetails?.phone_number}</span>
          </div>
          <div onClick={() => setShowPatientDetailCard(!showPatientDetailCard)} className='cursor-pointer'>
            <FontAwesomeIcon icon={faAngleDown} color='white' />
          </div>
        </div>
      )}
    </>
  );
};

export default PatientProfile;
