import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faCircleArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useLazyGetappointmentusersQuery,
  useLazyGetpatientdocumentsQuery,
  useUploadpatientdocumentsMutation,
  useUpdatepatientdocumentMutation
} from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import DeleteModal from './DeleteModal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { Colorcode } from '../../common/utils/Colorcode';

function Upload() {
  const navigate = useNavigate();
  const customerColor = Colorcode();
  const { appointment_id } = useParams();
  const [getappointmentusers] = useLazyGetappointmentusersQuery();
  const [getpatientdocuments] = useLazyGetpatientdocumentsQuery();
  const [uploadpatientdocuments] = useUploadpatientdocumentsMutation();
  const [updatepatientdocument] = useUpdatepatientdocumentMutation();

  const [appointmentData, setAppointmentData] = useState();
  const [patientDoc, setPatientDoc] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [documentID, setDocumentID] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [attachmentId, setAttachmentId] = useState('');

  const hiddenUserFileInput = useRef(null);

  const handleClick = () => {
    hiddenUserFileInput.current.click();
  };
  const handleUploadDoc = (e) => {
    let file = e.target.files[0];
    uploadpatientdocuments({
      endpoint: apiConstants.uploadpatientdocuments,
      method: 'POST',
      data: {
        objectName: e.target.files[0].name,
        appointment_id: appointment_id,
        attachment_desription: '',
        client_id: appointmentData?.client_id
      }
    }).then((result) => {
      if (result?.data) {
        fetch(result?.data.signedUrl, {
          method: 'PUT',
          body: file
        }).then(res => {
          console.log(res);
        });
        fetchPatientdocuments();
      }
    }).catch((error) => {
      console.error('error', error);
    });
    hiddenUserFileInput.current.value = ''; // Reset the file input value
  };
  const fetchPatientdocuments = () => {
    getpatientdocuments({
      endpoint: `${apiConstants.getpatientdocuments}/${appointment_id}`
    }).then((result) => {
      setPatientDoc(result?.data);
    }).catch((error) => {
      console.error('error', error);
    });
  };

  useEffect(() => {
    getappointmentusers({
      endpoint: `${apiConstants.getappointmentusers}/${appointment_id}`
    }).then((result) => {
      setAppointmentData(result?.data?.data);
    }).catch((error) => {
      console.error('error', error);
    });
    fetchPatientdocuments();
  }, []);

  const handleSubmit = (values) => {
    updatepatientdocument({
      endpoint: apiConstants.updatepatientdocument,
      method: 'PUT',
      data: {
        attachment_description: values?.description,
        attachment_id: attachmentId,
        original_name: values?.original_name
      }
    }).then((result) => {
      console.log('result1', result?.data);
      fetchPatientdocuments();
    }).catch((error) => {
      console.error('error', error);
    });
    setIsEdit(false);
  };

  const downloadFile = (fileName) => {
    window.open(`https://sakha-health.s3.amazonaws.com/${fileName}`);
  };

  return (
    <div className='medical-history-container mx-[6%] mt-[5%] mb-[15px]'>
      {deleteModal && <DeleteModal
        setDeleteModal={setDeleteModal}
        documentID={documentID}
        fetchPatientdocuments={fetchPatientdocuments}
      />}
      <input
        id="uploadDoc"
        type="file"
        accept=".xlsx, .png, .pdf, .jpg"
        ref={hiddenUserFileInput}
        style={{ display: 'none' }}
        onChange={(e) => handleUploadDoc(e)}
      />
      <div className='text-[#737575] flex flex-col items-center w-[100%] relative'>
        <div className='text-sm fw-500 absolute top-[20px] left-[40px] underline hover:cursor-pointer'
          style={{color: customerColor[0]}} onClick={() => navigate(-1)}>Back</div>
<div className='text-[30px] fw-500 mb-[40px]'>Upload Documents</div>
        <div className='text-l fw-500 flex justify-around w-[80%]'>
          <div>Appointment Id:  {appointment_id}</div>
          <div>Doctor:  Dr. {appointmentData?.doctor_name}</div>
          <div>Patient:  {appointmentData?.client_name}</div>
        </div>
        <div className='flex items-center w-[100%] pt-[30px]'>
          <hr className='border-[1px] border-[#c0c0c0] w-[10%]' />
          <span style={{color: customerColor[0]}}>Attachments</span>
          <hr className='border-[1px] border-[#c0c0c0] w-[100%]' />
        </div>
      </div>
      {patientDoc?.length > 0 ?
        (<div className='grid grid-cols-12 mb-2'>
          <div className='col-span-3 border-r border-[#c0c0c0] flex flex-col items-center pt-[20px]'>
            <FontAwesomeIcon icon={faCircleArrowUp}
              className='text-[160px]' style={{color: customerColor[0]}}
              onClick={() => handleClick()}
            />
            <div>Select files to upload</div>
          </div>
          <div className='col-span-9'>
            {patientDoc?.map((item, index) => (
              <div key={index} className='w-[100%] py-[10px] border-b border-[#c0c0c0] grid grid-cols-12'>
                {(isEdit && item.attachment_id === attachmentId) ? <>
                  <div className='col-span-12'>
                    <Formik
                      initialValues={{
                        original_name: item.original_name,
                        description: item.attachment_description
                      }}
                      validationSchema={yup.object().shape({
                        original_name: yup.string().required('Please enter file name'),
                        description: yup.string().required('Please enter description')
                      })}
                      onSubmit={handleSubmit}
                    >
                      <Form>
                        <div className='flex justify-between px-[20px] mb-[15px]'>
                          <div>
                            <Field
                              name="original_name"
                              type='text'
                              placeholder="Original Name"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                            outline-none w-full px-3"
                            />
                            <ErrorMessage name="original_name" component="div" className='text-[red] text-[12px]' />
                          </div>
                          <div>
                            <button
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
className="ml-0 rounded-[20px] text-white px-[25px] py-[5px] text-sm
                            mt-1.5 mx-auto flex cursor-pointer"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                        <div className='flex justify-between px-[20px] mb-[15px]'>
                          <div>
                            <Field
                              name="description"
                              type='text'
                              placeholder="Attachment Description"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full px-3"
                            />
                            <ErrorMessage name="description" component="div" className='text-[red] text-[12px]' />
                          </div>
                          <div>
                            <button className="ml-0 rounded-[20px] text-white px-[25px] py-[5px] text-sm
                              mt-1.5 mx-auto flex cursor-pointer"
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
onClick={() => setIsEdit(false)}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      </Form>
                    </Formik>
                  </div></> : <>
                  <div className='col-span-1 flex justify-center'>
                    <FontAwesomeIcon icon={faEdit}
                      className='text-[30px]' style={{color: customerColor[0]}}
                      onClick={() => { setIsEdit(true); setAttachmentId(item?.attachment_id); }}
                    />
                  </div>
                  <div className='col-span-11'>
                    <div className='flex justify-between px-[20px]'>
                      <div className='font-[600]'>{item.original_name}</div>
                      <div>
                        <button className="ml-0 rounded-[20px] text-white px-[25px] py-[5px] text-sm mt-1.5
                        mx-auto flex cursor-pointer"
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
                          onClick={() => downloadFile(item.attachment_name)}>
                          View
                        </button>
                      </div>
                    </div>
                    <div className='flex justify-between px-[20px]'>
                      <div className='font-[600]'>{item.attachment_description}</div>
                      <div>
                        <button className="ml-0 rounded-[20px] text-white px-[25px] py-[5px] text-sm mt-1.5
                        mx-auto flex cursor-pointer"
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
                          onClick={() => { setDeleteModal(true); setDocumentID(item?.attachment_id); }}>
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                </>}
              </div>
            ))}
          </div>
        </div>) :
        (<div className='w-[100%] flex flex-col items-center pt-[20px]'>
          <div>
            <FontAwesomeIcon icon={faCircleArrowUp}
              className='text-[160px] mb-[20px]' style={{color: customerColor[0]}}
              onClick={() => handleClick()}
            />
            <div>Select files to upload</div>
          </div>
        </div>)
      }

    </div >
  );
}

export default Upload;
