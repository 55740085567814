import React, { useState, useEffect } from 'react';
import {
  useLazyGetAdminSettingsQuery
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import { awsdetails } from '../../constants/awsdetails';
import Loader from '../common/Loader';
import DefaultImage from '../../images/brokenIm.png';
import SettingsModal from './SettingsModal';
// import { Gallery } from 'react-grid-gallery';
import { Colorcode } from '../common/utils/Colorcode';

const Settings = () => {
  const customerColor = Colorcode();
  const [getList] = useLazyGetAdminSettingsQuery();
  const url = awsdetails.awsUrl;
  const [loading, setLoading] = useState(false);
  const [settingsData, setSettingsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  // const IMAGES = [{
  //     src: settingsData?.image ? `${url}/${settingsData.image}` : DefaultImage,
  //     thumbnail: settingsData?.image ? `${url}/${settingsData.image}` : DefaultImage,
  //     thumbnailWidth: 120,
  //     thumbnailHeight: 60,
  //     isSelected: false,
  //     caption: settingsData?.image ? `${url}/${settingsData.description}` : ''
  // }];

  const fetchTableData = () => {
    setLoading(true);
    getList({
      endpoint: apiConstants.adminSettings,
      method: 'Get'
    }).then((result) => {
      setSettingsData(result?.data);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.error('error', error);
    });
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const logoUploadingModelShow = () => {
    setShowModal(true);
  };

  return (
    <div className="row mt-16 mx-40">
      {showModal &&
        <SettingsModal
          setShowModal={setShowModal}
          settingsData={settingsData}
        />
      }
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div className="w-[85%] mx-auto my-7">
          <div className="border-[1px] border-[#383678] rounded-lg">
            <div className='bg-[#383678] rounded-tl-[0.35rem] rounded-tr-[0.35rem] 
                text-xl text-white p-3 font-black'
              style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
            >
              General Settings
            </div>
            <div className="p-3.5">
              <br />
              <div className="row border-[0.5px] border-gray-700 rounded-lg shadow-md flex p-3">
                <div className="w-[80%]">
                  <h6 className="text-xs">1. Logo/Image For PDF`s</h6>
                </div>
                <div className="w-[15%]">
                  {/* <Gallery
                        images={IMAGES}
                        enableLightbox={true}
                        enableImageSelection={false}
                      //currentImageWillChange={onCurrentImageChange}
                        rowHeight={80}
                      /> */}
                  <img src={settingsData?.image ? `${url}/${settingsData.image}` : DefaultImage} />
                </div>
                <div className="w-[5%] h-7 flex items-center justify-center"
                  onClick={logoUploadingModelShow}>
                  <div className='rounded-lg bg-[#383678] p-2'>
                    <svg
                      viewBox="0 0 24 24"
                      fill="white"
                      height="1em"
                      width="1em"
                    >
                      <path d="M4 21a1 1 0 00.24 0l4-1a1 1 0 00.47-.26L21 7.41a2 2 0 
                        000-2.82L19.42 3a2 2 0 00-2.83 0L4.3 15.29a1.06 1.06 0 00-.27.47l-1 4A1 
                        1 0 003.76 21 1 1 0 004 21zM18 4.41L19.59 6 18 7.59 16.42 6zM5.91 16.51L15 
                        7.41 16.59 9l-9.1 9.1-2.11.52z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <ToastContainer /> */}
        </div>
      )}
    </div>
  );

};

export default Settings;