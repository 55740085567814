import React from 'react';

const CommonTitleComponent = ({
  heading,
  children,
  isListing,
  listingData,
  noPaddingTop,
  durationNumber,
  durationType
}) => {
  return (
    <div className={`${!noPaddingTop && 'pt-[20px]'} flex flex-col gap-[10px]`}>
      <p className='underline text-[14px] font-bold'>{heading}</p>
      {isListing && listingData?.length > 0 && (
        <ol className='list-decimal pl-[20px]'>
          {listingData?.map((list, index) => (
            <li key={index} className='text-[12px]'>
              {list?.name || list}
              {durationNumber && durationNumber !== null && (
                <>
                  <span>{' ' + '-' + ' ' + 'X' + ' ' + durationNumber}</span>
                  {durationType && durationType !== null && (
                    <span>{' ' + durationType}</span>
                  )}
                </>
              )}
            </li>
          ))}
        </ol>
      )}
      {children && (
        {...children}
      )}
    </div>
  );
};

export default CommonTitleComponent;
