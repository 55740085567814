import React from 'react';
import { Link } from 'react-router-dom';
import WelcomeScreenBG from '../../images/backgrounds/WelcomeScreenBG.jpg';

const WelcomePage = () => {
    return (
        <div
            className="h-screen bg-cover bg-center flex items-center px-4"
            style={{ backgroundImage: `url(${WelcomeScreenBG})` }}
        >
            <Link to="/login">
                <button
                    className="text-white text-lg px-[3rem] py-[0.5rem] rounded-full 
                    bg-yellow-500 border border-yellow-500 font-[900] shadow-md absolute 
                    left-[5rem] bottom-[10rem] transition-all"
                    onMouseOver={(e) => {
                        e.currentTarget.style.backgroundColor = '#b39c21';
                        e.currentTarget.style.boxShadow = '2px 2px 5px 0 rgba(0, 0, 0, 0.53)';
                    }}
                    onMouseOut={(e) => {
                        e.currentTarget.style.backgroundColor = '#c9ab0d';
                        e.currentTarget.style.boxShadow = 'none';
                    }}
                >
                    Login
                </button>
            </Link>
            <div
                className="absolute left-[5rem] bottom-[3rem] text-white"
                style={{ fontFamily: 'Poppins' }}
            >
                <h2 className="font-normal text-3xl mt-8">YOUR HEALTH</h2>
                <h1 className="font-extrabold text-3xl leading-tight">IS OUR PRIORITY</h1>
            </div>
        </div>
    );
};

export default WelcomePage;
