import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Formik, Form, Field } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';

const AnalyticsModal = ({
  setfilterPopup,
  setObjectData,
  objectData,
  getAnalytics,
  setfilterApplied
}) => {

  const handleSubmit = () => {
    getAnalytics();
    setfilterPopup(false);
    setfilterApplied(true);
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[10px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white">
              <div className="w-full relative">
                <div className='p-[20px] border-b-[1px] border-b-[#808080] flex justify-center'>
                  <span className='text-[#757776] text-[24px] '>
                    Filter by Date</span>
                </div>
                <FontAwesomeIcon icon={faClose}
                  className='text-[#000] right-[40px] top-[25px] text-[20px] cursor-pointer absolute'
                  onClick={() => setfilterPopup(false)}
                />
                <div className="w-full py-[30px] px-[40px] text-[#757776]">
                  <Formik
                    initialValues={{
                      startDate: '',
                      endDate: ''
                    }}
                  >
                    <Form className='flex items-center gap-[50px]'>
                      <div>
                        <label>Start Date</label>
                        <Field
                          name="start_date"
                          type='date'
                          placeholder="Start date*"
                          autoComplete="off"
                          className="border-b border-[#A9A9A9] opacity-70 bg-[#ffffff]
                            outline-none w-full text-[#000] mt-[10px]"
                          onChange={(e) => {
                            setObjectData({ ...objectData, start_date: e.target.value });
                          }}
                          value={objectData?.start_date}
                          max={new Date().toISOString().split('T')[0]}
                        />
                      </div>
                      <div>
                        <label>End Date</label>
                        <Field
                          name="end_date"
                          type='date'
                          placeholder="End date*"
                          autoComplete="off"
                          className="border-b border-[#A9A9A9] opacity-70 bg-[#ffffff]
                            outline-none w-full text-[#000] mt-[10px]"
                          onChange={(e) => {
                            setObjectData({ ...objectData, end_date: e.target.value });
                          }}
                          value={objectData?.end_date}
                          min={objectData?.start_date}
                          max={new Date().toISOString().split('T')[0]}
                        />
                      </div>
                    </Form>
                  </Formik>
                </div>
              </div>
              <div className='flex gap-[80px] my-[20px]'>
                <button
                  type='submit'
                  className="bg-[#383678] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                  rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                  style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
                <button
                  type='text'
                  className="bg-[#383678] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                  rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                  style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                  onClick={() => setObjectData({
                    start_date: '',
                    end_date: ''
                  })}
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default AnalyticsModal;
