import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as yup from 'yup';
import CustomerImage from '../../images/Customer_icon.png';
import * as yup from 'yup';
// import PatientWithSamePhoneModal from './PatientWithSamePhoneModal';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { useAddCustomerMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';

const Schema = yup.object().shape({
  customer_name: yup.string().required('Please Enter Customer Name Without Empty Spaces'),
  contact_name: yup.string().max(10).required('Please Enter Contact Name Without Empty Spaces'),
  email: yup.string().email('Must be valid email').required('Please Enter Valid Email')
});

const CreateCustomer = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [addCustomerData] = useAddCustomerMutation();

  const handleSubmit = (values) => {
    values.phone = phoneNumber;
    addCustomerData({
      endpoint: apiConstants.addCustomer,
      method: 'POST',
      data: {
        customer_name: values.customer_name,
        primary_contact_name: values.contact_name,
        primary_contact_phone: values.phone,
        primary_contact_email: values.email
      }
    }).then((result) => {
      console.log(result);
      navigate('/admin/customerList');
    }).catch((error) => {
      console.log(error);
      navigate('/admin/customerList');
    });
  };

  return (
    <div className='my-[2%] mx-[5%]'>
      <div className='bg-[#f4f4f4] grid grid-cols-12 mt-28'>
        <div className='col-span-6'>
          <div className='text-[#737575] text-[40px] mt-[25px] ml-36'>Create Customer</div>
          <img className="h-auto mx-auto ml-48 mt-16 w-[250px]" src={CustomerImage} alt="image description" />
        </div>

        <div className='col-span-6'>
          {/* form here */}
          <Formik
            initialValues={{
              customer_name: '',
              contact_name: '',
              email: ''
            }}
            validationSchema={Schema}
            onSubmit={handleSubmit}
          >
            <Form className='flex flex-col items-center gap-[50px] mt-[30px]'>
              <div className='relative'>
                <Field
                  name="customer_name"
                  type='text'
                  placeholder="Customer name*"
                  autoComplete="off"
                  className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
                />
                <ErrorMessage name="customer_name" component="div" className='text-red-600 absolute' />
              </div>
              {/* Contact number */}
              <div className='relative'>
                <Field
                  name="contact_name"
                  type='text'
                  placeholder="Contact Name"
                  autoComplete="off"
                  className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
                />
                <ErrorMessage name="contact_name" component="div" className='text-red-600 absolute' />
              </div>
              {/*  phone number*/}
              <div className="relative w-[40%]"
                style={{ display: 'grid', background: '#f4f4f4' }}>
                <Field name="phone" className='createCustomer border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3'>
                  {({ field }) => (
                    <PhoneInput
                      {...field}
                      name="phone"
                      type="tel"
                      placeholder="Phone Number"
                      country={'in'}
                      onlyCountries={['in']}
                      autoFormat={false}
                      onChange={(data) => {
                        setPhoneNumber('+' + data);
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="phone" component="div" className='text-red-600' />
              </div>
              {/* email field */}
              <div className='relative'>
                <Field
                  name="email"
                  type='text'
                  placeholder="Email*"
                  autoComplete="off"
                  className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
                />
                <ErrorMessage name="email" component="div" className='text-red-600 absolute' />
              </div>
              {/* Buttons */}
              <div className='flex gap-[40px] mb-20'>
                <button
                  type='submit'
                  className="bg-[#383678] text-white px-[50px] py-[5px] w-[150px] h-[48px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center"
                  style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                >
                  Submit
                </button>
                <button
                  type='text'
                  className="bg-[#383678] text-white px-[50px] py-[5px] w-[150px] h-[48px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center"
                  style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                  onClick={() => navigate('/admin/customerList')}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div >
  );
};

export default CreateCustomer;