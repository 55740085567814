import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const VitalGraphModal = ({
  handleModalClose,
  data
}) => {
  const [title, setTitle] = useState();
  const [vitalHistoryData, setVitalHistoryData] = useState(); 

  const handleVitalsData = () => {
    let tempHistoryData;
    if (data[0]) {
      setTitle(data[0]?.name);
      if (data[0].is_multiple_values === 1) {
        tempHistoryData = {
          labels: [],
          datasets: [
            {
              label: '',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgb(51, 207, 162)',
              borderColor: 'rgb(51, 207, 162)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgb(51, 207, 162)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgb(51, 207, 162)',
              pointHoverBorderColor: 'rgb(51, 207, 162)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: []
            }, {
              label: '',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(255, 159, 64)',
              borderColor: 'rgba(255, 159, 64)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(255, 159, 64)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgba(255, 159, 64)',
              pointHoverBorderColor: 'rgba(255, 159, 64)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: []
            }
          ]
        };

        for (let i = 0; i < data.length; i++) {
            tempHistoryData.labels.push(moment(data[i].date).format('DD MMM YYYY'));
            tempHistoryData.datasets[0].data.push(parseInt(data[i].value1));
            tempHistoryData.datasets[1].data.push(parseInt(data[i].value2));
            tempHistoryData.datasets[0].label = data[i].value1_desc;
            tempHistoryData.datasets[1].label = data[i].value2_desc;
        }
        setVitalHistoryData(tempHistoryData);
    } else {
        tempHistoryData = {
          labels: [],
          datasets: [
            {
              label: data[0].value1_desc,
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgb(51, 207, 162)',
              borderColor: 'rgb(51, 207, 162)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgb(51, 207, 162)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgb(51, 207, 162)',
              pointHoverBorderColor: 'rgb(51, 207, 162)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: []
            }
          ]
        };
        for (let i = 0; i < data.length; i++) {
            tempHistoryData.labels.push(moment(data[i].date).format('DD MMM YYYY'));
            tempHistoryData.datasets[0].data.push(data[i].value1);
        }
        setVitalHistoryData(tempHistoryData);
      }
    }
  };

  useEffect(() => {
    if (data?.length > 0) {
      handleVitalsData();
    }
  }, [data]);

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-2xl"
          >
            <div className="bg-white p-3">
              <div className="w-full">
                <div className='grid grid-cols-12 p-[20px] border-b-[2px] border-b-[#808080] items-center'>
                  <div className='col-span-10'>
                    <p className='text-[#757776]'>{title}</p>
                  </div>
                  <div 
                    className='col-span-2 flex items-center justify-end cursor-pointer' 
                    onClick={() => handleModalClose()}
                  >
                    <FontAwesomeIcon icon={faXmark} style={{ color: 'black' }} />
                  </div>
                </div>
              </div>
              <div className="w-full p-[20px] text-[#757776]">
                {vitalHistoryData && (
                  <Line data={vitalHistoryData} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VitalGraphModal;
