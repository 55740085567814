import React, { useEffect, useState } from 'react';
import SharedButton from '../SharedButton';
import EditImg from '../../../images/editIcon.png';
import Mail from '../../../images/mail.png';
import Phone from '../../../images/phone.png';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import PhoneInput from 'react-phone-input-2';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { useUpdateagentMutation } from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import * as yup from 'yup';

const Schema = yup.object().shape({
  first_name: yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid first name')
    .max(40).required('Please Enter First Name Without Empty Spaces & Numbers'),
  last_name: yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid last name')
    .max(40).required('Please Enter Last Name Without Empty Spaces & Numbers'),
  phone_number: yup.string().min(13, 'Please enter a valid phone number')
    .max(13, 'Please enter a valid phone number').required('Please Enter a Valid Phone Number'),
  email_id: yup.string().email('Must be a valid email').max(255).required('Email is required')
});

const DetailsView = ({
  cardData,
  handleCardData
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [phoneNo, setPhoneNo] = useState(cardData.phone_number || '');
  const [isEdit, setIsEdit] = useState(false);
  const [isToggle, setIsToggle] = useState(cardData.status === 'active' ? false : true);
  const [viewType, setViewType] = useState('frontdesk');

  const [updateAgentandFD] = useUpdateagentMutation();

  const toggleBar = () => {
    setIsToggle(!isToggle);
  };

  useEffect(() => {
    if (window?.location?.href?.includes('paramedic')) {
      setViewType('paramedic');
    } else {
      setViewType('frontdesk');
    }
  }, []);

  const handleKiosk = () => {
    navigate(`/admin/associate-kiosk/${id}`, { state: cardData.kiosk_code });
  };
  console.log('isToggle', isToggle);
  const handleSubmit = (values) => {
    updateAgentandFD({
      endpoint: viewType === 'frontdesk' ? apiConstants.updateagent :
        apiConstants.updateparamedic,
      method: 'PUT',
      data: {
        data: {
          email_id: values.email_id,
          first_name: values.first_name,
          id: id,
          last_name: values.last_name,
          phone_number: values.phone_number,
          status: isToggle ? 'inactive' : 'active'
        }
      }
    }).then(() => {
      handleCardData();
    }).catch(() => {
    });
    setIsEdit(false);
  };

  return (
    <div className='flex flex-col mt-16'>
      <div>
        <SharedButton
          className="ml-0 rounded-[20px] float-left text-[18px]
            bg-[#383678] text-white px-[30px] py-[7px] font-bold
            mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
          title='Back' onClick={() => navigate(-1)}
        />
      </div>
      <div className='bg-[#f4f4f4] rounded-lg mt-4 p-2 h-[575px] relative'>
        <div className='flex justify-center mt-6 w-full h-full'>
          <Formik
            initialValues={{
              first_name: cardData.first_name,
              last_name: cardData.last_name,
              email_id: cardData.email_id,
              phone_number: cardData.phone_number
            }}
            enableReinitialize
            validationSchema={Schema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form className='w-full flex gap-[5%]'>
                {isEdit ? <div className='flex gap-[20px] absolute top-[20px] right-[10px]'>
                  <div className=' flex items-center detailView'>
                    <div className='doctor-status'>Disable </div>
                    <Toggle
                      className='status-tog'
                      checked={isToggle}
                      onChange={() => toggleBar()}
                      icons={{
                        checked: <span style={{ fontFamily: 'Poppins' }}></span>,
                        unchecked: <span style={{ fontFamily: 'Poppins' }}></span>
                      }}
                    />
                  </div>
                  <SharedButton
                    className="ml-0 rounded-[20px] float-left text-[18px]
                  bg-[#383678] text-white px-[30px] py-[7px] font-bold
                    mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                    type='submit'
                    title='Save'
                  />
                </div> :
                  <div className='absolute right-[20px] top-[20px]'>
                    <img className='ml-3 cursor-pointer' src={EditImg} onClick={() => setIsEdit(true)} />
                  </div>}
                <div className='w-[45%] flex flex-col gap-[20px] items-center justify-center'>
                  {isEdit ?
                    (<>
                      <Field
                        name="first_name"
                        type='text'
                        placeholder="First Name"
                        autoComplete="off"
                        className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                      outline-none w-[50%] py-2 px-3 h-[50px]"
                      />
                      <ErrorMessage name="first_name" component="div" className='text-[red] text-[12px]' />
                      <Field
                        name="last_name"
                        type='text'
                        placeholder="Last Name"
                        autoComplete="off"
                        className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                        outline-none w-[50%] py-2 px-3 h-[50px]"
                      />
                      <ErrorMessage name="last_name" component="div" className='text-[red] text-[12px]' />
                    </>) :
                    <div className='mt-[10%]'>
                      {cardData.first_name && (
                        <label className='text-[#737575] text-5xl font-semibold'>
                          {cardData.first_name} {cardData.last_name}
                        </label>
                      )}
                    </div>}
                  <div className='flex gap-[20px] items-center'>
                    <div className='text-center'>
                      {cardData.first_name && (
                        <label className='text-[#737575] text-[20px] font-semibold'>
                          {viewType === 'frontdesk' ? '#FD' : '#PD'}{id}
                        </label>
                      )}
                    </div>
                    <div className='h-[22px] border-l-2 border-solid border-black'></div>
                    <div className=''>
                      {cardData.status}
                    </div>
                  </div>
                  {cardData.association_status && <div className='flex gap-[20px] items-center text-[20px]'>
                    <div className='text-center'>
                      Kiosk: <span className='text-[#074faa]'>{cardData.kiosk_name}</span>
                    </div>
                    <div className='h-[22px] border-l-2 border-solid border-black'></div>
                    <div className=''>
                      KioskID: <span className='text-[#074faa]'>{cardData.kiosk_code}</span>
                    </div>
                  </div>}
                  {!isEdit && cardData.status === 'active' &&
                    <div>
                      <SharedButton
                        className="ml-0 rounded-[20px] float-left 
                    bg-[#383678] text-white px-[30px] py-[5px] text-sm font-bold
                      mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        title={cardData.association_status ? 'Re-associate kiosk' : 'Associate kiosk'}
                        onClick={() => handleKiosk()}
                      />
                    </div>}
                </div>
                <div className='h-[80%] border-l-2 border-solid border-[#d3d3d3] my-8'></div>
                <div className='w-[45%] flex flex-col justify-center items-start ml-[5%] gap-[30px]'>
                  <div className='flex items-center gap-3'>
                    <div className='w-[85px] h-[65px] rounded-[50%] bg-[#d1d1d166] flex justify-center items-center'>
                      <img className='cursor-pointer' src={Mail} width={32} height={23} />
                    </div>
                    {isEdit ? <div>
                      <Field
                        name="email_id"
                        type='text'
                        placeholder="Email id"
                        autoComplete="off"
                        className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                        outline-none w-full py-2 px-3 h-[50px]"
                      />
                      <ErrorMessage name="email_id" component="div" className='text-[red] text-[12px]' /></div> :
                      <div className='text-[18px] text-[#999999]'>{cardData.email_id}</div>}
                  </div>
                  <div className='flex items-center w-[60%]'>
                    <div className='w-[85px] h-[65px] rounded-[50%] bg-[#d1d1d166]  flex justify-center items-center'>
                      <img className='cursor-pointer' src={Phone} width={37} height={30} />
                    </div>
                    {isEdit ?
                      <div>
                        <Field name="phone_number" className='w-[60%]'>
                          {({ field }) => (
                            <PhoneInput
                              {...field}
                              name="phone_number"
                              type="tel"
                              placeholder="Phone Number*"
                              country={'in'}
                              onlyCountries={['in']}
                              autoFormat={false}
                              value={phoneNo}
                              onChange={(data) => {
                                const phoneNumber = '+' + data;
                                setPhoneNo(phoneNumber);
                                setFieldValue('phone_number', phoneNumber);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="phone_number" component="div" className='text-[red] text-[12px]' /></div> :
                      <div className='text-[18px] text-[#999999]'>{cardData.phone_number}</div>}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default DetailsView;