import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../reducers/counterSlice';

const Counter = () => {
  const dispatch = useDispatch();
  const countValue = useSelector((state) => state.counter.value);
  return (
    <div className='flex flex-row gap-[10px] items-center justify-center pt-[50px]'>
      <button
        className='border-[1px] border-black rounded-sm px-[15px] py-[10px] bg-[#ece1eb]'
        onClick={() => dispatch(increment())}
      >
        +
      </button>
      <span className='font-bold text-[16px] text-[#x83d38]'>{countValue}</span>
      <button
        className='border-[1px] border-black rounded-sm px-[15px] py-[10px] bg-[#ece1eb]'
        onClick={() => dispatch(decrement())}
      >
        -
      </button>
    </div>
  );
};

export default Counter;
