import React, { useEffect, useState } from 'react';
import PatientDetailBox from '../common/PatientDetailBox';
import { apiConstants } from '../../../../constants/api';
import { useAddAppointmentDataMutation } from '../../../../reducers/allReducer';
import { useDispatch, useSelector } from 'react-redux';
import { updatFieldValue } from '../../../../reducers/selectedDropdownValuesSlice';

const TeleExamination = ({ appointmentId }) => {
  const addedTeleExaminationFromApi = useSelector((state) => state?.selectedDropdownValues?.tele_examination);
  const [addAppointmentData] = useAddAppointmentDataMutation();
  const [isTeleExaminationSaved, setIsTeleExaminationSaved] = useState(false);
  const userId = localStorage.getItem('user_id');
  const dispatch = useDispatch();

  const saveTeleExamination = () => {
    addAppointmentData({
      endpoint: `${apiConstants?.addAppointmentData}/${userId}`,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        tele_examination: addedTeleExaminationFromApi,
        type: 'tele_examination'
      }
    }).then(() => {
      setIsTeleExaminationSaved(true);
    });
  };

  const saveNote = (value) => {
    setIsTeleExaminationSaved(false);
    dispatch(updatFieldValue({ fieldName: 'tele_examination', fieldValue: value }));
  };

  useEffect(() => {
    if (addedTeleExaminationFromApi?.length > 0) {
      setIsTeleExaminationSaved(true);
    }
  }, []);

  return (
    <div className='grid grid-cols-12 gap-[30px]'>
      <div className='col-span-12'>
        <PatientDetailBox title='Tele Examination'>
          <div className='flex flex-col gap-[5px] p-[10px]'>
            <textarea
              placeholder='Please enter the description about tele-examination(if any)'
              className='text-[13px] rounded-[12px] border-[1px] border-[#383678] w-full h-[90px] 
                focus-visible:outline-none p-[10px] resize-none text-[#555]'
              onChange={(e) => saveNote(e.target.value)}
              value={addedTeleExaminationFromApi}
              rows={2}
            ></textarea>
            <p className={`${(addedTeleExaminationFromApi?.length > 0 &&
              !isTeleExaminationSaved) ? 'text-[#FF6302]' : 'text-[#808080]'} 
              font-[600] underline text-[15px] text-end cursor-pointer`}
              onClick={() => !isTeleExaminationSaved && saveTeleExamination()}>
              Save
            </p>
          </div>
        </PatientDetailBox>
      </div>
    </div>
  );
};

export default TeleExamination;