import React from 'react';
import { Formik, Form, Field } from 'formik';

const Modal = ({ heading }) => {
  // should be accepting from props
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-lg"
          >
            <div className="bg-white p-3">
              <div className="w-full">
                <div className="text-left">
                  <div className="grid grid-cols-12">
                    <h3
                      className="font-jost font-[600] lg:text-[13px] xl:text-[16px] xl-mid:text-[18px]
                          4xl:text-[20px] whitespace-nowrap mb-2 col-span-11"
                      id="modal-title"
                    >
                      {heading}
                    </h3>
                    <span
                      className="col-span-1 text-right flex items-start justify-end"
                      role="presentation"
                    ></span>
                  </div>
                  <Formik initialValues={{}}>
                    <Form>
                      <Field
                        name="fileName"
                        type="text"
                        placeholder="e.g. red sofa"
                        autoComplete="off"
                        className="customTextBoxColor block w-full h-[48px] px-2 py-1 mb-1 lg:text-[10px] xl:text-[12px]
                             xl-mid:text-[14px] 4xl:text-[14px] leading-normal text-gray-800 bg-white border
                              border-gray-200 rounded appearance-none"
                      />
                    </Form>
                  </Formik>
                </div>
              </div>
              <div
                className="w-full font-jost font-[400] lg:text-[12px] xl:text-[14px] 
                    xl-mid:text-[16px] 4xl:text-[18px] whitespace-nowrap mb-"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
