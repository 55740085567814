import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faAngleLeft, faSortAsc, faSortDesc } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
const SharedTable = ({
  tableData,
  tableHeaders,
  handleOnPageChange,
  isMoreData,
  totalPages,
  onRowSelected,
  onCheckboxSelected,
  showCheckbox,
  handleOnClickSort,
  isPagination,
  location,
  currentPageNumber,
  handleSelect
}) => {
  const isAdmin = localStorage.getItem('privileges') === 'admin' ? true : false;
  const navigate = useNavigate();
  //var [pageNumber, setPageNumber] = useState(1);
  const [sortType, setSortType] = useState('des');
  const [sortedHeader, setSortedHeader] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleNextPage = () => {
    const newPageNumber = currentPageNumber + 1;
    //pageNumber = newPageNumber;
    //setPageNumber(newPageNumber);
    handleOnPageChange(newPageNumber);
  };

  const handlePreviousPage = () => {
    const newPageNumber = currentPageNumber - 1;
    //pageNumber = newPageNumber;
    //setPageNumber(newPageNumber);
    handleOnPageChange(newPageNumber);
  };

  const handleSortArrow = (header) => {
    if (header?.headerName === sortedHeader) {
      setSortType((prev) => prev === 'desc' ? 'asc' : 'desc');
    } else {
      setSortedHeader(header?.headerName);
      setSortType('asc');
    }
  };

  const handleHeaderCheckboxChange = () => {
    setSelectedRows((prev) => {
      const newSelectedRows = prev.length === tableData.length ? [] : tableData.map((row) => row.id);
      return newSelectedRows;
    });
    onCheckboxSelected(selectedRows.length === tableData.length ? [] : tableData.map((row) => row.id));
  };

  const handleRowCheckboxChange = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows((prev) => prev.filter((id) => id !== rowId));
    } else {
      setSelectedRows((prev) => [...prev, rowId]);
    }
  };

  const isAllRowsSelected = selectedRows?.length === tableData?.length;

  return (
    <div>
      <table className='commonTable h-full w-full'>
        <thead>
          <tr>
            {showCheckbox && (
              <th className='w-[40%] relative'>
                <input
                  type="checkbox"
                  checked={isAllRowsSelected}
                  onChange={handleHeaderCheckboxChange}
                />
              </th>
            )}
            {tableHeaders?.map((header, index) => (
              <th key={index} className={`${header?.field === 'front_desk_status'
                || header?.field === 'paramedic_status'
                || header?.field === 'doctor_list_status'
                || header?.field === 'patient_list_status'
                || header?.field === 'admin_list_status'
                || header?.field === 'customer_list_status' ?
                'w-[150px] text-start' : 'w-full'} 
               relative ${header?.headerName !== 'Upload'
                && header?.headerName !== 'Appointment' && header?.headerName !== 'Action'
                && header?.headerName !== 'Available Doctors' && header?.headerName !== 'Prescription'
                && header?.headerName !== 'Medical Certificate' && window.location.pathname !== '/doctor'
                && header?.headerName !== 'Consult Date' && header?.headerName !== 'Status'
                && header?.headerName !== 'Profile' && header?.headerName !== 'Consult Type'
                && header?.headerName !== 'Address' && 'cursor-pointer'}`}
                onClick={() => {
                  header?.headerName !== 'Upload' && header?.headerName !== 'Profile'
                    && header?.headerName !== 'Appointment' && header?.headerName !== 'Status'
                    && header?.headerName !== 'Action' && header?.headerName !== 'Available Doctors'
                    && header?.headerName !== 'Prescription' && header?.headerName !== 'Medical Certificate'
                    && window.location.pathname !== '/doctor' && header?.headerName !== 'Consult Date'
                    && header?.headerName !== 'Consult Type' && header?.headerName !== 'Address'
                    && handleOnClickSort(header);
                  handleSortArrow(header);
                }}>

                {header?.headerName !== 'Prescription'
                  && header?.headerName !== 'Medical Certificate'
                  && header?.headerName}

                {(header?.headerName !== 'Upload'
                  && header?.headerName !== 'Appointment' && header?.headerName !== 'Action') &&
                  header?.headerName !== 'Available Doctors' && header?.headerName !== 'Prescription'
                  && header?.headerName !== 'Medical Certificate' && window.location.pathname !== '/doctor'
                  && header?.headerName !== 'Consult Date' && header?.headerName !== 'Status'
                  && header?.headerName !== 'Profile' && header?.headerName !== 'Consult Type'
                  && header?.headerName !== 'Address' && !isPagination && <span className={`absolute p-[5px]
                  ${(sortedHeader === header?.headerName && sortType === 'asc') ?
                      'top-[3px]' : '-top-[2px]'}`}
                  >
                    {(sortedHeader === header?.headerName && sortType === 'asc') ?
                      <FontAwesomeIcon icon={faSortAsc} /> :
                      <FontAwesomeIcon icon={faSortDesc} />
                    }
                  </span>}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData?.length > 0 && tableData?.map((row, rowIndex) => (
            <tr className='cursor-pointer' key={rowIndex} onClick={() => onRowSelected && onRowSelected(row)}>
              {showCheckbox && (
                <td>
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(row.id)}
                    onChange={() => {
                      onCheckboxSelected && onCheckboxSelected(row);
                      handleRowCheckboxChange(row.id);
                    }}
                  />
                </td>
              )}
              {tableHeaders?.map((headerField, index) => (
                <td key={index} onClick={
                  () => {
                    if (headerField?.field === 'doctor_name' && location === 'doctor_list') {
                      navigate(`./view-schedule/${row['doctor_id']}`);
                    } else if (location === 'kiosk_list' && isAdmin) {
                      navigate(`../kiosk_view/${row['id']}`);
                    } else if (location === 'paramedic_associate_list') {
                      handleSelect(row);
                    } else if (location === 'doctor_list') {
                      navigate(`../view-schedule/${row['doctor_id']}`);
                    }
                  }
                }>
                  {
                    headerField?.field === 'first_name' && location === 'patient_list' ?
                      <div>
                        <div>{row['first_name']} {row['last_name']}</div>
                        <div>{row['phone_number']}</div>
                      </div> :
                      <>{row[headerField?.field]}</>
                  }
                </td>
              ))}
            </tr>
          ))}
          {(tableData?.length === 0 || typeof (tableData?.length) === 'undefined') &&
            <tr>
              <td colSpan='7'>No records found</td>
            </tr>}
        </tbody>
      </table>
      {
        (!isPagination && totalPages > 0) &&
        <div className='flex gap-[10px] justify-end items-center mt-[20px] mb-[20px]'>
          <div className={`py-[10px] px-[15px] border-[1px] border-[#999] rounded-[10px]
          ${parseInt(currentPageNumber) === 1 ? 'opacity-[0.5] pointer-events-none' : 'cursor-pointer'}`}
            onClick={() => handlePreviousPage()}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <p className='text-[16px]'>

            {totalPages && <span>{`${currentPageNumber} / ${totalPages}`}</span>}
          </p>
          <div className={`py-[10px] px-[15px] border-[1px] border-[#999] rounded-[10px]
          ${isMoreData ? 'cursor-pointer' : 'pointer-events-none opacity-[0.5]'}`}
            onClick={() => handleNextPage()}>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      }
    </div >
  );
};

export default SharedTable;
