import React from 'react';

const Box = (props) => {
  let { name, value, color } = props;
  return (
    <div className={`relative ${color} shadow rounded-[20px] col-span-3 h-[80%]`}>
      <div className='grid grid-cols-12 gap-[10px] text-white items-center mt-[5px]'>
        <div className='col-span-6'>
          <p className='text-[45px] text-center fw-500'>{value}</p>
        </div>
        <div className='col-span-6'>
          <p className='items-end text-[15px] fw-500'>{name}</p>
        </div>
      </div>
    </div>
  );
};

export default Box;
