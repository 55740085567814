import React, { useState, useEffect } from 'react';
import PatientDetailBox from '../common/PatientDetailBox';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartSimple } from '@fortawesome/free-solid-svg-icons';
import { useGetPatientVitalHistoryInConsultMutation } from '../../../../reducers/allReducer';
import { apiConstants } from '../../../../constants/api';
import VitalGraphModal from './VitalGraphModal';
import Pacs from './Pacs';
import EcgModal from '../common/CommonPrescriptionView/EcgModal';
import Stethoscope from './Stethoscope';
import { Colorcode } from '../../../common/utils/Colorcode';

const Vitals = ({
  appointmentId,
  handleGetPatientVitals,
  handleGetEcgCoordinates
}) => {
  const customerColor = Colorcode();
  const vitalsData = useSelector((state) => state?.selectedDropdownValues?.vitals);
  const coordinates = useSelector((state) => state?.selectedDropdownValues?.ecgCoordinates);
  const [vitalGraphData, setVitalGraphData] = useState();
  const [isVitalGraphModalOpen, setIsVitalGraphModalOpen] = useState(false);
  const [showEcgModal, setShowEcgModal] = useState(false);
  const [isLiveStethoscope, setIsLiveStethoscope] = useState(false);
  const [getPatientVitalHistoryInConsult] = useGetPatientVitalHistoryInConsultMutation();

  const handleOnClickVitalGraph = (data) => {
    getPatientVitalHistoryInConsult({
      endpoint: apiConstants?.getPatientVitalHistoryInConsult,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        client_id: data?.client_id,
        device_id: data?.device_id
      }
    }).then((result) => {
      setVitalGraphData(result?.data?.history);
      setIsVitalGraphModalOpen(true);
    });
  };

  useEffect(() => {
    if (!showEcgModal) {
      const intervalId = setInterval(() => {
        handleGetPatientVitals();
        handleGetEcgCoordinates();
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [showEcgModal]);

  const CommonTable = ({ tableData }) => {
    return (
      <table className='prescriptionTable w-full h-full'>
        <thead>
          <tr className='bg-[#f2f2f2]'>
            <th className='text-[12px] p-[10px] text-[#555] text-start'>Device Name</th>
            <th className='text-[12px] p-[10px] text-[#555] text-start'>Value</th>
            <th></th>
          </tr>
        </thead>
        <tbody className='text-[12px] text-[#333]'>
          {tableData?.map((data, index) => (
            <tr key={index}>
              <td className='capitalize'>{data?.name}</td>
              <td>
                {data?.value}
              </td>
              {data?.device_id !== 10 && data?.history_count > 0 && (
                <td className='cursor-pointer' onClick={() => handleOnClickVitalGraph(data)}>
                  <FontAwesomeIcon icon={faChartSimple} />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      {isVitalGraphModalOpen && (
        <VitalGraphModal data={vitalGraphData} handleModalClose={() => setIsVitalGraphModalOpen(false)} />
      )}
      {showEcgModal && (
        <EcgModal data={coordinates} handleModalClose={() => setShowEcgModal(false)} />
      )}
      <div className='flex flex-col gap-[20px]'>
        <div className='grid grid-cols-12'>
          <div className='col-span-6 gap-[10px]'>
            <div className='pl-[5px] float-left'>
              <button
                className='text-white px-[15px] py-[5px] rounded-[50px] text-[13px] text-nowrap'
                style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                onClick={() => setIsLiveStethoscope(true)}
              >
                Live Stethoscope
              </button>
            </div>
            {coordinates && coordinates?.length > 0 && (
              <div className='pl-[5px] float-left'>
                <button
                  className='text-white px-[15px] py-[5px] rounded-[50px] text-[13px] text-nowrap'
                  style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                  onClick={() => setShowEcgModal(true)}
                >
                  ECG Graph
                </button>
              </div>
            )}
          </div>
          <div className='col-span-6'>
            {isLiveStethoscope && (
              <Stethoscope appointmentId={appointmentId} handleShowStethoscope={() => setIsLiveStethoscope(false)} />
            )}
          </div>
        </div>
        <div className='grid grid-cols-12 gap-[30px]'>
          <div className='col-span-6'>
            <PatientDetailBox title='Vitals'>
              {vitalsData?.vitals?.length > 0 ? (
                <CommonTable tableData={vitalsData?.vitals} />
              ) : (
                <div className='p-[20px]'>
                  <p className='text-[#777]'>No Data Sent</p>
                </div>
              )}
            </PatientDetailBox>
          </div>
          <div className='col-span-6'>
            <PatientDetailBox title='PACS'>
              {vitalsData?.pacs?.length > 0 ? (
                <Pacs vitalsData={vitalsData} />
              ) : (
                <div className='p-[20px]'>
                  <p className='text-[#777]'>No Data Sent</p>
                </div>
              )}
            </PatientDetailBox>
          </div>
        </div>
      </div>
    </>
  );
};

export default Vitals;
