import React from 'react';

const PrescriptionGeneratedModal = ({
  handleModalClose
}) => {
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[20px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-md"
          >
            <div className="bg-white p-[20px]">
              <div className='flex flex-col gap-[20px] justify-center items-center'>
                <p>
                  The prescription is successfully generated
                </p>
                <div className='flex gap-[10px]'>
                  <button 
                    type='button'
                    className='bg-[#383678] text-white px-[20px] py-[5px] rounded-[20px]' 
                    onClick={() => handleModalClose()}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionGeneratedModal;