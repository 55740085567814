import React, { useEffect, useState } from 'react';
import SharedButton from '../../common/SharedButton';
// import AgGridTable from '../../AgGridTablecommon/AgGridTable';
import SharedTable from '../../common/SharedTable';
import { useGetAgentVerifySlotsMutation, useGetDoctorSlotsVerifiedMutation } from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import moment from 'moment';

const VerifySlots = () => {

  const [verifyAgentSlots] = useGetAgentVerifySlotsMutation();
  const [verifyDoctorSlots] = useGetDoctorSlotsVerifiedMutation();
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();

  const tableHeaders = [
    { field: 'id', headerName: 'ID' },
    { field: 'doctor_name', headerName: 'Name' },
    { field: 'phone', headerName: 'Phone' }
  ];

  const fetchTableData = (page) => {
    verifyAgentSlots({
      endpoint: `${apiConstants.verifyAgentSlots}/${page}/all`,
      method: 'POST'
    }).then((result) => {
      let verifySlotsData = result?.data?.list;
      const modifiedData = verifySlotsData.map(item => ({
        ...item,
        start_time: moment(item.start_time, 'hh:mm a').format('hh:mm a'),
        end_time: moment(item.end_time, 'hh:mm a').format('hh:mm a'),
        is_request_for_delete: item.is_request_for_delete === 1 ? 'Yes' : 'No'
      }));
      setTableData(modifiedData);
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
    }).catch((error) => {
      console.error('error', error);
    });
  };

  useEffect(() => {
    fetchTableData(1);
  }, []);

  const handleOnPageChange = (pageNumber) => {
    fetchTableData(pageNumber);
  };

  const verify = () => {
    if (selectedRows.length > 0) {
      verifyDoctorSlots({
        endpoint: apiConstants.verifyDoctorSlots,
        method: 'PUT',
        data: {
          slot_ids: selectedRows
        }
      }).then(() => {
        setSelectedRows([]);
        fetchTableData(1);
      }).catch((error) => {
        console.error('error', error);
      });
    }
  };

  const addSelection = (id) => {
    const index = selectedRows.findIndex(row => row.id === id);
    if (index !== -1) {
      const newSelectedRows = [...selectedRows];
      newSelectedRows.splice(index, 1);
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows(prevSelectedRows => [...prevSelectedRows, { id }]);
    }
  };

  const onRowSelected = (event) => {
    const selectedId = event.data.id;
    addSelection(selectedId);
  };

  return (
    <div className="row mt-16">
      <div className="mx-40">
        <h4 className="text-2xl font-semibold border-b-4 border-[#383678] 
                text-[Coolvetica Rg] w-[145px]">
          Verify Slots
        </h4>
        <SharedButton className="ml-0 rounded-[20px] float-right bg-[#383678] text-white px-[50px] py-[5px] 
                    text-sm mt-1.5 mx-auto flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
          title="Verify" onClick={verify} />
      </div>
      <div className="mx-40 mt-14">
        <SharedTable
          tableData={tableData}
          tableHeaders={tableHeaders}
          handleOnPageChange={handleOnPageChange}
          isMoreData={isMoreData}
          totalPages={totalPages}
          rowSelection='multiple'
          onRowSelected={onRowSelected}
          showCheckbox
        />
      </div>
    </div>
  );
};

export default VerifySlots;