/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useGetdashboardanalyticsMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClose } from '@fortawesome/free-solid-svg-icons';
import AnalyticsModal from './AnalyticsModal';
import { useNavigate } from 'react-router-dom';

function DashboardAnalytics() {
  const navigate = useNavigate();

  const [getdashboardanalytics] = useGetdashboardanalyticsMutation();
  const [analyticsData, setAnalyticsData] = useState({});
  const [show_consults_by_kiosk, setShowConsultsByKiosk] = useState(false);
  const [show_consults_by_doctor, setShowConsultsByDoctor] = useState(false);
  const [show_average_consult_time_by_date, setShowAverageConsultTimeByDate] = useState(false);
  const [show_average_consult_time_by_doctor, setShowAverageConsultTimeByDoctor] = useState(false);
  const [show_consults_by_date, setShowConsultsByDate] = useState(false);
  const [consults_by_kiosk, setConsultsByKiosk] = useState({});
  const [consults_by_doctor, setConsultsByDoctor] = useState({});
  const [average_consult_time_by_date, setAverageConsultTimeByDate] = useState({});
  const [average_consult_time_by_doctor, setAverageConsultTimeByDoctor] = useState({});
  const [consults_by_date, setConsultsByDate] = useState({
    'labels': [],
    'datasets': []
  });
  const [filterPopup, setfilterPopup] = useState(false);
  const [filterApplied, setfilterApplied] = useState(false);
  const [objectData, setObjectData] = useState({
    start_date: '',
    end_date: ''
  });

  const getAnalytics = () => {
    getdashboardanalytics({
      endpoint: apiConstants.getdashboardanalytics,
      method: 'POST',
      data: {
        end_date: objectData?.end_date ? moment(objectData?.end_date).format('YYYY-MM-DD') : '',
        start_date: objectData?.start_date ? moment(objectData?.start_date).format('YYYY-MM-DD') : ''
      }
    }).then((result) => {
      setAnalyticsData(result?.data);

      if (result?.data) {
        if (result?.data.consults_by_kiosk.length > 0) {
          setShowConsultsByKiosk(true);
          let consultsByKiosk = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [],
              borderColor: [],
              borderWidth: 1
            }]
          };
          for (let i = 0; i < result?.data.consults_by_kiosk.length; i++) {
            consultsByKiosk.labels.push(result?.data.consults_by_kiosk[i].kiosk);
            consultsByKiosk.datasets[0].data.push(result?.data.consults_by_kiosk[i].doctor_consults);
            let color = Math.floor(Math.random() * 16777215).toString(16);
            consultsByKiosk.datasets[0].borderColor.push('#' + color);
            consultsByKiosk.datasets[0].backgroundColor.push('#' + color);
          }
          setConsultsByKiosk(consultsByKiosk);
        } else {
          setConsultsByKiosk({});
          setShowConsultsByKiosk(false);
        }

        if (result?.data.consults_by_doctor.length > 0) {
          setShowConsultsByDoctor(true);
          let consultsByDoctor = {
            labels: [],
            datasets: [{
              data: [],
              backgroundColor: [],
              borderColor: [],
              borderWidth: 1
            }]
          };
          for (let i = 0; i < result?.data.consults_by_doctor.length; i++) {
            consultsByDoctor.labels.push(result?.data.consults_by_doctor[i].doctor);
            consultsByDoctor.datasets[0].data.push(result?.data.consults_by_doctor[i].doctor_consults);
            let color = Math.floor(Math.random() * 16777215).toString(16);
            consultsByDoctor.datasets[0].borderColor.push('#' + color);
            consultsByDoctor.datasets[0].backgroundColor.push('#' + color);
          }
          setConsultsByDoctor(consultsByDoctor);
        } else {
          setConsultsByDoctor({});
          setShowConsultsByDoctor(false);
        }

        if (result?.data.average_consult_time_by_date.length > 0) {
          setShowAverageConsultTimeByDate(true);
          let averageConsultTimeByDate = {
            labels: [],
            datasets: [{
              label: 'Average Duration',
              fill: true,
              lineTension: 0.1,
              backgroundColor: 'rgba(255, 159, 64)',
              borderColor: 'rgba(255, 159, 64)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(255, 159, 64)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgb(51, 207, 162)',
              pointHoverBorderColor: 'rgb(51, 207, 162)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: []
            }]
          };
          for (let i = 0; i < result?.data.average_consult_time_by_date.length; i++) {
            averageConsultTimeByDate.labels.push(moment(result?.data.average_consult_time_by_date[i].appointment_date).format('YYYY-MM-DD'));
            averageConsultTimeByDate.datasets[0].data.push(result?.data.average_consult_time_by_date[i].average_consult_time);
          }
          setAverageConsultTimeByDate(averageConsultTimeByDate);
        } else {
          setAverageConsultTimeByDate({});
          setShowAverageConsultTimeByDate(false);
        }

        if (result?.data.average_consult_time_by_doctor.length > 0) {
          setShowAverageConsultTimeByDoctor(true);
          let averageConsultTimeByDoctor = {
            labels: [],
            datasets: [{
              label: 'Average Duration',
              data: [],
              backgroundColor: 'rgba(255, 99, 132, 1)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1
            }]
          };
          for (let i = 0; i < result?.data.average_consult_time_by_doctor.length; i++) {
            averageConsultTimeByDoctor.labels.push(result?.data.average_consult_time_by_doctor[i].doctor);
            averageConsultTimeByDoctor.datasets[0].data.push(result?.data.average_consult_time_by_doctor[i].average_consult_time);
          }
          setAverageConsultTimeByDoctor(averageConsultTimeByDoctor);
        } else {
          setAverageConsultTimeByDoctor({});
          setShowAverageConsultTimeByDoctor(false);
        }

        if (result?.data.consults_by_date.length > 0) {
          setShowConsultsByDate(true);
          let consultsByDate = {
            labels: [],
            datasets: [{
              label: 'Success',
              data: [],
              backgroundColor: 'rgb(51, 207, 162)'
            },
            {
              label: 'Pending',
              data: [],
              backgroundColor: 'rgba(255, 159, 64)'
            }]
          };

          for (let i = 0; i < result?.data.consults_by_date.length; i++) {
            consultsByDate.labels.push(moment(result?.data.consults_by_date[i].appointment_date).format('YYYY-MM-DD'));
            consultsByDate.datasets[0].data.push(result?.data.consults_by_date[i].successful_consults);
            consultsByDate.datasets[1].data.push(result?.data.consults_by_date[i].pending_consults);
          }

          setConsultsByDate(consultsByDate);
        } else {
          setConsultsByDate({});
          setShowConsultsByDate(false);
        }
      }
    })
      .catch((error) => {
        console.error('error', error);
      });
  };

  useEffect(() => {
    getAnalytics();
  }, []);

  const handleClear = () => {
    setfilterApplied(false);
    setObjectData({
      start_date: '',
      end_date: ''
    });
    getAnalytics();
  };

  useEffect(() => {
    getAnalytics();
  }, [filterApplied]);

  const options = {
    scales: {
      xAxes: [{
        stacked: true
      }],
      yAxes: [{
        stacked: true
      }]
    }
  };

  return (
    <div className="grid grid-cols-12 gap-4" style={{ background: '#fff', margin: '1% 2% 1% 2%' }}>
      {filterPopup &&
        <AnalyticsModal
          setfilterPopup={setfilterPopup}
          setObjectData={setObjectData}
          objectData={objectData}
          getAnalytics={getAnalytics}
          setfilterApplied={setfilterApplied}
        />}
      <div className="col-span-12 flex flex-col items-end">
        <div className='flex items-center gap-[50px]'>
          <h6 className="data-filter-selected">
            {filterApplied ? `Data is shown for dates from ${moment(objectData.start_date).format('Do MMM YYYY')} to 
          ${moment(objectData.end_date).format('Do MMM YYYY')}` : 'Data is shown for last 30 days'}</h6>
          <div className="graph-tabular-btn flex gap-2 w-[155px] h-[35px] items-center cursor-pointer"
            onClick={() => setfilterPopup(true)}
          >
            <div>Filter by Date</div>
            <FontAwesomeIcon icon={faCalendarAlt}
              className='text-[#fff] right-[40px] top-[25px] text-[20px] cursor-pointer'
            />
          </div>
        </div>
        {filterApplied && <div className="flex gap-2 w-[155px] h-[35px] items-center"
          onClick={() => handleClear()}
        >
          <div>Clear Filter</div>
          <FontAwesomeIcon icon={faClose}
            className='text-[#000] right-[40px] top-[25px] text-[20px] cursor-pointer'
          />
        </div>}
      </div>

      <div className="col-span-3">
        <div className="count-cont">
          <h1 className="text-center text-[36px] mt-[20px] mb-[10px]">
            {analyticsData?.average_consult_time ? analyticsData?.average_consult_time : 0}
          </h1>
          <h6 className="text-center text-[12px]">Average Consult Time (Mins)</h6>
        </div>
        <div className="count-cont">
          <h1 className="text-center text-[36px] mt-[20px] mb-[10px]">
            {analyticsData?.total_consults ? analyticsData?.total_consults : 0}
          </h1>
          <h6 className="text-center text-[12px]">Total Consults</h6>
        </div>
      </div>

      <div className="col-span-9">
        <div className="graph-cont">
          <div className='flex justify-between'>
            <h6 className="graph-heading text-[12px]">Total Consults - Time series
              {show_consults_by_date}
            </h6>
            <div className="graph-tabular-btn cursor-pointer"
              onClick={() => navigate('/admin/analytics-tabular/consults_by_date')}
            >Tabular View</div>
          </div>
          {show_consults_by_date &&
            <Bar
              data={consults_by_date}
              width={100}
              height={50}
              options={options}
            />
          }
          {!show_consults_by_date && <h6 className="no-record-found text-[12px]">No Records Found.</h6>}
        </div>
      </div>

      <div className="col-span-6">
        <div className="graph-cont graph-cont-mt graph-cont-mr">
          <div className='flex justify-between'>
            <h6 className="graph-heading text-[12px]">Average Consult Time</h6>
            <div className="graph-tabular-btn cursor-pointer"
              onClick={() => navigate('/admin/analytics-tabular/average_consult_time_by_date')}
            >Tabular View</div>
          </div>
          {show_average_consult_time_by_date && <Line data={average_consult_time_by_date} />}
          {!show_average_consult_time_by_date && <h6 className="no-record-found text-[12px]">No Records Found.</h6>}
        </div>
      </div>

      <div className="col-span-6">
        <div className="graph-cont graph-cont-mt graph-cont-ml">
          <div className='flex justify-between'>
            <h6 className="graph-heading text-[12px]">Average Consult time per Doctor</h6>
            <div className="graph-tabular-btn cursor-pointer"
              onClick={() => navigate('/admin/analytics-tabular/average_consult_time_by_doctor')}
            >Tabular View</div>
          </div>
          {show_average_consult_time_by_doctor && <Bar
            data={average_consult_time_by_doctor}
            width={100}
            height={50}
          />}
          {!show_average_consult_time_by_doctor && <h6 className="no-record-found text-[12px]">No Records Found.</h6>}
        </div>
      </div>

      <div className="col-span-6">
        <div className="graph-cont graph-cont-mt graph-cont-mr graph-cont-mb">
          <div className='flex justify-between'>
            <h6 className="graph-heading text-[12px]">Doctor Share in Total Consults</h6>
            <div className="graph-tabular-btn cursor-pointer"
              onClick={() => navigate('/admin/analytics-tabular/consults_by_doctor')}
            >Tabular View</div>
          </div>
          {show_consults_by_doctor && <Doughnut data={consults_by_doctor} />}
          {!show_consults_by_doctor && <h6 className="no-record-found text-[12px]">No Records Found.</h6>}
        </div>
      </div>
      <div className="col-span-6">
        <div className="graph-cont graph-cont-mt graph-cont-mr graph-cont-mb">
          <div className='flex justify-between'>
            <h6 className="graph-heading text-[12px]">Consults per Kiosk</h6>
            <div className="graph-tabular-btn cursor-pointer"
              onClick={() => navigate('/admin/analytics-tabular/consults_by_kiosk')}
            >Tabular View</div>
          </div>
          {show_consults_by_kiosk && <Doughnut data={consults_by_kiosk} />}
          {!show_consults_by_kiosk && <h6 className="no-record-found text-[12px]">No Records Found.</h6>}
        </div>
      </div>

    </div>

  );
}

export default DashboardAnalytics;