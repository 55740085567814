
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sakha_Logo from '../../images/Sakha-logo.png';
// import InputElement from '../common/InputElement';
import { useGetOtpMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import SharedButton from '../common/SharedButton';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactSelectFilters from '../common/ReactSelectFilters';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [validPhone, setValidPhone] = useState(0);
    const [error, setError] = useState(false);
    const [getOtp] = useGetOtpMutation();
    const [customersList, setCustomersList] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    // 8686896868

    const submitOtp = () => {
        // check API and get details
        getOtp({
            endpoint: `${apiConstants.getOtpDeatils}`,
            method: 'POST',
            data: {
                'customer_id': selectedCustomers ? selectedCustomers?.value : null,
                'phone_number': `+${validPhone}`,
                'fromResendOtp': false
            }
        }).then((result) => {
            if (result?.data?.success) {
                navigate('/forgotpassword/otp', { state: { phone: `+${validPhone}` } });
            } else if (result?.data?.input_required) {
                let tempArray = [];
                result?.data?.customers?.map((resultItem) => tempArray.push({
                    label: resultItem.customer_name,
                    value: resultItem.customer_id
                }));
                setCustomersList(tempArray);
            } else {
                setError(true);
            }
        });
    };
    const checkInput = (value) => {
        setValidPhone(value);
    };

    return (
        <div className='bg-cover bg-center bg-no-repeat mt-[7%]'>
            <div className="block mx-auto col-span-1/3" style={{ marginLeft: '33.33%' }}>
                <div className='login-parent-div w-[38%] row 
                bg-[#fefefe] items-center justify-center fixed top-1/2 left-1/2
        transform -translate-x-1/2 -translate-y-1/2 rounded-md p-[20px] shadow-lg hover:shadow-xl'>
                    <img onClick={() => { navigate('/'); }}
                        className='block mx-auto p-5 w-2/5 mt-5' src={Sakha_Logo} alt="Axon Logo" />
                    <div className='w-[90%] col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12'>
                        <div className='grid justify-center'>
                            <h1 className='text-3xl grid justify-center'>Forgot Your Password?</h1>
                            <p className='text-xl grid justify-center mt-4'>Enter your phone number</p>
                            <p className='text-xl grid justify-center'>and we will send you an OTP on your Email</p>
                        </div>
                        <div className="col-md-8 col-sm-8 col-xs-8 col-lg-8 mt-12"
                            style={{ marginBottom: '5%', display: 'grid' }}>
                            {/* <span className="login-span">Phone Number*</span> */}
                            <PhoneInput
                                name="phone"
                                type="tel"
                                placeholder="Phone Number"
                                country={'in'}
                                onlyCountries={['in']}
                                autoFormat={false}
                                value={''}
                                onChange={checkInput}
                            />
                        </div>
                        <div>
                            {(validPhone.length < 12 || error) && <p className='text-red-700 grid justify-center'>
                                Please Enter a Valid Phone Number</p>}
                        </div>
                        {customersList?.length > 0 &&
                            <div className='w-[100%] scheduleFilter' style={{ marginTop: '5%' }} >
                                <span className="login-span">Select Customer*</span>
                                <ReactSelectFilters
                                    customStyles={{
                                        valueContainer: provided => ({
                                            ...provided,
                                            backgroundColor: 'white',
                                            padding: '8px',
                                            height: '50px'
                                        })
                                    }}
                                    options={customersList}
                                    placeholder='Select Customer*'
                                    name='doctor'
                                    noDropDownIndicator={false}
                                    // handleDropdownInputChange={handleOnDropdownChange}
                                    onChange={(data) => {
                                        setSelectedCustomers(data);
                                    }}
                                    selectFieldValues={selectedCustomers}
                                    isMulti={false}
                                    disableEnterKey
                                />
                            </div>}

                        <div className='optBtn pt-4'>
                            <SharedButton className="bg-[#383678] text-white px-[50px] py-[5px]
                  rounded focus:outline-none text-xl mx-auto flex" onClick={submitOtp} type="submit" title="SEND OTP" />
                        </div>
                    </div >
                </div>
            </div >
        </div >
    );
};

export default ForgotPassword;
