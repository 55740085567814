import React from 'react';
import PatientDetailBox from '../PatientDetailBox';
import moment from 'moment';
import PrescriptionTable from '../../PatientDetails/Prescription/PrescriptionTable';

const DisplayPrescription = ({
  selfCare,
  followUpDate,
  drugs
}) => {
  const modifiedFollowUpDate = moment(followUpDate).format('DD MMMM YYYY');
  return (
    <div className='grid grid-cols-12 gap-[30px]'>
      <div className='col-span-12'>
        <PatientDetailBox title='Drugs Prescription'>
          <PrescriptionTable drugs={drugs} onlyDisplay />
        </PatientDetailBox>
      </div>
      <div className='col-span-6'>
        <PatientDetailBox title='Self Care'>
          <div className='p-[20px]'>
            <p className='text-sm text-[#777] fw-500'>{selfCare ? selfCare : 'No records found'}</p>
          </div>
        </PatientDetailBox>
      </div>
      <div className='col-span-6'>
        <PatientDetailBox title='Followup'>
          <div className='p-[20px]'>
            <input className='text-sm text-[#777] fw-500' type='text' value={modifiedFollowUpDate} readOnly />
          </div>
        </PatientDetailBox>
      </div>
    </div>
  );
};

export default DisplayPrescription;
