export const HeaderLinks = () => {
  const user_type = localStorage.getItem('user_type') ? localStorage.getItem('user_type') : '';
  const privileges = localStorage.getItem('privileges');
  let customerId = localStorage.getItem('customer_id') ?
    localStorage.getItem('customer_id') : '';
  let paramedic_slot_required = localStorage.getItem('paramedic_slot_required') ?
    localStorage.getItem('paramedic_slot_required') : '';

  const routeLinks = [
    {
      headerOption: 'Users',
      url: '',
      isVisible: (user_type === 'admin' && (privileges === 'admin' || privileges === 'superadmin')
        && window.location.pathname !== '/admin') ? true : false,
      sub_menu: [
        {
          subHeaderOption: 'Front Desk',
          url: '/admin/frontdesklist',
          isVisible: true
        }, {
          subHeaderOption: 'Paramedic',
          url: '/admin/paramediclist',
          isVisible: true
        }, {
          subHeaderOption: 'Doctor',
          url: '/admin/doctorlist',
          isVisible: true
        }, {
          subHeaderOption: 'Patient',
          url: '/admin/patientlist',
          isVisible: true
        }, {
          subHeaderOption: 'Admin',
          url: '/admin/adminUsers',
          isVisible: (user_type === 'admin' && privileges === 'superadmin') ? true : false
        }, {
          subHeaderOption: 'Customer',
          url: '/admin/customerList',
          isVisible: (user_type === 'admin' && privileges === 'superadmin') ? true : false
        }
      ]
    },
    {
      headerOption: 'Departments',
      url: '/admin/departmentList',
      isVisible: (user_type === 'admin' && privileges === 'admin') ? true : false
    },
    {
      headerOption: 'Upcoming Consults',
      url: user_type === 'admin' ? '/admin/upcoming-consults' : null,
      isVisible: (user_type === 'admin' && privileges === 'admin') ? true : false
    },
    {
      headerOption: 'Settings',
      url: (user_type === 'admin') ? '/admin/settings' : null,
      isVisible: (user_type === 'admin' && privileges === 'admin') ? true : false
    },
    {
      headerOption: 'Verify Slots',
      url: (user_type === 'admin') ? '/admin/verify-slots' : null,
      isVisible: (user_type === 'admin' && privileges === 'admin') ? true : false
    },
    {
      headerOption: 'Appointment',
      url: (user_type === 'admin') ? '/admin/appointmentList' : null,
      isVisible: (user_type === 'admin' && privileges === 'superadmin') ? true : false
    },
    // {
    //   headerOption: 'Privileges',
    //   url: (user_type === 'admin') ? '/admin/adminUserPrivileges' : null,
    //   isVisible: (user_type === 'admin' && privileges === 'superadmin') ? true : false
    // },
    // {
    //   headerOption: 'Roles',
    //   url: (user_type === 'admin') ? '/admin/role' : null,
    //   isVisible: (user_type === 'admin' && privileges === 'superadmin') ? true : false
    // },
    // {
    //   headerOption: 'Manage Concepts',
    //   url: (user_type === 'admin') ? '/admin/manage/concepts' : null,
    //   isVisible: (user_type === 'admin' && privileges === 'superadmin') ? true : false
    // },
    {
      headerOption: 'Kiosk',
      url: '/admin/kioskslist',
      isVisible: (user_type === 'admin' && privileges === 'superadmin' &&
        window.location.pathname !== '/admin') ? true : false
    },
    {
      headerOption: 'Customer',
      url: '/admin/customerList',
      isVisible: (user_type === 'admin' && privileges === 'superadmin') ? true : false
    },
    // {
    //   headerOption: 'More',
    //   url: '',
    //   isVisible: (user_type === 'admin' && privileges === 'superadmin') ? true : false,
    //   sub_menu: {
    //     subHeaderOption: 'Downloads',
    //     url: '/admin/downloads'
    //   }
    // },
    {
      headerOption: 'Patients',
      url: (user_type === 'agent') ? '/agent' : null,
      isVisible: user_type === 'agent' ? true : false
    },
    {
      headerOption: 'Dashboard',
      url: (user_type === 'doctor') ? '/doctor' : null,
      isVisible: (user_type === 'doctor' && (customerId !== 1 || customerId === 1)) ? true : false
    },
    {
      headerOption: 'Past Consults',
      url: (user_type === 'agent') ? '/agent/past-consults' :
        (user_type === 'doctor') ? '/doctor/pastconsults' :
          (user_type === 'patient') ? '/patient/pastconsults' : null,
      isVisible: user_type === 'patient' ||
        (user_type === 'doctor' && (customerId !== 1 || customerId === 1)) ||
        user_type === 'agent' ? true : false
    },
    {
      headerOption: 'Today\'s Consults',
      url: (user_type === 'agent') ? '/agent/todays-consults' : null,
      isVisible: user_type === 'agent' ? true : false
    },
    {
      headerOption: 'Doctors',
      url: (user_type === 'agent') ? '/agent/doctors' : null,
      isVisible: (user_type === 'agent' && customerId !== 1) ? true : false
    },
    // {   headerOption: 'Verify Patients', 
    //     url: (user_type === 'agent') ? '/agent/verifypatients' : null, 
    //     isVisible: (user_type === 'agent' && customerId !== 1 && paramedic_slot_required !== 0) ? true : false
    // },
    {
      headerOption: 'Paramedics',
      url: (user_type === 'agent') ? '/agent/paramedics' : null,
      isVisible: (user_type === 'agent' && customerId !== 1 && paramedic_slot_required === 0) ? true : false
    },
    {
      headerOption: 'Schedule Slots',
      url: (user_type === 'doctor') ? '/doctor/schedule-slot' : null,
      isVisible: (user_type === 'doctor' && customerId !== 1) ? true : false
    },
    {
      headerOption: 'Upload Documents',
      url: (user_type === 'doctor') ? '/doctor/upload-documents' : null,
      isVisible: (user_type === 'doctor' && customerId !== 1) ? true : false
    },
    {
      headerOption: 'Appointments',
      url: (user_type === 'paramedic') ? '/paramedic' : null,
      isVisible: user_type === 'paramedic' ? true : false
    }
  ];
  return routeLinks;
};