import React from 'react';
import { useNavigate } from 'react-router-dom';
import FrontDeskIcon from '../../images/icons/FrontDesk_icon.svg';
import DoctorIcon from '../../images/icons/Doctor_icon.svg';
import ParamedicIcon from '../../images/icons/Paramedic_icon.svg';
import PatientIcon from '../../images/icons/Patient_icon.svg';
import KioskIcon from '../../images/icons/Kiosk_icon.svg';
import AdminIcon from '../../images/icons/Admin_icon.svg';
import AnalyticsIcon from '../../images/icons/Analytics_icon.jpeg';

const Admin = () => {

  const privileges = localStorage.getItem('privileges');
  const navigateTo = useNavigate();

  const LandingOptions = [
    {
      landingOption: 'Front Desk',
      image: FrontDeskIcon,
      url: '/admin/frontdesklist',
      isVisible: true,wclass: 'w-[100px]'
    },
    {
      landingOption: 'Doctor',
      image: DoctorIcon,
      url: '/admin/doctorlist',
      isVisible: true,wclass: 'w-[100px]'
    },
    {
      landingOption: 'Paramedic',
      image: ParamedicIcon,
      url: '/admin/paramediclist',
      isVisible: true,wclass: 'w-[146px]'
    },
    {
      landingOption: 'Patient',
      image: PatientIcon,
      url: '/admin/patientlist',
      isVisible: true,wclass: 'w-[110px]'
    },
    {
      landingOption: 'Kiosk',
      image: KioskIcon,
      url: '/admin/kioskslist',
      isVisible: true,wclass: 'w-[100px]'
    },
    {
      landingOption: 'Admin',
      image: AdminIcon,
      url: '/admin/adminUsers',
      isVisible: privileges === 'superadmin' ? true : false,wclass: 'w-[100px]'
    },
    {
      landingOption: 'Analytics',
      image: AnalyticsIcon,
      url: '/admin/analytics',
      isVisible: privileges === 'admin' ? true : false,wclass: 'w-[100px]'
    }
  ];

  const redirectTo = (url) => {
    navigateTo(url);
  };
  return (
    <div className="w-[93%] flex flex-wrap justify-start pt-24 items-center mt-8 pl-9 font-Poppins">
      {LandingOptions.map((option, index) => (
        option.isVisible && (
          <div key={index} className="w-1/3 px-4 mb-8 flex flex-col items-center">
            <div
              className={option.wclass + ' bg-cover bg-center h-[100px] cursor-pointer mb-2'}
              onMouseOver={(e) => {
                if (option.landingOption !== 'Analytics') {
                  e.currentTarget.style.filter =
                    'invert(71%) sepia(100%) saturate(1000%) hue-rotate(358deg) brightness(119%) contrast(100%)';
                }
              }}
              onMouseOut={(e) => {
                if (option.landingOption !== 'Analytics') {
                  e.currentTarget.style.filter = 'none';
                }
              }}
              style={{ backgroundImage: `url(${option.image})` }}
              onClick={() => redirectTo(option.url)}
            ></div>
		<label className="h-[102px] w-196 text-[rgba(36,36,36,.77)] text-xl leading-[55px]
            text-center inline-block max-w-full mb-5 font-medium">
              {option.landingOption}
            </label>
          </div>
        )
      ))}
    </div>
  );
};

export default Admin;
