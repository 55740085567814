import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';
import Loader from '../common/Loader';
import {
  useGetKioskListByIdMutation,
  useUpdateKioskMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import ConfirmModal from '../common/ConfirmModal';
import Toggle from 'react-toggle';
const Schema = Yup.object().shape({
  name: Yup.string().required('Please Enter Kiosk Name'),
  code: Yup.string().max(10).required('Please Enter Kiosk Code'),
  address: Yup.string().required('Please Enter Address')
});

const EditKiosk = () => {
  const [updateKiosk] = useUpdateKioskMutation();
  const [getList] = useGetKioskListByIdMutation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [isToggle, setIsToggle] = useState(false);
  const fetchTableData = () => {
    setIsLoading(true);
    getList({
      endpoint: `${apiConstants.getKioskListById}/${id}`,
      method: 'GET'
    }).then((result) => {
      if (result?.error) {
        setShowModal(true);
        setMessage(result?.error?.data?.error);
        setError(true);
      } else {
        setResponse(result?.data);
      }
    }).catch(() => {
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  useEffect(() => {
    let timeoutId;
    if (showModal) {
      timeoutId = setTimeout(() => {
        setShowModal(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showModal]);

  const toggleBar = () => {
    setIsToggle(!isToggle);
  };

  const handleSubmit = (values) => {
    updateKiosk({
      endpoint: `${apiConstants.updateKiosk}`,
      method: 'PUT',
      data: {
        data: {
          id: response?.kiosk?.id,
          name: values.name,
          code: values.code,
          address: values.address,
          status: isToggle ? 2 : 1
        }
      }
    }).then((data) => {
      setShowModal(true);
      if (data?.error) {
        setMessage(data?.error?.data?.error);
        setError(true);
      } else {
        setError(false);
        fetchTableData();
        setMessage('Updated Successfully');
      }
    }).catch(() => {
    });
  };

  useEffect(() => {
    if (response) {
      setIsToggle(response?.kiosk?.status === 2 ? true : false);
    }
  }, [response]);

  return (
    <>
      {showModal &&
        <ConfirmModal msg={message} isError={isError} />
      }
      {isLoading ?
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div> :
        <div className='mt-24 mx-[5%]' >
          <button
            type='text'
            className="bg-[#383678] text-white w-[150px] h-[40px] items-center mb-2
                                        rounded-[20px] focus:outline-none text-[16px] flex justify-center mb-8"
            style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
            onClick={() => navigate('/admin/kioskslist')}
          >
            Back
          </button>
          <Formik
            initialValues={{
              name: response?.kiosk?.name,
              code: response?.kiosk?.code,
              address: response?.kiosk?.address,
              status: response?.kiosk?.status === 1 ? false : true
            }}
            validationSchema={Schema}
            onSubmit={handleSubmit}
          >
            {({ values }) => {
              return <Form className='bg-[#f4f4f4] rounded-[12px] p-[20px] mb-4'>
                <div className='flex items-center justify-end mb-2'>

                  {isEditing &&
                    <div className='flex items-center detailView'>
                      <div>Disable Kiosk</div>
                      <div className='mx-4'>
                        <Toggle
                          className='status-tog'
                          checked={isToggle}
                          onChange={() => toggleBar()}
                          icons={{
                            checked: <span style={{ fontFamily: 'Poppins' }}></span>,
                            unchecked: <span style={{ fontFamily: 'Poppins' }}></span>
                          }}
                        />
                      </div>
                      <div className='flex justify-end'>
                        <button
                          type='submit'
                          className="bg-[#383678] text-white w-[150px] h-[40px] items-center my-2
                                   rounded-[20px] focus:outline-none text-[16px] flex justify-center"
                          style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  }

                  {isEditing ? '' : <FontAwesomeIcon icon={faPenToSquare}
                    className='text-[#383678] cursor-pointer text-[30px]'
                    onClick={() => setIsEditing(true)}
                  />}
                </div>
                <div className='grid grid-cols-12'>
                  <div className='col-span-6 border-r border-black p-4'>
                    <div className='text-center p-6'>
                      <div>
                        <div className='text-[50px] text-[#727474] font-[600]'>
                          {isEditing ?
                            <><Field
                              name="name"
                              type="text"
                              placeholder="Kiosk Name*"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4] text-[black]
                     outline-none w-[415px] py-2"
                              value={values?.name}
                            />
                              <ErrorMessage
                                name="name" component="div" className='text-[red] text-[12px]' /></>
                            :
                            <>{response?.kiosk?.name}</>}
                        </div>
                        <div className='flex justify-center mt-4'>
                          <div className='text-[20px] text-[#727474] border-r-[2px] border-[#727474] pr-2'>
                            {response?.kiosk?.code}
                          </div>
                          <div className='text-[20px] text-[#727474] flex items-center pl-2'>
                            <div className={`w-[10px] h-[10px] 
                            ${response?.kiosk?.status === 1 ? 'bg-[#01C890]' : 'bg-[red]'}  mx-2 rounded-[50%]`}></div>
                            <div>{response?.kiosk?.status === 1 ? 'Active' : 'Disabled'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center justify-center mt-10 '>
                        <div className='flex-[1] flex justify-end'>
                          <div className='w-[60px] h-[60px] rounded-[50%]
                           bg-[#d8d8d8] flex justify-center items-center'>
                            <FontAwesomeIcon icon={faMapLocationDot}
                              className='text-[#383678] cursor-pointer text-[30px]'
                            />
                          </div>
                        </div>
                        <div className='ml-2 flex-[2] text-[20px] text-[#727474] text-left'>
                          {isEditing ?
                            <>
                              <Field
                                name="address"
                                type="text"
                                placeholder="Address*"
                                autoComplete="off"
                                className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4] text-[black]
                     outline-none py-2 w-[256px]"
                                value={values?.address}
                              />
                              <ErrorMessage
                                name="address" component="div" className='text-[red] text-[12px]' />
                            </>
                            :
                            <>{response?.kiosk?.address}</>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-span-6 p-4'>
                    <div>
                      <div className='text-[18px] font-[700] text-[#727474]'>Front Desk</div>
                      <div className="h-[150px] overflow-y-scroll bg-[white]">
                        {response?.FrontDesk?.length > 0 && response?.FrontDesk?.map((data, index) => (
                          <div key={index} className='flex border-b p-2'>
                            <div className='mr-4 text-[14px] text-[#727474]'>#{data?.agent_id}FD</div>
                            <div className='text-[14px] text-[#727474]'>{data?.first_name} {data?.last_name}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='flex justify-end'>
                      <button
                        disabled={isEditing}
                        type='button'
                        className={`${isEditing ? 'bg-[#d8d8d8] cursor-not-allowed' : 'bg-[#383678]'} 
                         text-white w-[150px] h-[40px] items-center my-2
                                   rounded-[20px] focus:outline-none text-[16px] flex justify-center`}
                        style={isEditing ? {} : { background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                        onClick={() => navigate(`../associate_list/frontdesk/${id}`)}
                      >
                        Associate
                      </button>
                    </div>
                    <div className='mt-4'>
                      <div className='text-[18px] font-[700] text-[#727474]'>Paramedic</div>
                      <div className="h-[150px] overflow-y-scroll bg-[white]">
                        {response?.Paramedic?.length > 0 && response?.Paramedic?.map((data, index) => (
                          <div key={index} className='flex border-b p-2'>
                            <div className='mr-4 text-[14px] text-[#727474]'>#{data?.paramedic_id}PD</div>
                            <div className='text-[14px] text-[#727474]'>{data?.name}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className='flex justify-end'>
                      <button
                        disabled={isEditing}
                        type='button'
                        className={`${isEditing ? 'bg-[#d8d8d8] cursor-not-allowed' : 'bg-[#383678]'} 
                         text-white w-[150px] h-[40px] items-center my-2
                                   rounded-[20px] focus:outline-none text-[16px] flex justify-center`}
                        style={isEditing ? {} : { background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                        onClick={() => navigate(`../associate_list/paramedic/${id}`)}
                      >
                        Associate
                      </button>
                    </div>
                  </div>
                </div>
              </Form>;
            }}
          </Formik>
        </div >}
    </>
  );
};

export default EditKiosk;