import React, { useEffect, useState } from 'react';
import {
  useUploadPhotoMutation
} from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';

const UploadPhotoModal = ({
  setIsUploadPhoto,
  isUploadPhoto,
  setImgUrl
}) => {
  const [uploadPhoto] = useUploadPhotoMutation();
  const [localTrack, setLocalTrack] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);

  useEffect(() => {
    if (isUploadPhoto) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: { width: 180, height: 180 } }).then(stream => {
          setLocalTrack(stream);
          var video = document.querySelector('video');

          try {
            video.srcObject = stream;
          } catch (error) {
            video.src = window.URL.createObjectURL(stream);
          }
          video.addEventListener('loadedmetadata', () => {
            video.play();
          });
        });
      }
    }
  }, []);

  const onClose = () => {
    localTrack.getTracks().forEach(function (track) {
      track.stop();
    });
    setIsUploadPhoto(false);
  };

  const screenCapture = (id) => {
    let video = document.getElementById(id);
    var w = 1280 * 1;
    var h = 720 * 1;
    var canvas = document.createElement('canvas');
    canvas.width = w;
    canvas.height = h;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, w, h);
    let url = canvas.toDataURL('image/png');
    setCapturedImage(url);
    localTrack.getTracks().forEach(function (track) {
      track.stop();
    });
  };

  const dataURItoBlob = (dataURI) => {
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else
      byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const Retake = () => {
    setCapturedImage(null);
    navigator.mediaDevices.getUserMedia({ video: { width: 180, height: 180 } }).then(stream => {
      setLocalTrack(stream);
      var video = document.querySelector('video');

      try {
        video.srcObject = stream;
      } catch (error) {
        video.src = window.URL.createObjectURL(stream);
      }
      video.play();
    });
  };

  const uploadToS3 = () => {
    let sendFile = dataURItoBlob(capturedImage);
    const formData = new FormData();
    let file = new File([sendFile], 'profile_pic.png');
    formData.append('image', file, file.name);
    uploadPhoto({
      endpoint: apiConstants.uploadPhoto,
      method: 'POST',
      data: formData || null
    }).then((result) => {
      setImgUrl(result?.data);
    }).catch((error) => {
      console.error('error', error);
    });
    setCapturedImage(null);
    setIsUploadPhoto(false);
  };

  return (
    <div className="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl transition-all 
          sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl">
            <div className="bg-white">
              <div className="w-full">
                <div className='p-[20px] border-b-[1px] border-b-[#808080] flex justify-center'>
                  <span className='text-[#757776] text-[24px] '>
                    {!capturedImage && <div className="modal-title">Take Photo</div>}
                    {capturedImage && <div className="modal-title">Preview</div>} </span>
                </div>
              </div>
              <div className="w-full pt-[10px] pb-[30px] px-[40px] text-[#757776]">
                {!capturedImage && <video style={{ height: '55vh' }} id="video"></video>}
                {capturedImage &&
                  <img style={{ width: 'auto', height: '55vh', display: 'block', margin: '0px auto 16px auto' }}
                    src={capturedImage} />}
                <div className='flex gap-[80px]'>
                  <button
                    type='submit'
                    className="bg-[#383678] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                          rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                    style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                    onClick={capturedImage ? Retake : () => screenCapture('video')}
                  >
                    {capturedImage ? 'Retake' : 'Capture'}
                  </button>
                  <button
                    type='text'
                    className="bg-[#808080] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                          rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                    onClick={capturedImage ? uploadToS3 : onClose}
                  >
                    {capturedImage ? 'Upload' : 'Cancel'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadPhotoModal;
