import React, { useEffect, useRef, useState } from 'react';
import DoctorIcon from '../../images/Doctoricon.svg';
import SignatureCanvas from 'react-signature-canvas';
import { useLazyGetDoctorDocumentsQuery, useUploadDoctorDocumentMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { Colorcode } from '../common/utils/Colorcode';

const customerColor = Colorcode();
const UploadDocuments = () => {
  const sigRef = useRef(null);
  const fileInputRef = useRef(null);
  const [getDoctorDocuments] = useLazyGetDoctorDocumentsQuery();
  const [uploadDoctorDocument] = useUploadDoctorDocumentMutation();
  const userId = localStorage.getItem('user_id') || '';
  const [doctorDocuments, setDoctorDocuments] = useState();
  const [uploadedFileName, setUploadedFileName] = useState();
  const [uploadedSignName, setUploadedSignName] = useState();
  const [isError, setIsError] = useState(false);

  const handleCanvasClear = () => {
    if (sigRef.current) {
      sigRef.current.clear();
    }
  };

  const handleGetDoctorDocuments = () => {
    getDoctorDocuments({
      endpoint: `${apiConstants?.getDoctorDocuments}/${userId}`
    }).then((result) => {
      setDoctorDocuments(result?.data?.data);
      const data = result?.data?.data;
      if(data?.certificate !== null){
        let certFileName = data?.certificate?.split('/');
        let nameIndex = certFileName.length - 1;
        setUploadedFileName(certFileName[nameIndex]);
    }
    if(data?.signature !== null){
        let signatureFileName = data?.signature?.split('/');
        let nameIndex = signatureFileName.length - 1;
        setUploadedSignName(signatureFileName[nameIndex]);
    }
    });
  };

  useEffect(() => {
    handleGetDoctorDocuments();
  }, []);

  const openDocument = (document) => {
    window.open(document);
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleSaveSignature = () => {
    const dataUrl = sigRef?.current?.toDataURL('image/png');
    if (sigRef?.current?.isEmpty()) {
      setIsError(true);
      return;
    }
    const blob = dataURItoBlob(dataUrl);
    uploadDoctorDocument({
      endpoint: `${apiConstants?.uploadDoctorDocument}`,
      method: 'POST',
      data: {
        doctor_id: userId,
        fileName: 'docSign.png',
        type: 'signature'
      }
    }).then((result) => {
      if (result && result?.data) {
        const signedUrl = result?.data?.signedUrl;
        fetch(signedUrl, {
          method: 'PUT',
          body: blob
        }).then(() => {
          toast.success('Uploaded successfully');
          handleGetDoctorDocuments();
        });
      }
    });
  };

  const onFileChange = (e) => {
    e?.preventDefault();
    const file = e?.target?.files[0];
    if (['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'].includes(file?.type)) {
      uploadDoctorDocument({
        endpoint: `${apiConstants?.uploadDoctorDocument}`,
        method: 'POST',
        data: {
          doctor_id: userId,
          fileName: file?.name,
          type: 'certificate'
        }
      }).then((result) => {
        if (result && result?.data) {
          const signedUrl = result?.data?.signedUrl;
          fetch(signedUrl, {
            method: 'PUT',
            body: file
          }).then(() => {
            handleGetDoctorDocuments();
          });
        }
      });
    }
  };

  return (
    <div className='p-[50px]'>
      <div className='bg-[#f4f4f4] rounded-[8px] p-[30px]'>
        <p className='text-[30px] text-[#737575] fw-500 mb-[40px]'>Upload Documents</p>
        <div className='grid grid-cols-12 gap-[10px] pt-[20px] p-[20px]'>
          <div className='col-span-6'>
            <img src={DoctorIcon} alt='' className='h-[285px] w-[285px] m-auto' />
          </div>
          <div className='col-span-4'>
            <div className='flex flex-col gap-[10px]'>
              <div className='flex justify-between'>
                <p className='text-[16px] text-[#4e4f51] font-[700]'>Professional Degree Certificate</p>
                <button
                  className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px] text-[12px] text-nowrap h-fit'
                  style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
                  onClick={() => openDocument(doctorDocuments?.certificate)}
                >
                  View
                </button>
              </div>
              <div onClick={() => fileInputRef?.current?.click()}
                className='flex gap-[10px] items-center cursor-pointer'
              >
                <FontAwesomeIcon icon={faPaperclip} color='#383678' />
                <input
                  type='file'
                  ref={fileInputRef}
                  id='fileUplaod'
                  className='hidden'
                  onChange={onFileChange}
                  onDrop={onFileChange}
                />
                <p className='text-[12px]'>{uploadedFileName}</p>
              </div>
              <div className='flex justify-between'>
                <p className='text-[16px] text-[#4e4f51] font-[700]'>Digital Signature</p>
                <button
                  className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px] text-[12px] text-nowrap h-fit'
                  style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
                  onClick={() => openDocument(doctorDocuments?.signature)}
                >
                  View
                </button>
              </div>
              <p className='text-[12px]'>{uploadedSignName}</p>
              <SignatureCanvas
                ref={sigRef}
                penColor='black'
                canvasProps={{className: 'sigCanvas'}}
              />
              {isError && (
                <p className='text-[12px] text-[#ff0000] font-[500]'>Please sign before saving.</p>
              )}
              <div className='flex w-[75%] justify-between'>
                <button 
                  className='bg-blue-600 text-white px-[50px] py-[5px] rounded-[50px] text-[15px] text-nowrap'
                  style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
                  onClick={() => handleCanvasClear()}
                >
                  Clear
                </button>
                <button
                  className='bg-blue-600 text-white px-[50px] py-[5px] rounded-[50px] text-[15px] text-nowrap'
                  style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
                  onClick={() => handleSaveSignature()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocuments;
