import React, { useState, useEffect, useRef } from 'react';
import {
  useGetAdminDoctorsListMutation,
  useDoctorsbulkuploadMutation,
  useDoctorschedulebulkuploadMutation,
  useLazyGetsamplecsvdoctorQuery
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import CommonFilterSelect from '../common/FilterDropdown';
import Loader from '../common/Loader';
import SharedTable from '../common/SharedTable';
import { useNavigate } from 'react-router-dom';
import DoctorModalToDownloadSample from './DoctorModalToDownloadSample';
import VerifyDoctorModal from './VerifyDoctorModal';
import { Colorcode } from '../common/utils/Colorcode';

const DoctorsList = () => {
  const isAdmin = localStorage.getItem('privileges') === 'admin' ? true : false;
  const navigate = useNavigate();
  const customerColor = Colorcode();
  //const customerColorForGradient = Colorcode()+'bb';
  const [getList] = useGetAdminDoctorsListMutation();
  const [doctorsbulkupload] = useDoctorsbulkuploadMutation();
  const [doctorschedulebulkupload] = useDoctorschedulebulkuploadMutation();
  const [getsamplecsvdoctor] = useLazyGetsamplecsvdoctorQuery();

  const [loading, setLoading] = useState(false);
  const [loadOnce, setLoadOnce] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [pageNumber, setPageNumber] = useState('1');
  const [filterString, setFilterString] = useState('all');
  const [searchString, setSearchString] = useState('');
  const [doctorModal, setDoctorModal] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [rowData, setRowData] = useState({});
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });

  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'online', label: 'Online' },
    { value: 'offline', label: 'Offline' },
    { value: 'busy', label: 'Busy' },
    { value: 'disabled', label: 'Disabled' }
  ];
  const toggleButtons = isAdmin ? [
    { buttonName: 'Create', url: '/admin/create/doctor' },
    { buttonName: 'Bulk Upload Doctor Slots', url: 'bulkDocSlots' },
    { buttonName: 'Bulk Upload Doctors', url: 'bulkUploadDoc' }
  ] : [];

  const hiddenUploadFileInput = useRef(null);
  const hiddenUploadSlotsFileInput = useRef(null);

  const handleBulkUploadS = () => {
    hiddenUploadFileInput.current.click();
  };
  const handleBulkUploadSlots = () => {
    hiddenUploadSlotsFileInput.current.click();
  };

  const bulkUploadDoc = (e) => {
    const formData = new FormData();
    formData.append('csv', e.target.files[0], e.target.files[0].name);
    doctorsbulkupload({
      endpoint: apiConstants.doctorsbulkupload,
      method: 'POST',
      data: formData
    }).then((result) => {
      console.log('result', result);
    }).catch((error) => {
      console.error('error', error);
    });
  };

  const bulkUploadDocSlots = (e) => {
    const formData = new FormData();
    formData.append('csv', e.target.files[0], e.target.files[0].name);
    doctorschedulebulkupload({
      endpoint: apiConstants.doctorschedulebulkupload,
      method: 'POST',
      data: formData
    }).then((result) => {
      console.log('result', result);
    }).catch((error) => {
      console.error('error', error);
    });
  };

  const rightToggleButtons = isAdmin ? [
    { buttonName: 'DownLoad Sample File For Slots Upload', url: '' },
    { buttonName: 'DownLoad Sample File For Doctors Upload', url: 'downloadSampleFileDoc' }
  ] : [];

  const preSelectedFilter = {
    selectedOption: 'all' // Set the initial value to 'option1'
  };

  const tableHeaders = [
    { field: 'user_id', headerName: 'ID' },
    { field: 'first_name', headerName: 'Name' },
    { field: 'email_id', headerName: 'E-Mail' },
    { field: 'phone_number', headerName: 'Phone No' },
    { field: 'customer_name', headerName: 'Customer' },
    { field: 'doctor_list_status', headerName: 'Status' }
  ];

  if (isAdmin) {
    tableHeaders.push({ field: 'profile_photo', headerName: 'Profile' });
    tableHeaders.push({ field: 'action', headerName: 'Action' });
    tableHeaders.push({ field: 'details', headerName: 'Details' });
  }

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const fetchTableData = (page, status, search) => {
    if (!loadOnce) { setLoading(true); }
    getList({
      endpoint: `${apiConstants.adminDoctorsList}/${page}/${status}/${search}`,
      method: 'POST',
      data: {
        consult_type: '',
        current_time: '',
        data: {},
        date: '',
        field: sort.headerFiled,
        type: sort?.sortType,
        kiosk_id: ''
      }
    }).then((result) => {
      let doctorsListData = result?.data?.list;
      const modifiedData = doctorsListData.map(item => ({
        ...item,
        first_name: item.first_name + ' ' + item.last_name,
        doctor_list_status: (item.status === 'available') ? <div className='flex justify-start items-center gap-[5px]'>
          <div className="w-[10px] h-[10px] bg-[green] rounded-full"></div>
          <div>Online</div>
        </div> : (item.status === 'logged-out' ?
          <div className='flex justify-start items-center gap-[5px]'>
            <div className="w-[10px] h-[10px] bg-[red] rounded-full"></div>
            <div>Offline</div>
          </div> : (item.status === 'busy' ?
            <div className='flex justify-start items-center gap-[5px]'>
              <div className="w-[10px] h-[10px] bg-[#808080] rounded-full"></div>
              <div>Busy</div>
            </div> : (item.status === 'inactive' ?
              <div className='flex justify-start items-center gap-[5px]'>
                <div className="w-[10px] h-[10px] bg-[#808080] rounded-full"></div>
                <div>Disabled</div>
              </div> : <div className='flex justify-start items-center gap-[5px]'>
                <div className="w-[10px] h-[10px] bg-[#808080] rounded-full"></div>
                <div>Disabled</div>
              </div>))),
        details: (item?.is_verified === 1) ? (<button className="ml-0 rounded-[20px] float-right
         bg-[#383678] text-white px-[25px] py-[5px]
        text-sm mt-1.5 mx-auto flex customerGradientColor cursor-pointer"
          style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
          onClick={() => navigate(`/admin/doctor-view/${item?.user_id}`)}>
          Details
        </button>) : 'Not Verified',
        action: item?.is_verified === 1 ? 'Verified' :
          (<button className="mr-6 rounded-[20px] float-right bg-[#383678] text-white px-[25px] py-[5px]
        text-sm mt-1.5 mx-auto flex customerGradientColor cursor-pointer"
            style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
            onClick={() => setIsVerify(true)}>
            Verify
          </button>),
        profile_photo: <img src={item.profile_photo} />
      }));
      setTableData(modifiedData);
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setPageNumber(parseInt(result?.data?.current_page));
      setLoading(false);
      setLoadOnce(true);
    }).catch((error) => {
      setLoading(false);
      console.error('error', error);
    });
  };

  const handleDownloadSampleFileDoc = () => {
    getsamplecsvdoctor({
      endpoint: apiConstants.getsamplecsvdoctor
    }).then((result) => {
      window.open(result.data.csv_url);
    }).catch((error) => {
      console.error('error', error);
    });
  };

  useEffect(() => {
    fetchTableData(pageNumber, filterString, searchString);
  }, [pageNumber, searchString, sort]);

  useEffect(() => {
    fetchTableData(1, filterString, searchString);
  }, [filterString]);

  const handleOnPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleFilterSelect = (selectedOption) => {
    setFilterString(selectedOption.value);
  };

  const onSearch = (search) => {
    setPageNumber(1);
    setSearchString(search);
  };
  const onRowSelected = (event) => {
    setRowData(event);
    // if (!isVerify) {
    //   navigate(`/admin/doctor-view/${event.user_id}`);
    // }
    // if (isAdmin) {
    //   navigate(`/admin/doctor-view/${event.user_id}`);
    // }
  };

  const onToggleButtonClick = (url) => {
    if (url === 'bulkDocSlots') {
      handleBulkUploadSlots();
    } else if (url === 'bulkUploadDoc') {
      handleBulkUploadS();
    } else {
      navigate(url);
    }
  };

  const onRightToggleButtonClick = (url) => {
    if (url === 'downloadSampleFileDoc') {
      handleDownloadSampleFileDoc();
    } else {
      setDoctorModal(true);
    }
  };

  return (
    <div className="row mt-6 mx-24">
      {doctorModal &&
        <DoctorModalToDownloadSample
          setDoctorModal={setDoctorModal}
        />}
      {isVerify &&
        <VerifyDoctorModal
          setIsVerify={setIsVerify}
          rowData={rowData}
          fetchTableData={fetchTableData}
        />}
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div>
          <input
            id="uploadDoc"
            type="file"
            accept=".csv"
            ref={hiddenUploadFileInput}
            style={{ display: 'none' }}
            onChange={(e) => bulkUploadDoc(e)}
          />
          <input
            id="uploadDoc"
            type="file"
            accept=".csv"
            ref={hiddenUploadSlotsFileInput}
            style={{ display: 'none' }}
            onChange={(e) => bulkUploadDocSlots(e)}
          />
          <CommonFilterSelect
            initialValues={preSelectedFilter}
            options={filterOptions}
            onSearch={onSearch}
            toggleButtons={toggleButtons}
            toggleButtonClick={onToggleButtonClick}
            rightToggleButtons={rightToggleButtons}
            rightToggleButtonClick={onRightToggleButtonClick}
            onChange={handleFilterSelect}
            showFilter
          />
          <div className="mt-7">
            <h4 style={{ borderColor: customerColor[0] }} className="text-2xl font-semibold border-b-4
                            text-[Coolvetica Rg] list-header-text w-36 text-[#4f4f4f]">
              Doctors List
            </h4>
          </div>
          <div className="mt-10">
            <SharedTable
              tableData={tableData}
              tableHeaders={tableHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              onRowSelected={onRowSelected}
              handleOnClickSort={handleOnClickSort}
              currentPageNumber={pageNumber}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DoctorsList;
