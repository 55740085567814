import React, { useState, useEffect } from 'react';
import { useGetDepartmentsListMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import CommonFilterSelect from '../common/FilterDropdown';
import Loader from '../common/Loader';
import { useNavigate } from 'react-router-dom';
import SharedTable from '../common/SharedTable';
import { Colorcode } from '../common/utils/Colorcode';

const DepartmentsList = () => {

  const navigate = useNavigate();
  const customerColor = Colorcode();
  const user_type = localStorage.getItem('user_type');
  const [getList] = useGetDepartmentsListMutation();
  const [loading, setLoading] = useState(false);
  const [loadOnce, setLoadOnce] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [pageNumber, setPageNumber] = useState('1');
  const [filterString, setFilterString] = useState('all');
  const [searchString, setSearchString] = useState('');
  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'disabled', label: 'Disabled' }
  ];

  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const preSelectedFilter = {
    selectedOption: 'All' // Set the initial value to 'option1'
  };

  const tableHeaders = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Department Name' },
    { field: 'questions_count', headerName: 'Questions Added' },
    { field: 'status', headerName: 'Status' }
  ];

  const fetchTableData = (page, status, search) => {
    if (!loadOnce) { setLoading(true); }
    getList({
      endpoint: `${apiConstants.departmentsList}/${page}/${status}/${search}`,
      method: 'POST',
      data: {
        field: sort.headerFiled,
        type: sort?.sortType
      }
    }).then((result) => {
      let departmentsListData = result?.data?.list;
      const modifiedData = departmentsListData.map(item => ({
        ...item,
        questions_count: item.questions_count > 0 ? 'Yes' : 'No',
        status: item.status === 1 ?
          <div className='flex justify-start items-center gap-[5px]'>
            <div className="w-[10px] h-[10px] bg-[green] rounded-full"></div>
            <div>Active</div>
          </div> : item.status === 2 ? <div className='flex justify-start items-center gap-[5px]'>
            <div className="w-[10px] h-[10px] bg-[#808080] rounded-full"></div>
            <div>Disabled</div>
          </div> : ''
      }));
      setTableData(modifiedData);
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setLoading(false);
      setLoadOnce(true);
    }).catch((error) => {
      setLoading(false);
      console.error('error', error);
    });
  };

  useEffect(() => {
    fetchTableData(pageNumber, filterString, searchString);
  }, [pageNumber, filterString, searchString, sort]);

  const handleOnPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleFilterSelect = (selectedOption) => {
    setFilterString(selectedOption.value);
  };

  const onSearch = (search) => {
    setPageNumber(1);
    setSearchString(search);
  };

  const onRowSelected = (event) => {
    if (user_type === 'admin') {
      navigate(`/admin/${event.id}/medical-history`);
    } else {
      navigate(`/admin/department-detail-view/${event.id}`);
    }
  };

  return (
    <div className="row mt-6 mx-24">
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div>
          <CommonFilterSelect
            initialValues={preSelectedFilter}
            options={filterOptions}
            placeholder={preSelectedFilter.selectedOption}
            onChange={handleFilterSelect}
            onSearch={onSearch}
            showFilter
          />
          <div>
            <h4 style={{ borderColor: customerColor[0] }} className="text-2xl font-semibold border-b-4
                        text-[Coolvetica Rg] list-header-text w-max text-[#4f4f4f]">
              Department List
            </h4>
          </div>
          <div className="mt-10">
            <SharedTable
              tableData={tableData}
              tableHeaders={tableHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              onRowSelected={onRowSelected}
              handleOnClickSort={handleOnClickSort}
              currentPageNumber={pageNumber}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DepartmentsList;
