import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetFutureConsultsMutation } from '../../reducers/allReducer';
import SharedTable from '../common/SharedTable';
import { apiConstants } from '../../constants/api';
import moment from 'moment';
import CommonFilterSelect from '../common/FilterDropdown';
import { Colorcode } from '../common/utils/Colorcode';

const UpcomingAppointments = () => {
  const customerColor = Colorcode();
  const [getFutureConsults] = useGetFutureConsultsMutation();
  const [tableData, setTableData] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [pageNumber, setPageNumber] = useState('1');
  const [filterString, setFilterString] = useState('all');
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });
  const navigate = useNavigate();

  const tableHeaders = [
    { field: 'appointment_id', headerName: 'Appointment Id' },
    { field: 'name', headerName: 'Patient Name' },
    { field: 'phone_number', headerName: 'Phone No' },
    { field: 'appointment_time', headerName: 'Appointment' },
    { field: 'status', headerName: 'Status' },
    { field: 'action', headerName: 'Action' }
  ];

  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'scheduled', label: 'Scheduled' },
    { value: 'cancelled', label: 'Cancelled' }
  ];

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const preSelectedFilter = {
    selectedOption: 'all' // Set the initial value to 'option1'
  };

  const handleFilterSelect = (selectedOption) => {
    setFilterString(selectedOption.value);
  };

  const onSearch = (search) => {
    setSearchString(search);
  };

  const handleOnPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const joinMeeting = (appointmentId, meetingId) => {
    const url = `/doctor/opdappointment/${appointmentId}/${meetingId}`;
    navigate(url);
  };

  const getFutureConsultDetails = (page, filter, search) => {
    if (search !== '') {
      page = 1;
      setPageNumber(1);
    }
    getFutureConsults({
      endpoint: `${apiConstants.getFutureConsults}/${page}/${filter}/${search}`,
      method: 'POST',
      data: {
        'field': sort?.headerFiled,
        'type': sort?.sortType,
        'consult_type': '',
        'data': {},
        'kiosk_id': '',
        'current_time': '',
        'date': ''
      }
    }).then((result) => {
      let futureConsultList = result?.data?.list;
      let modifiedFutureConsultList = futureConsultList?.map(listData => ({
        ...listData,
        status: listData.status[0].toUpperCase() + listData.status.slice(1),
        appointment_time: moment(listData.appointment_time).format('DD MMM YYYY, hh:mm A'),
        action: listData?.status === 'scheduled' || listData?.status === 'Scheduled' ?
          (<div className='flex text-center'><button className="rounded-[20px]
          float-right text-white px-[25px] py-[5px] text-sm mx-auto flex cursor-pointer"
            style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
            onClick={() => joinMeeting(listData.appointment_id, listData.meetingId)}>Join</button></div>)
          : (<div>-</div>)
      })
      );
      setTableData(modifiedFutureConsultList);
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setPageNumber(parseInt(result?.data?.current_page));
    });
  };

  useEffect(() => {
    getFutureConsultDetails(pageNumber, filterString, searchString);
  }, [pageNumber, filterString, searchString, sort]);

  return (
    <div className="row mt-6 mx-24">
      <div className=''>
        <CommonFilterSelect
          initialValues={preSelectedFilter}
          options={filterOptions}
          onSearch={onSearch}
          onChange={handleFilterSelect}
          showFilter
        />
      </div>
      <div className="mt-7">
        <h4 className="text-2xl font-semibold border-b-4 text-[Coolvetica Rg] list-header-text w-max"
          style={{ borderColor: customerColor[0] }}>
          Upcoming Consult List
        </h4>
      </div>
      <div className="mt-10">
        <SharedTable
          tableData={tableData}
          tableHeaders={tableHeaders}
          handleOnPageChange={handleOnPageChange}
          isMoreData={isMoreData}
          totalPages={totalPages}
          handleOnClickSort={handleOnClickSort}
          currentPageNumber={pageNumber}
        />
      </div>
    </div>
  );
};

export default UpcomingAppointments;
