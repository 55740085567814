import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReactSelectFilters from '../../../../common/ReactSelectFilters';
import { useAddAppointmentDataMutation, useLazyGetMedicinesQuery } from '../../../../../reducers/allReducer';
import { apiConstants } from '../../../../../constants/api';
import { 
  daysTypeOptions,
  dosageTypes,
  routesOptions, 
  // selectedDaysOptions, 
  timesPerDayOptions
} from './constantDropdownOptions';
import { useDispatch, useSelector } from 'react-redux';
import { editDrugData, updateDrugsData } from '../../../../../reducers/selectedDropdownValuesSlice';
import * as Yup from 'yup';

const AddDrugModal = ({
  handleModalClose,
  drugTypes,
  appointmentId,
  isDrugEdit
}) => {
  const [drugSearchValue, setDrugSearchValue] = useState();
  const [drugSearchData, setDrugSearchData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [getMedicines] = useLazyGetMedicinesQuery();
  const [addAppointmentData] = useAddAppointmentDataMutation();
  const selectedDrugToEdit = useSelector((state) => state?.selectedDropdownValues?.selectedDrugDataToEdit);
  const userId = localStorage.getItem('user_id');
  const dispatch = useDispatch();
  const initialDosageUnit = { label: 'mg', value: 'mg' };
  const initialDaysType = { label: 'Every Day', value: 'everyday' };
  const initialValues = {
    drug_type: selectedDrugToEdit?.drug_type || '',
    drug_name: selectedDrugToEdit?.drug_name || '',
    dosage: selectedDrugToEdit?.dosage || '',
    dosage_unit: selectedDrugToEdit?.dosage_unit || initialDosageUnit,
    timesADay: selectedDrugToEdit?.timesADay || '',
    timings: selectedDrugToEdit?.timings,
    daysType: selectedDrugToEdit?.daysType || initialDaysType,
    daysSelected: selectedDrugToEdit?.daysSelected || initialDaysType?.value,
    route: selectedDrugToEdit?.route || '',
    duration: selectedDrugToEdit?.duration || '',
    instructions: selectedDrugToEdit?.instructions || ''
  };

  const validationSchema = Yup.object().shape({
    drug_type: Yup.object().required('Please enter value'),
    drug_name: Yup.object().required('Please enter value'),
    dosage: Yup.string().required('Please enter value'),
    timesADay: Yup.string().required('Please enter value'),
    // timings: Yup.array().required('Please enter value'),
    route: Yup.object().required('Please enter value'),
    duration: Yup.string().required('Please enter value')
  });

  const getTimingsArray = (timesPerDay) => {
    let tempArray = null;
    if (timesPerDay >= 1 && timesPerDay <= 4) {
      tempArray = new Array('Morning', 'Afternoon', 'Evening', 'Night');
    } else if (timesPerDay > 4) {
      tempArray = new Array();
      for (let i = 0; i < 24; i++) {
        if (i < 10) {
            tempArray.push('0' + i);
        } else {
            tempArray.push(i);
        }
      }
    }
    if (tempArray !== null) {
      tempArray = tempArray.map((array_item) => {
        return {
          selected: false,
          label: array_item,
          value: array_item
        };
      });
    }
    return tempArray;
  };

  const handleDrugSearchInputChange = (value) => {
    setDrugSearchValue(value);
  };

  const getDrugsData = (value) => {
    getMedicines({
      endpoint: `${apiConstants?.getMedicines}?query=${value}`
    }).then((result) => setDrugSearchData(result?.data?.drugs));
  };

  useEffect(() => {
    if (drugSearchValue?.length >= 2) {
      getDrugsData(drugSearchValue);
    }
  }, [drugSearchValue]);

  const getOptionLabel = (option) => {
    return option?.name;
  };

  const handleOnSubmitDrugData = (values) => {
    if (values?.timings?.length < values?.timesADay) {
      setIsError(true);
    } else {
      setIsError(false);
      addAppointmentData({
        endpoint: `${apiConstants?.addAppointmentData}/${userId}`,
        method: 'POST',
        data: {
          appointment_id: appointmentId,
          drug: [
            {
              appointment_id: appointmentId,
              daysSelected: values?.daysSelected,
              daysType: values?.daysType?.value,
              dosage: values?.dosage,
              dosage_unit: values?.dosage_unit?.value,
              drugId: values?.drug_name?.id,
              drugName: values?.drug_name?.name,
              drug_type: values?.drug_type?.value,
              duration: Number(values?.duration),
              instructions: values?.instructions,
              route: values?.route?.value,
              timesADay: Number(values?.timesADay),
              timings: values?.timings,
              timings_raw: timesPerDayOptions
            }
          ],
          type: 'drug'
        }
      }).then((result) => {
        if (isDrugEdit) {
          dispatch(editDrugData({ id: values?.drug_name?.id, 
            dataToReplace: result?.data?.data[values?.drug_name?.id]}));
        } else {
          dispatch(updateDrugsData(result?.data?.data[values?.drug_name?.id]));
        }
        handleModalClose();
      });
    }
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white p-3">
              <div className='flex justify-end' onClick={() => handleModalClose()}>
                <p className='underline text-[18px] cursor-pointer text-[#383678] pr-[20px]'>Close</p>
              </div>
              <Formik
                initialValues={initialValues}
                onSubmit={handleOnSubmitDrugData}
                enableReinitialize
                validationSchema={validationSchema}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className='flex flex-col gap-[10px] p-[20px] addDrug'>
                      <div className='grid grid-cols-12 gap-[10px]'>
                        <div className='col-span-4'>
                          <Field name='drug_type'>
                            {({ field }) => (
                              <ReactSelectFilters 
                                {...field}
                                options={drugTypes}
                                placeholder='Drug Type'
                                name='drugTypeDropdown'
                                onChange={(option) => {
                                  setFieldValue('drug_type', option);
                                }}
                                disableEnterKey
                                selectFieldValues={values?.drug_type}
                                isDisabled={isDrugEdit}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="drug_type" component="div" className='text-red-600'/>
                        </div>
                        <div className='col-span-4'>
                          <Field name='drug_name'>
                            {({ field }) => (
                              <ReactSelectFilters 
                                {...field}
                                options={drugSearchData}
                                placeholder='Search'
                                name='drugSearchDropdown'
                                noDropDownIndicator
                                handleDropdownInputChange={handleDrugSearchInputChange}
                                onChange={(option) => setFieldValue('drug_name', option)}
                                noOptionMessage='No matches found'
                                disableEnterKey
                                getOptionLabel={getOptionLabel}
                                isSearchable={true}
                                selectFieldValues={values?.drug_name}
                                isDisabled={isDrugEdit}
                              />
                            )}
                          </Field>
                          <ErrorMessage name="drug_name" component="div" className='text-red-600'/>
                        </div>
                        <div className='col-span-4'>
                          <div className='flex flex-col'>
                            <div className='flex w-full'>
                              <Field type='text' name='dosage' className='w-full text-[#777]' placeholder='Dosage' />
                              <Field name='dosage_unit'>
                                {({ field }) => (
                                  <ReactSelectFilters 
                                    {...field}
                                    options={dosageTypes}
                                    name='dosageTypeDropdown'
                                    onChange={(option) => {
                                      setFieldValue('dosage_unit', option);
                                    }}
                                    disableEnterKey
                                    selectFieldValues={values?.dosage_unit}
                                  />
                                )}
                              </Field>
                            </div>
                            <ErrorMessage name="dosage" component="div" className='text-red-600'/>
                          </div>
                        </div>
                      </div>
                      <div className='w-full pt-[20px]'>
                        <Field type='text' name='timesADay' placeholder='Times(per day)' className='w-full text-[#777]' 
                          onChange={(e) => {setFieldValue('timings', ''); 
                          setFieldValue('timesADay', e?.target?.value);}} />
                          <ErrorMessage name="timesADay" component="div" className='text-red-600'/>
                        {values?.timesADay !== null && (  
                          <>  
                            <div className={`flex gap-[15px] ${values?.timesADay > 0 && 'pt-[20px]'} flex-wrap`}>
                              {getTimingsArray(values?.timesADay)?.map((option, index) => (
                                <div key={index} className='flex gap-[5px]'>
                                  <label className='text-[12px] text-[#777]'>{option?.label}</label>
                                    <Field
                                      type='checkbox'
                                      name='timings'
                                      value={option.value}
                                      checked={values?.timings?.includes(option.value)}
                                      onChange={() => {
                                        const maxSelections = parseInt(values?.timesADay);
                                        let selectedTimings = [...values.timings];
                                        const timingIndex = selectedTimings.indexOf(option.value);
                                        
                                        if (timingIndex !== -1) {
                                          // Uncheck the checkbox if it's already selected
                                          selectedTimings.splice(timingIndex, 1);
                                        } else if (selectedTimings.length < maxSelections) {
                                          // Check the checkbox only if maximum selections not reached
                                          selectedTimings.push(option.value);
                                        }
                                        setFieldValue('timings', selectedTimings);
                                      }}
                                    />
                                </div>
                              ))}
                            </div>
                            {isError && (
                              <p className='text-[12px] text-[#ff0000] font-[500]'>
                                Please select a proper value for duration
                              </p>
                            )}
                          </>
                        )}
                      </div>
                      <div className='w-full pt-[20px]'>
                        <Field name='daysType' className='w-full'>
                          {({ field }) => (
                            <ReactSelectFilters 
                              {...field}
                              options={daysTypeOptions}
                              name='daydTypeDropdown'
                              onChange={(option) => {
                                setFieldValue('daysType', option);
                                if (option?.value === 'selected_days') {
                                  setFieldValue('daysSelected', '');
                                } else {
                                  setFieldValue('daysSelected', option?.value);
                                }
                              }}
                              disableEnterKey
                              selectFieldValues={values?.daysType}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="daysType" component="div" className='text-red-600'/>
                        {/* {values?.daysType?.value === 'selected_days' && (
                          <div className='flex gap-[15px] pt-[20px] flex-wrap'>
                            {selectedDaysOptions?.map((option, index) => (
                              <div key={index} className='flex gap-[5px]'>
                                <label className='text-[12px] text-[#777]'>{option?.label}</label>
                                  <Field
                                    type='checkbox'
                                    name='daysSelected'
                                    value={option.value}
                                  />
                              </div>
                            ))}
                          </div>
                        )} */}
                      </div>
                      <div className='grid grid-cols-12 gap-[10px] pt-[20px] items-end'>
                        <div className='col-span-6'>
                          <Field name='route' className='w-full'>
                            {({ field }) => (
                              <ReactSelectFilters 
                                {...field}
                                options={routesOptions}
                                name='routesDropdown'
                                onChange={(option) => {
                                  setFieldValue('route', option);
                                }}
                                disableEnterKey
                                noDropDownIndicator
                                selectFieldValues={values?.route}
                                placeholder='Route'
                              />
                            )}
                          </Field>
                          <ErrorMessage name="route" component="div" className='text-red-600'/>
                        </div>
                        <div className='col-span-6'>
                          <Field type='text' name='duration' placeholder='Duration(No. of Days.)' 
                            className='w-full text-[#777]' />
                          <ErrorMessage name="duration" component="div" className='text-red-600'/>
                        </div>
                      </div>
                      <div className='w-full pt-[20px]'>
                        <Field type='text' name='instructions' placeholder='Add Instructions' className='w-full' />
                      </div>
                      <div className='pt-[40px] flex justify-center'>
                        <button 
                          type='submit'
                          className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px]' 
                          // disabled={isSubmitting}
                          style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)'}}
                        >
                            Save
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDrugModal;
