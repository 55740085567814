import moment from 'moment';
import React from 'react';
import RecursivePreviewComponent from './RecursivePreviewComponent';

const MedicalHistoryPreview = ({ historyData }) => {
  return (
    <div className='flex flex-col gap-[10px] px-[60px]'>
      <p className='text-[24px] text-[#737575] text-center font-[700] underline'>
        {historyData?.name?.toUpperCase()}
      </p>
      <div className='grid grid-cols-12'>
        <p className='col-span-6 text-[14px] text-[#737575] font-[600]'>
          Created Date: {moment(historyData?.date_created)?.format('LLL')}
        </p>
        <div className='text-[14px] text-[#737575] font-[600] flex gap-[10px] items-center'>
          <div className={`w-[10px] h-[10px] ${historyData?.status === 1 ? 'bg-[green]' : 
          'bg-[#808080]'} rounded-full`}></div>
          {historyData?.status === 1 ? 'Active' : 'Disabled'}
        </div>
      </div>
      <div className='p-[20px] border-y-[1px] border-y-black mt-[20px]'>
        <p className='text-[14px] text-[#737575] font-[600] text-center'>QUESTIONS PRE-VIEW</p>
      </div>
      <RecursivePreviewComponent questionData={JSON.parse(historyData?.questions_json)} />
    </div>
  );
};

export default MedicalHistoryPreview;