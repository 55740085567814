import React from 'react';
import DynamicInputElements from './DynamicInputElements';
import { updateMedicalHistoryDetails } from '../../../../../reducers/medicalHistorySlice';
import { useDispatch } from 'react-redux';

export const SecondChildComponent = ({
  displayData,
  deepCopy,
  dispatch,
  superParentId,
  secondChildId,
  parentData,
  superParentData,
  firstChildId,
  parentId,
  errorData
}) => {

  const handleOnSecondChildInputElementChange = (data, e) => {
    const { id, value } = e.target;
    let dataToReplace;
    const tempData = deepCopy(data);
    tempData.questions[id].value = value;
    dataToReplace = tempData;
    let parentDataTemp = deepCopy(parentData);
    parentDataTemp.questions[parentId].data[secondChildId].children = dataToReplace;
    let superParentDataTemp = deepCopy(superParentData);
    superParentDataTemp.data[firstChildId].children = parentDataTemp;
  
    dispatch(updateMedicalHistoryDetails({
      depth: 2,
      dataToReplace: superParentDataTemp,
      parentId: superParentId
    }));
  };

  return (
    <div className='pt-[5px]'>
      {displayData?.questions && Object.keys(displayData?.questions)?.map((item, index) => {
        const questionItem = displayData?.questions[item];
        return (
          <div key={index} className='flex flex-col gap-[5px]'>
            <div className='grid grid-cols-12 items-center'>
              <div className='col-span-6'>
                <p className='pl-[100px]'>{questionItem?.title}</p>
              </div>
              <div className='col-span-6 pl-[100px]'>
                <DynamicInputElements
                  type={questionItem?.type}
                  title={questionItem?.title}
                  value={questionItem?.value}
                  data={questionItem?.data}
                  depth={3}
                  handleOnInputElementChange={(e) => handleOnSecondChildInputElementChange(displayData, e )}
                  id={item}
                  error={errorData && errorData[item] && errorData[item].error ?
                     errorData[item].error : null}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export const FirstChildComponent = ({
  displayData,
  deepCopy,
  dispatch,
  parentId,
  firstChildId,
  parentData,
  errorData
}) => {
  const handleOnFirstChildInputElementChange = (data, e) => {
    const { id, value } = e.target;
    let dataToReplace;
    const tempData = deepCopy(data);
    tempData.questions[id].value = value;
    dataToReplace = tempData;
    let parentDataTemp = deepCopy(parentData);
    parentDataTemp.data[firstChildId].children = dataToReplace;

    dispatch(updateMedicalHistoryDetails({
      depth: 2,
      dataToReplace: parentDataTemp,
      parentId: parentId
    }));
  };

  return (
    <div className='pt-[5px]'>
      {displayData?.questions && Object.keys(displayData?.questions)?.map((item, index) => {
        const questionItem = displayData?.questions[item];
        return (
          <div key={index} className='flex flex-col gap-[5px]'>
            <div className='grid grid-cols-12 items-center'>
              <div className='col-span-6'>
                <p className='pl-[60px]'>{questionItem?.title}</p>
              </div>
              <div className='col-span-6 pl-[60px]'>
                <DynamicInputElements
                  type={questionItem?.type}
                  title={questionItem?.title}
                  value={questionItem?.value}
                  data={questionItem?.data}
                  depth={2}
                  handleOnInputElementChange={(e) => handleOnFirstChildInputElementChange(displayData, e )}
                  id={item}
                  error={errorData && errorData[item] && errorData[item].error ? 
                    errorData[item].error : null}
                />
              </div>
            </div>
            {Object.keys(questionItem?.data)?.map((childItem, index) => {
              return (
                <div key={index}>
                  {questionItem?.data[childItem]?.isChild && 
                    (questionItem?.data[childItem].title === questionItem?.value) && (
                    <SecondChildComponent 
                      displayData={questionItem?.data[childItem]?.children}
                      deepCopy={deepCopy}
                      dispatch={dispatch}
                      superParentId={parentId}
                      secondChildId={childItem}
                      parentData={displayData}
                      superParentData={parentData}
                      firstChildId={firstChildId}
                      parentId={item}
                      errorData={(errorData && errorData[item] && errorData[item].children)
                         ? errorData[item].children : {}}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const ParentComponent = ({
  displayData,
  deepCopy,
  errorData
}) => {
  const dispatch = useDispatch();
  const handleOnParentInputElementChange = (e) => {
    const { id, value } = e.target;

    dispatch(updateMedicalHistoryDetails({
      depth: 1,
      dataToReplace: value,
      parentId: id
    }));
  };

  return (
    <div className='pt-[5px]'>
      {displayData?.questions && Object.keys(displayData?.questions)?.map((item, index) => {
        const questionItem = displayData?.questions[item];
        return (
          <div key={index} className='flex flex-col gap-[5px]'>
            <div className='grid grid-cols-12 items-center'>
              <div className='col-span-6'>
                <p className='pl-[20px]'>{questionItem?.title}</p>
              </div>
              <div className='col-span-6 pl-[20px]'>
                <DynamicInputElements
                  type={questionItem?.type}
                  title={questionItem?.title}
                  value={questionItem?.value}
                  data={questionItem?.data}
                  depth={1}
                  handleOnInputElementChange={handleOnParentInputElementChange}
                  id={item}
                  error={(errorData && errorData[item] && errorData[item].error) ? errorData[item].error : ''}
                />
              </div>
            </div>
            {Object.keys(questionItem?.data)?.map((childItem, index) => {
              return (
                <div key={index}>
                  {questionItem?.data[childItem]?.isChild && 
                    (questionItem?.data[childItem].title === questionItem?.value) && (
                    <FirstChildComponent
                      displayData={questionItem?.data[childItem]?.children}
                      deepCopy={deepCopy}
                      dispatch={dispatch}
                      parentId={item}
                      firstChildId={childItem}
                      parentData={questionItem}
                      errorData={(errorData && errorData[item] && errorData[item].children)
                         ? errorData[item].children : {}}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ParentComponent;