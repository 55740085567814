import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faUser, faList } from '@fortawesome/free-solid-svg-icons';
import DefaultImage from '../../images/general_pic.png';
import { useVerifydoctorMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import { Colorcode } from '../common/utils/Colorcode';

const VerifyDoctorModal = ({
  setIsVerify,
  rowData,
  fetchTableData
}) => {
  const [verifydoctor] = useVerifydoctorMutation();
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const customerColor = Colorcode();

  const handleSubmit = () => {
    verifydoctor({
      endpoint: `${apiConstants.verifydoctor}/${rowData.user_id}`,
      method: 'PUT'
    }).then((result) => {
      if (result?.data?.success) {
        setIsVerify(false);
        fetchTableData(1, 'all', '');
      }
    }).catch(() => {
      setIsVerify(false);
    });
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-start justify-center p-4 text-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[15px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white">
              <div className="w-full relative">
                <div className='p-[20px] border-b-[1px] border-b-[#808080] flex justify-center'>
                  <span className='text-[#757776] text-[24px] '>
                    Verify Doctor</span>
                  <FontAwesomeIcon icon={faXmark}
                    className='text-gray-500 absolute right-[40px] top-[25px] text-[30px] cursor-pointer'
                    onClick={() => setIsVerify(false)} />
                </div>
                <div className="w-full py-[30px] px-[40px] text-[#757776] overflow-y-scroll">
                  <div className='grid grid-cols-12 gap-5'>
                    <div className='col-span-4 border-2 border-[#000] rounded-[20px] p-[5px]'>
                      <img src={rowData.profile_photo ? rowData.profile_photo : DefaultImage} alt='' />
                    </div>
                    <div className='col-span-8 flex flex-col gap-4'>
                      <div className='flex gap-[10px]'>
                        <FontAwesomeIcon icon={faUser}
                          className='text-gray-500 right-[40px] top-[25px] text-[30px] cursor-pointer' />
                        <span>Dr {rowData?.first_name}</span>
                      </div>
                      <div className='flex gap-[10px]'>
                        <FontAwesomeIcon icon={faList}
                          className='text-gray-500 right-[40px] top-[25px] text-[30px] cursor-pointer' />
                        <span>{rowData?.certificate ? rowData?.certificate : 'No certificate found'}</span>
                      </div>
                      <div className='flex gap-[10px]'>
                        <FontAwesomeIcon icon={faList}
                          className='text-gray-500 right-[40px] top-[25px] text-[30px] cursor-pointer' />
                        <span>{rowData?.signature ? rowData?.signature : 'No signature found'}</span>
                      </div>
                    </div>
                    <div className='col-span-12 flex gap-5'>
                      <input
                        type='checkbox'
                        onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                      />
                      <div>I hearby confirm that the doctor name, photo and degree certificate are valid</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex gap-[80px] my-[20px]'>
                <button
                  type='submit'
                  className={`${isCheckboxChecked ? 'bg-[#383678]' : 'bg-[#808080] cursor-not-allowed'} text-white 
                  px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px] rounded-[20px] focus:outline-none text-[15px] 
                  mx-auto flex justify-center`}
                  style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                  onClick={() => handleSubmit()}
                  disabled={!isCheckboxChecked}
                >
                  Verify
                </button>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default VerifyDoctorModal;

