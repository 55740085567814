import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import AdminImage from '../../images/admin_icon.png';
import * as yup from 'yup';
// import PatientWithSamePhoneModal from './PatientWithSamePhoneModal';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { useCreateAdminMutation, useLazyGetcustomersQuery } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import ReactSelectFilters from '../common/ReactSelectFilters';

const Schema = yup.object().shape({
  first_name: yup.string().required('Please Enter First Name'),
  last_name: yup.string().max(10).required('Please Enter Last Name'),
  email: yup.string().email('Must be valid email').required('Please Enter Valid Email')
});

const CreateAdmin = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [addAdmin] = useCreateAdminMutation();
  const [getcustomers] = useLazyGetcustomersQuery();
  const [cusotmerList, setCusotmerList] = useState([]);
  const [radio, setRadio] = useState('admin');
  const [customerName, setCustomerName] = useState();

  const privacypolicy = () => {
    window.open('https://smarthealth.sakhaglobal.com/privacypolicy');
  };

  const termsandconditions = () => {
    window.open('https://smarthealth.sakhaglobal.com/termsandconditions');
  };

  const setRadioButton = (value) => {
    setRadio(value);
  };

  const handleSubmit = (values) => {
    let callApi = false;
    if (radio === 'admin') {
      if (customerName?.value) {
        callApi = true;
      } else {
        callApi = false;
      }
    } else {
      callApi = true;
    }
    values.phone = phoneNumber;
    if (callApi) {
      addAdmin({
        endpoint: apiConstants.createAdmin,
        method: 'POST',
        data: {
          data: {
            fname: values.first_name,
            lname: values.last_name,
            phone_number: values.phone,
            email_id: values.email,
            privilege: radio,
            customer_id: customerName?.value
          }
        }
      }).then(() => {
        navigate('/admin/adminUsers');
      }).catch(() => {
        navigate('/admin/adminUsers');
      });
    }
  };

  useEffect(() => {
    getcustomers({
      endpoint: apiConstants.getcustomers
    }).then((result) => {
      const list = result?.data;
      if (list.length > 0) {
        const transformedArray = list.map(item => ({
          value: item.customer_id,
          label: item.customer_name
        }));
        setCusotmerList(transformedArray);
      } else {
        setCusotmerList([]);
      }
    }).catch(() => {
    });
  }, []);

  const handleOnDropdownChange = () => {
  };

  return (
    <div className='my-[2%] mx-[5%]'>
      <div className='bg-[#f4f4f4] grid grid-cols-12 mt-28 rounded-[16px]'>
        <div className='col-span-6'>
          <div className='text-[#737575] text-[40px] mt-[25px] ml-48'>Create Admin</div>
          <img className="h-auto max-w-lg mx-auto ml-36 mt-10" src={AdminImage} alt="image description" />
        </div>
        <div className='col-span-6'>
          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              email: ''
            }}
            validationSchema={Schema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form className='flex flex-col items-center gap-[30px] mt-[30px]'>
                <div className='relative w-[55%]'>
                  <Field
                    name="first_name"
                    type='text'
                    placeholder="First name*"
                    autoComplete="off"
                    className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
                  />
                  <ErrorMessage name="first_name" component="div" className='text-[red] text-[12px]' />
                </div>
                <div className='relative w-[55%]'>
                  <Field
                    name="last_name"
                    type='text'
                    placeholder="Last Name*"
                    autoComplete="off"
                    className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
                  />
                  <ErrorMessage name="last_name" component="div" className='text-[red] text-[12px]' />
                </div>
                <div className="relative w-[55%]"
                  style={{ display: 'grid', background: '#f4f4f4' }}>
                  <Field name="phone" className='createCustomer border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3'>
                    {({ field }) => (
                      <PhoneInput
                        {...field}
                        name="phone"
                        type="tel"
                        placeholder="Phone Number"
                        country={'in'}
                        onlyCountries={['in']}
                        autoFormat={false}
                        onChange={(data) => {
                          setPhoneNumber('+' + data);
                        }}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="phone" component="div" className='text-[red] text-[12px]' />
                </div>
                {/* email field */}
                <div className='w-[55%]'>
                  <Field
                    name="email"
                    type='text'
                    placeholder="Email*"
                    autoComplete="off"
                    className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
                  />
                  <ErrorMessage name="email" component="div" className='text-[red] text-[12px]' />
                </div>
                <div className='w-[55%]'>
                  <span className='mr-40'>Admin Privilege*</span>
                  <div className='flex gap-4 mt-[16px]'>
                    <div className='flex-[1]'>
                      <div className='flex'>
                        <Field
                          name="admin"
                          type='radio'
                          checked={radio === 'admin'}
                          placeholder="Admin"
                          className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                        outline-none mr-2 cursor-pointer"
                          onChange={() => setRadioButton('admin')}
                        />
                        <div className='opacity-60'>Admin</div>
                      </div>
                      <ErrorMessage name="email" component="div" className='text-[red] text-[12px]' />
                    </div>
                    <div className='flex-[1]'>
                      <div className='flex'>
                        <Field
                          name="superadmin"
                          type='radio'
                          checked={radio === 'superadmin'}
                          placeholder="Super Admin"
                          className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                        outline-none mr-2 cursor-pointer"
                          onChange={() => setRadioButton('superadmin')}
                        />
                        <div className='opacity-60'>Superadmin</div>
                      </div>
                      <ErrorMessage name="email" component="div" className='text-[red] text-[12px]' />
                    </div>
                  </div>
                </div>
                {/* customer */}
                {radio === 'admin' &&
                  <div className='createUser w-[55%]'>
                    <Field name="customer" className='w-full relative'>
                      {({ field }) => (
                        <ReactSelectFilters
                          {...field}
                          customStyles={{
                            valueContainer: provided => ({
                              ...provided,
                              backgroundColor: '#f4f4f4',
                              padding: '8px',
                              height: '50px',
                              width: '100px'
                            })
                          }}
                          options={cusotmerList}
                          placeholder='Customer*'
                          name='customer'
                          noDropDownIndicator={false}
                          handleDropdownInputChange={handleOnDropdownChange}
                          onChange={(data) => {
                            setCustomerName(data);
                            setFieldValue('customer', data?.value);
                          }}
                          selectFieldValues={customerName}
                          isMulti={false}
                          disableEnterKey
                        />
                      )}
                    </Field>
                    <ErrorMessage name="customer" component="div" className='text-red-600' />
                  </div>}
                {/* Buttons */}
                <div className='flex gap-[40px] w-[55%]'>
                  <button
                    type='submit'
                    className="bg-[#383678] text-white px-[50px] py-[5px] w-[150px] h-[48px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center"
                    style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                  >
                    Submit
                  </button>
                  <button
                    type='text'
                    className="bg-[#383678] text-white px-[50px] py-[5px] w-[150px] h-[48px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center"
                    style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                    onClick={() => navigate('/admin/adminUsers')}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div>
          </div>
          <div className='text-[#737575] ml-32 mt-10 mb-10'>By clicking on Submit you accept our
            <span className='underline cursor-pointer hover:font-semibold'
              onClick={privacypolicy}> Privacy Policy</span> and
            <span className='underline cursor-pointer hover:font-semibold'
              onClick={termsandconditions}> Terms and Conditions.</span>
          </div>
        </div>
      </div>
    </div >
  );
};

export default CreateAdmin;