import React, { useState, useEffect } from 'react';
import { useGetMedicalHistoryListMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import CommonFilterSelect from '../common/FilterDropdown';
import Loader from '../common/Loader';
import moment from 'moment';
import SharedTable from '../common/SharedTable';
import { useNavigate } from 'react-router-dom';

const MedicalHistoryList = () => {

    const navigate = useNavigate();
    const pathSegments = new URL(window.location.href).pathname.split('/').filter(segment => segment !== '');
    const [getList] = useGetMedicalHistoryListMutation();
    const [loading, setLoading] = useState(false);
    const [loadOnce, setLoadOnce] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isMoreData, setIsMoreData] = useState(false);
    const [totalPages, setTotalPages] = useState();
    const [pageNumber, setPageNumber] = useState('1');
    const [filterString, setFilterString] = useState('all');
    const [searchString, setSearchString] = useState('');
    const filterOptions = [
        { value: 'all', label: 'All' },
        { value: 'active', label: 'Active' },
        { value: 'inactive', label: 'Disabled' }
    ];
	const [sort, setSort] = useState({
        sortType: '',
        headerField: ''
    });
    const toggleButtons = [
        { buttonName: 'Back', url: '/admin/departmentList' },
        { buttonName: '+ Add Section', url: `/admin/medical-history/${pathSegments[1]}/create` }
    ];

  const preSelectedFilter = {
    selectedOption: 'all' // Set the initial value to 'option1'
  };

  const tableHeaders = [
    { field: 'name', headerName: 'Section Name' },
    { field: 'sort_order', headerName: 'Sort Order' },
    { field: 'date_created', headerName: 'Created Date' },
    { field: 'status', headerName: 'Status' }
  ];

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header?.field === sort?.headerFiled) {
      setSort({
      ...sort,
      sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
      sortType: 'asc',
      headerFiled: header?.field
      });
    }
  };

  const fetchTableData = (page, status, search) => {
    if (!loadOnce) { setLoading(true); }
    getList({
      endpoint: `${apiConstants.medicalHistoryList}/${page}/${status}/${search}`,
      method: 'POST',
      data: {
        department_id: pathSegments[1],
        field: '',
        type: ''
      }
    }).then((result) => {
      let medicalHistoryListData = result?.data?.list;
      const modifiedData = medicalHistoryListData.map(item => ({
        ...item,
        date_created: moment(item.date_created).format('DD MMM YYYY, hh:mm A'),
        status: item.status === 1 ? <div className='flex justify-start items-center gap-[5px]'>
          <div className="w-[10px] h-[10px] bg-[green] rounded-full"></div>
          <div>Active</div>
        </div> : item.status === 2 ? <div className='flex justify-start items-center gap-[5px]'>
          <div className="w-[10px] h-[10px] bg-[#808080] rounded-full"></div>
          <div>Disabled</div>
        </div> : ''
      }));
      setTableData(modifiedData);
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setLoading(false);
      setLoadOnce(true);
    }).catch((error) => {
      setLoading(false);
      console.error('error', error);
    });
  };

  useEffect(() => {
    fetchTableData(pageNumber, filterString, searchString);
  }, [pageNumber, filterString, searchString]);

  const handleOnPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleFilterSelect = (selectedOption) => {
    setFilterString(selectedOption.value);
  };

  const onSearch = (search) => {
	setPageNumber(1);
    setSearchString(search);
  };

  const onRowSelected = (event) => {
      navigate(`/admin/medical-history/${event.id}/view`);
  };

  const onToggleButtonClick = (url) => {
    navigate(url);
  };

  return (
    <div className="row mt-6 mx-24">
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div>
          <CommonFilterSelect
            initialValues={preSelectedFilter}
            onSearch={onSearch}
            toggleButtons={toggleButtons}
            toggleButtonClick={onToggleButtonClick}
          />
          <div className="mt-7">
            <h4 className="text-2xl font-semibold border-b-4 border-[#383678]
                            text-[Coolvetica Rg] list-header-text w-max text-[#4f4f4f]">
              Medical History List
            </h4>
          </div>
          <div className='flex mt-7'>
            {filterOptions && filterOptions.map((item, index) => {
              const isFilterActive = filterString === item.value;

              return (
                <React.Fragment key={item.value}>
                  <a
                    onClick={() => handleFilterSelect(item)}
                    className={`filter-anchor cursor-pointer
                    ${isFilterActive ? 'underline text-[#01BF86]' : 'text-[#979797]'}`}
                  >
                    {item.label}
                  </a>
                  {index < filterOptions.length - 1 && (
                    <div className="mx-2 h-[1px] bg-gray-300">|</div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <div className="mt-10">
            <SharedTable
              tableData={tableData}
              tableHeaders={tableHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              onRowSelected={onRowSelected}
              handleOnClickSort={handleOnClickSort}
              currentPageNumber={pageNumber}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MedicalHistoryList;
