import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useLazyGetAppointmentDetailsQuery,
  useLazyGetCoordinatesQuery,
  useLazyGetPatientReportsQuery
} from '../../../../reducers/allReducer';
import { apiConstants } from '../../../../constants/api';
import CommonPrescriptionView from '../common/CommonPrescriptionView';
import { useDispatch } from 'react-redux';
import { savePatientDetails } from '../../../../reducers/appointmentDetailsSlice';

const PrescriptionView = () => {
  const {appointmentId} = useParams();
  const [getAppointmentDetails] = useLazyGetAppointmentDetailsQuery();
  const [getpatientReports] = useLazyGetPatientReportsQuery();
  const [getCoordinates] = useLazyGetCoordinatesQuery();
  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [duration, setDuration] = useState();
  const [patientReports, setPatientReports] = useState([]);
  const [pacs, setPacs] = useState([]);
  const [coordinates, setCoordinates] = useState();
  const dispatch = useDispatch();

  const handleGetAppointmentDetails = () => {
    getAppointmentDetails({
      endpoint: `${apiConstants?.getAppointmentDetails}/${appointmentId}`
    }).then((result) => {
      setAppointmentDetails(result?.data?.appointment_detials?.appointment_detials);
      setPacs(result?.data?.appointment_detials?.pacs);
      getDuration(result?.data?.appointment_detials);
      dispatch(savePatientDetails({ 
        fieldName: 'patientDetails', 
        fieldValue: result?.data?.appointment_detials?.appointment_detials?.patient_details
      }));
    });
  };

  const handleGetPatientReports = () => {
    getpatientReports({
      endpoint: `${apiConstants?.getPatientReports}/${appointmentId}`
    }).then((result) => {
      setPatientReports(result?.data);
    });
  };

  const handleGetCoordinates = () => {
    getCoordinates({
      endpoint: `${apiConstants?.getCoordinates}?appointment_id=${appointmentId}`
    }).then((result) => {
      setCoordinates(result?.data[0]?.totalPoints1);
    });
  };

  useEffect(() => {
    if (appointmentId) {
     handleGetAppointmentDetails();
     handleGetPatientReports();
     handleGetCoordinates();
    }
  }, [appointmentId]);

  const getDuration = (appointmentData) => {
    const data = appointmentData?.appointment_detials?.appointment_data;
    if (data?.duration_number !== null && data?.duration_type !== null) {
      const duration = data?.duration_number + ' ' + data?.duration_type;
      setDuration(duration?.split('\n'));
    }
  };

  return (
    <div>
      <CommonPrescriptionView
        patientDetails={appointmentDetails?.patient_details}
        symptomsList={appointmentDetails?.symptoms}
        presentingIllness={appointmentDetails?.appointment_data?.presenting_illness?.split('\n')}
        diagnosis={appointmentDetails?.diagnosis}
        differentialDiagnosis={appointmentDetails?.differential_diagnosis}
        provisionalDiagnosis={appointmentDetails?.provisional_diagnosis}
        investigation={appointmentDetails?.investigation}
        referredTo={appointmentDetails?.appointment_data?.referred_to?.split('\n')}
        selfCare={appointmentDetails?.appointment_data?.prescription_instructions}
        followUpDate={appointmentDetails?.appointment_data?.followup_date}
        duration={duration}
        drugs={appointmentDetails?.drugs}
        teleExamination={appointmentDetails?.appointment_data?.tele_examination}
        patientReports={patientReports}
        vitals={appointmentDetails?.vitals}
        pacs={pacs}
        coordinates={coordinates}
      />
    </div>
  );
};

export default PrescriptionView;