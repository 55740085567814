import React, { useState, useEffect } from 'react';
import SharedButton from '../common/SharedButton';
import {
  useGetkiosksforassociationMutation,
  useUpdatekioskforuserMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import SharedTable from '../common/SharedTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from '../common/ConfirmModal';

const AssociateKiosk = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [getkiosksforassociation] = useGetkiosksforassociationMutation();
  const [updatekioskforuser] = useUpdatekioskforuserMutation();

  const [search, setSearch] = useState('');
  const [kioskList, setKioskList] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [pageNumber, setPageNumber] = useState('1');
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [kioskId, setKisokId] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [selectedKioskCode, setSelectedKioskCode] = useState(location?.state || '');
  const [msg, setMsg] = useState('');

  const { id } = useParams();

  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });

  const handleRowData = (data) => {
    setKisokId(data.id);
    setSelectedKioskCode(data?.code);
  };

  const handleOnClickSort = (header) => {
	setPageNumber(1);
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const handleSearch = (e) => {
setPageNumber(1);
    setSearch(e.target.value);
  };

  const handleAction = () => {
    updatekioskforuser({
      endpoint: apiConstants.updatekioskforuser,
      method: 'PUT',
      data: {
        kiosk_id: kioskId,
        user_id: id
      }
    }).then(() => {
      setConfirmModal(true);
      setMsg('Kiosk Updated Successfully');
    });
    setTimeout(() => {
      setConfirmModal(false);
      navigate(-1);
    }, 2000);
  };

  const fetchKioskList = (pageNumber, filter) => {
    const pageNum = pageNumber || 1;
    const type = filter || 'all';
    getkiosksforassociation({
      endpoint: search ? `${apiConstants.getkiosksforassociation}/${pageNum}/${type}/${search}` :
        `${apiConstants.getkiosksforassociation}/${pageNum}/${type}`,
      method: 'POST',
      data: {
        consult_type: '',
        current_time: '',
        data: {},
        date: '',
        field: '',
        kiosk_id: '',
        type: '',
        user_id: id
      }
    }).then((result) => {
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
setPageNumber(parseInt(result?.data?.current_page));

      let kioskList = result?.data?.list;
      const modifiedData = kioskList.map(item => ({
        ...item,
        status: item.status === 1 ?
          <div className='flex justify-start items-center gap-[5px]'>
            <div className="w-[10px] h-[10px] bg-[#808080] rounded-full"></div>
            <div>Disabled</div>
          </div> : <div className='flex justify-start items-center gap-[5px]'>
            <div className="w-[10px] h-[10px] bg-[#383678] rounded-full"></div>
            <div>Enabled</div>
          </div>,
        action: (<input type='radio' checked={item.code === selectedKioskCode} />)
      }));
      setKioskList(modifiedData);
    });
  };

  useEffect(() => {
    fetchKioskList(1);
  }, [search, sort, selectedKioskCode]);

  useEffect(() => {
  }, []);

  const patientHeaders = [
    { field: 'id', headerName: '#ID' },
    { field: 'name', headerName: 'Kiosk Name' },
    { field: 'code', headerName: 'Kiosk Code' },
    { field: 'address', headerName: 'Address' },
    { field: 'status', headerName: 'Status' },
    { field: 'action', headerName: 'Select' }
  ];

  const handleOnPageChange = (pageNumber) => {
    fetchKioskList(pageNumber);
  };
  return (
    <>
      {confirmModal &&
        <ConfirmModal
          msg={msg}
        />}
      <div className='w-full h-full flex flex-col justify-center'>
        <div className='grid grid-cols-12 px-[6%]'>
          <div className='col-span-2 flex justify-center items-center pt-[50px]'>
            <SharedButton
              className="ml-0 rounded-[20px] float-right bg-[#383678] text-white px-[25px] py-[5px]
            text-sm mt-1.5 mx-auto flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
              type="text"
              title="Back"
              onClick={() => navigate(-1)} />
          </div>

          <div className='col-span-10'>
            <div className="" style={{ float: 'right', marginTop: 10 }}>
              <div className=''>
                <div className="container-list-header">
                  <div className="search relative">
                    <input
                      type="text"
                      placeholder=" "
                      value={search}
                      autoComplete="off"
                      id="patientSearch"
                      name="patientSearch"
                      onChange={(e) => handleSearch(e)}
                      onFocus={() => setShowSearchIcon(false)}
                      onBlur={() => setShowSearchIcon(true)} />
                    <FontAwesomeIcon icon={faSearch}
                      className={`text-gray-500 absolute right-[10px] top-0 text-[20px]
                      search-icon ${showSearchIcon ? 'search-visible' : 'search-hidden'}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-12 pl-[6%] pr-[30px] pt-[30px]'>
          <div className='col-span-12 pb-[15px]'>
            <span className='underline-heading'>
              Kiosk List
            </span>
          </div>
        </div>
        <div className="grid grid-cols-12 px-[6%] pb-[30px]">
          <div className='col-span-12 mt-[20px]'>
            <SharedTable
              tableData={kioskList}
              tableHeaders={patientHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              onRowSelected={handleRowData}
              handleOnClickSort={handleOnClickSort}
currentPageNumber={pageNumber}
            />
          </div>
        </div>
        <div className='self-center'>
          <SharedButton
            className="ml-0 rounded-[20px] float-right bg-[#383678] text-white px-[25px] py-[5px]
            text-sm mt-1.5 mx-auto flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
            type="text"
            title="Save"
            onClick={() => handleAction()} />
        </div>
      </div>
    </>
  );
};

export default AssociateKiosk;
