import React, { useEffect, useState } from 'react';
import CanvasChart from './CanvasChart';

const EcgModal = ({
  handleModalClose,
  data
}) => {
  const [chartData, setChartData] = useState();
  useEffect(() => {
    if (data) {
      const tempData = data.map((value) => ({ y: value }));
      setChartData(tempData);
    }
  }, [data]);

  const options = {
    animationEnabled: true,
    theme: 'light2',
    title: {
      text: 'ECG',
      horizontalAlign: 'left',
      fontColor: '#EB0102'
    },
    subtitles: [
      {
        text: `${new Date()}`,
        horizontalAlign: 'left'
      }
    ],
    axisY: {
      gridColor: '#EB0102',
      lineColor: '#EB0102',
      tickThickness: 0,
      labelFormatter: function () {
        return '';
      }
    },
    axisX: {
      gridColor: '#EB0102',
      lineColor: '#EB0102',
      tickThickness: 0,
      labelFormatter: function () {
        return '';
      }
    },
    data: [
      {
        type: 'spline',
        color: '#383678',
        dataPoints: chartData
        
      }
    ]
  };
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-2xl"
          >
            <div className="bg-white p-3">
              <div className='flex flex-col gap-[10px]'>
                <CanvasChart options={options} />
                <div className='flex justify-end'>
                  <button 
                    className='bg-gray-500 text-white px-[15px] py-[5px] rounded-[50px] 
                      text-[13px] text-nowrap mr-[10px]'
                    onClick={() => handleModalClose()}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcgModal;
