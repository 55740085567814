import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ReactDatePicker from 'react-datepicker';
import ReactSelectFilters from '../../common/ReactSelectFilters';
import {
  useGetDoctorSlotsForDateMutation,
  useSchedulekioskappointmentMutation,
  useUpdatekioskappointmentMutation
} from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import moment from 'moment';
import * as yup from 'yup';
import { Colorcode } from '../../common/utils/Colorcode';

const customerColor = Colorcode();
const Schema = yup.object().shape({
  dateSlot: yup.string().required('Please Select Slot Date'),
  timeslot: yup.object().required('Please Select Slot time'),
  doctor: yup.object().required('Please Select Doctor'),
  complaint: yup.string().required('Please enter chief complaints here')
});

const ScheduleModal = ({
  setScheduleModal,
  handlePatient,
  rowData,
  setAction,
  action,
  setConfirmModal,
  setMsg
}) => {

  const [formVal, setFormVal] = useState({
    dateSlot: action !== '' && new Date(rowData?.opd_appointment_date) || '',
    complaint: '',
    doctor: action !== '' ? { value: rowData?.opd_doctor_id, label: rowData?.opd_doctor_name } : '',
    timeslot: rowData?.opd_appointment_time_display_value && {
      value: rowData?.opd_appointment_time_display_value,
      label: rowData?.opd_appointment_time_display_value
    } || '',
    cancelRequest: ''
  });

  useEffect(() => {
    if (action && rowData) {
      setFormVal({
        dateSlot: new Date(rowData?.opd_appointment_date),
        complaint: rowData?.opd_problem_description,
        checkbox: rowData.is_followup,
        followup_appointment_id: rowData.followup_appointment_id,
        doctor: { value: rowData?.opd_doctor_id, label: rowData?.opd_doctor_name, freeslots: [] },
        timeslot: {
          value: rowData?.opd_appointment_time_display_value,
          label: rowData?.opd_appointment_time_display_value
        },
        cancelRequest: ''
      });
    }
  }, [action, rowData]);

  const [doctorSlots] = useGetDoctorSlotsForDateMutation();
  const [schedulekioskappointment] = useSchedulekioskappointmentMutation();
  const [updatekioskappointment] = useUpdatekioskappointmentMutation();
  const [doctorOptions, setDoctorOptions] = useState(null);
  const [isDoctor, setIsDoctor] = useState(false);
  const [isTimeSlot, setIsTimeSlot] = useState(false);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);

  const cancelOption = [
    { value: 'Patient Request', label: 'Patient Request' },
    { value: 'Doctor Request', label: 'Doctor Request' },
    { value: 'Others', label: 'Others' }
  ];

  const handleOnDropdownChange = () => {
  };

  useEffect(() => {
    if (formVal?.dateSlot) {
      doctorSlots({
        endpoint: apiConstants.getDoctorSlotsForDate,
        method: 'POST',
        data: {
          current_time: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          date: moment(formVal.dateSlot).format('YYYY-MM-DD'),
          kiosk_id: localStorage.getItem('kiosk_id') ? JSON.parse(localStorage.getItem('kiosk_id')) : ''
        }
      }).then((result) => {
        const list = result?.data?.doctors;
        if (list.length > 0) {
          const transformedArray = list?.map(item => ({
            value: item.doctor_id,
            label: item.doctor_name,
            freeslots: item.freeslots
          }));
          setDoctorOptions(transformedArray);
          setIsDoctor(true);
        } else {
          setDoctorOptions([]);
          setIsDoctor(false);
          setIsTimeSlot(false);
        }
      }).catch((error) => {
        console.error('error', isTimeSlot, error);
      });
    }
  }, [formVal.dateSlot]);

  const handleSubmit = (values) => {
    if (action === '') {
      schedulekioskappointment({
        endpoint: apiConstants.schedulekioskappointment,
        method: 'POST',
        data: {
          appointment_date: moment(values?.dateSlot).format('YYYY-MM-DD'),
          appointment_time: moment(values?.timeslot?.value, 'h:mm a').format('HH:mm:ss'),
          appointment_type: 1,
          client_id: rowData?.user_id,
          doctor_id: values?.doctor?.value,
          followup_appointment_id: rowData?.followup_appointment_id,
          is_followup: values?.checkbox ? true : false,
          problem_description: values?.complaint
        }
      }).then((result) => {
        handlePatient();
        setScheduleModal(false);
        setAction('');
        setConfirmModal(true);
        setMsg('Appointment scheduled successfully');
        console.log('result', result);
      }).catch((error) => {
        console.error('error', error);
      });
      setTimeout(() => {
        setConfirmModal(false);
      }, 2000);
    } else {
      updatekioskappointment({
        endpoint: apiConstants.updatekioskappointment,
        method: 'PUT',
        data: {
          appointment_date: moment(values?.dateSlot).format('YYYY-MM-DD'),
          appointment_time: moment(values?.timeslot?.value, 'h:mm a').format('HH:mm:ss'),
          appointment_id: rowData?.opd_appointment_id,
          appointment_status: action.toLowerCase(),
          cancel_reason: values?.cancel_reason || '',
          doctor_id: values?.doctor?.value,
          problem_description: values?.complaint
        }
      }).then(() => {
        handlePatient();
        setScheduleModal(false);
        setAction('');
        setConfirmModal(true);
        setMsg('Appointment updated successfully');
      }).catch((error) => {
        console.error('error', error);
      });
      setTimeout(() => {
        setConfirmModal(false);
      }, 2000);
    }
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white">
              <div className="w-full">
                <div className='p-[20px] border-b-[1px] border-b-[#808080] flex justify-center'>
                  <span className='text-[#777] text-[22px] fw-500'>
                    {action !== '' ? 'Reschedule Appointment' :
                      rowData.followup_appointment_id ? 'Schedule Follow-Up' : 'Schedule Appointment'}</span>
                </div>
              </div>
              <div className="w-full py-[30px] px-[40px] text-[#757776]">
                <Formik
                  initialValues={formVal}
                  validationSchema={Schema}
                  enableReinitialize
                  onSubmit={handleSubmit}
                >
                  {({ values, setFieldValue }) => {

                    return <Form className='flex flex-col items-center gap-[10px] w-[80%] ml-[10%]'>
                      {rowData.followup_appointment_id && <div className='flex gap-[10px]'>
                        <Field
                          name="checkbox"
                          type='checkbox'
                          placeholder=""
                          autoComplete="off"
                          className="border border-[#464796] w-[20px]"
                        />
                        <div className='text-[#737575] text-[20px]'>Is this a follow-up appointment?</div>
                      </div>}
                      <div className='col-span-5 flex flex-col relative scheduleDate w-[100%]'>
                        <Field name="dateSlot" className='w-full relative scheduleDate'>
                          {({ field }) => (
                            <div className="">
                              <ReactDatePicker
                                {...field}
                                showIcon
                                minDate={new Date()}
                                maxDate={moment(new Date()).add(10, 'years').toDate()}
                                placeholderText='Select Appointment Date*'
                                onChange={(data) => {
                                  setFormVal({ ...formVal, dateSlot: data, doctor: '' });
                                  setFieldValue('dateSlot', data);
                                }}
                                selected={formVal?.dateSlot ? formVal?.dateSlot : new Date()}
                                toggleCalendarOnIconClick
                                showPopperArrow={false}
                                className='w-full cursor-pointer pl-[10px]'
                                value={formVal?.dateSlot}
                                icon={''}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                              />
                            </div>
                          )}
                        </Field>
                        <ErrorMessage name="dateSlot" component="div"
                          className='text-[red] text-[14px] text-center mt-2' />
                      </div>
                      {isDoctor && <div className='w-[100%] scheduleFilter'>
                        <Field name="doctor" className='w-full relative'>
                          {({ field }) => (
                            <ReactSelectFilters
                              {...field}
                              customStyles={{
                                valueContainer: provided => ({
                                  ...provided,
                                  backgroundColor: 'white',
                                  padding: '8px',
                                  height: '50px'
                                })
                              }}
                              options={doctorOptions}
                              placeholder='Select Doctor*'
                              name='doctor'
                              noDropDownIndicator={false}
                              handleDropdownInputChange={handleOnDropdownChange}
                              onChange={(data) => {
                                setFormVal({ ...formVal, doctor: data });
                                setFieldValue('doctor', data?.value);
                                setIsTimeSlot(true);
                                const tempSlot = [];
                                data?.freeslots?.map((slot) => (
                                  tempSlot.push({
                                    label: slot.display_slots,
                                    value: slot.display_slots
                                  })
                                ));
                                setSelectedTimeSlot(tempSlot);
                              }}
                              selectFieldValues={formVal.doctor}
                              isMulti={false}
                              disableEnterKey
                            />
                          )}
                        </Field>
                        <ErrorMessage name="doctor" component="div"
                          className='text-[red] text-[14px] text-center mt-2' />
                      </div>}
                      <div>
                        {values?.dateSlot && doctorOptions?.length === 0 && <div
                          className='text-[red] text-[12px]'>No Doctors Available</div>}
                      </div>
                      {formVal.doctor && <div className='w-[100%] scheduleFilter'>
                        <Field name="timeslot" className='w-full relative'>
                          {({ field }) => (
                            <ReactSelectFilters
                              {...field}
                              customStyles={{
                                valueContainer: provided => ({
                                  ...provided,
                                  backgroundColor: 'white',
                                  padding: '8px',
                                  height: '50px'
                                })
                              }}
                              options={selectedTimeSlot ? selectedTimeSlot : []}
                              placeholder='Select Slot*'
                              name='timeslot'
                              noDropDownIndicator={false}
                              handleDropdownInputChange={handleOnDropdownChange}
                              onChange={(data) => {
                                setFormVal({ ...formVal, timeslot: data });
                                setFieldValue('timeslot', data?.value);
                              }}
                              selectFieldValues={formVal.timeslot}
                              isMulti={false}
                              disableEnterKey
                            />
                          )}
                        </Field>
                        <ErrorMessage name="timeslot" component="div"
                          className='text-[red] text-[14px] text-center mt-2' />
                      </div>}
                      <Field
                        as="textarea"
                        name="complaint"
                        placeholder="Please enter chief complaints here*"
                        autoComplete="off"
                        rows="5" // Set the number of rows to limit the textarea
                        className="border border-[#A9A9A9] opacity-70 rounded-[10px]
                      outline-none w-full py-2 px-3"
                      />
                      <ErrorMessage name="complaint" component="div"
                        className='text-[red] text-[14px] text-center mt-2 mb-[25px]' />
                      {action && <>
                        {action === 'Cancelled' && <div className='w-[100%]  mb-[20px]'>
                          <div className='scheduleFilter mb-[10px]'>
                            <Field name="cancelRequest" className='w-full relative'>
                              {({ field }) => (
                                <ReactSelectFilters
                                  {...field}
                                  className='w-[50%]'
                                  options={cancelOption}
                                  placeholder='Select Request'
                                  name='cancelRequest'
                                  noDropDownIndicator={false}
                                  handleDropdownInputChange={handleOnDropdownChange}
                                  onChange={(data) => {
                                    setFormVal({ ...formVal, cancelRequest: data });
                                    setFieldValue('cancelRequest', data?.value);
                                  }}
                                  selectFieldValues={formVal.cancelRequest}
                                  isMulti={false}
                                  disableEnterKey
                                  isSearchable={false}
                                />
                              )}
                            </Field>
                            <ErrorMessage name="cancelRequest" component="div"
                              className='text-[red] text-[14px] text-center mt-2' />
                          </div>
                          {formVal.cancelRequest &&
                            <Field
                              as="textarea"
                              name="cancel_reason"
                              placeholder="Please enter appointment cancel reason*"
                              autoComplete="off"
                              rows="3" // Set the number of rows to limit the textarea
                              className="border border-[#A9A9A9] opacity-70 rounded-[10px]
                            outline-none w-full py-2 px-3"
                            />}
                        </div>}
                        <div className='flex gap-[20px]'>
                          <div className='flex gap-[5px]'>
                            <Field
                              name="status"
                              type='radio'
                              value="Pending"
                              checked={action === 'Pending'}
                              placeholder="Pending"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                              outline-none w-full py-2 px-3"
                              onChange={() => setAction('Pending')}
                            />
                            <div>Pending</div>
                          </div>
                          <div className='flex gap-[5px]'>
                            <Field
                              name="status"
                              type='radio'
                              value="Scheduled"
                              checked={action === 'Scheduled'}
                              placeholder="Scheduled"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                              outline-none w-full py-2 px-3"
                              onChange={() => setAction('Scheduled')}
                            />
                            <div>Scheduled</div>
                          </div>
                          <div className='flex gap-[5px]'>
                            <Field
                              name="status"
                              type='radio'
                              value="Cancelled"
                              checked={action === 'Cancelled'}
                              placeholder="Cancelled"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                              outline-none w-full py-2 px-3"
                              onChange={() => setAction('Cancelled')}
                            />
                            <div>Cancelled</div>
                          </div>
                        </div>
                      </>}
                      <div className='flex gap-[80px]'>
                        <button
                          type='submit'
                          className="bg-[#383678] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                        rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                          style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                        >
                          {action !== '' ? 'Update' : 'Schedule'}
                        </button>
                        <button
                          type='text'
                          className="bg-[#808080] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                        rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                          onClick={() => { setScheduleModal(false); setAction(''); }}
                        >
                          {action !== '' ? 'Close' : 'Cancel'}
                        </button>
                      </div>
                    </Form>;
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default ScheduleModal;
