import React, { useState, useEffect } from 'react';
import {
  useGetAdminKiosksListMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import CommonFilterSelect from '../common/FilterDropdown';
import Loader from '../common/Loader';
import SharedTable from '../common/SharedTable';
import { useNavigate } from 'react-router-dom';
import { Colorcode } from '../common/utils/Colorcode';

const Kiosks = () => {
  const isAdmin = localStorage.getItem('privileges') === 'admin' ? true : false;
  const navigate = useNavigate();
  const customerColor = Colorcode();
  const [getList] = useGetAdminKiosksListMutation();
  const privileges = localStorage.getItem('privileges');
  const [loading, setLoading] = useState(false);
  const [loadOnce, setLoadOnce] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [pageNumber, setPageNumber] = useState('1');
  const [filterString, setFilterString] = useState('all');
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });
  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Disabled' }
  ];
  const toggleButtons = isAdmin ? [
    { buttonName: 'View Users', url: '/admin/kiosk/users' },
    { buttonName: 'Create', url: '/admin/create/kiosk' }
  ] : [];

  if (privileges === 'admin') {
    // toggleButtons.splice(0, 0, { buttonName: 'Create', url: '/admin/create/kiosk' });
  }

  const preSelectedFilter = {
    selectedOption: 'all' // Set the initial value to 'option1'
  };

  const tableHeaders = [
    { field: 'id', headerName: '#ID' },
    { field: 'name', headerName: 'Kiosk Name' },
    { field: 'code', headerName: 'Kiosk Code' },
    { field: 'address', headerName: 'Address' },
    { field: 'customer_name', headerName: 'Customer' },
    { field: 'status', headerName: 'Status' }
  ];

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const fetchTableData = (page, status, search) => {
    if (!loadOnce) { setLoading(true); }
    getList({
      endpoint: `${apiConstants.adminKiosksList}/${page}/${status}/${search}`,
      method: 'POST',
      data: {
        consult_type: '',
        current_time: '',
        data: {},
        date: '',
        field: sort.headerFiled,
        type: sort?.sortType,
        kiosk_id: ''
      }
    }).then((result) => {
      let kiosksListData = result?.data?.list;
      const modifiedData = kiosksListData.map(item => ({
        ...item,
        status: (item.status === 1 ? <div className='flex justify-center items-center gap-[5px]'>
          <div className="w-[10px] h-[10px] bg-[green] rounded-full"></div>
          <div>Active</div>
        </div> : <div className='flex justify-center items-center gap-[5px]'>
          <div className="w-[10px] h-[10px] bg-[#808080] rounded-full"></div>
          <div>Disabled</div>
        </div>)
      }));
      setTableData(modifiedData);
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setPageNumber(parseInt(result?.data?.current_page));
      setLoading(false);
      setLoadOnce(true);
    }).catch((error) => {
      setLoading(false);
      console.error('error', error);
    });
  };

  useEffect(() => {
    fetchTableData(pageNumber, filterString, searchString);
  }, [pageNumber, filterString, searchString, sort]);

  const handleOnPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleFilterSelect = (selectedOption) => {
    setFilterString(selectedOption.value);
  };

  const onSearch = (search) => {
    setPageNumber(1);
    setSearchString(search);
  };

  const onToggleButtonClick = (url) => {
    navigate(url);
  };

  return (
    <div className="row mt-6 mx-24">
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div>
          <CommonFilterSelect
            initialValues={preSelectedFilter}
            options={filterOptions}
            onSearch={onSearch}
            toggleButtons={toggleButtons}
            toggleButtonClick={onToggleButtonClick}
            onChange={handleFilterSelect}
            showFilter
          />
          <div className="mt-7">
            <h4 style={{ borderColor: customerColor[0] }} className="text-2xl font-semibold border-b-4 
                            text-[Coolvetica Rg] list-header-text w-[125px] text-[#4f4f4f]">
              Kiosks List
            </h4>
          </div>
          <div className="mt-10">
            <SharedTable
              tableData={tableData}
              tableHeaders={tableHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              handleOnClickSort={handleOnClickSort}
              currentPageNumber={pageNumber}
              location={'kiosk_list'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Kiosks;
