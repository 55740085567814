import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sakha_Logo from '../../images/Sakha-logo.png';
import SharedButton from '../common/SharedButton';
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useResetPasswordMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';

const ChangePassword = () => {
    const navigate = useNavigate();
    const [passwordtype, setPasswordType] = useState('password');
    const [confirmPasswordtype, setConfirmPasswordtype] = useState('password');
    const location = useLocation();
    const [passwordValue, setPasswordValue] = useState('');
    const [confirmPasswordValue, setConfirmPasswordValue] = useState('');
    const [errorMsg, setErrorMsg] = useState(true);
    const [resetPassword] = useResetPasswordMutation();

    const showPassword = () => {
        if (passwordtype === 'password') {
            setPasswordType('text');
        } else if (passwordtype === 'text') {
            setPasswordType('password');
        }
    };
    const showConfirmPassword = () => {
        if (confirmPasswordtype === 'password') {
            setConfirmPasswordtype('text');
        } else if (confirmPasswordtype === 'text') {
            setConfirmPasswordtype('password');
        }
    };

    const checkPassword = (e) => {
        setPasswordValue(e.target.value);
    };

    const checkConfirmPassword = (e) => {
        setConfirmPasswordValue(e.target.value);
    };

    const checkBothPassword = () => {
        (passwordValue === confirmPasswordValue) ? setErrorMsg(false) : setErrorMsg(true);
    };

    useEffect(() => {
        if (!errorMsg) {
            resetPassword({
                endpoint: `${apiConstants.resetPassword}`,
                method: 'POST',
                data: {
                    password: confirmPasswordValue,
                    token: location?.state?.auth
                }
            }).then((result) => {
                if (result.data.success) {
                    navigate('/login');
                }
            });
        }
    }, [!errorMsg]);

    return (
        <div className='bg-cover bg-center bg-no-repeat mt-[7%]'>
            <div className="block mx-auto col-span-1/3" style={{ marginLeft: '33.33%' }}>
                <div className='login-parent-div w-[38%] row 
                bg-[#fefefe] items-center justify-center fixed top-1/2 left-1/2
        transform -translate-x-1/2 -translate-y-1/2 rounded-md p-[20px] shadow-lg hover:shadow-xl'>
                    <img onClick={() => { navigate('/'); }}
                        className='block mx-auto p-5 w-2/5 mt-5' src={Sakha_Logo} alt="Axon Logo" />
                    <div className='w-[90%] col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12'>
                        <div className='grid justify-center'>
                            <h1 className='text-3xl grid justify-center'>Reset your password</h1>
                        </div>
                        {/* password button */}
                        <Formik initialValues={{}}>
                            <Form>
                                {/* first password input */}
                                <div className='relative grid grid-cols-12 mt-6'>
                                    <Field
                                        name="password"
                                        type={passwordtype === 'password' ? 'password' : 'text'}
                                        onChange={checkPassword}
                                        placeholder="Password*"
                                        autoComplete="off"
                                        value={passwordValue}
                                        className="border border-[#A9A9A9] opacity-70
                          outline-none w-full py-2 px-3 rounded-[5px] col-span-12"
                                    />
                                    {/* eye icon */}
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <button
                                            type="button"
                                            onClick={showPassword}
                                            className="focus:outline-none"
                                        >
                                            {passwordtype === 'password' ? (
                                                // Eye icon when password is visible
                                                <FontAwesomeIcon icon={faEye} className="text-gray-500" />
                                            ) : (
                                                // Eye-slash icon when password is hidden
                                                <FontAwesomeIcon icon={faEyeSlash} className="text-gray-500" />
                                            )}
                                        </button>
                                    </div>
                                </div>
                                {/* second password input */}
                                <div className='relative grid grid-cols-12 mt-6'>
                                    <Field
                                        name="confirmpassword"
                                        type={confirmPasswordtype === 'password' ? 'password' : 'text'}
                                        onChange={checkConfirmPassword}
                                        placeholder="Confirm Password*"
                                        autoComplete="off"
                                        value={confirmPasswordValue}
                                        className="border border-[#A9A9A9] opacity-70
                          outline-none w-full py-2 px-3 rounded-[5px] col-span-12"
                                    />
                                    {/* eye icon */}
                                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                                        <button
                                            type="button"
                                            onClick={showConfirmPassword}
                                            className="focus:outline-none"
                                        >
                                            {confirmPasswordtype === 'password' ? (
                                                // Eye icon when password is visible
                                                <FontAwesomeIcon icon={faEye} className="text-gray-500" />
                                            ) : (
                                                // Eye-slash icon when password is hidden
                                                <FontAwesomeIcon icon={faEyeSlash} className="text-gray-500" />
                                            )}
                                        </button>
                                    </div>
                                </div>
                                {errorMsg && <p className='text-red-700 grid justify-center'>
                                    Password do not match</p>}
                            </Form>
                        </Formik>
                        {/* password button ends */}

                        <div className='optBtn pt-4 mb-8 mt-4'>
                            <SharedButton className="bg-[#383678] text-white px-[50px] py-[5px]
                  rounded focus:outline-none text-xl mx-auto flex" onClick={checkBothPassword}
                                type="submit" title="Reset Password" />
                        </div>
                        {/* <div className="flex justify-center text-center mt-5">
                            <a className="flex items-center text-blue-700 hover:text-blue-900 cursor-pointer">
                                <span className="font-bold">Resend OTP</span>
                                <i className='bx bx-caret-right ml-1'></i></a>
                        </div> */}
                    </div >
                </div>
            </div >
        </div >
    );
};

export default ChangePassword;