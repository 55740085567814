import { createSlice } from '@reduxjs/toolkit';

export const selectedDropdownValuesSlice = createSlice({
  name: 'selectedDropdownValues',
  initialState: {
    symptoms: [],
    investigation: [],
    differential_diagnosis: [],
    diagnosis: [],
    provisional_diagnosis: [],
    diagnosis_add_note: '',
    differential_diagnosis_add_note: '',
    presenting_illness: '',
    provisional_diagnosis_add_note: '',
    symptoms_add_note: '',
    tele_examination: '',
    drugsData: [],
    selectedDrugDataToEdit: [],
    duration_number: '',
    duration_type: '',
    vitals: {},
    ecgCoordinates: [],
    pdfId: '',
    appointmentType: '',
    selfCare: '',
    followupDate: ''
  },
  reducers: {
    updateSelectedData: (state, action) => {
      const { fieldName, fieldValue } = action.payload;
      if (Array.isArray(fieldValue)) {
        state[fieldName] = fieldValue;
      } else {
        state[fieldName].push(fieldValue);
      }
    },
    removeValueFromSelectedData: (state, action) => {
      const { fieldName, fieldValue } = action.payload;
      
      // Filter out the object with the specified id
      state[fieldName] = state[fieldName].filter(item => item.id !== fieldValue?.id);
    },
    updatFieldValue: (state, action) => {
      const { fieldName, fieldValue } = action.payload;
      state[fieldName] = fieldValue;
    },
    updateDrugsData: (state, action) => {
      if (Array.isArray(action?.payload)) {
        state.drugsData = action?.payload;
      } else {
        state.drugsData.push(action?.payload);
      }
    },
    editDrugData: (state, action) => {
      const { id, dataToReplace } = action.payload;
      const index = state?.drugsData?.findIndex(item => item.drugId === id);
      if (index !== -1) {
        state.drugsData[index] = dataToReplace;
      }
    },
    deleteDrugData: (state, action) => {
      state.drugsData = state?.drugsData?.filter((drug => drug?.drugId !== action?.payload));
    },
    setSeletedDrugDataToEdit: (state, action) => {
      state.selectedDrugDataToEdit = action?.payload;
    }
  }
});

export const {
  updateSelectedData,
  removeValueFromSelectedData,
  updatFieldValue,
  updateDrugsData,
  deleteDrugData,
  setSeletedDrugDataToEdit,
  editDrugData
} = selectedDropdownValuesSlice.actions;
export default selectedDropdownValuesSlice.reducer;
