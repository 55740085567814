import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DeleteDrugModal from './DeleteDrugModal';
import { useDeleteAppointmentDataMutation } from '../../../../../reducers/allReducer';
import { apiConstants } from '../../../../../constants/api';
import { deleteDrugData, setSeletedDrugDataToEdit } from '../../../../../reducers/selectedDropdownValuesSlice';

const PrescriptionTable = ({
  appointmentId,
  handleOnEditDrug,
  drugs,
  onlyDisplay
}) => {
  const drugsData = onlyDisplay ? drugs : useSelector((state) => state?.selectedDropdownValues?.drugsData);
  const [isDeleteDrug, setIsDeleteDrug] = useState(false);
  const [drugToDelete, setDrugToDelete] = useState();
  const dispatch = useDispatch();
  const [deleteAppointmentData] = useDeleteAppointmentDataMutation();
  let tableHeaders = ['Medicine', 'Route', 'Dosage', 'Frequency', 'Duration', 'Timings',
    'Instructions'];
  if (!onlyDisplay) {
    tableHeaders.push('Edit', 'Delete');
  }

  const displayTimings = (timings) => {
    return (
      <div className='flex gap-[5px] justify-center flex-wrap'>
        {timings?.map((data, index) => (
          <div key={index}>
            {index !== (timings?.length -1) ?
              (timings?.length < 4 ?
                (data[0] + ' ' + '|') : (data + ' ' + '|')) :
              (timings?.length < 4 ? data[0] : data)
            }
          </div>
        ))}
      </div>
    );
  };

  const displaySelectedDays = (days) => {
    return (
      <>
        {!Array.isArray(days) ? (
          <div className='truncate' title={days}>{days}</div>
        ) : (
          <div className='flex gap-[5px] justify-center flex-wrap'>
            {days?.map((data, index) => (
              <div key={index}>
                {index !== (days?.length -1) ?
                  data?.substr(0, 3) + ' ' + '|' :
                  data?.substr(0, 3)
                }
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  const handleDeleteDrugModalClose = () => {
    setIsDeleteDrug(false);
  };

  const handleDeleteDrug = () => {
    setIsDeleteDrug(false);
    deleteAppointmentData({
      endpoint: `${apiConstants?.deleteAppointmentData}/${appointmentId}`,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        id: drugToDelete?.drugId,
        type: 'drug'
      }
    }).then(() => dispatch(deleteDrugData(drugToDelete?.drugId)));
  };

  const handleEditDrug = (drug) => {
    const keysToAddLabelValue = ['daysType', 'dosage_unit', 'drug_type', 'route'];
    const tempData = {};
    Object.keys(drug)?.forEach((data) => {
      if (data === 'drugName') {
        tempData['drug_name'] = {
          label: drug?.['drugName'],
          value: drug?.['drugName'],
          name: drug?.['drugName'],
          id: drug?.drugId
        };
      } else if(keysToAddLabelValue?.includes(data)) {
        tempData[data] = { label: drug?.[data], value: drug?.[data]};
      } else {
        tempData[data] = drug?.[data];
      }
    });
    dispatch(setSeletedDrugDataToEdit(tempData));
    handleOnEditDrug();
  };

  return (
    <>
      {isDeleteDrug && (
        <DeleteDrugModal
          handleModalClose={handleDeleteDrugModalClose}
          handleDeleteDrug={handleDeleteDrug}
          drugToDelete={drugToDelete}
        />
      )}
      <table className='prescriptionTable w-full h-full table-fixed'>
        <thead>
          <tr className='bg-[#f2f2f2]'>
            {tableHeaders?.map((header, index) => (
              <th key={index} className='text-[12px] p-[10px] text-[#555] font-[600]'>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody className='text-[13px] text-[#383678] text-center'>
          {drugsData?.length > 0 ? (
            drugsData?.map((drug, index) => (
              <tr key={index}>
                <td 
                  title={!onlyDisplay && (drug?.drug_type + ' ' + drug?.drugName)} 
                  className={`${!onlyDisplay && 'truncate'}`}
                >
                  {onlyDisplay ? drug?.drug_name : (drug?.drug_type + ' ' + drug?.drugName)}
                </td>
                <td className={`${!onlyDisplay && 'truncate'}`} title={!onlyDisplay && drug?.route}>{drug?.route}</td>
                <td>{drug?.dosage}{drug?.dosage_unit}</td>
                <td>
                  {onlyDisplay ? displaySelectedDays(drug?.interval_days) :
                  displaySelectedDays(drug?.daysSelected)}
                </td>
                <td>{drug?.duration + ' ' + 'days'}</td>
                <td title={!onlyDisplay && drug?.timings}>{displayTimings(drug?.timings)}</td>
                <td title={!onlyDisplay && drug?.instructions} className={`${!onlyDisplay && 'truncate'}`}>
                  {drug?.instructions}
                </td>
                {!onlyDisplay && (
                  <>
                    <td>
                      <FontAwesomeIcon
                        icon={faEdit}
                        className='cursor-pointer'
                        onClick={() => handleEditDrug(drug)}
                      />
                    </td>
                    <td>
                      <FontAwesomeIcon
                        icon={faTrash}
                        className='cursor-pointer'
                        onClick={() => {
                          setDrugToDelete(drug);
                          setIsDeleteDrug(true);
                        }}
                      />
                    </td>
                  </>
                )}
              </tr>
            ))
          ) : (
          <div className='p-[10px] w-full'>
            <p className='text-sm text-[#777] fw-500 text-center'>No records found</p>
          </div>
          )}
        </tbody>
      </table>
    </>
  );
};

export default PrescriptionTable;
