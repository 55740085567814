const BASE_HOSTNAME = 'https://smarthealth.sakhaglobal.com';

// const BASE_HOSTNAME = 'http://localhost:3000';

const urlMiddleware = (received) => {
  let totalUrl = '';
  let totalfilter = '';
  let index = 0;

  totalUrl += `${BASE_HOSTNAME}`;
  totalUrl += received.endpoint;

  if (received.filter) {
    totalfilter += '?';
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(received.filter)) {
      const filter = String(`${key}=${value}`);
      totalfilter += filter;
      if (index < Object.keys(received.filter).length - 1) {
        totalfilter += '&';
      }
      index += 1;
    }
    totalUrl += totalfilter;
  }
  return `${totalUrl}`;
};

export default urlMiddleware;