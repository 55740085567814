import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import DoctorLandingPage from './index';
import Sidebar from './Sidebar';
import Header from '../common/Header';
import OpdAppointment from './OpdAppointment';
import PastConsults from './PastConsuts';
import PrescriptionView from './OpdAppointment/PresciptionView';
import ScheduleSlots from './SlotScheduling';
import MedicalHistoryView from './MedicalHistoryPrescriptionView/MedicalHistoryView';
import UpcomingAppointments from './UpcomingAppointments';
import UploadDocuments from './UploadDocuments';

const DoctorRoutes = () => {
  const location = useLocation();
  // to check if the current URL matches '/doctor'
  const isDoctorPage = location.pathname === '/doctor';
  const isPrescriptionPage = location.pathname.includes('/prescription');
  const user_type = 'doctor';
  let authentication = localStorage.getItem('access_token');
  let UserType = localStorage.getItem('user_type');
  if ((authentication && authentication.length < 5) && window.location.pathname === `/${user_type}`) {
    window.location.replace('/login');
  } else if ((authentication && authentication.length < 5) && window.location.pathname.length >= 56) {
    setTimeout(() => {
      window.location.replace('/login');
    }, 10);
  } else if ((authentication && authentication.length > 5) && UserType !== user_type) {
    setTimeout(() => {
      window.location.replace('/login');
    }, 10);
  }
  return (
    <div className='w-full h-full'>
      <div className='grid grid-cols-12'>
        {(isDoctorPage || isPrescriptionPage) && (
          <div className={`${isPrescriptionPage ? 'col-span-3' : 'col-span-2'}`}>
            <Sidebar />
          </div>
        )}
        <div className={`${isDoctorPage ? 'col-span-10' : (isPrescriptionPage ? 'col-span-9' : 'col-span-12')} h-full`}>
          <Header />
          <Routes>
            {authentication === null && window.location.pathname === `/${user_type}` &&
              window.location.replace('/login')
            }
            <Route path='/' element={<DoctorLandingPage />} />
            <Route path='/opdappointment/:appointmentId/:meetingID' element={<OpdAppointment />} />
            <Route path='/pastconsults' element={<PastConsults />} />
            <Route path='/prescription/:appointmentId/view' element={<PrescriptionView />} />
            <Route path='/schedule-slot' element={<ScheduleSlots />} />
            <Route path='/prescription/:appointmentId/medical-history/view' element={<MedicalHistoryView />} />
            <Route path='/upcoming-appointments' element={<UpcomingAppointments />} />
            <Route path='/upload-documents' element={<UploadDocuments />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default DoctorRoutes;