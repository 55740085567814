import React, { useState, useEffect } from 'react';
import { useGetAdminFrontDeskListMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import CommonFilterSelect from '../common/FilterDropdown';
import Loader from '../common/Loader';
import SharedTable from '../common/SharedTable';
import { useNavigate } from 'react-router-dom';
import { Colorcode } from '../common/utils/Colorcode';

const FrontDeskList = () => {
  const customerColor = Colorcode();
  const isAdmin = localStorage.getItem('privileges') === 'admin' ? true : false;
  const navigate = useNavigate();
  const [getList] = useGetAdminFrontDeskListMutation();
  const [loading, setLoading] = useState(false);
  const [loadOnce, setLoadOnce] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [pageNumber, setPageNumber] = useState('1');
  const [filterString, setFilterString] = useState('all');
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });

  const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'active', label: 'Active' },
    { value: 'logged-in', label: 'Online' },
    { value: 'inactive', label: 'Disabled' }
  ];
  const toggleButtons = isAdmin ? [
    { buttonName: 'Create', url: '/admin/create/agent' }
  ] : [];

  const preSelectedFilter = {
    selectedOption: 'all' // Set the initial value to 'option1'
  };

  const tableHeaders = [
    { field: 'user_id', headerName: 'ID' },
    { field: 'name', headerName: 'Name' },
    { field: 'email_id', headerName: 'E-Mail' },
    { field: 'phone_number', headerName: 'Phone No' },
    { field: 'customer_name', headerName: 'Customer' },
    { field: 'front_desk_status', headerName: 'Status' }
  ];

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header.field === 'name') {
      header.field = 'first_name';
    }

    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const fetchTableData = (page, status, search) => {
    if (!loadOnce) { setLoading(true); }
    getList({
      endpoint: `${apiConstants.adminFrontDeskList}/${page}/${status}/${search}`,
      method: 'POST',
      data: {
        consult_type: '',
        current_time: '',
        data: {},
        date: '',
        field: sort.headerFiled,
        type: sort?.sortType,
        kiosk_id: ''
      }
    }).then((result) => {
      let frontDeskListData = result?.data?.list;
      const modifiedData = frontDeskListData.map(item => ({
        ...item,
        name: item.first_name + ' ' + item.last_name,
        front_desk_status: (item.activity_status === 'logged-out' && item.status === 'active') ?
          <div className='flex justify-start items-center gap-[5px]'>
            <div className="w-[10px] h-[10px] bg-[red] rounded-full"></div>
            <div>Offline</div>
          </div> :
          ((item.activity_status === 'logged-out' || item.activity_status === 'logged-in') &&
            item.status === 'Inactive') ?
            <div className='flex justify-start items-center gap-[5px]'>
              <div className="w-[10px] h-[10px] bg-[#808080] rounded-full"></div>
              <div>Disabled</div>
            </div> :
            (item.activity_status === 'logged-in' && item.status === 'active') ?
              <div className='flex justify-start items-center gap-[5px]'>
                <div className="w-[10px] h-[10px] bg-[green] rounded-full"></div>
                <div>Online</div>
              </div> :
              <div className='flex justify-start items-center gap-[5px]'>
                <div className="w-[10px] h-[10px] bg-[#808080] rounded-full"></div>
                <div>Disabled</div>
              </div>
      }));
      setTableData(modifiedData);
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setPageNumber(parseInt(result?.data?.current_page));
      setLoading(false);
      setLoadOnce(true);
    }).catch(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchTableData(pageNumber, filterString, searchString);
  }, [pageNumber, searchString, sort]);

  useEffect(() => {
    fetchTableData(1, filterString, searchString);
  }, [filterString]);

  const handleOnPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleFilterSelect = (selectedOption) => {
    setFilterString(selectedOption.value);
  };

  const onSearch = (search) => {
    setPageNumber(1);
    setSearchString(search);
  };

  const onRowSelected = (event) => {
    if (isAdmin) {
      navigate(`/admin/frontdesk-detail-view/${event.user_id}`);
    }
  };

  const onToggleButtonClick = (url) => {
    navigate(url);
  };

  return (
    <div className="row mt-6 mx-24">
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div>
          <CommonFilterSelect
            initialValues={preSelectedFilter}
            options={filterOptions}
            onSearch={onSearch}
            toggleButtons={toggleButtons}
            toggleButtonClick={onToggleButtonClick}
            onChange={handleFilterSelect}
            showFilter
          />
          <div className="mt-7">
            <h4 style={{ borderColor: customerColor[0] }} className="text-2xl font-semibold border-b-4
                            text-[Coolvetica Rg] list-header-text w-[170px] text-[#4f4f4f]">
              FrontDesk List
            </h4>
          </div>
          <div className="mt-10">
            <SharedTable
              tableData={tableData}
              tableHeaders={tableHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              onRowSelected={onRowSelected}
              handleOnClickSort={handleOnClickSort}
              currentPageNumber={pageNumber}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default FrontDeskList;
