import React, { useState, useEffect } from 'react';
import {
    useGetAdminKiosksListMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import CommonFilterSelect from '../common/FilterDropdown';
import Loader from '../common/Loader';
import SharedTable from '../common/SharedTable';
import { useNavigate } from 'react-router-dom';
import { Colorcode } from '../common/utils/Colorcode';

const Kiosks = () => {

    const navigate = useNavigate();
	const customerColor = Colorcode();
    const [getList] = useGetAdminKiosksListMutation();
    // const privileges = localStorage.getItem('privileges');
    const [loading, setLoading] = useState(false);
    const [loadOnce, setLoadOnce] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isMoreData, setIsMoreData] = useState(false);
    const [totalPages, setTotalPages] = useState();
    const [pageNumber, setPageNumber] = useState('1');
    const [filterString, setFilterString] = useState('all');
    const [searchString, setSearchString] = useState('');
    const [sort, setSort] = useState({
        sortType: '',
        headerFiled: ''
    });
    const filterOptions = [
        { value: 'all', label: 'All' },
        { value: 'frontdesk', label: 'Front desk' },
        { value: 'paramedic', label: 'Paramedic' }
    ];

    const preSelectedFilter = {
        selectedOption: 'all' // Set the initial value to 'option1'
    };

    const tableHeaders = [
        { field: 'name', headerName: 'Kiosk Name' },
        { field: 'code', headerName: 'Kiosk Code' },
        { field: 'paramedic_name', headerName: 'Name' },
        { field: 'email_id', headerName: 'E-Mail' },
        { field: 'phone_number', headerName: 'Phone No' },
        { field: 'user_type_data', headerName: 'User Type' }
    ];

    const handleOnClickSort = (header) => {
		setPageNumber(1);
        if (header?.field === sort?.headerFiled) {
            setSort({
                ...sort,
                sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
            });
        } else {
            setSort({
                sortType: 'asc',
                headerFiled: header?.field
            });
        }
    };

    const fetchTableData = (page, status, search) => {
        if (!loadOnce) { setLoading(true); }
        getList({
            endpoint: `${apiConstants.getAllKioskUsersList}/${page}/${status}/${search}`,
            method: 'POST',
            data: {
                consult_type: '',
                current_time: '',
                data: {},
                date: '',
                field: sort.headerFiled,
                type: sort?.sortType,
                kiosk_id: ''
            }
        }).then((result) => {
            let kiosksListData = result?.data?.list;
            const modifiedData = kiosksListData.map(item => ({
                ...item,
                user_type_data: (item.user_type === 'agent') ? 'Front Desk' : 'Paramedic'

            }));
            setTableData(modifiedData);
            setIsMoreData(result?.data?.moreData);
            setTotalPages(result?.data?.total_pages);
			setPageNumber(parseInt(result?.data?.current_page));
            setLoading(false);
            setLoadOnce(true);
        }).catch((error) => {
            setLoading(false);
            console.error('error', error);
        });
    };

    useEffect(() => {
        fetchTableData(pageNumber, filterString, searchString);
    }, [pageNumber, filterString, searchString, sort]);

    const handleOnPageChange = (pageNumber) => {
        setPageNumber(pageNumber);
    };

    const handleFilterSelect = (selectedOption) => {
        setFilterString(selectedOption.value);
    };

    const onSearch = (search) => {
		setPageNumber(1);
        setSearchString(search);
    };

    const onToggleButtonClick = (url) => {
        navigate(url);
    };

    return (
        <div className="row mt-6 mx-24">
            {loading ? (
                <div className="flex items-center justify-center py-72">
                    <Loader />
                </div>
            ) : (
                <div>
                    <CommonFilterSelect
                        initialValues={preSelectedFilter}
                        options={filterOptions}
                        onSearch={onSearch}
                        toggleButtonClick={onToggleButtonClick}
                        onChange={handleFilterSelect}
                        showFilter
                    />
                    <div className="mt-7">
                        <h4 style={{borderColor: customerColor[0]}} className="text-2xl font-semibold border-b-4
                            text-[Coolvetica Rg] list-header-text w-[180px] text-[#4f4f4f]">
                            Kiosk User List
                        </h4>
                    </div>
                    <div className="mt-10">
                        <SharedTable
                            tableData={tableData}
                            tableHeaders={tableHeaders}
                            handleOnPageChange={handleOnPageChange}
                            isMoreData={isMoreData}
                            totalPages={totalPages}
                            handleOnClickSort={handleOnClickSort}
							currentPageNumber={pageNumber}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Kiosks;
