import React from 'react';
import { useNavigate } from 'react-router-dom';

const PatientWithSamePhoneModal = ({
  setIsSamePhone,
  samePhoneNoList
}) => {
  const navigate = useNavigate();

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-baseline sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[10px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white">
              <div className="w-full">
                <div className='p-[20px] border-b-[1px] border-b-[#d8d8d8] flex justify-center text-center'>
                  <span className='text-[#757776] text-[18px] '>
                    There are patient records in our system with the entered
                    phone number. Are you one of these patients?</span>
                </div>
              </div>
              <div className='w-full flex justify-center text-[18px] pt-[25px] px-[10px]'>
                <table className='w-full'>
                  <thead className='border-b-[1px] border-b-[#d8d8d8] text-[#555555]'>
                    <tr>
                      <th className='font-[400]'>Id</th>
                      <th className='font-[400]'>Name</th>
                      <th className='font-[400]'>Relation</th>
                      <th className='font-[400]'>Phone Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {samePhoneNoList.map((row, index) => (
                      <tr key={index} className='border-b-[1px] border-b-[#d8d8d8] h-[60px] text-[#777777]'>
                        <td>{row.client_id}</td>
                        <td>{row.first_name + ' ' + row.last_name}</td>
                        <td>{row.parent_relation}</td>
                        <td>{row.phone_number}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="w-full py-[30px] px-[40px] text-[#757776]">
                <div className='flex gap-[80px]'>
                  <button
                    type='submit'
                    className="bg-[#EFEFEF] text-[#333333] px-[50px] py-[5px] w-[100px] h-[36px] leading-[25px]
                    focus:outline-none text-[15px] mx-auto flex justify-center border border-[#333333]"
                    onClick={() => navigate('/agent')}
                  >
                    Yes
                  </button>
                  <button
                    type='text'
                    className="bg-[#808080] text-white px-[50px] py-[5px] w-[100px] h-[36px] leading-[25px]
                    focus:outline-none text-[15px] mx-auto flex justify-center"
                    onClick={() => { setIsSamePhone(false); }}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default PatientWithSamePhoneModal;
