import React, { useState, useEffect } from 'react';
import SharedButton from '../common/SharedButton';
import {
  useGetAllClientsMutation,
  useGetAvaialbleDoctorsMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import SharedTable from '../common/SharedTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSearch } from '@fortawesome/free-solid-svg-icons';
import ScheduleModal from './components/ScheduleModal';
import ConfirmModal from '../common/ConfirmModal';
import AdvanceSearchModal from './components/AdvanceSearchModal';
import { Colorcode } from '../common/utils/Colorcode';

const Agents = () => {
  const navigate = useNavigate();
  const customerColor = Colorcode();
  const [getAllClients] = useGetAllClientsMutation();
  const [getAvaialbleDoctors] = useGetAvaialbleDoctorsMutation();
  const [pageNumber, setPageNumber] = useState('1');
  const [search, setSearch] = useState('');
  const [patientList, setPatientList] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [filter, setFilter] = useState('all');
  const [scheduleModal, setScheduleModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
  const [msg, setMsg] = useState('');
  const [rowData, setRowData] = useState();
  const [action, setAction] = useState('');
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [searchFilter, setSearchFilter] = useState({
    patient: '',
    startDate: '',
    phone: '',
    doctor: '',
    endDate: ''
  });
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });
  const linksForFilter = ['All', 'New Patients', 'Revisiting', 'Scheduled'];

  const handleRowData = (data) => {
    setRowData(data);
  };

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const createPatient = () => {
    navigate('/agent/create');
  };

  const handleSearch = (e) => {
    setPageNumber(1);
    setSearch(e.target.value);
  };

  const clearSearchData = () => {
    setFilter('all');
    setSearch('');
    setSearchFilter({
      patient: '',
      startDate: '',
      phone: '',
      doctor: '',
      endDate: ''
    });
    // Use the callback function to ensure that fetchPatientList is called with the updated searchFilter
    setSearchFilter((prevSearchFilter) => {
      fetchPatientList(1, 'all', prevSearchFilter); // Pass the previous state to fetchPatientList
      return prevSearchFilter; // Return the previous state
    });
  };

  const handleChange = (type) => {
    let arr = (linksForFilter[type])?.split(' ');
    let valueToPass = arr[0];
    setFilter((valueToPass).toLowerCase());
  };

  const fetchDoctorsList = () => {
    const currentDateTime = moment();
    const formattedTime = currentDateTime.format('YYYY-MM-DD HH:mm:ss');
    const formattedDate = currentDateTime.format('YYYY-MM-DD');
    getAvaialbleDoctors({
      endpoint: apiConstants.getAvaialbleDoctors,
      method: 'POST',
      data: {
        'consult_type': '',
        'current_time': formattedTime,
        'data': {},
        'date': formattedDate,
        'field': '',
        'kiosk_id': localStorage.getItem('kiosk_id') ? JSON.parse(localStorage.getItem('kiosk_id')) : '',
        'type': ''
      }

    }).then((result) => {
      setDoctorList(result?.data?.list);
    });
  };

  const handleAction = (data) => {
    setAction(data);
    setScheduleModal(true);
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const CheckStatusColor = (item) => {
    if (item?.opd_appointment_status === 'scheduled') {
      return customerColor[0];
    } else if (new Date(moment(`${item.opd_appointment_date} ${item.opd_appointment_time_display_value}`,
      'YYYY-MM-DD hh:mm A')) < new Date()) {
      return '#ff0000';
    } else {
      return '#800000';
    }
  };

  const CheckStatus = (item) => {
    if (item?.opd_appointment_status === 'scheduled') {
      return 'Scheduled';
    } else if (new Date(moment(`${item.opd_appointment_date} ${item.opd_appointment_time_display_value}`,
      'YYYY-MM-DD hh:mm A')) < new Date()) {
      return 'Delayed';
    } else {
      return 'Pending';
    }
  };

  const fetchPatientList = (pageNumber, filter, searchedFilter) => {
    const pageNum = pageNumber || 1;
    const type = filter || 'all';
    getAllClients({
      endpoint: search ? `${apiConstants.getallclients}/${pageNum}/${type}/${search}` :
        `${apiConstants.getallclients}/${pageNum}/${type}`,
      method: 'POST',
      data: {
        consult_type: '',
        current_time: '',
        data: {
          field: sort.headerFiled,
          type: sort?.sortType,
          patient: searchedFilter?.patient || '',
          doctor: searchedFilter?.doctor || '',
          phone_number: searchedFilter?.phone || '',
          email_id: '',
          start_date: searchedFilter?.startDate && moment(searchedFilter.startDate).format('YYYY-MM-DD') || '',
          end_date: searchedFilter?.endDate && moment(searchedFilter.endDate).format('YYYY-MM-DD') || ''
        },
        field: sort.headerFiled,
        type: sort?.sortType,
        date: '',
        kiosk_id: localStorage.getItem('kiosk_id') ? JSON.parse(localStorage.getItem('kiosk_id')) : ''
      }
    }).then((result) => {
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setPageNumber(parseInt(result?.data?.current_page));

      let patientList = result?.data?.list;
      const modifiedData = patientList?.length > 0 && patientList?.map(item => ({
        ...item,
        opd_appointment_id_details: item.opd_appointment_id ? 'OPD' : '-',
        opd_appointment_date: item.opd_appointment_date === null ?
          (<button style={item.followup_appointment_id ? { background: '#ff6302' } :
            { background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
            className={`m-auto rounded-[20px] ${item.followup_appointment_id ? 'bg-[#ff6302]' :
              'bg-[#383678] bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9] '}
           text-white px-[25px] py-[5px] text-sm flex
           cursor-pointer`}
            onClick={() => setScheduleModal(true)}>Schedule</button>) :
          item.opd_appointment_date,
        opd_appointment_status_details: item.opd_appointment_status ?
          <div className='flex flex-col cursor-pointer'>
            <FontAwesomeIcon icon={faEdit}
              className='text-[20px]' style={{ color: CheckStatusColor(item) }}
              onClick={() => handleAction(capitalizeFirstLetter(item.opd_appointment_status))}
            />
            <span
              style={{ color: CheckStatusColor(item) }}
              onClick={() => handleAction(capitalizeFirstLetter(item.opd_appointment_status))}>
              {CheckStatus(item)}
            </span>
          </div> : '-'
      }));
      setPatientList(modifiedData);
    });
  };

  useEffect(() => {
    fetchPatientList(1, filter, searchFilter);
    fetchDoctorsList();
  }, [filter, search, sort]);

  useEffect(() => {
    fetchDoctorsList();
  }, []);

  const handlePatient = () => {
    fetchPatientList(1, filter, searchFilter);
  };

  const patientHeaders = [
    { field: 'user_id', headerName: 'ID' },
    { field: 'first_name', headerName: 'Name' },
    { field: 'adhar_number', headerName: 'Unique ID' },
    { field: 'opd_appointment_id_details', headerName: 'Consult Type' },
    { field: 'opd_appointment_date', headerName: 'Appointment' },
    { field: 'opd_appointment_status_details', headerName: 'Action' },
    { field: 'opd_doctor_name', headerName: 'Doctor' }
  ];

  const doctorHeaders = [
    { field: 'doctor_name', headerName: 'Available Doctors' }
  ];

  const handleOnPageChange = (pageNumber) => {
    fetchPatientList(pageNumber, filter, searchFilter);
  };

  return (
    <>
      {scheduleModal &&
        <ScheduleModal
          setScheduleModal={setScheduleModal}
          setAction={setAction}
          handlePatient={handlePatient}
          rowData={rowData}
          action={action}
          setConfirmModal={setConfirmModal}
          setMsg={setMsg}
        />}
      {confirmModal &&
        <ConfirmModal
          msg={msg}
        />}
      {isAdvanceSearch &&
        <AdvanceSearchModal
          setIsAdvanceSearch={setIsAdvanceSearch}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          fetchPatientList={fetchPatientList}
        />}
      <div className='w-full h-full'>
        <div className='grid grid-cols-12 px-[50px]'>

          <div className='col-span-2 flex justify-center items-center pt-[50px]'>
            <SharedButton
              className="ml-0 rounded-[20px] float-right bg-[#383678] text-white px-[25px] py-[5px]
            text-sm mt-1.5 mx-auto flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
              type="text"
              title="Create"
              onClick={() => createPatient()} />
          </div>

          <div className='col-span-10'>
            <div className="" style={{ float: 'right', marginTop: 10 }}>
              <div className=''>

                <div className="container-list-header">

                  <div className="search relative mb-2">
                    <input
                      type="text"
                      placeholder=" "
                      value={search}
                      autoComplete="off"
                      id="patientSearch"
                      name="patientSearch"
                      onChange={(e) => handleSearch(e)}
                      onFocus={() => setShowSearchIcon(false)}
                      onBlur={() => setShowSearchIcon(true)} />
                    <FontAwesomeIcon icon={faSearch}
                      className={`text-gray-500 absolute right-[10px] top-0 text-[20px]
                      search-icon ${showSearchIcon ? 'search-visible' : 'search-hidden'}`} />
                    {/* <div>
                    <svg>
                      <use xlinkHref="#path" />
                    </svg>
                  </div> */}
                  </div>
                  {/* <svg xmlns="axon" style={{ display: 'none' }}>
                  <symbol xmlns="axon" viewBox="0 0 160 28" id="path">
                    <path d="M32.9418651,-20.6880772 C37.9418651,-20.6880772 40.9418651,-16.6880772 40.9418651,
                    -12.6880772 C40.9418651,-8.68807717 37.9418651,-4.68807717 32.9418651,-4.68807717 C27.9418651,
                    -4.68807717 24.9418651,-8.68807717 24.9418651,-12.6880772 C24.9418651,-16.6880772 27.9418651,
                    -20.6880772 32.9418651,-20.6880772 L32.9418651,-29.870624 C32.9418651,-30.3676803 33.3448089,
                    -30.770624 33.8418651,-30.770624 C34.08056,-30.770624 34.3094785,-30.6758029 34.4782612,
                    -30.5070201 L141.371843,76.386562"
                      transform="translate(83.156854, 22.171573) rotate(-225.000000) translate(-83.156854, -22.171573)">
                    </path>
                  </symbol>
                </svg> */}

                </div>
              </div>
              {/* {Consult_Type.includes('1') && */}
              <label style={{ fontFamily: 'Poppins', fontSize: 15, color: '#737575', marginTop: 25, fontWeight: 600 }} >
                Need more specific results?<br />
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setIsAdvanceSearch(true)}>
                  Try Advanced Search
                </span>
                <br />
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => clearSearchData()}>Clear Filter</span>
              </label>
              {/* } */}
            </div>
          </div>
        </div>

        <div className='grid grid-cols-12 pl-[70px] pr-[30px] pt-[30px]'>
          <div className='col-span-12 pb-[15px]'>
            <span className='underline-heading' style={{ borderColor: customerColor[0] }}>
              Patient List
            </span>
          </div>
          <div className='col-span-12'>
            {Object.keys(linksForFilter)?.map((item, index) => {
              let styleSheet = {
                color: '#979797'
              };
              if (filter === (linksForFilter[item]).toLowerCase()) {
                styleSheet = {
                  color: customerColor[0]
                };
              } else if (filter === 'new' && linksForFilter[item] === 'New Patients') {
                styleSheet = {
                  color: customerColor[0]
                };
              }
              return (
                <span key={index}>
                  <a
                    style={styleSheet}
                    onClick={() => handleChange(item)}
                    className={`hover:cursor-pointer px-[10px] text-sm
                  ${filter === (linksForFilter[item]).toLowerCase() && 'underline'}
                  ${filter === 'new' && linksForFilter[item] === 'New Patients' && 'underline'}`}>
                    {linksForFilter[item]}
                  </a>
                  {index !== Object.keys(linksForFilter).length - 1 && <span className='text-[#979797]'> | </span>}
                </span>
              );
            })}
          </div>

        </div>
        <div className="grid grid-cols-12 p-[30px]">
          <div className='col-span-10 mt-[10px]'>
            <SharedTable
              tableData={patientList}
              tableHeaders={patientHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              onRowSelected={handleRowData}
              handleOnClickSort={handleOnClickSort}
              currentPageNumber={pageNumber}
              location="patient_list"
            />
          </div>
          <div className='col-span-2 mt-[10px] px-[20px]'>
            <SharedTable
              tableData={doctorList}
              tableHeaders={doctorHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              handleOnClickSort={handleOnClickSort}
              isPagination
              location="doctor_list"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Agents;
