import { createSlice } from '@reduxjs/toolkit';

export const appointmentDetailsSlice = createSlice({
  name: 'appointmentDetails',
  initialState: {
    patientDetails: []
  },
  reducers: {
    savePatientDetails: (state, action) => {
      const { fieldValue, fieldName } = action.payload;
      state[fieldName] = fieldValue;
    }
  }
});

export const {
 savePatientDetails
} = appointmentDetailsSlice.actions;
export default appointmentDetailsSlice.reducer;
