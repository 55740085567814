import React, { useEffect, useState } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import { awsdetails } from '../../../../constants/awsdetails';
import PacsModal from './PacsModal';
import { Colorcode } from '../../../common/utils/Colorcode';

const customerColor = Colorcode();
const Pacs = ({ vitalsData }) => {
  const [pacsData, setPacsData] = useState({
    stethData: [],
    otoscopeImages: []
  });
  const [isOtoscopeModalOpen, setIsOtoscopeModalOpen] = useState(false);

  const handleSetPacsData = () => {
    let tempStethData = [], tempOtoscopeImages = [];
    vitalsData?.pacs?.map((pac) => {
      if (pac?.device_id === 11) {
        tempStethData.push(pac);
      } else if (pac?.device_id === 8) {
        const obj = {
          src: `${awsdetails.awsUrl}/` + pac.attachment_name,
          thumbnail: `${awsdetails.awsUrl}/` + pac.attachment_name,
          width: 1200,
          height: 1200,
          description: pac.attachment_description
        };
        tempOtoscopeImages.push(obj);
      }
    });
    setPacsData({
      stethData: tempStethData,
      otoscopeImages: tempOtoscopeImages
    });
  };

  useEffect(() => {
    if (vitalsData?.pacs?.length > 0) {
      handleSetPacsData();
    }
  }, [vitalsData]);

  const handleOpenOtoscopeModal = () => {
    setIsOtoscopeModalOpen(true);
  };

  return (
    <>
      {isOtoscopeModalOpen && (
        <PacsModal data={pacsData?.otoscopeImages} handleModalClose={() => setIsOtoscopeModalOpen(false)} />
      )}
      <table className='prescriptionTable w-full h-full'>
        <thead>
          <tr className='bg-[#f2f2f2] grid grid-cols-12'>
            <th className='text-[12px] p-[10px] text-[#555] text-start col-span-6'>Device Name</th>
            <th className='text-[12px] p-[10px] text-[#555] text-start col-span-6'>Document</th>
          </tr>
        </thead>
        <tbody className='text-[12px] text-[#333]'>
          {pacsData?.stethData?.length > 0 && (
            <>
              {pacsData?.stethData?.map((data, index) => (
                <tr key={index} className='grid grid-cols-12 items-end grid-flow-row'>
                  <td className='col-span-4'>{data?.name}</td>
                  <td className='col-span-8'>
                    <ReactAudioPlayer
                      src={`${awsdetails.awsUrl}/` + data.attachment_name}
                      controls
                      style={{
                        width: '100%',
                        height: 19
                      }}
                    />
                  </td>
                </tr>
              ))}
            </>
          )}
          {pacsData?.otoscopeImages?.length > 0 && (
            <tr className='grid grid-cols-12 items-center'>
              <td className='col-span-4'>Otoscope</td>
              <td className='cursor-pointer underline col-span-8 text-center'>
              <button
                type='button'
                className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px]'
                style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
                onClick={() => handleOpenOtoscopeModal()}
                >
                  Preview
                </button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default Pacs;
