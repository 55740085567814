import React, { useEffect, useState } from 'react';
import {
  useLazyGetAppointmentTypeQuery,
  useLazyGetMedicalCertificateDetailsQuery,
  useSendMedicalCertificateMutation
} from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import { useParams } from 'react-router-dom';
import MedicalCertificateModal from './MedicalCertificate/MedicalCertificateModal';
import MedicalCertifiateForm from './MedicalCertificate/MedicalCertificateForm';
import MedicalCertificatePreview from './MedicalCertificate/MedicalCertificatePreview';
import MedicalCertificateGeneratedModal from './MedicalCertificate/MedicalCertificateGeneratedModal';
import { Colorcode } from '../../common/utils/Colorcode';

const customerColor = Colorcode();
const AppointmentType = () => {
  const [getAppointmentType] = useLazyGetAppointmentTypeQuery();
  const [getMedicalCertificateDetails] = useLazyGetMedicalCertificateDetailsQuery();
  const [sendMedicalCertificate] = useSendMedicalCertificateMutation();
  const {appointmentId} = useParams();
  const [appointmentType, setAppointmentType] = useState();
  const [problemDesc, setProblemDesc] = useState();
  const [isMedicalCertificateModalOpen, setIsMedicalCertificateModalOpen] = useState(false);
  const [isMedicalCertificatePreviewModalOpen, setIsMedicalCertificatePreviewModalOpen] = useState(false);
  const [isMedicalCertificateGeneratedModalOpen, setIsMedicalCertificateGeneratedModalOpen] = useState(false);
  const [certificateDetails, setCertificateDetails] = useState();

  const getAppointmentTypeAndProblem = () => {
    getAppointmentType({
      endpoint: `${apiConstants?.getAppointmentType}/${appointmentId}`
    }).then((result) => {
      if (result?.data?.is_followup === 1) {
        setAppointmentType('Follow up');
      } else {
        setAppointmentType('New');
      }
      if (result?.data?.problem_description?.length > 175) {
        setProblemDesc(result?.data?.problem_description?.substring(0,174) + '...');
      } else {
        setProblemDesc(result?.data?.problem_description);
      }
    });
  };

  useEffect(() => {
    getAppointmentTypeAndProblem();
  }, []);

  const handleGetMedicalCertificateDetails = () => {
    getMedicalCertificateDetails({
      endpoint: `${apiConstants.getMedicalCertificateDetails}/${appointmentId}`
    }).then((result) => {
      setCertificateDetails(result?.data?.certificate_details);
      setIsMedicalCertificateModalOpen(true);
    });
  };

  const handleModalClose = () => {
    setIsMedicalCertificateModalOpen(false);
    setIsMedicalCertificatePreviewModalOpen(false);
  };

  const handlePreviewButtonClick = () => {
    setIsMedicalCertificateModalOpen(false);
    setIsMedicalCertificatePreviewModalOpen(true);
  };

  const handleOnSuccessCertificateGeneration = () => {
    handleModalClose();
    setIsMedicalCertificateGeneratedModalOpen(true);
  };

  const handleGenerateCertificate = () => {
    sendMedicalCertificate({
      endpoint: apiConstants.sendMedicalCertificate,
      method: 'POST',
      data: {
        appointment_id: appointmentId,
        type: 'medical'
      }
    }).then((result) => {
      if (result && result?.data) {
        handleOnSuccessCertificateGeneration();
      }
    });
  };

  const handleCertificateGeneratedModalClose = () => {
    setIsMedicalCertificateGeneratedModalOpen(false);
  };

  return (
    <>
      {isMedicalCertificateModalOpen && (
        <MedicalCertificateModal
          heading='Medical Certificate'
          handleModalClose={handleModalClose}
          buttonName='Preview'
          handleButtonClick={handlePreviewButtonClick}
        >
          <MedicalCertifiateForm
            certificateDetails={certificateDetails}
            handleModalClose={handleModalClose}
            appointmentId={appointmentId}
          />
        </MedicalCertificateModal>
      )}
      {isMedicalCertificatePreviewModalOpen && (
        <MedicalCertificateModal
          heading='Medical Certificate Preview'
          handleModalClose={handleModalClose}
          buttonName='Generate Certificate'
          handleButtonClick={handleGenerateCertificate}
          justifyEnd
        >
          <MedicalCertificatePreview
            certificateDetails={certificateDetails}
          />
        </MedicalCertificateModal>
      )}
      {isMedicalCertificateGeneratedModalOpen && (
        <MedicalCertificateGeneratedModal
          handleButtonClick={handleCertificateGeneratedModalClose}
        />
      )}
      <div className='flex flex-col gap-[10px] text-[14px]'>
        <div className='flex justify-between'>
          <div className='flex items-center'>
            <p className='font-semibold text-sm text-[#000]'>Appointment ID:</p>
            <p className='pl-[10px]'>{appointmentId}</p>
          </div>
          <div className='flex items-center'>
            <p className='font-semibold'>Type:</p>
            <p className='pl-[10px]'>{appointmentType}</p>
          </div>
        </div>
        <div className='flex justify-between pb-[20px]'>
          <div className='flex items-center'>
            <p className='font-semibold'>Chief Complaints:</p>
            <p className='pl-[10px]'>{problemDesc}</p>
          </div>
          <div className='cursor-pointer' onClick={() => handleGetMedicalCertificateDetails()}>
            <p className='underline font-semibold text-[15px]' style={{color: customerColor[0]}}>Medical Certificate</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentType;
