import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sakha_Logo from '../../images/Sakha-logo.png';
// import InputElement from '../common/InputElement';
import 'react-phone-input-2/lib/style.css';
import { useVerifyOtpMutation } from '../../reducers/allReducer';
import OtpInput from 'react-otp-input';
import SharedButton from '../common/SharedButton';
import { apiConstants } from '../../constants/api';

const OtpModule = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const phone = location.state.phone;
    const [otp, setOtp] = useState('');
    const [verifyOtpDetails] = useVerifyOtpMutation();

    const verifyOtp = () => {
        verifyOtpDetails({
            endpoint: `${apiConstants.verifyOtp}`,
            method: 'POST',
            data: {
                otp: otp,
                phone_number: phone
            }
        }).then((result) => {
            if (result?.data?.success) {
                navigate('/resetpassword', { state: result.data });
            }
        });
    };
    return (
        <div className='bg-cover bg-center bg-no-repeat mt-[7%]'>
            <div className="block mx-auto col-span-1/3" style={{ marginLeft: '33.33%' }}>
                <div className='login-parent-div w-[38%] row 
                bg-[#fefefe] items-center justify-center fixed top-1/2 left-1/2
        transform -translate-x-1/2 -translate-y-1/2 rounded-md p-[20px] shadow-lg hover:shadow-xl'>
                    <img onClick={() => { navigate('/'); }}
                        className='block mx-auto p-5 w-2/5 mt-5' src={Sakha_Logo} alt="Axon Logo" />
                    <div className='w-[90%] col-span-12 sm:col-span-12 md:col-span-12 lg:col-span-12'>
                        <div className='grid justify-center'>
                            <h1 className='text-3xl grid justify-center'>OTP Sent</h1>
                            <p className='text-xl grid justify-center mt-4'>Enter OTP sent on your</p>
                            <p className='text-xl grid justify-center'>register Email ID</p>
                        </div>
                        <div id="otp" className="flex flex-row justify-center text-center px-2 mt-5">
                            <OtpInput
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                renderSeparator={<span>-</span>}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>
                        <div className='optBtn pt-4 mb-8 mt-4'>
                            <SharedButton className="bg-[#383678] text-white px-[50px] py-[5px]
                  rounded focus:outline-none text-xl mx-auto flex"
                                onClick={verifyOtp} type="submit" title="Verify OTP" />
                        </div>
                        {/* <div className="flex justify-center text-center mt-5">
                            <a className="flex items-center text-blue-700 hover:text-blue-900 cursor-pointer">
                                <span className="font-bold">Resend OTP</span>
                                <i className='bx bx-caret-right ml-1'></i></a>
                        </div> */}
                    </div >
                </div>
            </div >
        </div >
    );
};

export default OtpModule;