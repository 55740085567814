import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Select from 'react-select';

const ReactSelectFilters = ({
  name,
  options,
  onChange,
  placeholder,
  noDropDownIndicator,
  isClearable,
  customStyles,
  handleDropdownInputChange,
  selectFieldValues,
  noOptionMessage,
  isMulti,
  menuListMaxHeight,
  disableEnterKey,
  getOptionLabel,
  controlShouldRenderValue,
  isSearchable,
  isDisabled,
  displaySearchIcon,
  fullBorder,
  containerBackground
}) => {
  // eslint-disable-next-line no-unused-vars

  const defaultCustomStyles = {
    valueContainer: provided => ({
      ...provided,
      minHeight: '44px',
      padding: '0px',
      backgroundColor: containerBackground || 'white'
    }),
    multiValueGeneric: (provided) => ({
      ...provided,
      fontSize: '12px',
      fontFamily: 'Jost',
      fontWeight: '400',
      color: '#7D7D7D'
    }),
    singleValue: provided => ({
      ...provided,
      top: '60%',
      color: '#7D7D7D',
      fontSize: '14px'
    }),
    menu: provided => ({
      ...provided,
      zIndex: 100,
      overflow: 'scroll',
      '::-webkit-scrollbar': {
        width: '0px',
        height: '0px'
      }
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: (noDropDownIndicator || isDisabled) ? 'none' : ''
    }),
    control: provided => ({
      ...provided,
      boxShadow: 'none',
      borderColor: '#D9D9D9',
      backgroundColor: containerBackground || '#f4f4f4',
      borderTop: !fullBorder && 'none', // Remove border from the top
      borderLeft: !fullBorder && 'none', // Remove border from the left
      borderRight: !fullBorder && 'none', // Remove border from the right
      '&:hover': {
        borderColor: '#D9D9D9'
      },
      cursor: isMulti ? 'text' : 'pointer'
    }),
    option: (provided) => ({
      ...provided,
      backgroundColor: 'white',
      color: '#333333', // Change color to red if selected

      '&:hover': {
        backgroundColor: '#f1f1f1'
      },
      '&:active': {
        backgroundColor: '#f1f1f1'
      },
      cursor: 'pointer', // added the cursor pointer to the options
      zIndex: 100,
      overflow: 'auto',
      fontSize: '13px'
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: menuListMaxHeight || '150px',
      overflow: 'auto',
      // added the scrollbar to the dropDown options
      '::-webkit-scrollbar': {
        width: '0px',
        height: '0px'
      }
    }),
    multiValue: (provided) => ({
      ...provided,
      height: '24px',
      backgroundColor: '#F0F0F0',
      // borderRadius: '100px',
      fontSize: '12px',
      fontFamily: 'Jost',
      fontWeight: '400',
      paddingLeft: '4px',
      paddingRight: '6px',
      color: '#7D7D7D !important',
      alignItems: 'center'
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'transparent',
        color: '#7D7D7D'
      },
      color: '#7D7D7D'
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: 'black',
      textAlign: 'left',
      fontWeight: '400',
      fontFamily: 'Jost',
      fontSize: '12px'
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '16px'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      backgroundColor: containerBackground || 'white'
    })
  };

  const onPlatformSelect = (platform) => {
    onChange && onChange(platform);
    // helpers.setValue(platform);
  };

  // Handle Enter key event
  const handleKeyDown = (event) => {
    if (disableEnterKey && event?.key === 'Enter') {
      event?.preventDefault();
    }
  };

  const getDefaultLabel = (option) => {
    return option?.label;
  };

  return (
    <div className={`flex min-w-[90px] lg:text-[12px] xl:text-[14px] 2xl:text-[16px] 
      whitespace-nowrap items-center customSelectCSS ' ${isDisabled && 'cursor-not-allowed'}`}>
      <Select
        menuPlacement='auto'
        name={name}
        value={selectFieldValues || null}
        onChange={(value) => onPlatformSelect(value)}
        options={options}
        isSearchable={isSearchable}
        placeholder={placeholder}
        styles={customStyles ? { ...defaultCustomStyles, ...customStyles } : defaultCustomStyles}
        isClearable={isClearable || false}
        className=' border-[#D9D9D9] w-full mx-[10px]'
        isMulti={isMulti}
        onInputChange={(e) => handleDropdownInputChange && handleDropdownInputChange(e)}
        noOptionsMessage={() => noOptionMessage || 'No options'}
        openMenuOnClick={!noOptionMessage}
        onKeyDown={handleKeyDown}
        getOptionLabel={(option) => getOptionLabel ? getOptionLabel(option) : getDefaultLabel(option)}
        controlShouldRenderValue={controlShouldRenderValue}
        isDisabled={isDisabled}
        backspaceRemovesValue={false}
      />
      {displaySearchIcon && (
        <FontAwesomeIcon icon={faSearch} color='#919191' />
      )}
    </div>
  );
};
export default ReactSelectFilters;
