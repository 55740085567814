import React from 'react';
import PatientDetailBox from '../common/PatientDetailBox';
import SearchComponent from '../common/SearchComponent';
import { apiConstants } from '../../../../constants/api';
import { useLazySearchDiagnosisListQuery } from '../../../../reducers/allReducer';
import Reports from './Reports';

const Diagnosis = ({ appointmentId }) => {
  const [searchDiagnosisList] = useLazySearchDiagnosisListQuery();
  return (
    <div className='grid grid-cols-12 gap-[30px]'>
      <div className='col-span-6'>
        <PatientDetailBox title='Differential Diagnosis'>
          <SearchComponent 
            placeholder='Search'
            dropdownName='differentialDiagnosisDropdown'
            appointmentId={appointmentId}
            addAppointmentType='differential_diagnosis'
            saveNoteType='differential_diagnosis_add_note'
            endpointForDataSearch={apiConstants.searchDiagnosisList}
            hookForApiCall={searchDiagnosisList}
            fieldNameForSearchApi='diagnosisList'
          />
        </PatientDetailBox>
      </div>
      <div className='col-span-6'>
        <PatientDetailBox title='Diagnosis'>
          <SearchComponent 
            placeholder='Search'
            dropdownName='diagnosisDropdown'
            appointmentId={appointmentId}
            addAppointmentType='diagnosis'
            saveNoteType='diagnosis_add_note'
            endpointForDataSearch={apiConstants.searchDiagnosisList}
            hookForApiCall={searchDiagnosisList}
            fieldNameForSearchApi='diagnosisList'
          />
        </PatientDetailBox>
      </div>
      <div className='col-span-6'>
        <PatientDetailBox title='Provisional Diagnosis'>
          <SearchComponent 
            placeholder='Search'
            dropdownName='provisionalDiagnosisDropdown'
            appointmentId={appointmentId}
            addAppointmentType='provisional_diagnosis'
            saveNoteType='provisional_diagnosis_add_note'
            endpointForDataSearch={apiConstants.searchDiagnosisList}
            hookForApiCall={searchDiagnosisList}
            fieldNameForSearchApi='diagnosisList'
          />
        </PatientDetailBox>
      </div>
      <div className='col-span-6'>
        <PatientDetailBox title='Reports'>
          <Reports />
        </PatientDetailBox>
      </div>
    </div>
  );
};

export default Diagnosis;