/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ProfilePic from '../../../images/general_pic.png';
import ReactSelectFilters from '../../common/ReactSelectFilters';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import {
  CountryDropdown,
  RegionDropdown
} from 'react-country-region-selector';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import {
  useCreatePatientMutation,
  useLazyGetConceptBloodGroupQuery,
  useLazyGetallmedicaldepartmentsQuery,
  useLazyGetpatientswithsamephoneQuery
} from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import PatientWithSamePhoneModal from './PatientWithSamePhoneModal';
import UploadPhotoModal from './UploadPhotoModal';

const Schema = yup.object().shape({
  title: yup.string().required('Please Select title'),
  first_name: yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid first name')
    .max(40).required('Please Enter First Name Without Empty Spaces & Numbers'),
  last_name: yup.string()
    .matches(/^[A-Za-z ]*$/, 'Please enter valid last name')
    .max(40).required('Please Enter Last Name Without Empty Spaces & Numbers'),
  phone: yup.string().min(13, 'Please enter a valid phone number').max(13, 'Please enter a valid phone number').required('Please Enter a Valid Phone Number'),
  password: yup.string().required('Please enter password atleast 8 digits, Password Should Contain One Number,Upper Case, Lower Case & Special Character')
    .min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
  email_id: yup.string().email('Must be a valid email').max(255).required('Email is required'),
  dateOfBirth: yup.string().required('DOB is required'),
  gender: yup.string().required('Gender is required'),
  maritalStatus: yup.string().required('Marital Status is required'),
  bloodGroup: yup.string().required('Blood Group is required'),
  address_line_1: yup.string().required('Address is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  city: yup.string().required('City is required'),
  pincode: yup.string().min(6, 'Please enter 6 digit').max(6, 'Please enter 6 digit').required('Please Enter Valid Pincode')
});

function Register() {
  const [formVal, setFormVal] = useState({
    title: '',
    phone: '',
    dob: '',
    gender: '',
    maritalStatus: '',
    bloodGroup: '',
    department: '',
    country: '',
    state: '',
    checkbox: ''
  });

  const navigate = useNavigate();
  const [createPatient] = useCreatePatientMutation();
  const [getConceptBloodGroup] = useLazyGetConceptBloodGroupQuery();
  const [getallmedicaldepartments] = useLazyGetallmedicaldepartmentsQuery();
  const [getpatientswithsamephone] = useLazyGetpatientswithsamephoneQuery();
  const [bloodOptions, SetBloodOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [samePhoneNoList, setSamePhoneNoList] = useState([]);
  const [isSamePhone, setIsSamePhone] = useState(false);
  const [isUploadPhoto, setIsUploadPhoto] = useState(false);
  const [imgUrl, setImgUrl] = useState('');

  const [passwordType, setPassowrdType] = useState('password');

  const options = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Sh', label: 'Sh' },
    { value: 'Smt', label: 'Smt' },
    { value: 'Dr', label: 'Dr' },
    { value: 'B/O', label: 'B/O' }
  ];

  const GenderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
    { value: 'Other', label: 'Other' }
  ];

  const MaritalOptions = [
    { value: 'Single', label: 'Single' },
    { value: 'Married', label: 'Married' },
    { value: 'Divorced', label: 'Divorced' },
    { value: 'Widowed', label: 'Widowed' }
  ];

  const relationOptions = [
    { value: 'Father', label: 'Father' },
    { value: 'Mother', label: 'Mother' },
    { value: 'Son', label: 'Son' },
    { value: 'Daughter', label: 'Daughter' },
    { value: 'Husband', label: 'Husband' },
    { value: 'Wife', label: 'Wife' },
    { value: 'Brother', label: 'Brother' },
    { value: 'Sister', label: 'Sister' }
  ];

  const handleOnDropdownChange = () => {
  };

  const privacypolicy = () => {
    window.open('https://smarthealth.sakhaglobal.com/privacypolicy');
  };

  const termsandconditions = () => {
    window.open('https://smarthealth.sakhaglobal.com/termsandconditions');
  };

  useEffect(() => {
    getConceptBloodGroup({
      endpoint: apiConstants.getConceptBloodGroup
    }).then((result) => {
      const list = result?.data.conceptList;
      if (list.length > 0) {
        const transformedArray = list.map(item => ({
          value: item.concept_id,
          label: item.name
        }));
        SetBloodOptions(transformedArray);
      }
    }).catch((error) => {
      console.error('error', error);
    });
    getallmedicaldepartments({
      endpoint: apiConstants.getallmedicaldepartments
    }).then((result) => {
      const list = result?.data;
      if (list.length > 0) {
        const transformedArray = list.map(item => ({
          value: item.id,
          label: item.name
        }));
        setDepartmentOptions(transformedArray);
      }
    }).catch((error) => {
      console.error('error', error);
    });
  }, []);

  const handleSubmit = (values) => {
    createPatient({
      endpoint: apiConstants.createPatient,
      method: 'POST',
      data: {
        data: {
          address_line_1: values.address_line_1,
          address_line_2: values.address_line_2,
          adhar_number: values.uniqueID,
          allergies: values.allergies,
          allergies_concept_id: null,
          blood_group: formVal.bloodGroup.label,
          blood_group_concept_id: formVal.bloodGroup.value,
          city: values.city,
          country: values.country,
          customer_id: localStorage.getItem('customer_id') && JSON.parse(localStorage.getItem('customer_id')),
          dob: moment(values.dateOfBirth).format('YYYY-MM-DD'),
          email_id: values.email_id,
          first_name: values.first_name,
          gender: values.gender,
          last_name: values.last_name,
          marital_status: values.maritalStatus,
          medical_department_id: 1,//values.department,
          parent_relation: values.relation,
          password: values.password,
          patient_reference_id: '',
          phone_number: values.phone,
          pincode: values.pincode,
          profile_photo: imgUrl,
          state: values.state,
          title: values.title,
          user_id: JSON.parse(localStorage.getItem('user_id'))
        },
        step: 'demography'
      }
    }).then((data) => {
      if (data?.data) {
        navigate('/agent');
      } else {
        console.log('error');
      }
    }).catch((error) => {
      console.log('error', error);
    });
  };

  useEffect(() => {
    getpatientswithsamephone({
      endpoint: `${apiConstants.getpatientswithsamephone}/${formVal.phone}`
    }).then((result) => {
      setSamePhoneNoList(result?.data);
      if (result?.data?.length > 0) {
        setIsSamePhone(true);
      }
    }).catch((error) => {
      console.error('error', error);
    });
  }, [formVal.phone]);

  const showPassword = () => {
    if (passwordType === 'password') {
      setPassowrdType('text');
    } else if (passwordType === 'text') {
      setPassowrdType('password');
    }
  };

  return (
    <div className='my-[2%] mx-[5%]'>
      {isSamePhone &&
        <PatientWithSamePhoneModal
          setIsSamePhone={setIsSamePhone}
          samePhoneNoList={samePhoneNoList}
        />}
      {isUploadPhoto &&
        <UploadPhotoModal
          setIsUploadPhoto={setIsUploadPhoto}
          isUploadPhoto={isUploadPhoto}
          setImgUrl={setImgUrl}
        />}
      <div className='bg-[#f4f4f4] flex flex-col items-center rounded-md'>
        <div className='text-[#737575] text-[60px] mt-[30px] font-[600]'>Patient Registration</div>
        <Formik
          initialValues={{
            title: '',
            first_name: '',
            last_name: '',
            phone: '',
            password: '',
            dateOfBirth: '',
            gender: '',
            maritalStatus: '',
            bloodGroup: '',
            address_line_1: '',
            country: '',
            state: '',
            city: '',
            pincode: ''
          }}
          validationSchema={Schema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values }) => (
            <Form className='flex flex-col items-center gap-[50px] mt-[30px]'>
              <div className='flex gap-[100px] justify-between w-[100%] px-[100px] createUser'>
                <div className='flex flex-col w-[40%] gap-[15px]'>
                  <div className='flex gap-[15px]'>
                    <img
                      title="Click to change profile pic"
                      className="reg-user-icon"
                      src={imgUrl ? imgUrl : ProfilePic}
                      onClick={() => setIsUploadPhoto(true)}
                    />
                    <div className='flex flex-col gap-[15px]'>
                      <div className='flex items-baseline'>
                        <div>
                          <Field name="title" className='w-full'>
                            {({ field }) => (
                              <ReactSelectFilters
                                {...field}
                                customStyles={{
                                  valueContainer: provided => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4',
                                    padding: '8px',
                                    height: '50px',
                                    width: '100px'
                                  })
                                }}
                                options={options}
                                placeholder='Title*'
                                name='title'
                                noDropDownIndicator={false}
                                handleDropdownInputChange={handleOnDropdownChange}
                                onChange={(data) => {
                                  setFormVal({ ...formVal, title: data });
                                  setFieldValue('title', data?.value);
                                }}
                                selectFieldValues={formVal.title}
                                isMulti={false}
                                disableEnterKey
                              />
                            )}
                          </Field>
                          <ErrorMessage name="title" component="div" className='text-[red] text-[12px]' />
                        </div>
                        <div className='ml-4'>
                          <Field
                            name="first_name"
                            type='text'
                            placeholder="First Name*"
                            autoComplete="off"
                            className=" border-b h-[50px] border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                            outline-none w-full py-[11px]"
                          />
                          <ErrorMessage name="first_name" component="div" className='text-[red] text-[12px]' />
                        </div>
                      </div>
                      <div>
                        <Field
                          name="last_name"
                          type='text'
                          placeholder="Last name*"
                          autoComplete="off"
                          className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full"
                        />
                        <ErrorMessage name="last_name" component="div" className='text-[red] text-[12px]' />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Field
                      name="email_id"
                      type='text'
                      placeholder="Email"
                      autoComplete="off"
                      className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                    outline-none w-full"
                    />
                    <ErrorMessage name="email_id" component="div" className='text-[red] text-[12px]' />
                  </div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12"
                      style={{ display: 'grid', background: '#f4f4f4' }}>
                      <Field name="phone" className='w-full'>
                        {({ field }) => (
                          <PhoneInput
                            {...field}
                            name="phone"
                            type="tel"
                            placeholder="Phone Number*"
                            country={'in'}
                            onlyCountries={['in']}
                            autoFormat={false}
                            value={formVal.phone}
                            onChange={(data) => {
                              const phoneNumber = '+' + data;
                              setFormVal({ ...formVal, phone: phoneNumber });
                              setFieldValue('phone', phoneNumber);
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="phone" component="div" className='text-[red] text-[12px]' />
                    </div>
                  </div>
                  {samePhoneNoList?.length > 0 &&
                    <div>
                      <Field name="relation" className='w-full relative'>
                        {({ field }) => (
                          <ReactSelectFilters
                            {...field}
                            className='w-[50%]'
                            options={relationOptions}
                            placeholder='Relation'
                            name='relation'
                            noDropDownIndicator={false}
                            handleDropdownInputChange={handleOnDropdownChange}
                            onChange={(data) => {
                              setFormVal({ ...formVal, relation: data });
                              setFieldValue('relation', data?.value);
                            }}
                            selectFieldValues={formVal.relation}
                            isMulti={false}
                            disableEnterKey
                          />
                        )}
                      </Field>
                    </div>}
                  <div className='relative mt-2'>
                    <div>
                      <Field
                        name="password"
                        type={passwordType === 'password' ? 'password' : 'text'}
                        placeholder="Password*"
                        autoComplete="off"
                        className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                      outline-none w-full"
                      />
                      <ErrorMessage name="password" component="div" className='text-[red] text-[12px]' />
                    </div>
                    <div className="absolute inset-y-0 right-0 flex items-baseline pr-3">
                      <button
                        type="button"
                        onClick={showPassword}
                        className="focus:outline-none"
                      >
                        {passwordType === 'password' ? (
                          <FontAwesomeIcon icon={faEye} className="text-gray-500" />
                        ) : (
                          <FontAwesomeIcon icon={faEyeSlash} className="text-gray-500" />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className='flex gap-[15px]'>
                    <div className='flex flex-col gap-[15px] w-[50%]'>
                      <div className='col-span-5 flex flex-col relative'>
                        <Field name="dateOfBirth" className='w-full relative'>
                          {({ field }) => (
                            <div className="relative registerDate">
                              <ReactDatePicker
                                {...field}
                                showIcon
                                minDate={moment(new Date()).subtract(100, 'years').toDate()}
                                maxDate={new Date()}
                                placeholderText='Date of Birth*'
                                onChange={(data) => {
                                  setFormVal({ ...formVal, dob: data });
                                  setFieldValue('dateOfBirth', data);
                                }}
                                selected={formVal?.dob}
                                toggleCalendarOnIconClick
                                showPopperArrow={false}
                                className='w-full cursor-pointer'
                                value={formVal?.dob}
                                icon={''}
                                dateFormat="dd/MM/yyyy"
                                showYearDropdown
                                yearDropdownItemNumber={100}
                                scrollableYearDropdown
                              />
                              <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-500 absolute bottom-[15px] right-[20px]" />
                            </div>
                          )}
                        </Field>
                        <ErrorMessage name="dateOfBirth" component="div" className='text-[red] text-[12px]' />
                      </div>
                      <div>
                        <Field name="maritalStatus" className='w-full relative'>
                          {({ field }) => (
                            <ReactSelectFilters
                              {...field}
                              customStyles={{
                                valueContainer: provided => ({
                                  ...provided,
                                  backgroundColor: '#f4f4f4',
                                  padding: '8px',
                                  height: '50px'
                                })
                              }}
                              // className='w-[50%]'
                              options={MaritalOptions}
                              placeholder='Marital Status*'
                              name='maritalStatus'
                              noDropDownIndicator={false}
                              handleDropdownInputChange={handleOnDropdownChange}
                              onChange={(data) => {
                                setFormVal({ ...formVal, maritalStatus: data });
                                setFieldValue('maritalStatus', data?.value);
                              }}
                              selectFieldValues={formVal.maritalStatus}
                              isMulti={false}
                              disableEnterKey
                            />
                          )}
                        </Field>
                        <ErrorMessage name="maritalStatus" component="div" className='text-[red] text-[12px]' />
                      </div>
                    </div>
                    <div className='flex flex-col gap-[15px] w-[50%]'>
                      <div>
                        <Field name="gender" className='w-full relative'>
                          {({ field }) => (
                            <ReactSelectFilters
                              {...field}
                              customStyles={{
                                valueContainer: provided => ({
                                  ...provided,
                                  backgroundColor: '#f4f4f4',
                                  padding: '8px',
                                  height: '50px'
                                })
                              }}
                              className='w-[50%]'
                              options={GenderOptions}
                              placeholder='Gender*'
                              name='gender'
                              noDropDownIndicator={false}
                              handleDropdownInputChange={handleOnDropdownChange}
                              onChange={(data) => {
                                setFormVal({ ...formVal, gender: data });
                                setFieldValue('gender', data?.value);
                              }}
                              selectFieldValues={formVal.gender}
                              isMulti={false}
                              disableEnterKey
                            />
                          )}
                        </Field>
                        <ErrorMessage name="gender" component="div" className='text-[red] text-[12px]' />
                      </div>
                      <div>
                        <Field name="bloodGroup" className='w-full relative'>
                          {({ field }) => (
                            <ReactSelectFilters
                              {...field}
                              customStyles={{
                                valueContainer: provided => ({
                                  ...provided,
                                  backgroundColor: '#f4f4f4',
                                  padding: '8px',
                                  height: '50px'
                                })
                              }}
                              className='w-[50%]'
                              options={bloodOptions}
                              placeholder='Blood Group'
                              name='bloodGroup'
                              noDropDownIndicator={false}
                              handleDropdownInputChange={handleOnDropdownChange}
                              onChange={(data) => {
                                setFormVal({ ...formVal, bloodGroup: data });
                                setFieldValue('bloodGroup', data?.value);
                              }}
                              selectFieldValues={formVal.bloodGroup}
                              isMulti={false}
                              disableEnterKey
                            />
                          )}
                        </Field>
                        <ErrorMessage name="bloodGroup" component="div" className='text-[red] text-[12px]' />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex flex-col w-[40%] gap-[15px]'>
                  <div className="my-2">
                    <Field
                      name="Department"
                      type='text'
                      value={departmentOptions[0]?.label}
                      placeholder="Department"
                      readOnly
                      autoComplete="off"
                      className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                    outline-none w-full"
                    />
                  </div>
                  <div className="my-2">
                    <Field
                      name="allergies"
                      type='text'
                      placeholder="Allergies"
                      autoComplete="off"
                      className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                    outline-none w-full"
                    />
                  </div>
                  <div className="my-2">
                    <Field
                      name="address_line_1"
                      type='text'
                      placeholder="Address Line 1*"
                      autoComplete="off"
                      className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                    outline-none w-full"
                    />
                    <ErrorMessage name="address_line_1" component="div" className='text-[red] text-[12px]' />

                  </div>
                  <Field
                    name="address_line_2"
                    type='text'
                    placeholder="Address Line 2"
                    autoComplete="off"
                    className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                    outline-none w-full"
                  />
                  <div className='flex gap-[15px]'>
                    <div className='flex flex-col gap-[15px] w-[200px] my-2'>
                      <div>
                        <Field name="country">
                          {({ field }) => (
                            <CountryDropdown
                              {...field}
                              className="dropdown-toggle"
                              whitelist={['IN', 'MY', 'LK']}
                              value={formVal?.country}
                              id="country"
                              defaultOptionLabel="Select Country*"
                              onChange={(data) => {
                                setFormVal({ ...formVal, country: data });
                                setFieldValue('country', data);
                              }}
                              style={{ fontFamily: 'Poppins' }}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="country" component="div" className='text-[red] text-[12px]' />
                      </div>
                      <Field
                        name="city"
                        type='text'
                        placeholder="City*"
                        autoComplete="off"
                        className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                        outline-none w-full my-2"
                      />
                      <ErrorMessage name="city" component="div" className='text-[red] text-[12px]' />
                    </div>
                    <div className='flex flex-col gap-[15px] w-[200px] my-2'>
                      <div>
                        <Field name="state">
                          {({ field }) => (
                            <RegionDropdown
                              {...field}
                              className="col-md-12 col-xs-12 btn btn-default dropdown-toggle"
                              country={formVal?.country}
                              id="state"
                              blankOptionLabel="Select State*"
                              defaultOptionLabel="Select State*"
                              value={formVal?.state}
                              style={{ fontFamily: 'Poppins' }}
                              onChange={(data) => {
                                setFormVal({ ...formVal, state: data });
                                setFieldValue('state', data);
                              }}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="state" component="div" className='text-[red] text-[12px]' />
                      </div>
                      <Field
                        name="pincode"
                        type='text'
                        placeholder="Pincode*"
                        autoComplete="off"
                        className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                        outline-none w-full my-2"
                      />
                      <ErrorMessage name="pincode" component="div" className='text-[red] text-[12px]' />
                    </div>
                  </div>
                  <Field
                    name="uniqueID"
                    type='text'
                    placeholder="Unique ID"
                    autoComplete="off"
                    className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                    outline-none w-full"
                  />
                </div>
              </div>
              <div className='flex gap-[10px]'>
                <Field
                  name="checkbox"
                  type='checkbox'
                  placeholder=""
                  autoComplete="off"
                  className="border border-[#464796] w-[20px]"
                />
                <div className='text-[#737575] text-[16px] font-[700]'>Confirm details and proceed</div>
              </div>
              <div className='flex gap-[80px]'>
                <button
                  type='submit'
                  className={`bg-[#383678] text-white px-[50px] py-[5px] w-[150px] h-[48px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center
                   ${!values.checkbox && 'cursor-not-allowed bg-black'}`}
                  style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                  disabled={!values.checkbox}
                >
                  Register
                </button>
                <button
                  type='text'
                  className="bg-[#808080] text-white px-[50px] py-[5px] w-[150px] h-[48px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center"
                  onClick={() => navigate('/agent')}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <div className='py-[30px] text-[#737575]'>By clicking on Register you accept our
          <span className='underline cursor-pointer hover:font-semibold'
            onClick={privacypolicy}> Privacy Policy</span> and
          <span className='underline cursor-pointer hover:font-semibold'
            onClick={termsandconditions}> Terms and Conditions.</span>
        </div>
      </div>
    </div >
  );
}

export default Register;
