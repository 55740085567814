import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SharedButton from '../common/SharedButton';
import EditImg from '../../images/editIcon.png';
import {
  useLazyGetadminViewQuery,
  useUpdateadminMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import Loader from '../common/Loader';
import Mail from '../../images/mail.png';
import Phone from '../../images/phone.png';
import User from '../../images/userIcon.png';
import Customer from '../../images/customerIcon.png';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import PhoneInput from 'react-phone-input-2';
import ReactSelectFilters from '../common/ReactSelectFilters';
import * as Yup from 'yup'; // Import Yup

const AdminDetailView = () => {
  const navigate = useNavigate();

  const [getadminView] = useLazyGetadminViewQuery();
  const [updateadmin] = useUpdateadminMutation();

  const [loading, setLoading] = useState(false);
  const [detailsCardData, setDetailsCardData] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [phoneNo, setPhoneNo] = useState(detailsCardData.phone_number);
  const [privilege, setPrivilege] = useState({ value: detailsCardData?.privilege, label: detailsCardData?.privilege });

  const { id } = useParams();

  const options = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Super Admin', label: 'Super Admin' }
  ];

  useEffect(() => {
    setPhoneNo(detailsCardData?.phone_number);
    setPrivilege({ value: detailsCardData?.privilege, label: detailsCardData?.privilege });
    setIsToggle(detailsCardData?.status === 'active' ? false : true);
  }, [detailsCardData]);

  const cardData = () => {
    setLoading(true);
    getadminView({
      endpoint: `${apiConstants.getadminView}/${id}`
    }).then((result) => {
      let doctorViewData = result?.data;
      const modifiedData = {
        ...doctorViewData,
        id: `#DR${id}`
      };
      setDetailsCardData(modifiedData);
      setLoading(false);
    }).catch((error) => {
      setLoading(false);
      console.error('error', error);
    });
  };
  useEffect(() => {
    cardData();
  }, []);

  const handleSubmit = (values) => {
    updateadmin({
      endpoint: apiConstants.updateadmin,
      method: 'PUT',
      data: {
        data: {
          customer_id: 1,
          email_id: values?.email_id,
          first_name: values?.first_name,
          id: id,
          last_name: values?.last_name,
          phone_number: values?.phone_number,
          privilege: values?.privilege,
          status: isToggle ? 'inactive' : 'active',
          user_type: 'admin'
        }
      }
    }).then(() => {
      cardData();
    }).catch(() => {
    });
    setIsEdit(false);
  };

  const toggleBar = () => {
    setIsToggle(!isToggle);
  };

  const handleOnDropdownChange = () => {
  };

  // Validation Schema
  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    email_id: Yup.string().email('Invalid email address').required('Email is required'),
    phone_number: Yup.string().min(13, 'Please enter a valid phone number')
      .max(13, 'Please enter a valid phone number').required('Please Enter a Valid Phone Number'),
    privilege: Yup.string().required('Privilege is required')
  });

  return (
    <div className="row mt-16 mx-28">
      {loading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div className='flex flex-col mt-16'>
          <div>
            <SharedButton
              className="ml-0 rounded-[20px] float-left text-[18px]
            bg-[#383678] text-white px-[30px] py-[7px] font-bold
              mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
              title='Back' onClick={() => navigate(-1)}
            />
          </div>
          <div className='bg-[#f4f4f4] rounded-lg mt-4 p-2 h-[575px] relative'>
            <div className='flex justify-center mt-6 w-full h-full'>
              <Formik
                initialValues={{
                  first_name: detailsCardData.first_name,
                  last_name: detailsCardData.last_name,
                  email_id: detailsCardData.email_id,
                  phone_number: detailsCardData.phone_number,
                  privilege: detailsCardData.privilege
                }}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue }) => (
                  <Form className='w-full flex gap-[5%]'>
                    {isEdit ? <div className='flex gap-[20px] absolute top-[20px] right-[10px]'>
                      <div className='flex items-center detailView'>
                        <div className='doctor-status'>Disable Admin</div>
                        <Toggle
                          className='status-tog'
                          checked={isToggle}
                          onChange={() => toggleBar()}
                          icons={{
                            checked: <span style={{ fontFamily: 'Poppins' }}></span>,
                            unchecked: <span style={{ fontFamily: 'Poppins' }}></span>
                          }}
                        />
                      </div>
                      <SharedButton
                        className="ml-0 rounded-[20px] float-left text-[18px]
                      bg-[#383678] text-white px-[30px] py-[7px] font-bold
                        mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        type='submit'
                        title='Save'
                      />
                    </div> :
                      <div className='absolute right-[20px]'>
                        <img className='ml-3 cursor-pointer' src={EditImg} onClick={() => setIsEdit(true)} />
                      </div>}
                    <div className='w-[45%] flex flex-col gap-[20px] items-center justify-center'>
                      {isEdit ?
                        (<>
                          <div>
                            <Field
                              name="first_name"
                              type='text'
                              placeholder="First Name"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                          outline-none px-3 h-[50px]"
                            />
                            <ErrorMessage name="first_name" component="div" className='text-[red] text-[12px]' />
                          </div>
                          <div>
                            <Field
                              name="last_name"
                              type='text'
                              placeholder="Last Name"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                            outline-none px-3 h-[50px]"
                            />
                            <ErrorMessage name="last_name" component="div" className='text-[red] text-[12px]' />
                          </div>
                        </>) :
                        <div className='mt-[10%]'>
                          {detailsCardData.first_name && (
                            <label className='text-[#737575] text-5xl font-semibold'>
                              {detailsCardData.first_name} {detailsCardData.last_name}
                            </label>
                          )}
                        </div>}
                      <div className='flex gap-[20px] items-center'>
                        <div className='text-center'>
                          {detailsCardData.first_name && (
                            <label className='text-[#737575] text-[20px] font-semibold'>
                              {'#AD'}{id}
                            </label>
                          )}
                        </div>
                        <div className='h-[22px] border-l-2 border-solid border-black'></div>
                        <div className=''>
                          {detailsCardData.status}
                        </div>
                      </div>
                    </div>
                    <div className='h-[80%] border-l-2 border-solid border-[#d3d3d3] my-8'></div>
                    <div className='w-[45%] flex flex-col justify-center items-start ml-[5%] gap-[30px]'>
                      <div className='flex items-center gap-3'>
                        <div className='w-[65px] h-[65px] rounded-[50%] bg-[#d1d1d166] 
                        flex justify-center items-center'>
                          <img className='cursor-pointer' src={Mail} width={32} height={23} />
                        </div>
                        <div>
                          {isEdit ?
                            <Field
                              name="email_id"
                              type='text'
                              placeholder="Email*"
                              autoComplete="off"
                              className="border-b border-[#A9A9A9] bg-[#f4f4f4] opacity-70
                            outline-none w-full py-2 px-3 h-[50px]"
                            />
                            :
                            <div className='text-[18px] text-[#999999]'>{detailsCardData.email_id}</div>}
                          <ErrorMessage name="email_id" component="div" className='text-[red] text-[12px]' />
                        </div>
                      </div>
                      <div className='flex items-center w-[60%]'>
                        <div className='w-[65px] h-[65px] mr-2 rounded-[50%] bg-[#d1d1d166]  
                        flex justify-center items-center'>
                          <img className='cursor-pointer' src={Phone} width={37} height={30} />
                        </div>
                        <div>
                          {isEdit ?
                            <Field name="phone_number" className='w-[60%]'>
                              {({ field }) => (
                                <PhoneInput
                                  {...field}
                                  name="phone_number"
                                  type="tel"
                                  placeholder="Phone Number*"
                                  country={'in'}
                                  onlyCountries={['in']}
                                  autoFormat={false}
                                  value={phoneNo}
                                  onChange={(data) => {
                                    const phoneNumber = '+' + data;
                                    setPhoneNo(phoneNumber);
                                    setFieldValue('phone_number', phoneNumber);
                                  }}
                                />
                              )}
                            </Field> :
                            <div className='text-[18px] text-[#999999]'>{detailsCardData.phone_number}</div>}
                          <ErrorMessage name="phone_number" component="div" className='text-[red] text-[12px]' />
                        </div>
                      </div>
                      <div className='flex items-center gap-3 createUser'>
                        <div className='w-[65px] h-[65px] rounded-[50%] bg-[#d1d1d166] 
                        flex justify-center items-center'>
                          <img className='cursor-pointer' src={User} width={32} height={23} />
                        </div>
                        <div>
                          {isEdit ?
                            <Field name="privilege" className='w-full relative'>
                              {({ field }) => (
                                <ReactSelectFilters
                                  {...field}
                                  customStyles={{
                                    valueContainer: provided => ({
                                      ...provided,
                                      backgroundColor: '#f4f4f4',
                                      padding: '8px',
                                      height: '50px',
                                      minWidth: '180px',
                                      maxWidth: '100%'
                                    })
                                  }}
                                  options={options}
                                  placeholder='Privilege*'
                                  name='privilege'
                                  noDropDownIndicator={false}
                                  handleDropdownInputChange={handleOnDropdownChange}
                                  onChange={(data) => {
                                    setPrivilege(data);
                                    setFieldValue('privilege', data?.value);
                                  }}
                                  selectFieldValues={privilege}
                                  isMulti={false}
                                  disableEnterKey
                                />
                              )}
                            </Field> :
                            <div className='text-[18px] text-[#999999]'>{detailsCardData.privilege}</div>}
                          <ErrorMessage name="privilege" component="div" className='text-[red] text-[12px]' />
                        </div>
                      </div>
                      {privilege?.value === 'Admin' && <div className='flex items-center gap-3'>
                        <div className='w-[85px] h-[65px] rounded-[50%] bg-[#d1d1d166] 
                        flex justify-center items-center'>
                          <img className='cursor-pointer' src={Customer} width={32} height={23} />
                        </div>
                        <div className='text-[18px] text-[#999999]'>{detailsCardData.customer_name}</div>
                      </div>}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDetailView;