import React, { useEffect, useState } from 'react';
import RecursiveComponent from './RecursiveComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveMedicalHistoryData, updateMedicalHistoryData
} from '../../../../../reducers/medicalHistorySlice';
import { useAddPatientMedicalHistoryMutation } from '../../../../../reducers/allReducer';
import { apiConstants } from '../../../../../constants/api';
import BodyPainDiagram from './BodyPainDiagram';
import Attachments from './Attachments';
import { Colorcode } from '../../../../common/utils/Colorcode';

export const deepCopy = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj; // If the input is not an object or is null, return it as is
  }
  const newObj = Array.isArray(obj) ? [] : {}; // Determine if the input is an array or object
  for (let key in obj) {
    newObj[key] = deepCopy(obj[key]); // Recursively copy nested properties
  }
  return newObj;
};

export function validateStringEmptyOnSpace(string) {
  // const namePattern = /^[a-zA-Z_ !@#$&()'\\-`.+,/\"]*$/;
  const namePattern = /^[^-\s][a-z/\0-9/A-Z/_\s-]*$/;
  if (string && string.match(namePattern)) {
    return true;
  }
  return 'No Empty Spaces Allowed';
}

const MedicalHistory = ({ medicalHistoryData, clientId }) => {
  const customerColor = Colorcode();
  const [headersToShow, setHeadersToShow] = useState();
  const [showBodyPainDiagram, setShowBodyPainDiagram] = useState(false);
  const [showAttachments, setShowAttachments] = useState(false);
  const [errorData, setErrorData] = useState();
  const dispatch = useDispatch();
  const dataToDisplay = useSelector((state) => state?.medicalHistoryData?.medicalHistoryDetailsToDisplay);
  const initialMedicalHistoryValues = useSelector((state) => state?.medicalHistoryData?.medicalHistory);
  const selectedTitle = useSelector((state) => state?.medicalHistoryData?.selectedTabTitle);
  const [addPatientMedicalHistory] = useAddPatientMedicalHistoryMutation();

  const handleSetHeaders = (data) => {
    let tempHeaders = [];
    for (let i = 0; i < data?.length; i++) {
      tempHeaders.push(data[i]?.medical_history_section_name);
    }
    tempHeaders.push('Body Pain Diagram', 'Attachments');
    setHeadersToShow(tempHeaders);
  };

  const dispatchToSave = (medicalHistoryData, bodyPainDetails, userAttachments, selectedTabTitle) => {
    dispatch(saveMedicalHistoryData({ fieldName: 'medicalHistory', fieldValue: medicalHistoryData }));
    dispatch(saveMedicalHistoryData({ fieldName: 'bodyPainDetails', fieldValue: bodyPainDetails }));
    dispatch(saveMedicalHistoryData({ fieldName: 'attachmentDetails', fieldValue: userAttachments }));
    dispatch(saveMedicalHistoryData({
      fieldName: 'selectedTabTitle',
      fieldValue: selectedTabTitle
    }));
  };

  const handleMedicalHistoryData = (data) => {
    let tempData = [];
    if ((data.medical_history_details).length <= 0) {
      tempData = data.medical_history_questions;
    } else if ((data.medical_history_details).length === (data.medical_history_questions).length) {
      tempData = data.medical_history_details;
    } else if ((data.medical_history_details).length < (data.medical_history_questions).length) {
      for (let i = 0; i < (data.medical_history_questions).length; i++) {
        for (let j = 0; j < (data.medical_history_details).length; j++) {
          if (data.medical_history_questions[i].id === data.medical_history_details[j].medical_history_section_id) {
            tempData[i] = data.medical_history_details[j];
          } else if (data.medical_history_questions[i].id !==
            data.medical_history_details[j].medical_history_section_id) {
            if (tempData[i] === undefined) {
              tempData[i] = data.medical_history_questions[i];
            }
          }
        }
      }
    }

    if (tempData?.length > 0) {
      // deep copy data to assign json_value with new value
      const newData = deepCopy(tempData);
      for (let i = 0; i < tempData.length; i++) {
        if (tempData[i]?.json_value) {
          newData[i].json_value = JSON.parse(tempData[i]?.json_value);
          newData[i].Ext_id = tempData[i].id;
          newData[i].id = tempData[i].medical_history_section_id;
        } else if (!tempData[i].json_value) {
          newData[i].json_value = JSON.parse(tempData[i]?.questions_json);
        }
        newData[i].medical_history_section_name = (tempData[i].name) ?
          tempData[i].name : tempData[i].medical_history_section_name;
        newData[i].medical_history_section_id = newData[i].id;
      }
      handleSetHeaders(newData);
      dispatchToSave(newData, data?.body_pain_details, data?.user_attachments,
        newData[0]?.medical_history_section_name);
    } else {
      dispatchToSave(data.medical_history_details, data?.body_pain_details, data?.user_attachments,
        'Body Pain Diagram');
      setShowBodyPainDiagram(true);
    }
  };

  useEffect(() => {
    if (medicalHistoryData) {
      handleMedicalHistoryData(medicalHistoryData);
    }
  }, [medicalHistoryData]);

  const handleOnTabClick = (value) => {
    dispatch(saveMedicalHistoryData({
      fieldName: 'selectedTabTitle',
      fieldValue: value
    }));
  };

  useEffect(() => {
    if (selectedTitle === 'Body Pain Diagram') {
      setShowAttachments(false);
      setShowBodyPainDiagram(true);
    } else if (selectedTitle === 'Attachments') {
      setShowBodyPainDiagram(false);
      setShowAttachments(true);
    } else if (selectedTitle) {
      setShowBodyPainDiagram(false);
      setShowAttachments(false);
      const index = initialMedicalHistoryValues?.findIndex((d) =>
        d?.medical_history_section_name === selectedTitle);
      const value = initialMedicalHistoryValues[index].json_value;
      dispatch(saveMedicalHistoryData({ fieldName: 'medicalHistoryDetailsToDisplay', fieldValue: value }));
    }
  }, [selectedTitle]);

  const updateStateData = () => {
    dispatch(updateMedicalHistoryData(dataToDisplay));
  };

  useEffect(() => {
    if (dataToDisplay && Object.keys(dataToDisplay)?.length > 0) {
      updateStateData();
    }
  }, [dataToDisplay]);

  const validateRepeaterSubChild = (question_data) => {
    let result = {
      status: true,
      error_data: {}
    }, childValidationStatus = true;
    let validators = validateStringEmptyOnSpace, validationResultData = '';
    Object.keys(question_data.questions).map((items) => {
      result.error_data[items] = { error: '', isChild: false, children: {} };
    });
    Object.keys(question_data.questions).map((items) => {
      validationResultData = validators(question_data.questions[items].value);
      if (validationResultData !== true) {
        result.error_data[items].error = 'Please fill this field';
        result.status = false;
      } else {
        result.error_data[items].error = '';
      }
      Object.keys(question_data.questions[items].data).map((item) => {
        if ((question_data.questions[items].value === question_data.questions[items].data[item].title)
          && question_data.questions[items].data[item].isChild) {
          result.error_data[items].isChild = true;
          let question_data1 = question_data.questions[items].data[item].isChild ?
            question_data.questions[items].data[item].children : {};
          let validateRepeaterChild = validateRepeaterChild(question_data1);
          result.status = result.status && validateRepeaterChild.status;
          result.error_data[items].children = validateRepeaterChild.error_data;
        }
      });
    });

    result.status = result.status && childValidationStatus;
    return result;
  };

  const validateRepeaterChild = (question_data) => {
    let result = {
      status: true,
      error_data: {}
    }, childValidationStatus = true;
    Object.keys(question_data.questions).map((items) => {
      result.error_data[items] = { error: '', isChild: false, children: {} };
    });
    let validators = validateStringEmptyOnSpace, validationResultData = '';
    Object.keys(question_data.questions).map((items) => {
      validationResultData = validators(question_data.questions[items].value);
      if (validationResultData !== true) {
        result.error_data[items].error = 'Please fill this field';
        result.status = false;
      } else {
        result.error_data[items].error = '';
      }
      Object.keys(question_data.questions[items].data).map((item) => {
        if ((question_data.questions[items].value === question_data.questions[items].data[item].title) &&
          question_data.questions[items].data[item].isChild) {
          result.error_data[items].isChild = true;
          let question_data1 = question_data.questions[items].data[item].children;
          let validateRepeaterSubChildValue = validateRepeaterSubChild(question_data1);
          childValidationStatus = result.status && validateRepeaterSubChildValue.status;
          result.error_data[items].children = validateRepeaterSubChildValue.error_data;
        }
      });
    });

    result.status = result.status && childValidationStatus;
    return result;
  };

  const validate = (questionsData) => {
    let error_data = {};
    let childValidations = true, result = true, questions = questionsData, validationResultData = '';
    let validators = validateStringEmptyOnSpace;
    Object.keys(questions).map((items) => {
      error_data[items] = { error: '', isChild: false, children: {} };
    });

    Object.keys(questions).map((items) => {
      validationResultData = validators(questions[items].value);
      if (validationResultData !== true) {
        error_data[items].error = 'Please fill this field';
        result = false;
      } else {
        error_data[items].error = '';
        result = true;
      }
      Object.keys(questions[items].data).map((item) => {
        if ((questions[items].value === questions[items].data[item].title) && questions[items].data[item].isChild) {
          error_data[items].isChild = true;
          let question_data = questions[items].data[item].children;
          let validateRepeaterChildValue = validateRepeaterChild(question_data);
          childValidations = validateRepeaterChildValue.status;
          error_data[items].children = validateRepeaterChildValue.error_data;
        }
      });
    });

    setErrorData(error_data);
    return result && childValidations;
  };

  const handleSaveMedicalHistory = () => {
    const data = initialMedicalHistoryValues?.find((d) =>
      d?.medical_history_section_name === selectedTitle);
    if (validate(data?.json_value?.questions) === false) {
      return;
    }
    addPatientMedicalHistory({
      endpoint: apiConstants?.addPatientMedicalHistory,
      method: 'POST',
      data: {
        client_id: clientId,
        json_value: JSON.stringify(data?.json_value),
        medical_history_section_id: data?.medical_history_section_id,
        medical_history_section_name: data?.medical_history_section_name
      }
    });
  };

  return (
    <div className='flex flex-col gap-[20px]'>
      <div className='flex overflow-auto p-[10px] whitespace-nowrap'>
        {headersToShow?.map((header, index) => (
          <div
            key={index}
            className='px-[10px] cursor-pointer'
            onClick={() => handleOnTabClick(header)}
          >
            <span className={`text-[#5b5b5b] text-[15px] ${selectedTitle === header && 'underline'}`}>{header}</span>
          </div>
        ))}
      </div>
      {dataToDisplay && !showBodyPainDiagram && !showAttachments && (
        <div className='p-[20px] pl-[100px] max-h-[63.5vh] overflow-y-auto overflow-x-auto text-[#888] 
          text-[14px] font-[400]'
        >
          <RecursiveComponent
            displayData={dataToDisplay}
            deepCopy={deepCopy}
            errorData={errorData}
          />
          <div className='p-[40px] flex justify-center'>
            <button
              type='submit'
              className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px]'
              style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
              onClick={() => handleSaveMedicalHistory()}
            >
              Save
            </button>
          </div>
        </div>
      )}
      {showBodyPainDiagram && !showAttachments && (
        <div className='p-[20px] max-h-[63.5vh] overflow-y-auto overflow-x-hidden'>
          <BodyPainDiagram clientId={clientId} />
        </div>
      )}
      {showAttachments && (
        <div className='p-[20px] max-h-[63.5vh] overflow-y-auto overflow-x-hidden'>
          <Attachments clientId={clientId} />
        </div>
      )}
    </div>
  );
};

export default MedicalHistory;