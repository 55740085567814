import { Formik, Form, Field } from 'formik';
import React from 'react';
import ReactSelectFilters from '../../../../common/ReactSelectFilters';

const DynamicInputElements = ({
  depth,
  handleOnInputElementChange,
  id,
  error,
  type,
  title,
  value,
  data,
  options
}) => {

  const renderInputElement = () => {
    switch (type) {
      case 'text':
      case 'number':
      case 'email':
        return (
          <Field
            type={type}
            name={title}
            value={value} 
            onChange={handleOnInputElementChange}
            id={id}
            className='p-[10px] w-full bg-transparent'
            placeholder={title}
          />
        );
      case 'textarea':
        return (
          <Field 
            type='text'
            name={title}
            placeholder={title}
            value={value}
            onChange={handleOnInputElementChange}
            id={id}
            className='p-[10px] bg-transparent'
          />
        );
      case 'radio':
        return (
          <div className='flex flex-row gap-[20px] flex-wrap'>
            {Object.keys(data)?.map((item, index) => {
              const isChecked = data[item]?.title === value ? true : false;
              return (
                <div key={index} className='flex flex-row gap-[5px] items-center'>
                  <Field 
                    key={index}
                    type='radio' 
                    name={title} 
                    value={data[item]?.title} 
                    checked={isChecked}
                    onChange={handleOnInputElementChange}
                    id={id}
                  />
                  <span className='whitespace-nowrap'>{data[item]?.title}</span>
                </div>
              );
            })}
          </div>
        );
      case 'dropdown':
        return (
          <ReactSelectFilters
            options={options}
            placeholder={title}
            name={title}
            onChange={(option) => {
              handleOnInputElementChange(option, id);
            }}
            disableEnterKey
            selectFieldValues={value}
            containerBackground='transparent'
          />
        );
      default:
        console.log('error');
    }
  };

  return (
    <Formik>
      <Form>
        <div className={`pl-[${depth * 5}px]`}>
          {renderInputElement()}
          {error && (
            <p className='text-[12px] text-[#ff0000] font-[500]'>{error}</p>
          )}
        </div>
      </Form>
    </Formik>
  );
};

export default DynamicInputElements;