import { faArrowUp, faEdit, faFileUpload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EditReportDetailsModal from './EditReportDetailsModal';
import {
  useGetSignedUrlForAttachmentsDoctorMutation,
  useOnAttachmentDeleteMutation,
  useOnAttachmentUpdateMutation, 
  useOnAttachmentViewMutation,
  useSaveAttachmentMutation
} from '../../../../../reducers/allReducer';
import { apiConstants } from '../../../../../constants/api';
import {
  addNewAttachment,
  deleteAttachment,
  updateAttachmentDetails
} from '../../../../../reducers/medicalHistorySlice';
import DeleteAttachmentModal from './DeleteAttachmentModal';

const Attachments = ( { clientId}) => {
  const attachments = useSelector((state) => state?.medicalHistoryData?.attachmentDetails);
  const [onAttachmentUpdate] = useOnAttachmentUpdateMutation();
  const [onAttachmentDelete] = useOnAttachmentDeleteMutation();
  const [onAttachmentView] = useOnAttachmentViewMutation();
  const [saveAttachment] = useSaveAttachmentMutation();
  const [getSignedUrlForAttachmentsDoctor] = useGetSignedUrlForAttachmentsDoctorMutation();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isEditReportModalOpen, setIsEditReportModalOpen] = useState(false);
  const [isDeleteAttachmentModalOpen, setIsDeleteAttachmentModalOpen] = useState(false);
  const [dataToDelete, setDataToDelete] = useState();
  const [dataToEdit, setDataToEdit] = useState({
    description: '',
    id: '',
    name: ''
  });

  const handleOnClickReportEdit = (data) => {
    setDataToEdit({
      description: data?.attachment_description,
      id: data?.attachment_id,
      name: data?.original_name
    });
    setIsEditReportModalOpen(true);
  };

  const handleSaveEditDetails = () => {
    onAttachmentUpdate({
      endpoint: `${apiConstants?.onAttachmentUpdate}/${dataToEdit?.id}`,
      method: 'PUT',
      data: {
        attachment_description: dataToEdit?.description,
        attachment_name: dataToEdit?.name
      }
    }).then(() => {
      dispatch(updateAttachmentDetails({ id: dataToEdit?.id, description: dataToEdit?.description }));
      setIsEditReportModalOpen(false);
    });
  };

  const handleOnClickDeleteAttachment = (data) => {
    setDataToDelete(data);
    setIsDeleteAttachmentModalOpen(true);
  };

  const handleDeleteAttachment = () => {
    onAttachmentDelete({
      endpoint: `${apiConstants?.onAttachmentDelete}/${clientId}`,
      method: 'POST',
      data: {
        attachment_id: dataToDelete?.attachment_id
      }
    }).then(() => {
      dispatch(deleteAttachment({ id: dataToDelete?.attachment_id }));
      setIsDeleteAttachmentModalOpen(false);
    });
  };

  const handleOnClickViewAttachment = (data) => {
    onAttachmentView({
      endpoint: `${apiConstants?.onAttachmentView}/${clientId}`,
      method: 'POST',
      data: {
        attachment_id: data?.attachment_id
      }
    }).then((result) => {
      let typeOfFile1 = result?.data?.split('.');
      let typeOfFile = typeOfFile1[5].split('?');
      // eslint-disable-next-line max-len
      const winFeature = 'toolbar=no,titlebar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no';
      if (typeOfFile[0] === 'jpg' || typeOfFile[0] === 'pdf' || typeOfFile[0] === 'jpeg' || typeOfFile[0] === 'png') {
        window.open(result?.data, 'Image/*', winFeature);
      } else {
        window.open(result?.data);
      }
    });
  };

  const handleSaveAttachment = (data) => {
    saveAttachment({
      endpoint: `${apiConstants?.saveAttachment}/${clientId}`,
      method: 'POST',
      data
    }).then((result) => {
      if (result && result?.data) {
        dispatch(addNewAttachment(result?.data));
      }
    });
  };

  const onFileChange = (e) => {
    e?.preventDefault();
    if (e?.target?.files && e?.target?.files?.length > 0) {
      const fileUploaded = e?.target?.files[0];
      getSignedUrlForAttachmentsDoctor({
        endpoint: `${apiConstants?.getSignedUrlForAttachmentsDoctor}/${clientId}`,
        method: 'POST',
        data: {
          objectName: fileUploaded?.name
        }
      }).then((result) => {
        const params = {
          filename: result?.data?.filename,
          originalFileName: result?.data?.originalFileName,
          attachment_description: ''
        };
        if (result && result?.data) {
          let signedUrl = result?.data?.signedUrl;
          fetch(signedUrl, {
              method: 'PUT',
              body: fileUploaded
          }).then(() => {
            handleSaveAttachment(params);
          });
        }
      });
    }
  };

  return (
    <>
      {isEditReportModalOpen && (
        <EditReportDetailsModal
          handleModalClose={() => setIsEditReportModalOpen(false)}
          data={dataToEdit}
          setDataToEdit={setDataToEdit}
          handleSaveEditDetails={handleSaveEditDetails}
        />
      )}
      {isDeleteAttachmentModalOpen && (
        <DeleteAttachmentModal
          handleModalClose={() => setIsDeleteAttachmentModalOpen(false)}
          handleDeleteAttachment={handleDeleteAttachment}
          heading='Are you sure you want to delete this attachment'
        />
      )}
      <div className='grid grid-cols-12 gap-[10px]'>
        <div className={`${attachments?.length > 0 ? 'col-span-4 border-r-[1px] border-r-[#d9d9d9]' : 'col-span-12'} 
          p-[20px]`}>
          <div onClick={() => fileInputRef?.current?.click()} 
            className='flex flex-col gap-[10px] items-center cursor-pointer'
          >
            <FontAwesomeIcon icon={faArrowUp} color='#383678' className='w-[50px] h-[50px]' />
            <input
              type='file'
              ref={fileInputRef}
              id='fileUplaod'
              className='hidden'
              onChange={onFileChange}
              onDrop={onFileChange}
            />
            <p className='text-[16px] text-[#383678] font-[700]'>Upload</p>
            <p className='text-[15px] text-[#888] font-[500] text-center'>Drag your files here or browse to upload</p>
          </div>
        </div>
        <div className='col-span-8'>
          <div className='flex flex-col gap-[10px]'>
            {attachments?.map((data, index) => (
              <div key={index} className='grid grid-cols-12 gap-[20px] border-b-[1px] border-b-[#d9d9d9] p-[10px]'>
                <div className='col-span-7 flex gap-[10px] break-all'>
                  <div className='cursor-pointer' 
                    onClick={() => handleOnClickReportEdit(data)}
                  >
                    <FontAwesomeIcon icon={faEdit} color='#383678' />
                  </div>
                  <div className='flex flex-col gap-[5px]'>
                    <p className='text-[17px] text-[#888] font-[700]'>{data?.original_name}</p>
                    <p className='text-[12px] text-[#999]'>
                      {data?.attachment_description ?  data?.attachment_description : 
                      'Please add description of the report here'}
                    </p>
                  </div>
                </div>
                <div className='col-span-3'>
                  <p className='text-[14px] text-[#999]'>
                    Uploaded on - {moment(data?.date_created).format('DD MMM YYYY')}
                  </p>
                </div>
                <div className='col-span-1 cursor-pointer' onClick={() => handleOnClickViewAttachment(data)}>
                  <FontAwesomeIcon icon={faFileUpload} color='#383678' />
                </div>
                <div className='col-span-1 cursor-pointer' onClick={() => handleOnClickDeleteAttachment(data)}>
                  <FontAwesomeIcon icon={faTrash} color='#383678' />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Attachments;