import React from 'react';
import PatientDetailBox from '../PatientDetailBox';

const DisplayTeleExamination = ({
  teleExamination
}) => {

  return (
    <div className='flex flex-col gap-[30px]'>
      <div className='w-[80%]'>
        <PatientDetailBox title='Tele Examination'>
          <div className='flex flex-col gap-[20px]'>
            {teleExamination && (
              <div className='p-[20px]'>
                <p className='text-sm text-[#777] fw-500'>{teleExamination}</p>
              </div>
            )}
            {!teleExamination && (
              <div className='p-[20px]'>
                <p className='text-sm text-[#777] fw-500'>No records found</p>
              </div>
            )}
          </div>
        </PatientDetailBox>
      </div>
    </div>
  );
};

export default DisplayTeleExamination;
