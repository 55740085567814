import React from 'react';
import CommonTitleComponent from './CommonTitleComponent';
import PatientDetailsInPrescription from './PatientDetailsInPrescription';
import PrescriptionTableInPreview from './PrescriptionTableInPreview';
import { Colorcode } from '../../../../common/utils/Colorcode';

const PrescriptionPreviewModal = ({
  handleModalClose,
  handleSendToPatient,
  prescriptionData
}) => {
  const customerColor = Colorcode();
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-3xl"
          >
            <div className="bg-white p-3">
              <div className="w-full">
                <div className='grid grid-cols-12 p-[20px] border-b-[2px] border-b-[#e5e5e5] items-center'>
                  <div className='col-span-3' onClick={() => handleSendToPatient()}>
                    <button
                      className='bg-blue-600 text-white px-[15px] py-[5px] rounded-[50px] text-[12px] text-nowrap'
                      style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                    >
                      Send to patient
                    </button>
                  </div>
                  <div className={`col-span-7 flex ${'justify-center'}`}>
                    <p className='text-[#757776]'>Prescription Preview</p>
                  </div>
                  <div
                    className='col-span-2 flex items-center justify-end cursor-pointer text-[14px] 
                      text-[#383678] underline'
                    onClick={() => handleModalClose()}
                  >
                    Close
                  </div>
                </div>
              </div>
              <div className="w-full p-[20px] text-[#757776] max-h-[75vh] overflow-auto">
                <CommonTitleComponent heading='General Information' noPaddingTop>
                  <PatientDetailsInPrescription patientDetails={prescriptionData?.patient_details} />
                </CommonTitleComponent>
                {prescriptionData?.symptoms?.length > 0 && (
                  <CommonTitleComponent
                    heading='Chief Complaints'
                    isListing={true}
                    listingData={prescriptionData?.symptoms}
                    durationNumber={prescriptionData?.appointment_data?.duration_number}
                    durationType={prescriptionData?.appointment_data?.duration_type}
                  />
                )}
                {prescriptionData?.vitals?.length > 0 && (
                  <CommonTitleComponent heading='Vitals'>
                    <div className='p-[10px] border-[1px] border-black text-[12px]'>
                      <div className='grid grid-cols-12 gap-[10px] text-[12px] text-[#333]'>
                        {prescriptionData?.vitals?.map((vital, index) => (
                          <div className='col-span-3' key={index}>
                            <p className='whitespace-break-spaces'>{vital?.name}: {vital?.value}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </CommonTitleComponent>
                )}
                <div className='grid grid-cols-12'>
                  {prescriptionData?.appointment_data?.tele_examination !== null && (
                    <div className='col-span-6'>
                      <CommonTitleComponent
                        heading='Tele Examination'
                        isListing={true}
                        listingData={prescriptionData?.appointment_data?.tele_examination?.split('\n')}
                      />
                    </div>
                  )}
                  {prescriptionData?.differential_diagnosis?.length > 0 && (
                    <div className='col-span-6'>
                      <CommonTitleComponent
                        heading='Differential Diagnosis'
                        isListing={true}
                        listingData={prescriptionData?.differential_diagnosis}
                      />
                    </div>
                  )}
                </div>
                <div className='grid grid-cols-12'>
                  {prescriptionData?.diagnosis?.length > 0 && (
                    <div className='col-span-6'>
                      <CommonTitleComponent
                        heading='Diagnosis'
                        isListing={true}
                        listingData={prescriptionData?.diagnosis}
                      />
                    </div>
                  )}
                  {prescriptionData?.provisional_diagnosis?.length > 0 && (
                    <div className='col-span-6'>
                      <CommonTitleComponent
                        heading='Provisional Diagnosis'
                        isListing={true}
                        listingData={prescriptionData?.provisional_diagnosis}
                      />
                    </div>
                  )}
                </div>
                {prescriptionData?.investigation?.length > 0 && (
                  <CommonTitleComponent
                    heading='Investigation'
                    isListing={true}
                    listingData={prescriptionData?.investigation}
                  />
                )}
                <CommonTitleComponent heading='Prescription'>
                  <PrescriptionTableInPreview data={prescriptionData?.drugs} />
                </CommonTitleComponent>
                <CommonTitleComponent
                  heading='General Advice'
                  isListing={true}
                  listingData={prescriptionData?.appointment_data?.prescription_instructions?.split('\n')}
                >
                  <div className='flex flex-col gap-[5px]'>
                    {prescriptionData?.appointment_data?.referred_to !== null && (
                      <span className='text-[15px] font-bold'>
                        Referred to {prescriptionData?.appointment_data?.referred_to}
                      </span>
                    )}
                    {prescriptionData?.appointment_data?.refer_to_higher_center === 1 && (
                      <span className='text-[15px] font-bold'>
                        Referred to higher center for further advice and management
                      </span>
                    )}
                  </div>
                </CommonTitleComponent>
                <CommonTitleComponent heading='Follow Up'>
                  <p className='text-[12px]'>{prescriptionData?.appointment_data?.followup_date || 'No Follow up'}</p>
                </CommonTitleComponent>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionPreviewModal;