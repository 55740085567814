import React from 'react';
import AppointmentList from '../PatientDetails/PastConsultsModal/AppointmentList';

const MedicalHistoryModal = ({
  heading,
  isDisplayHeader,
  handleModalClose,
  children,
  showAppointments,
  appointmentList,
  pastConsultAppointmentId,
  handleOnClickAppointment
}) => {
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-8xl h-[91vh]"
          >
            <div className="bg-white p-3 grid grid-cols-12">
              <div className='col-span-3 p-[20px] border-r-[1px] border-r-[#dee2e6]'>
                <div className='flex flex-col gap-[20px]'>
                  <div className='flex gap-[10px] p-[20px] items-center'>
                    <p className='text-[15px] text-[#383678]'>{heading}</p>
                    <div className='w-full'>
                      <hr style={{ color: '#e1e1e1'}} />
                    </div>
                  </div>
                  {showAppointments && (
                    <AppointmentList
                      appointmentList={appointmentList}
                      pastConsultAppointmentId={pastConsultAppointmentId}
                      handleOnClickAppointment={handleOnClickAppointment}
                    />
                  )}
                </div>
              </div>
              <div className='col-span-9 p-[20px]'>
                <div className='flex flex-col gap-[10px]'>
                  <div className={`flex items-center ${isDisplayHeader ? 
                    'justify-between border-b-[1px] border-b-[#dee2e6]' : 'justify-end'}`}
                  >
                    {isDisplayHeader && (
                      <p className='text-[40px] text-[#737575] p-[30px]'>{heading}</p>
                    )}
                    <div className='flex justify-end' onClick={() => handleModalClose()}>
                      <p className='underline text-[18px] cursor-pointer text-[#383678] pr-[20px]'>Close</p>
                    </div>
                  </div>
                  <div className='w-full'>
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalHistoryModal;
