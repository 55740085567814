import React, { useEffect, useState } from 'react';
import {
  useLazyGetkiosksfordoctorassociationQuery,
  useAssociatedoctorswithkiosksMutation
} from '../../reducers/allReducer';
import { useParams } from 'react-router-dom';
import { apiConstants } from '../../constants/api';
import ModalTable from './ModalTable';

const AssociateKioskModal = ({
  setAssociateModal,
  setIsConfirm,
  setMsg
}) => {
  const [getkiosksfordoctorassociation] = useLazyGetkiosksfordoctorassociationQuery();
  const [associatedoctorswithkiosks] = useAssociatedoctorswithkiosksMutation();
  const [kioskList, setKioskList] = useState([]);
  const { id } = useParams();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  // Function to handle the select all checkbox
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedItems(kioskList.map(item => item.id));
    } else {
      setSelectedItems([]);
    }
  };

  useEffect(() => {
    if (kioskList.length !== selectedItems.length) {
      setSelectAll(false);
    } else {
      setSelectAll(true);
    }
  }, [selectedItems]);

  // Function to handle individual checkbox
  const handleCheckboxChange = (item) => {
    if (selectedItems.includes(item.id)) {
      setSelectedItems(selectedItems.filter(itemId => itemId !== item.id));
    } else {
      setSelectedItems([...selectedItems, item.id]);
    }
  };

  useEffect(() => {
    getkiosksfordoctorassociation({
      endpoint: `${apiConstants.getkiosksfordoctorassociation}/${id}`
    }).then((result) => {
      setKioskList(result?.data);
    }).catch((error) => {
      console.error('error', error);
    });
  }, []);

  useEffect(() => {
    const doctorIds = kioskList.map(item => item.kiosk_doctor_id && item.id);
    const filteredIds = doctorIds.filter(id => id);
    setSelectedItems(filteredIds);
  }, [kioskList]);

  const handleSubmit = () => {
    associatedoctorswithkiosks({
      endpoint: apiConstants.associatedoctorswithkiosks,
      method: 'POST',
      data: {
        doctor_id: id,
        kiosk_ids: selectedItems
      }
    }).then((result) => {
      setMsg(result.data.message);
    }).catch((error) => {
      console.error('error', error);
    });
    setAssociateModal(false);
    setIsConfirm(true);
    setTimeout(() => {
      setIsConfirm(false);
    }, 2000);
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white">
              <div className="w-full">
                <div className='p-[20px] border-b-[1px] border-b-[#808080] flex justify-center'>
                  <span className='text-[#757776] text-[24px] '>
                    Select Kiosk</span>
                </div>
                <div className="w-full py-[30px] px-[40px] text-[#757776]">
                  <ModalTable
                    list={kioskList}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectAll={handleSelectAll}
                    selectAll={selectAll}
                    selectedItems={selectedItems}
                  />
                </div>
              </div>
              <div className='flex gap-[80px] my-[20px]'>
                <button
                  type='submit'
                  className="bg-[#383678] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                  rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                  style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
                <button
                  type='text'
                  className="bg-[#383678] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                  rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                  style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                  onClick={() => setAssociateModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default AssociateKioskModal;
