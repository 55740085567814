import React, { useEffect, useRef } from 'react';
import * as CanvasJS from '@canvasjs/charts';

const CanvasChart = ({ options }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    const chart = new CanvasJS.Chart(containerRef.current, options);
		chart.render();

    return() => {
      chart.destroy();
    };
  },[options]);

  return (
    <div ref={containerRef} className='w-full relative h-[400px]'>

    </div>
  );
};

export default CanvasChart;