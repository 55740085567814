import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Colorcode } from '../../../common/utils/Colorcode';

const MedicalCertificateModal = ({
  heading,
  handleModalClose,
  children,
  buttonName,
  handleButtonClick,
  justifyEnd
}) => {
  const customerColor = Colorcode();
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white p-3">
              <div className="w-full">
                <div className='grid grid-cols-12 p-[20px] border-b-[2px] border-b-[#808080] items-center'>
                  <div className='col-span-3' onClick={() => handleButtonClick()}>
                    <button
                      className='bg-blue-600 text-white px-[15px] py-[5px] rounded-[50px] text-[12px] text-nowrap'
                      style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                    >
                      {buttonName}
                    </button>
                  </div>
                  <div className={`col-span-7 flex ${justifyEnd ? 'justify-end' : 'justify-center'}`}>
                    <p className='text-[#757776]'>{heading}</p>
                  </div>
                  <div
                    className='col-span-2 flex items-center justify-end cursor-pointer'
                    onClick={() => handleModalClose()}
                  >
                    <FontAwesomeIcon icon={faXmark} style={{ color: 'black' }} />
                  </div>
                </div>
              </div>
              <div className="w-full p-[20px] text-[#757776]">
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MedicalCertificateModal;
