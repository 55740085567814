import React from 'react';

function ModalTable({
  list,
  handleCheckboxChange,
  handleSelectAll,
  selectAll,
  selectedItems
}) {

  return (
    <div className="font-sans">
      <div className="mb-4">
        <input type="checkbox" checked={selectAll} onChange={handleSelectAll} className="mr-2 leading-tight" />
        <span className="text-sm">Select all</span>
      </div>
      <table className="w-full table-auto">
        <thead>
          <tr>
            <th className="px-4 py-2 border-b border-gray-400 bg-gray-200 text-left">Select</th>
            <th className="px-4 py-2 border-b border-gray-400 bg-gray-200 text-left">Kiosk</th>
          </tr>
        </thead>
        <tbody>
          {list.map(item => (
            <tr key={item.id}>
              <td className="px-4 py-2 border-b border-gray-400">
                <input
                  type="checkbox"
                  checked={selectedItems.includes(item.id)}
                  onChange={() => handleCheckboxChange(item)}
                  className="mr-2 leading-tight"
                />
              </td>
              <td className="px-4 py-2 border-b border-gray-400">{item.name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ModalTable;