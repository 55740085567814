import React from 'react';
import PainDiagram from '../../../../../images/BodyPainDiagram.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { updateBodyPainDetails } from '../../../../../reducers/medicalHistorySlice';
import { useAddBodyPainDetailsMutation } from '../../../../../reducers/allReducer';
import { apiConstants } from '../../../../../constants/api';
import { Colorcode } from '../../../../common/utils/Colorcode';

const BodyPainDiagram = ({ clientId, onlyDisplay }) => {
  const customerColor = Colorcode();
  const bodyPainDetails = onlyDisplay ?
    useSelector((state) => state?.medicalHistoryData?.bodyPainDetailsPrescription) :
    useSelector((state) => state?.medicalHistoryData?.bodyPainDetails);
  const [addBodyPainDetails] = useAddBodyPainDetailsMutation();
  const dispatch = useDispatch();

  const handleOnChange = (data) => {
    dispatch(updateBodyPainDetails({ id: data?.body_part_number }));
  };

  const handleSaveBodyPainDetails = () => {
    let painIds = [];
    bodyPainDetails?.map((data) => {
      if (data?.client_selected_pain_id) {
        painIds?.push(data?.body_part_number);
      }
    });
    addBodyPainDetails({
      endpoint: apiConstants?.addBodyPainDetails,
      method: 'POST',
      data: {
        client_id: clientId,
        pain_ids: painIds
      }
    });
  };

  return (
    <div className='pt-[5px]'>
      <div className='grid grid-cols-12 gap-[10px]'>
        <div className='col-span-7'>
          <img src={PainDiagram} alt='' />
        </div>
        <div className='col-span-5'>
          <div className='grid grid-cols-12 gap-[10px]'>
            {bodyPainDetails?.map((data) => (
              <div key={data?.id} className='flex gap-[10px] col-span-6'>
                <input
                  type='checkbox'
                  checked={data?.client_selected_pain_id}
                  onChange={() => handleOnChange(data)}
                  className={`${onlyDisplay && 'pointer-events-none'} h-[20px] w-[20px]`}
                />
                <p>{data?.body_part_number} - {data?.pain_area}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {!onlyDisplay && (
        <div className='p-[40px] flex justify-center'>
          <button
            type='submit'
            className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px]'
            style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
            onClick={() => handleSaveBodyPainDetails()}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default BodyPainDiagram;