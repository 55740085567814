import React, { useState } from 'react';
import PatientDetailBox from '../PatientDetailBox';
import { awsdetails } from '../../../../../constants/awsdetails';
import EcgModal from './EcgModal';

const DisplayVitals = ({
  vitals,
  pacs,
  coordinates
}) => {
  const [showEcgModal, setShowEcgModal] = useState(false);
  const handleAttachmentClick = (data) => {
    const awsUrl = awsdetails?.awsUrl;
    window.open(`${awsUrl}/${data?.attachment_name}`, 'img', 'target=popup');
  };

  const CommonTable = ({ tableData, isPacs }) => {
    return (
      <table className='prescriptionTable w-full h-full'>
        <thead>
          <tr className='bg-[#f2f2f2]'>
            <th className='text-[12px] p-[10px] text-[#555] text-start'>Device Name</th>
            <th className='text-[12px] p-[10px] text-[#555] text-start'>Value</th>
          </tr>
        </thead>
        <tbody className='text-[13px] text-[#383678]'>
          {tableData?.length === 0 && (
            <div className='p-[20px]'>
            <p className='text-sm text-[#777] fw-500 text-center'>No records found</p>
            </div>
          )}
          {tableData?.map((data, index) => (
            <tr key={index}>
              <td className='capitalize text-[#777] fw-500'>{data?.name}</td>
              {isPacs ? (
                <td
                  className='cursor-pointer underline text-[#2889f0]'
                  onClick={() => handleAttachmentClick(data)}
                >
                  {data?.attachment_description}
                </td>
              ) : (
                <td className='text-[#777] fw-500'>{data?.value}</td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const handleEcgModalClose = () => {
    setShowEcgModal(false);
  };

  return (
    <>
      {showEcgModal && (
        <EcgModal data={coordinates} handleModalClose={handleEcgModalClose} />
      )}
      <div className='flex flex-col gap-[30px]'>
        {coordinates && coordinates?.length > 0 && (
          <div className='mt-[-20px] mb-[-10px]'>
            <button
                className='text-white px-[15px] py-[5px] rounded-[50px] text-[13px] text-nowrap'
                style={{ background: 'linear-gradient(40.66deg,#0094ff,#0094ff)'}}
                onClick={() => setShowEcgModal(true)}
              >
                ECG Graph
              </button>
          </div>
        )}
        <div className='grid grid-cols-12 gap-[30px]'>
          <div className='col-span-6'>
            <PatientDetailBox title='Vitals'>
              <CommonTable tableData={vitals} />
            </PatientDetailBox>
          </div>
          <div className='col-span-6'>
            <PatientDetailBox title='PACS'>
              <CommonTable tableData={pacs} isPacs />
            </PatientDetailBox>
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayVitals;
