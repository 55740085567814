import React from 'react';
import successImage from '../../../images/success.png';

const SectionSuccessModal = ({ isEdit }) => {
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white p-[20px]">
              <div className='flex flex-col justify-center items-center gap-[20px]'>
                <img src={successImage} alt='' className='w-[100px] h-[100px]' />
                <p className='text-[#737575] text-[27px]'>
                  {isEdit ? 'Section Updated Successfully' : 'Section Added Successfully'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSuccessModal;
