import React, { useState, useEffect } from 'react';
import { useGetUsersAssociationMutation, useCreateAssociateUsersWithKioskMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import Loader from '../common/Loader';
import SharedTable from '../common/SharedTable';
import { useNavigate, useParams } from 'react-router-dom';
import { Colorcode } from '../common/utils/Colorcode';
import ConfirmModal from '../common/ConfirmModal';

const ParamedicAssociate = () => {
  const { id } = useParams();
  const customerColor = Colorcode();
  const navigate = useNavigate();
  const [selectedId, setSelectedId] = useState([]);
  const [GetList] = useGetUsersAssociationMutation();
  const [updateKoisk] = useCreateAssociateUsersWithKioskMutation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [pageNumber, setPageNumber] = useState('1');
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });
  const [showModal, setShowModal] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState(null);

  const tableHeaders = [
    { field: 'user_id', headerName: 'ID' },
    { field: 'name', headerName: 'Name' },
    { field: 'phone_number', headerName: 'Phone Number' },
    { field: 'fontdesk_list_select', headerName: 'Select' }
  ];

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header.field === 'name') {
      header.field = 'first_name';
    }

    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const handleSelect = (item) => {
    if (selectedId?.includes(item?.user_id)) {
      setSelectedId(prevIds => prevIds.filter(id => id !== item.user_id));
    } else {
      setSelectedId(prevIds => [...prevIds, item.user_id]);
    }
  };

  const fetchTableData = (page) => {
    setIsLoading(true);
    GetList({
      endpoint: `${apiConstants.getUsersForAssociation}/${page}/paramedic`,
      method: 'POST',
      data: {
        consult_type: '',
        current_time: '',
        data: {},
        date: '',
        field: sort.headerFiled,
        type: sort?.sortType,
        kiosk_id: ''
      }
    }).then((result) => {
      let frontDeskListData = result?.data?.list;
      const modifiedData = frontDeskListData.map(item => ({
        ...item,
        fontdesk_list_select: <div>
          <input type='checkbox' style={{ height: '20px', width: '20px' }} />
        </div>
      }));

      setTableData(modifiedData);
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setPageNumber(parseInt(result?.data?.current_page));
    }).catch(() => {
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchTableData(pageNumber);
  }, [pageNumber, sort]);

  const handleOnPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  useEffect(() => {
    let timeoutId;
    if (showModal) {
      timeoutId = setTimeout(() => {
        setShowModal(false);
      }, 2000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showModal]);

  const handleSave = () => {
    updateKoisk({
      endpoint: `${apiConstants.associateUsersWithKiosk}`,
      method: 'POST',
      data: {
        kiosk_id: id,
        user_ids: selectedId.join(',')
      }
    }).then((data) => {
      setShowModal(true);
      if (data?.error) {
        setMessage(data?.error?.data?.error);
        setError(true);
      } else {
        setError(false);
        fetchTableData();
        setMessage('Associate Successfully');
      }
    }).catch(() => {
    });
  };

  return (
    <div className="row mt-6 mx-24">
      {showModal &&
        <ConfirmModal msg={message} isError={isError} />
      }

      {isLoading ? (
        <div className="flex items-center justify-center py-72">
          <Loader />
        </div>
      ) : (
        <div>
          <button
            type='text'
            className="bg-[#383678] text-white w-[150px] h-[40px] items-center mb-2
                                    rounded-[20px] focus:outline-none text-[16px] flex justify-center"
            style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
            onClick={() => navigate(`/admin/kiosk_view/${id}`)}
          >
            Back
          </button>
          <div className="mt-7">
            <h4 style={{ borderColor: customerColor[0] }} className="text-2xl font-semibold border-b-4
                        text-[Coolvetica Rg] list-header-text w-[170px] text-[#4f4f4f]">
              Paramedic List
            </h4>
          </div>
          <div className="mt-10">
            <SharedTable
              tableData={tableData}
              tableHeaders={tableHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              handleOnClickSort={handleOnClickSort}
              currentPageNumber={pageNumber}
              handleSelect={handleSelect}
              location={'paramedic_associate_list'}
            />
          </div>
          <div>
            <button
              type='text'
              className="bg-[#383678] text-white w-[150px] h-[40px] items-center mb-2
                                    rounded-[20px] focus:outline-none text-[16px] flex justify-center"
              style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
              onClick={() => handleSave()}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default ParamedicAssociate;