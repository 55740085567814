import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Counter from '../components/Counter';
import LoginPage from '../components/Login';
import TestPage from '../components/common/TestPage';
import WelcomePage from '../components/WelcomeScreen';
import AdminRoutes from '../components/Admin/AdminRoutes';
import DoctorRoutes from '../components/Doctor/DoctorRoutes';
import Loader from '../components/common/Loader';
import Modal from '../components/common/Modal';
import AgentsRoutes from '../components/Agents/AgentsRoutes';
import ForgotPassword from '../components/Forgotpassword/index';
import OTP from '../components/Otp/index';
import ChangePassword from '../components/ChangePassword';

const ProjectRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<WelcomePage />} />
      <Route path="/counter" element={<Counter />} />
      <Route path="/testPage" element={<TestPage />} />
      <Route path="/admin/*" element={<AdminRoutes />} />
      <Route path="/doctor/*" element={<DoctorRoutes />} />
      <Route path="/agent/*" element={<AgentsRoutes />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/forgotpassword/otp" element={<OTP />} />
      <Route path="/loader" element={<Loader />} />
      <Route path="/modal" element={<Modal />} />
      <Route path="/resetpassword" element={<ChangePassword />} />
    </Routes>
  );
};

export default ProjectRoutes;
