/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Loader from '../common/Loader';
import {
  useGetVitalsAnalyticsMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import ConfirmModal from '../common/ConfirmModal';
import { useParams } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClose } from '@fortawesome/free-solid-svg-icons';
import AnalyticsModal from './AnalyticsModal';

function PatientDetailView() {
  const [getList] = useGetVitalsAnalyticsMutation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isError, setError] = useState(false);
  const [message, setMessage] = useState(null);
  const [response, setResponse] = useState(null);
  const [heartRateByDate, setHeartRateByDate] = useState(null);
  const [bloodOxygenByDate, setBloodOxygenByDate] = useState(null);
  const [BloodPressureByDate, setBloodPressureByDate] = useState(null);
  const [temperatureByDate, setTemperatureByDate] = useState(null);
  const [weightByDate, setWeightByDate] = useState(null);
  const [heightByDate, setHeightByDate] = useState(null);
  const [filterPopup, setfilterPopup] = useState(false);
  const [filterApplied, setfilterApplied] = useState(false);
  const [isCleared, setIsCleared] = useState(false);
  const [objectData, setObjectData] = useState({
    start_date: '',
    end_date: ''
  });

  const handleClear = () => {
    setfilterApplied(false);
    setObjectData({
      start_date: '',
      end_date: ''
    });
    setIsCleared(!isCleared);
  };

  const fetchTableData = () => {
    setIsLoading(true);
    getList({
      endpoint: `${apiConstants.getVitalsAnalytics}`,
      method: 'POST',
      data: {
        client_id: id,
        end_date: objectData?.end_date,
        start_date: objectData?.start_date
      }
    }).then((result) => {
      if (result?.error) {
        setShowModal(true);
        setMessage(result?.error?.data?.error);
        setError(true);
      } else {
        setResponse(result?.data);
      }
    }).catch(() => {
    }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchTableData();
  }, [isCleared]);

  useEffect(() => {
    if (response) {
      if (response.blood_oxygen_by_date) {
        let blood_oxygen_by_date = {
          labels: [],
          datasets: [
            {
              label: 'Blood Oxygen by date',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(87, 193, 123)',
              borderColor: 'rgba(87, 193, 123)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(87, 193, 123)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgb(87, 193, 123)',
              pointHoverBorderColor: 'rgb(87, 193, 123)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: []
            }
          ]
        };
        for (let i = 0; i < response.blood_oxygen_by_date.length; i++) {
          blood_oxygen_by_date.labels.push(moment(response.blood_oxygen_by_date[i].appointment_date).format('YYYY-MM-DD'));
          blood_oxygen_by_date.datasets[0].data.push(response.blood_oxygen_by_date[i].blood_oxygen);
        }
        setBloodOxygenByDate(blood_oxygen_by_date);
      }

      if (response.heart_rate_by_date) {
        let heart_rate_by_date = {
          labels: [],
          datasets: [
            {
              label: 'Heart Rate by date',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(87, 193, 123)',
              borderColor: 'rgba(87, 193, 123)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(87, 193, 123)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgb(87, 193, 123)',
              pointHoverBorderColor: 'rgb(87, 193, 123)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: []
            }
          ]
        };
        for (let i = 0; i < response.heart_rate_by_date.length; i++) {
          heart_rate_by_date.labels.push(moment(response.heart_rate_by_date[i].appointment_date).format('YYYY-MM-DD'));
          heart_rate_by_date.datasets[0].data.push(response.heart_rate_by_date[i].bpm);
        }
        setHeartRateByDate(heart_rate_by_date);
      }

      if (response.bp_systolic_by_date) {
        let bp_systolic_by_date = {
          labels: [],
          datasets: [
            {
              label: 'BP- Systolic',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(87, 193, 123)',
              borderColor: 'rgba(87, 193, 123)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(87, 193, 123)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgb(87, 193, 123)',
              pointHoverBorderColor: 'rgb(87, 193, 123)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: []
            }
            ,
            {
              label: 'Bp- Diastolic',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(255,140,0)',
              borderColor: 'rgba(255,140,0)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(255,140,0)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgb(255,140,0)',
              pointHoverBorderColor: 'rgb(255,140,0)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: []
            }
          ]
        };
        for (let i = 0; i < response.bp_systolic_by_date.length; i++) {
          bp_systolic_by_date.labels.push(moment(response.bp_systolic_by_date[i].appointment_date).format('YYYY-MM-DD'));
          bp_systolic_by_date.datasets[0].data.push(response.bp_systolic_by_date[i].bp_systolic);
          bp_systolic_by_date.datasets[1].data.push(response.bp_diastolic_by_date[i].bp_diastolic);
        }
        setBloodPressureByDate(bp_systolic_by_date);
      }

      if (response.temperature_by_date) {
        let temperature_by_date = {
          labels: [],
          datasets: [
            {
              label: 'temperature by date',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(87, 193, 123)',
              borderColor: 'rgba(87, 193, 123)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(87, 193, 123)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgb(87, 193, 123)',
              pointHoverBorderColor: 'rgb(87, 193, 123)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: []
            }
          ]
        };
        for (let i = 0; i < response.temperature_by_date.length; i++) {
          // eslint-disable-next-line max-len
          temperature_by_date.labels.push(moment(response.temperature_by_date[i].appointment_date).format('YYYY-MM-DD'));
          temperature_by_date.datasets[0].data.push(response.temperature_by_date[i].temperature);
        }
        setTemperatureByDate(temperature_by_date);
      }

      if (response.weight_by_date) {
        let weight_by_date = {
          labels: [],
          datasets: [
            {
              label: 'weight by date',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(87, 193, 123)',
              borderColor: 'rgba(87, 193, 123)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(87, 193, 123)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgb(87, 193, 123)',
              pointHoverBorderColor: 'rgb(87, 193, 123)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: []
            }
          ]
        };
        for (let i = 0; i < response.weight_by_date.length; i++) {
          weight_by_date.labels.push(moment(response.weight_by_date[i].appointment_date).format('YYYY-MM-DD'));
          weight_by_date.datasets[0].data.push(response.weight_by_date[i].weight);
        }
        setWeightByDate(weight_by_date);
      }

      if (response.height_by_date) {
        let height_by_date = {
          labels: [],
          datasets: [
            {
              label: 'height by date',
              fill: false,
              lineTension: 0.1,
              backgroundColor: 'rgba(87, 193, 123)',
              borderColor: 'rgba(87, 193, 123)',
              borderCapStyle: 'butt',
              borderDash: [],
              borderDashOffset: 0.0,
              borderJoinStyle: 'miter',
              pointBorderColor: 'rgba(87, 193, 123)',
              pointBackgroundColor: '#fff',
              pointBorderWidth: 1,
              pointHoverRadius: 5,
              pointHoverBackgroundColor: 'rgb(87, 193, 123)',
              pointHoverBorderColor: 'rgb(87, 193, 123)',
              pointHoverBorderWidth: 2,
              pointRadius: 1,
              pointHitRadius: 10,
              data: []
            }
          ]
        };
        for (let i = 0; i < response.height_by_date.length; i++) {
          height_by_date.labels.push(moment(response.height_by_date[i].appointment_date).format('YYYY-MM-DD'));
          height_by_date.datasets[0].data.push(response.height_by_date[i].height);
        }
        setHeightByDate(height_by_date);
      }
    }
  }, [response]);

  return (
    <div className="p-6">
      {showModal &&
        <ConfirmModal msg={message} isError={isError} />
      }
      {isLoading ? <div className="flex items-center justify-center py-72">
        <Loader />
      </div> :
        <>
          {filterPopup &&
            <AnalyticsModal
              setfilterPopup={setfilterPopup}
              setObjectData={setObjectData}
              objectData={objectData}
              getAnalytics={fetchTableData} // refresh API
              setfilterApplied={setfilterApplied}
            />
          }

          <div className="col-span-12 flex flex-col items-end mb-2">
            <div className='flex items-center gap-[50px]'>
              <h6 className="data-filter-selected">
                {filterApplied ? `Data is shown for dates from ${moment(objectData.start_date).format('Do MMM YYYY')} to 
          ${moment(objectData.end_date).format('Do MMM YYYY')}` : 'Data is shown for last 30 days'}</h6>
              <div className="graph-tabular-btn flex gap-2 w-[155px] h-[35px] items-center cursor-pointer"
                onClick={() => setfilterPopup(true)}
              >
                <div>Filter by Date</div>
                <FontAwesomeIcon icon={faCalendarAlt}
                  className='text-[#fff] right-[40px] top-[25px] text-[20px] cursor-pointer'
                />
              </div>
            </div>
            {filterApplied && <div className="flex gap-2 w-[155px] h-[35px] items-center"
              onClick={() => handleClear()}
            >
              <div>Clear Filter</div>
              <FontAwesomeIcon icon={faClose}
                className='text-[#000] right-[40px] top-[25px] text-[20px] cursor-pointer'
              />
            </div>}
          </div>

          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-3 border border-black p-8 rounded-md">
              <h1 className="text-center text-[36px] mb-[10px]">
                {response?.average_blood_oxygen ? response?.average_blood_oxygen : 0}</h1>
              <h6 className="text-center text-[12px]">Patient Average Blood Oxygen</h6>
            </div>
            <div className="col-span-3 border border-black p-8 rounded-md">
              <h1 className="text-center text-[36px] mb-[10px]">
                {response?.average_weight ? response?.average_weight : 0}</h1>
              <h6 className="text-center text-[12px]">Average weight</h6>
            </div>
            <div className="col-span-3 border border-black p-8 rounded-md">
              <h1 className="text-center text-[36px] mb-[10px]">
                {response?.average_temperature ? response?.average_temperature : 0}</h1>
              <h6 className="text-center text-[12px]">Average temperature</h6>
            </div>
            <div className="col-span-3 border border-black p-8 rounded-md">
              <h1 className="text-center text-[36px] mb-[10px]">
                {response?.average_height ? response?.average_height : 0}</h1>
              <h6 className="text-center text-[12px]">Average height</h6>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4 my-4">
            <div className="col-span-3">
              <div className="border border-black p-8 rounded-md">
                <h1 className="text-center text-[36px] mb-[10px]">
                  {response?.average_bp_diastolic ? response?.average_bp_diastolic : 0}</h1>
                <h6 className="text-center text-[12px]">Average Diastolic Blood Pressure</h6>
              </div>
              <div className="border border-black p-8 rounded-md my-4">
                <h1 className="text-center text-[36px] mb-[10px]">
                  {response?.average_bp_systolic ? response?.average_bp_systolic : 0}</h1>
                <h6 className="text-center text-[12px]">Average Systolic Blood Pressure</h6>
              </div>
              <div className="border border-black p-8 rounded-md">
                <h1 className="text-center text-[36px] mb-[10px]">
                  {response?.average_blood_glucose ? response?.average_blood_glucose : 0}</h1>
                <h6 className="text-center text-[12px]">Average blood glucose</h6>
              </div>
            </div>
            <div className="col-span-9 border border-black p-4 rounded-md">
              <div>Heart Rate</div>
              <div>
                {heartRateByDate ?
                  <Line data={heartRateByDate} />
                  : <div>No Records Found</div>
                }
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4 my-4">
            <div className="col-span-6 border border-black p-4 rounded-md">
              <div>Blood Oxygen over Time</div>
              <div>
                {bloodOxygenByDate ?
                  <Line data={bloodOxygenByDate} />
                  : <div>No Records Found</div>
                }
              </div>
            </div>
            <div className="col-span-6 border border-black p-4 rounded-md">
              <div>Blood Pressure</div>
              <div>
                {BloodPressureByDate ?
                  <Line data={BloodPressureByDate} />
                  : <div>No Records Found</div>
                }
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4 my-4">
            <div className="col-span-6 border border-black p-4 rounded-md">
              <div>Temperature</div>
              <div>
                {temperatureByDate ?
                  <Line data={temperatureByDate} />
                  : <div>No Records Found</div>
                }
              </div>
            </div>
            <div className="col-span-6 border border-black p-4 rounded-md">
              <div>Weight</div>
              <div>
                {weightByDate ?
                  <Line data={weightByDate} />
                  : <div>No Records Found</div>
                }
              </div>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-4 my-4">
            <div className="col-span-6 border border-black p-4 rounded-md">
              <div>Height</div>
              <div>
                {heightByDate ?
                  <Line data={heightByDate} />
                  : <div>No Records Found</div>
                }
              </div>
            </div>
          </div>
        </>}

    </div>
  );
}

export default PatientDetailView;