import React from 'react';
import moment from 'moment';
import SharedButton from '../common/SharedButton';
import dayjs from 'dayjs';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import errorImage from '../../images/icons/warning_icon.png';
import { Colorcode } from '../common/utils/Colorcode';
const customerColor = Colorcode();

export const SlotModal = (props) => {
  return (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-50">
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white rounded-3xl shadow-xl w-1/3">
            <div className="row mb-5">
              <div className='text-center p-5'>
                <label>{props.title}</label>
              </div>
              <hr className="border-gray-500"></hr>
              <div className="flex pt-5 pl-10 w-full">
                {/* <label className="mr-4 text-sm font-medium text-gray-700"></label> */}
                <div className="w-1/2">
                  <input
                    type="radio"
                    id="particularDay"
                    name="slotOption"
                    value="Particular Day."
                    checked={props.radioValue === 'Particular Day.'}
                    onChange={props.handleRadioValue}
                    className="mr-2"
                  />
                  <label htmlFor="particularDay" className="text-sm font-medium text-gray-700 cursor-pointer">
                    Particular Day
                  </label>
                </div>
                <div className="flex items-center  w-1/2">
                  <input
                    type="radio"
                    id="multipleDays"
                    name="slotOption"
                    value="Multiple Days."
                    checked={props.radioValue === 'Multiple Days.'}
                    onChange={props.handleRadioValue}
                    className="mr-2"
                  />
                  <label htmlFor="multipleDays" className="text-sm font-medium text-gray-700 cursor-pointer">
                    Multiple Days
                  </label>
                </div>
              </div>
              {props.radioValue === 'Particular Day.' && (
                <div className="row pr-10 pt-1 pl-10">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Date</label>
                    <input
                      type="date"
                      value={props.particularDate === '' ? moment().format('YYYY-MM-DD') : props.particularDate}
                      onChange={props.onParticularDate}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300
                          focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    {props.errorAddData.particularDate && (
                      <label className="text-red-500">{props.errorAddData.particularDate}</label>
                    )}
                  </div>
                  <div className='flex gap-10 pt-5 pb-10'>
                    <div className='flex-1 w-9/12'>
                      {/* <label className="block text-sm font-medium text-gray-700">Start time</label> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          desktopModeMediaQuery="mobile"
                          label="Start time"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                          }}
                          minutesStep={5}
                          format='hh:mm a'
                          onAccept={(val) => {
                            props.onTimeChange(val.$d, 'startTime');
                          }}
                        />
                      </LocalizationProvider>

                      {props.errorAddData.startTime &&
                        <label className="text-red-500">{props.errorAddData.startTime}</label>}
                    </div>

                    <div className='flex-1 w-9/12'>
                      {/* <label className="block text-sm font-medium text-gray-700">End time</label> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>

                        <TimePicker
                          desktopModeMediaQuery="mobile"
                          label="End Time"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock
                          }}
                          minutesStep={5}
                          format='hh:mm a'
                          onAccept={(val) => {
                            props.onTimeChange(val.$d, 'endTime');
                          }}
                        />

                      </LocalizationProvider>
                      {props.errorAddData.endTime &&
                        <label className="text-red-500">{props.errorAddData.endTime}</label>}
                    </div>

                  </div>

                </div>
              )}
              {/* Add similar structure for 'Multiple Days.' */}

              {props.radioValue === 'Multiple Days.' && (
                <div className="row pt-5  pl-10 pr-10">
                  <div className="flex gap-10">
                    <div className='flex-1'>
                      <label className="block text-sm font-medium text-gray-700">Start Date</label>
                      <input
                        type="date"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300
                      focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        value={props.startDate === '' ?
                          moment(props.currentDate).startOf('month').format('YYYY-MM-DD') : props.startDate}
                        id="startDate"
                        // onBlur={props.onTimeBlur()}
                        onChange={(e) => props.onMultipleDate(e)}
                      />
                      {props.errorAddData && props.errorAddData['startDate'] && (
                        <label className="text-red-500">{props.errorAddData['startDate']}</label>
                      )}
                    </div>
                    <div className='flex-1 '>
                      <label className="block text-sm font-medium text-gray-700">End date</label>
                      <input
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300
                      focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        type="date"
                        value={props.endDate === '' ?
                          moment(props.currentDate).startOf('month').format('YYYY-MM-DD') : props.endDate}
                        id="endDate"
                        // onBlur={props.onTimeBlur()}
                        onChange={(e) => props.onMultipleDate(e)}
                      />
                      {props.errorAddData && props.errorAddData['endDate'] && (
                        <label className="text-red-500">{props.errorAddData['endDate']}</label>
                      )}
                      {props.endDate && props.startDate &&
                        moment(props.startDate).diff(props.endDate, 'seconds') > 0 && (
                          <p className="text-red-500 text-sm mt-2">Start date shouldnt be greater than end date</p>
                        )}
                    </div>

                  </div>

                  <div className='flex gap-10 pt-5 pb-10'>
                    <div className='flex-1 w-9/12'>
                      {/* <label className="block text-sm font-medium text-gray-700">Start time</label> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          desktopModeMediaQuery="mobile"
                          label="Start time"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock
                          }}
                          minutesStep={5}
                          format='hh:mm a'
                          onAccept={(val) => {
                            props.onTimeChange(val.$d, 'startTime');
                          }}
                        />
                      </LocalizationProvider>

                      {props.errorAddData.startTime &&
                        <label className="text-red-500">{props.errorAddData.startTime}</label>}
                    </div>

                    <div className='flex-1 w-9/12'>
                      {/* <label className="block text-sm font-medium text-gray-700">End time</label> */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>

                        <TimePicker
                          desktopModeMediaQuery="mobile"
                          label="End Time"
                          viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock
                          }}
                          minutesStep={5}
                          format='hh:mm a'
                          onAccept={(val) => {
                            props.onTimeChange(val.$d, 'endTime');
                          }}
                        />

                      </LocalizationProvider>
                      {props.errorAddData.endTime &&
                        <label className="text-red-500">{props.errorAddData.endTime}</label>}
                    </div>

                  </div>
                </div>
              )}

            </div>
            <hr className="border-gray-500 pt-6"></hr>
            <div className="flex items-center pb-5 pl-12 pr-12">
              <SharedButton className="rounded-[20px] text-white h-10
                            px-[50px] py-[10px] text-sm  font-bold mx-auto flex"
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
onClick={() => props.addEvent()}
                title="Add" />
              <SharedButton className="rounded-[20px] text-white h-10
                            px-[50px] py-[10px] text-sm  font-bold mx-auto flex"
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
onClick={() => props.modalClose()}
                title="Close" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const FailedModal = (props) => {
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-50">
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white rounded-3xl shadow-xl h-80 w-1/3">
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
              className="flex justify-center items-center w-full mt-10"
              width="200" height="100">
                <path fill="#c52626" d="M569.5 440C588 472 564.8 512 527.9 512H48.1
    c-36.9 0-60-40.1-41.6-72L246.4 24c18.5-32 64.7-32 83.2 0l239.9 416zM288
    354c-25.4 0-46 20.6-46 46s20.6 46 46 46 46-20.6 46-46-20.6-46-46-46zm-43.
    7-165.3l7.4 136c.3 6.4 5.6 11.3 12 11.3h48.5c6.4 0 11.6-5 12-11.3l7.4-136c.
    4-6.9-5.1-12.7-12-12.7h-63.4c-6.9 0-12.4 5.8-12 12.7z"/>
              </svg> */}
              <img src={errorImage} className="block mx-auto" alt="Error Image" />
            <div className="text-center">

              <label style={{
                fontFamily: 'Poppins', fontSize: 18,
                color: '#737575'
              }}>{props.failedMessage}</label>
            </div>
            <hr className="border-gray-500 pt-6"></hr>
            <div className="items-center pb-5 pl-12 pr-12">
              <SharedButton className="rounded-[20px] text-white h-10
                            px-[50px] py-[10px] text-sm  font-bold mx-auto flex"
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
onClick={() => props.openDeleteModal('failModal')}
                title="OK" />
            </div>
            <div style={{ 'justify-content': 'space-around' }}>
              {/* <Button bsClass="col-md-2 concept-modal-btn-update" style={{ marginLeft: '42%' }}
>Ok</Button> */}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export const UpdateEventModal = (props) => {
  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-50">
        <div className="flex items-center justify-center min-h-screen">
          <div className="bg-white rounded-3xl shadow-xl  w-1/3">
            <div className='text-center p-5'>
              <label>Update Slot</label>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="h-6 w-6 cancel-update-slot-icon cursor-pointer float-right"
                onClick={() =>
                  props.openDeleteModal('closeUpdate')
                  // this.setState({ startTime: '', endTime: '', currentEditData: {}, updateEventModal: false })
                }
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
            <hr className="border-gray-500"></hr>
            <div className="row pr-10 pt-10 pl-10">
              <div>
                <label className="block text-sm font-medium text-gray-700">Date</label>
                <input
                  type="date"
                  value={props.currentEditData.date}
                  id="startDate" disabled
                  className="block w-full rounded-md cursor-not-allowed border-gray-300 shadow-sm
                      focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
                {props.errorAddData.particularDate && (
                  <label className="text-red-500">{props.errorAddData.particularDate}</label>
                )}
              </div>
              <div className='flex gap-10 pt-10 pb-10'>
                <div className='flex-1 w-9/12'>
                  {/* <label className="block text-sm font-medium text-gray-700">Start time</label> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimePicker
                      desktopModeMediaQuery="mobile"
                      label="Start time"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock
                      }}
                      minutesStep={5}
                      defaultValue={dayjs(props.startTime)}
                      id="startTime"
                      format='hh:mm a'
                      // onBlur={(e) => props.handleupdateblur(e)}
                      onAccept={(val) => {
                        props.onTimeChange(val.$d, 'startTime');
                      }}
                      renderInput={(props) => <input {...props} />}
                      disabled={props.disableButtons}
                    />
                  </LocalizationProvider>

                  {props.errorAddData && props.errorAddData.startTime && props.errorAddData.editStartTime !== '' &&
                    <label className="text-red-500">{props.errorAddData.startTime}</label>}
                </div>

                <div className='flex-1 w-9/12'>
                  {/* <label className="block text-sm font-medium text-gray-700">End time</label> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>

                    <TimePicker
                      desktopModeMediaQuery="mobile"
                      label="End Time"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock
                      }}
                      minutesStep={5}
                      value={dayjs(props.endTime)}
                      id="endTime"
                      format='hh:mm a'
                      // onBlur={(e) => props.handleupdateblur(e)}
                      onAccept={(val) => {
                        props.onTimeChange(val.$d, 'endTime');
                      }}
                      onChange={(e) => {
                        console.log('chnge function', e);
                      }}
                      disabled={props.disableButtons}
                    />

                  </LocalizationProvider>
                  {props.errorAddData && props.errorAddData.endTime && props.errorAddData.editEndTime !== '' &&
                    <label className="text-red-500">{props.errorAddData.endTime}</label>}
                </div>

              </div>

            </div>
            <hr className="border-gray-500 pt-6"></hr>
            <div className="flex items-center pb-5 pl-12 pr-12">
              <SharedButton className="rounded-[20px] text-white h-10
                            px-[50px] py-[10px] text-sm  font-bold mx-auto flex"
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
onClick={() => props.updateEvent()}
                disabled={props.disableButtons}
                title="Update" />
              <SharedButton className="rounded-[20px] text-white h-10
                            px-[50px] py-[10px] text-sm  font-bold mx-auto flex"
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
                onClick={() => props.openDeleteModal('openDelete')}
                disabled={props.disableButtons}
                title="Delete" />
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export const DeleteModal = (props) => {
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-800 bg-opacity-50">
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white rounded-3xl shadow-xl  w-1/3">
          <div className='text-center p-5'>
            <label>Delete Slot</label>
          </div>
          <hr className="border-gray-500"></hr>
          <div className="row p-10">
            <label className="text-lg text-gray-600 font-medium mt-4">
              Are you sure you want to delete this slot?
            </label>
            <textarea placeholder="Enter Reason"
              className="border border-gray-300 mt-6 p-2 rounded-lg w-full" value={props.deleteEventReason}
              onChange={(e) => props.handledeleteReason(e)}
            // onBlur={this.onTimeBlur.bind(this)}
            >
            </textarea>
            {props.errorAddData && props.errorAddData['deleteReason'] !== '' &&
              <label style={{ color: 'red' }}>{props.errorAddData['deleteReason']}</label>
            }
          </div>
          <hr className="border-gray-500 pt-6"></hr>
          <div className="flex items-center pb-5 pl-12 pr-12">
            <SharedButton className="rounded-[20px] text-white h-10
                            px-[50px] py-[10px] text-sm  font-bold mx-auto flex"
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
onClick={() => props.deleteEvent()}
              title="Yes" />
            <SharedButton className="rounded-[20px] text-white h-10
                            px-[50px] py-[10px] text-sm  font-bold mx-auto flex"
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
              onClick={() => props.openDeleteModal('confirmDelete')}
              title="No" />
          </div>
        </div>
      </div>
    </div>
  );
};
