import React, { useEffect, useState } from 'react';
import PatientProfile from './PatientProfle';
import { useParams } from 'react-router-dom';
import Symptoms from './Symptoms';
import Vitals from './Vitals';
import TeleExamination from './TeleExamination';
import Investigation from './Investigation';
import Diagnosis from './Diagnosis';
import {
  useLazyGetCoordinatesQuery,
  useLazyGetPatientVitalsQuery,
  useLazyListAppointmentDataQuery
} from '../../../../reducers/allReducer';
import { apiConstants } from '../../../../constants/api';
import { useDispatch } from 'react-redux';
import {
  updatFieldValue,
  updateDrugsData,
  updateSelectedData
} from '../../../../reducers/selectedDropdownValuesSlice';
import Prescription from './Prescription/Prescription';
import { getPatientHeaders } from '../common/PatientHeaderOptions';
// import { Colorcode } from '../../../common/utils/Colorcode';

const PatientDetails = () => {
  // const customerColor = Colorcode();
  const { appointmentId } = useParams();
  const dispatch = useDispatch();
  const [getListAppointmentData] = useLazyListAppointmentDataQuery();
  const [getPatientVitals] = useLazyGetPatientVitalsQuery();
  const [getCoordinates] = useLazyGetCoordinatesQuery();
  // const [followupDate, setFollowupDate] = useState();
  const [referredInfo, setReferredInfo] = useState({
    referred_to: null,
    refer_to_higher_center: 0
  });
  const [showPatientHeaderDetail, setShowPatientHeaderDetail] = useState({
    showSymptoms: true,
    showVitals: false,
    showTeleExamination: false,
    showInvestigation: false,
    showDiagnosis: false,
    showPrescription: false
  });
  const [patientInfoHeaderOptions, setPatientInfoHeaderOptions] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState('Symptoms');
  const [showPatientDetailCard, setShowPatientDetailCard] = useState(true);

  const dropdownValuesTitleArray = [
    'symptoms',
    'investigation',
    'differential_diagnosis',
    'diagnosis',
    'provisional_diagnosis'
  ];

  const addedNotesValuesTitleArray = [
    'diagnosis_add_note',
    'differential_diagnosis_add_note',
    'presenting_illness',
    'provisional_diagnosis_add_note',
    'symptoms_add_note',
    'tele_examination'
  ];

  const setAddedNotesValuesFromApi = (data) => {
    addedNotesValuesTitleArray?.map((title) => (
      dispatch(updatFieldValue({ fieldName: title, fieldValue: data?.[title] }))
    ));
  };

  const setDropdownValuesFromApi = (data) => {
    dropdownValuesTitleArray?.map((title) => (
      dispatch(updateSelectedData({ fieldName: title, fieldValue: data?.[title] }))
    ));
  };

  const setDrugsData = (data) => {
    const valuesArray = [];
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        // Push the value corresponding to the current key into the array
        valuesArray.push(data[key]);
      }
    }
    dispatch(updateDrugsData(valuesArray));
  };

  const handleGetListAppointmentData = () => {
    getListAppointmentData({
      endpoint: `${apiConstants.listAppointmentData}/${appointmentId}`
    }).then((result) => {
      setDropdownValuesFromApi(result?.data);
      setAddedNotesValuesFromApi(result?.data?.appointment_data);
      setDrugsData(result?.data?.drugs);
      if (result?.data?.appointment_data?.followup_date) {
        const date = new Date(result?.data?.appointment_data?.followup_date);
        // setFollowupDate(new Date(result?.data?.appointment_data?.followup_date));
        dispatch(updatFieldValue({
          fieldName: 'followupDate',
          fieldValue: date
        }));
      }
      dispatch(updatFieldValue({
        fieldName: 'tele_examination',
        fieldValue: result?.data?.appointment_data?.tele_examination
      }));
      dispatch(updatFieldValue({
        fieldName: 'presenting_illness',
        fieldValue: result?.data?.appointment_data?.presenting_illness
      }));
      if (result?.data?.appointment_data?.duration_number !== null) {
        dispatch(updatFieldValue({
          fieldName: 'duration_number',
          fieldValue: {
            label: result?.data?.appointment_data?.duration_number,
            value: result?.data?.appointment_data?.duration_number
          }
        }));
      }
      if (result?.data?.appointment_data?.duration_type !== null) {
        dispatch(updatFieldValue({
          fieldName: 'duration_type',
          fieldValue: {
            label: result?.data?.appointment_data?.duration_type,
            value: result?.data?.appointment_data?.duration_type
          }
        }));
      }
      setReferredInfo({
        referred_to: result?.data?.appointment_data?.referred_to,
        refer_to_higher_center: result?.data?.appointment_data?.refer_to_higher_center
      });
      dispatch(updatFieldValue({
        fieldName: 'pdfId',
        fieldValue: result?.data?.appointment_data?.pdf_id
      }));
      dispatch(updatFieldValue({
        fieldName: 'appointmentType',
        fieldValue: result?.data?.appointment_data?.appointment_type
      }));
      dispatch(updatFieldValue({
        fieldName: 'selfCare',
        fieldValue: result?.data?.appointment_data?.prescription_instructions
      }));
    });
  };

  const handleGetPatientVitals = () => {
    getPatientVitals({
      endpoint: `${apiConstants?.getPatientVitals}/${appointmentId}`
    }).then((result) => {
      dispatch(updatFieldValue({
        fieldName: 'vitals',
        fieldValue: result?.data
      }));
    });
  };

  const handleGetEcgCoordinates = () => {
    getCoordinates({
      endpoint: `${apiConstants?.getCoordinates}?appointment_id=${appointmentId}`
    }).then((result) => {
      dispatch(updatFieldValue({
        fieldName: 'ecgCoordinates',
        fieldValue: result?.data?.[0]?.totalPoints1
      }));
    });
  };

  useEffect(() => {
    handleGetListAppointmentData();
    handleGetPatientVitals();
    handleGetEcgCoordinates();
    const headerOptions = getPatientHeaders(setShowPatientHeaderDetail);
    setPatientInfoHeaderOptions(headerOptions);
  }, []);

  return (
    <div className='flex flex-col gap-[10px] w-full h-full'>
      <PatientProfile
        appointmentId={appointmentId}
        showPatientDetailCard={showPatientDetailCard}
        setShowPatientDetailCard={setShowPatientDetailCard}
      />
      <div className='flex gap-[30px] border-t-[1px] border-b-[1px] p-[10px] mt-[20px] flex-wrap'>
        {patientInfoHeaderOptions.map((option, index) => (
          <div key={index} className={`cursor-pointer ${option?.title === selectedTitle
            && 'border-b-[#383678] border-b-[2px]'}`}
            onClick={() => {
              setSelectedTitle(option?.title);
              option.handleClick();
              setShowPatientDetailCard(false);
            }}
          >
            <p className='text-[14px]'>{option?.title}</p>
          </div>
        ))}
      </div>
      <div className='pt-[20px]'>
        {showPatientHeaderDetail?.showSymptoms && (
          <Symptoms
            appointmentId={appointmentId}
          />
        )}
        {showPatientHeaderDetail?.showVitals && (
          <Vitals
            appointmentId={appointmentId}
            handleGetPatientVitals={handleGetPatientVitals}
            handleGetEcgCoordinates={handleGetEcgCoordinates}
          />
        )}
        {showPatientHeaderDetail?.showTeleExamination && (
          <TeleExamination appointmentId={appointmentId} />
        )}
        {showPatientHeaderDetail?.showInvestigation && (
          <Investigation
            appointmentId={appointmentId}
          />
        )}
        {showPatientHeaderDetail?.showDiagnosis && (
          <Diagnosis
            appointmentId={appointmentId}
          />
        )}
        {showPatientHeaderDetail?.showPrescription && (
          <Prescription
            appointmentId={appointmentId}
            // followupDate={followupDate}
            referredInfo={referredInfo}
          />
        )}
      </div>
    </div >
  );
};

export default PatientDetails;
