import React from 'react';

const paddingConstants = {
  1: 'pl-[20px]',
  2: 'pl-[60px]',
  3: 'pl-[100px]'
};

const RecursiveComponentPrescription = ({ displayData, depth }) => {
  return (
    <div className='pt-[5px]'>
      {displayData?.questions && Object.keys(displayData?.questions)?.map((item, index) => {
        const questionItem = displayData?.questions[item];
        return (
          <div key={index} className={`flex flex-col gap-[5px] ${depth === 1 &&
            Object.keys(displayData?.questions)?.length > 1 && 'border-b-[1px] border-b-[#d9d9d9] p-[5px]'}`}
          >
            <div className='grid grid-cols-12 items-center'>
              <div className={`col-span-6 ${paddingConstants[depth]}`}>
                <p className='text-sm text-[#888] fw-500'>{questionItem?.title}</p>
              </div>
              <div className={`col-span-6 ${paddingConstants[depth]}`}>
                <p className={`${questionItem?.value === 'No' || questionItem?.value === 'no'
                  ? 'text-[#383678]' : 'text-[#ff6300]'} text-sm fw-500`}>
                    {questionItem?.value}
                </p>
              </div>
            </div>
            {Object.keys(questionItem?.data)?.map((childItem, index) => {
              return (
                <div key={index}>
                  {questionItem?.data[childItem]?.isChild &&
                    (questionItem?.data[childItem].title === questionItem?.value) && (
                    <RecursiveComponentPrescription
                      displayData={questionItem?.data[childItem]?.children}
                      depth={depth + 1}
                    />
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default RecursiveComponentPrescription;
