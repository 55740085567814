// CommonFilterSelect.js

import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import Select from 'react-select';
import SharedButton from '../SharedButton';
import { Colorcode } from '../../common/utils/Colorcode';

const CommonFilterSelect = ({
    initialValues,
    options,
    placeholder,
    onChange,
    toggleButtons,
    rightToggleButtons,
    onSearch,
    showFilter,
    toggleButtonClick,
    rightToggleButtonClick
}) => {
    const [isSearchFocused, setIsSearchFocused] = useState(false);
    const customerColor = Colorcode();

    return (
        <div>
            <div className="flex items-center">
                <div className="flex w-[100%]">
                    {toggleButtons && toggleButtons.map((button, index) => (
                        <SharedButton
                            key={index}
                            className="ml-0 rounded-[20px] float-right 
                            bg-[#383678] text-white px-[20px] py-[5px] text-[16px]
                        mt-1.5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                            style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                            title={button.buttonName} onClick={() => toggleButtonClick(button.url)}
                        />
                    ))}
                </div>
                <div className="flex justify-end w-full">
                    {showFilter && (<Formik initialValues={initialValues}>
                        <Form>
                            <Field
                                name="selectedOption"
                                component={(props) => (
                                    <FilterSelect {...props} options={options} onChange={onChange} />
                                )}
                                placeholder={placeholder}
                            />
                        </Form>
                    </Formik>)}
                    <div className={`relative ml-7 flex items-center ${isSearchFocused ? 'hidden' : 'block'}`}>
                        <svg
                            viewBox="0 0 21 21"
                            fill="currentColor"
                            height="2em"
                            width="2em"
                            onClick={() => setIsSearchFocused(true)}
                        >
                            <g
                                fill="none"
                                fillRule="evenodd"
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M13.5 8.5 A5 5 0 0 1 8.5 13.5 A5 5 0 0 1 3.5 8.5 A5 5 0 0 1 13.5 8.5 z" />
                                <path d="M17.571 17.5L12 12" />
                            </g>
                        </svg>
                    </div>
                    <div className={`relative ml-7 flex items-center ${!isSearchFocused ? 'hidden' : 'block'}`}>
                        <input
                            type="text"
                            placeholder="Search"
                            autoComplete="off"
                            id="search"
                            name="searchField"
                            onChange={(event) => onSearch(event.target.value)}
                            className={`border-0 border-b-2 p-2 w-28 focus:outline-none 
                            ${isSearchFocused ? 'block' : 'hidden'}`}
                            onBlur={() => setIsSearchFocused(false)}
                        />
                    </div>
                </div>
            </div>
            <div className='flex flex-col items-end w-full'>
                {rightToggleButtons && rightToggleButtons.map((button, index) => (
                    <SharedButton
                        key={index}
                        className="ml-0 rounded-[20px] float-right bg-[#383678] text-white px-[30px] font-bold
                        py-[5px] text-sm mt-5 mr-3 flex bg-gradient-to-r from-[#383678] via-[#5f59b3] to-[#7976c9]"
                        title={button.buttonName} onClick={() => rightToggleButtonClick(button.url)}
                    />
                ))}
            </div>
        </div>

    );
};

const FilterSelect = ({ field, form, options, onChange, ...props }) => {

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            border: state.isFocused ? 'none' : provided.border,
            boxShadow: state.isFocused ? 'none' : provided.boxShadow
        })
    };

    return (
        <Select
            {...field}
            {...props}
            options={options}
            onChange={(option) => {
                form.setFieldValue(field.name, option);
                onChange(option);
            }}
            styles={customStyles}
            onBlur={field.onBlur}
            isSearchable={false}
            className='border-0 w-44 border-b-2'
        />
    );
};

export default CommonFilterSelect;
