import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState } from 'react';

const AppointmentList = ({
  appointmentList,
  pastConsultAppointmentId,
  handleOnClickAppointment
}) => {
  const [searchValue, setSearchValue] = useState('');

  const onClickAppointment = (appointmentId) => {
    if (appointmentId !== pastConsultAppointmentId) {
      if (searchValue === '') {
        const url = `getallresults/${appointmentId}`;
        handleOnClickAppointment(url);
      } else {
        handleOnClickAppointment(`${searchValue}/${appointmentId}`);
      }
    }
  };

  const handleOnSearch = (value) => {
    handleOnClickAppointment(value);
  };

  return (
    <div className='flex flex-col gap-[20px]'>
      <div className='flex items-center'>
        <input
          type='text'
          name='pastConsultSearch'
          onChange={(e) =>{ setSearchValue(e?.target?.value); handleOnSearch(e?.target?.value);}}
          placeholder='Search past consult'
          className='w-full'
        />
        <FontAwesomeIcon icon={faSearch} color='#919191' />
      </div>
      <div className='flex flex-col gap-[10px] h-[60vh] overflow-auto'>
        {appointmentList?.length > 0 && appointmentList?.map((appointment, index) => (
          <div 
            key={index} 
            className={`${pastConsultAppointmentId === appointment?.appointment_id 
              ? 'bg-[#383678]' : 'bg-[#b4b4b4]'} p-[10px] text-white cursor-pointer`}
            onClick={() => onClickAppointment(appointment?.appointment_id)}
          >
            <p>{moment(appointment?.appointment_time).format('DD MMMM YYYY')}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppointmentList;