import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AdminLandingPage from './index';
import Sidebar from './Sidebar';
import Header from '../common/Header';
import VerifySlots from './VerifySlots';
import UpcomingConsultsList from './UpcomingConsultsList';
import DepartmentsList from './DepartmentsList';
import MedicalHistoryList from './MedicalHistoryList';
import FrontDeskList from './FrontDeskList';
import NewParamedicList from './NewParamedicList';
import DoctorsList from './DoctorList';
import PatientList from './PatientList';
import Kiosks from './Kiosks';
import Settings from './Settings';
import AdminList from './AdminList';
import CustomerList from './CustomerList';
import AppointmentList from './AppointmentList';
import CreateAdmin from './CreateAdmin';
import CreateCustomer from './CreateCustomer';
import DoctorDetailsView from './DoctorDetailView';
import DoctorCreate from './CreateDoctor';
import ParamedicCreate from './CreateParamedic';
import AgentCreate from './CreateFrontDesk';
import FrontDeskDetailView from './FrontDeskDetailView';
import ParamedicDetailView from './ParamedicDetailView';
import AssociateKios from './AssociateKiosk';
import CustomerDetailView from './CustomerDetailView';
import AdminDetailView from './AdminDetailView';
import CustomerConfiguration from './CustomerConfiguration';
import PatientDetailView from './PatientDetailView';
import KioskUserList from './KioskUserList';
import DashboardAnalytics from './DashboardAnalytics';
import CreateKiosk from './CreateKiosk';
import EditKiosk from './EditKiosk';
import FrontDeskAssociate from './FrontDeskAssociate';
import ParamedicAssociate from './ParamedicAssociate';
import TabConsultByDate from './TabConsultByDate';
import TabAvgConsultTimeByDate from './TabAvgConsultTimeByDate';
import TabConsultByDoctor from './TabConsultByDoctor';
import TabAvgConsultTimeByDoctor from './TabAvgConsultTimeByDoctor';
import TabConsultByKiosk from './TabConsultByKiosk';
import CreateMedicalHistory from './CreateMedicalHistory';
import ViewMedicalHistory from './ViewMedicalHistory';

const AdminRoutes = () => {
  const location = useLocation();
  // to check if the current URL matches '/admin'
  const isAdminPage = location.pathname === '/admin';
  const user_type = 'admin';
  let authentication = localStorage.getItem('access_token');
  let UserType = localStorage.getItem('user_type');
  if ((authentication && authentication.length < 5) && window.location.pathname === `/${user_type}`) {
    window.location.replace('/login');
  } else if ((authentication && authentication.length < 5) && window.location.pathname.length >= 7) {
    setTimeout(() => {
      window.location.replace('/login');
    }, 10);
  } else if ((authentication && authentication.length > 5) && UserType !== user_type) {
    setTimeout(() => {
      window.location.replace('/login');
    }, 10);
  } else if (authentication === null && window.location.pathname.length >= 7) {
    setTimeout(() => {
      window.location.replace('/login');
    }, 10);
  }

  return (
    <div className='w-full h-full'>
      <div className='grid grid-cols-12 h-full'>
        {isAdminPage && (
          <div className='col-span-3'>
            <Sidebar />
          </div>
        )}
        <div className={`col-span-${isAdminPage ? 9 : 12} h-full`}>
          <Header />
          <Routes>
            {authentication === null && window.location.pathname === `/${user_type}` &&
              window.location.replace('/login')
            }
            <Route path='/' element={<AdminLandingPage />} />
            <Route path='/departmentList' element={<DepartmentsList />} />
            <Route path='/verify-slots' element={<VerifySlots />} />
            <Route path='/upcoming-consults' element={<UpcomingConsultsList />} />
            <Route path='/:id/medical-history' element={<MedicalHistoryList />} />
            <Route path='/frontdesklist' element={<FrontDeskList />} />
            <Route path='/paramediclist' element={<NewParamedicList />} />
            <Route path='/frontdesk-detail-view/:id' element={<FrontDeskDetailView />} />
            <Route path='/paramedic-detail-view/:id' element={<ParamedicDetailView />} />
            <Route path='/patient-view/:id' element={<PatientDetailView />} />
            <Route path='/doctor-view/:id' element={<DoctorDetailsView />} />
            <Route path='/customer/:id/view' element={<CustomerDetailView />} />
            <Route path='/customer/:id/appearance' element={<CustomerConfiguration />} />
            <Route path='/adminUser/:id/view' element={<AdminDetailView />} />
            <Route path='/doctorlist' element={<DoctorsList />} />
            <Route path='/patientlist' element={<PatientList />} />
            <Route path='/kioskslist' element={<Kiosks />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/adminUsers' element={<AdminList />} />
            <Route path='/analytics' element={<DashboardAnalytics />} />
            <Route path='/analytics-tabular/consults_by_date' element={<TabConsultByDate />} />
            <Route path='/analytics-tabular/average_consult_time_by_date' element={<TabAvgConsultTimeByDate />} />
            <Route path='/analytics-tabular/average_consult_time_by_doctor' element={<TabAvgConsultTimeByDoctor />} />
            <Route path='/analytics-tabular/consults_by_doctor' element={<TabConsultByDoctor />} />
            <Route path='/analytics-tabular/consults_by_kiosk' element={<TabConsultByKiosk />} />
            <Route path='/customerList' element={<CustomerList />} />
            <Route path='/appointmentList' element={<AppointmentList />} />
            <Route path='/create/admin' element={<CreateAdmin />} />
            <Route path='/create/customer' element={<CreateCustomer />} />
            <Route path='/create/doctor' element={<DoctorCreate />} />
            <Route path='/create/paramedic' element={<ParamedicCreate />} />
            <Route path='/create/agent' element={<AgentCreate />} />
            <Route path='/associate-kiosk/:id' element={<AssociateKios />} />
            <Route path='/kiosk/users' element={< KioskUserList />} />
            <Route path='/create/kiosk' element={< CreateKiosk />} />
            <Route path='/kiosk_view/:id' element={< EditKiosk />} />
            <Route path='/associate_list/frontdesk/:id' element={< FrontDeskAssociate />} />
            <Route path='/associate_list/paramedic/:id' element={< ParamedicAssociate />} />
            <Route path='/medical-history/:id/create' element={<CreateMedicalHistory />} />
            <Route path='/medical-history/:id/view' element={<ViewMedicalHistory />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminRoutes;