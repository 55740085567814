import React from 'react';
import { Colorcode } from '../utils/Colorcode';

const SharedButton = (props) => {
    const customerColor = Colorcode();
    const buttonData = props;
    const color = '#383678';
    const colorGradient = color + 'bb';
    let updatedColor = buttonData.title === 'LOGIN' ? [color, colorGradient] : customerColor;

    return (
        <button
            className={buttonData.className}
            style={{ background: `linear-gradient(90deg, ${updatedColor[0]}, ${updatedColor[1]})` }}
            onClick={buttonData.onClick}
            type={buttonData.type}
            disabled={buttonData.disabled}
        >
            {buttonData.title}
        </button>
    );
};

export default SharedButton;
