import React, { useState, useEffect } from 'react';
import SharedTable from '../../common/SharedTable';
import { useGetPastConsultantsMutation } from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import { useNavigate } from 'react-router-dom';
import CommonFilterSelect from '../../common/FilterDropdown';
import moment from 'moment';
import { Colorcode } from '../../common/utils/Colorcode';

const PastConsults = () => {
  const customerColor = Colorcode();
  const [getPastConsultants] = useGetPastConsultantsMutation();
  const [tableData, setTableData] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [pageNumber, setPageNumber] = useState('1');
  const [searchString, setSearchString] = useState('');
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });
  const navigate = useNavigate();

  const tableHeaders = [
    { field: 'appointment_id', headerName: '#ID' },
    { field: 'first_name', headerName: 'Patient Name' },
    { field: 'appointment_time_updated', headerName: 'ConsultDate' },
    { field: 'prescription', headerName: 'Prescription' },
    { field: 'consult_details', headerName: 'Medical Certificate' }
    // { field: 'action', headerName: 'Action' }
  ];

  const handleOnClickSort = (header) => {
    setPageNumber(1);
    if (header.field === 'appointment_time_updated') {
      header.field = 'appointment_time';
    }
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const handleOnPageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const onSearch = (search) => {
    setPageNumber(1);
    setSearchString(search);
  };

  const prescription = (pdf) => {
    window.open(`https://sakha-health.s3-ap-southeast-1.amazonaws.com/prescriptionfiles/${pdf}.pdf`);
  };

  const consultDetails = (appointmentId) => {
    const url = `/doctor/prescription/${appointmentId}/view`;
    navigate(url);
  };

  const getPastConsultDetails = (page, searchString) => {
    // if(searchString !== '' && page !== 1) {
    //   page = 1;
    //   setPageNumber(1);
    // }
    getPastConsultants({
      endpoint: `${apiConstants.getPastConsultants}/${page}/all/${searchString}`,
      method: 'POST',
      data: {
        'field': sort?.headerFiled,
        'type': sort?.sortType,
        'user_id': localStorage.getItem('user_id'),
        'consult_type': '',
        'data': {},
        'kiosk_id': '',
        'current_time': '',
        'date': ''
      }
    }).then((result) => {
      let pastConsultList = result?.data?.list;
      let modifiedPastConsultList = pastConsultList.map(listData => ({
        ...listData,
        // appointment_time_updated: new Date(listData.appointment_time).toUTCString(),
        appointment_time_updated: moment(listData.appointment_time).format('DD MMM YYYY, hh:mm A'),
        prescription: (<button className="rounded-[20px] text-white px-[25px] py-[5px]
        text-sm mt-1.5 mx-auto flex cursor-pointer"
          style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
          onClick={() => prescription(listData.customer_appointment_id)}>Prescription</button>),
        consult_details: (<button className="rounded-[20px] text-white px-[25px]
        py-[5px] text-sm mt-1.5 mx-auto flex cursor-pointer"
          style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
          onClick={() => consultDetails(listData?.appointment_id)}>Details</button>)
      })
      );
      setTableData(modifiedPastConsultList);
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
      setPageNumber(parseInt(result?.data?.current_page));
    });
  };

  useEffect(() => {
    getPastConsultDetails(pageNumber, searchString);
  }, [pageNumber, searchString, sort]);
  return (
    <>
      <div className="row mt-6 mx-24">
        <div className=''>
          <CommonFilterSelect
            onSearch={onSearch}
          />
        </div>
        <div className="mt-7">
          <h4 className="text-2xl font-semibold border-b-4 text-[Coolvetica Rg] list-header-text w-44"
            style={{ borderColor: customerColor[0] }}>
            Past Consults
          </h4>
        </div>
        <div className="mt-10">
          <SharedTable
            tableData={tableData}
            tableHeaders={tableHeaders}
            handleOnPageChange={handleOnPageChange}
            isMoreData={isMoreData}
            totalPages={totalPages}
            handleOnClickSort={handleOnClickSort}
            currentPageNumber={pageNumber}
          />
        </div>
      </div>
    </>
  );
};

export default PastConsults;
