import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useSaveLogoMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import { Formik, Form, Field } from 'formik';
import { useParams } from 'react-router-dom';
import { Colorcode } from '../common/utils/Colorcode';

const SettingsModal = ({
  setShowModal,
  settingsData
}) => {
  const customerColor = Colorcode();
  const [saveLogo] = useSaveLogoMutation();
  const [description, setDescription] = useState(settingsData?.description);

  const hiddenUserFileInput = useRef(null);
  const userId = localStorage.getItem('user_id');
  const { id } = useParams();

  const handleClick = () => {
    hiddenUserFileInput.current.click();
  };
  const handleUploadDoc = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    formData.append('user_id', userId);
    formData.append('name', settingsData?.name);
    formData.append('description', description);
    formData.append('customer_id', id ? id : '');
    saveLogo({
      endpoint: apiConstants.saveLogo,
      method: 'POST',
      data: formData
    }).then((result) => {
      console.log(result);
    }).catch((error) => {
      console.error('error', error);
    });
    hiddenUserFileInput.current.value = '';
    setShowModal(false);
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <input
        id="uploadDoc"
        type="file"
        accept=".png, .jpg"
        ref={hiddenUserFileInput}
        style={{ display: 'none' }}
        onChange={(e) => handleUploadDoc(e)}
      />
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white">
              <div className="w-full">
                <div className='p-[20px] border-b-[1px] border-b-[#808080] flex justify-center'>
                  <span className='text-[#757776] text-[24px] '>
                    Description</span>
                </div>
              </div>
              <div className="w-full py-[30px] px-[40px] text-[#757776] flex flex-col items-start">
                <Formik
                  initialValues={{
                    name: settingsData?.name,
                    description: settingsData?.description
                  }}
                  enableReinitialize
                >
                  <Form className='flex flex-col items-center gap-[30px] mt-[30px] createUser'>
                    <div className='w-full'>
                      <label>Name of Logo*</label>
                      <Field
                        name="name"
                        type='text'
                        placeholder="Logo Name"
                        autoComplete="off"
                        readOnly
                        className="border-b border-[#A9A9A9] opacity-70 bg-[#fff]
                          outline-none w-full py-2 px-3 cursor-not-allowed"
                      />
                    </div>
                    <div>
                      <label>Description of the Logo*</label>
                      <Field
                        name="description"
                        type='text'
                        placeholder="Description"
                        autoComplete="off"
                        className="border-b border-[#A9A9A9] bg-[#fff] opacity-70
                        outline-none w-full py-2 px-3"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                    </div>
                  </Form>
                </Formik>
                <button
                  type='text'
                  className='bg-[#383678] cursor-pointer text-white px-[10px] py-[5px] w-[125px] h-[36px] gap-[5px]
                  leading-[25px] rounded-[20px] focus:outline-none text-[15px] flex justify-center 
                  items-center mt-[25px]'
                  style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                  onClick={() => handleClick()}
                >
                  <FontAwesomeIcon icon={faPlus}
                    className='text-white right-[40px] top-[25px] text-[10px]' />

                  <span>Pick file</span>
                </button>
              </div>
              <div className="w-full">
                <div className='p-[20px] border-t-[1px] border-b-[#808080] flex justify-center'>
                  <button
                    type='submit'
                    className='bg-[#808080] cursor-pointer text-white 
                  px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px] rounded-[20px] focus:outline-none text-[15px] 
                  mx-auto flex justify-center'
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default SettingsModal;
