import React from 'react';

const PatientInfoBox = ({ patientDetails }) => {
  return (
    <div className='flex flex-col gap-[20px] p-[10px] text-[14px]'>
      <div className='grid grid-cols-12'>
        <div className='col-span-6'>
          <div className='grid grid-cols-12 gap-[10px]'>
            <span className='col-span-5'>Appointment ID</span>
            <span className='col-span-7 text-[#808080] fw-500'>{patientDetails?.appointment_id}</span>
          </div>
        </div>
        <div className='col-span-6'>
          <div className='grid grid-cols-12 gap-[10px]'>
            <span className='col-span-5'>Appointment Type</span>
            <span className='col-span-7 text-[#808080]
fw-500'>{patientDetails?.is_followup === 2 ? 'New' : 'Followup'}</span>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-12'>
        <div className='col-span-6'>
          <div className='grid grid-cols-12 gap-[10px]'>
            <span className='col-span-5'>Kiosk</span>
            <span className='col-span-7 text-[#808080] fw-500'>{patientDetails?.kiosk_name}</span>
          </div>
        </div>
        <div className='col-span-6'>
          <div className='grid grid-cols-12 gap-[10px]'>
            <span className='col-span-5'>Chief Complaint</span>
            <span className='col-span-7 text-[#808080] fw-500'>{patientDetails?.problem_description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInfoBox;
