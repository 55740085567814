import { Field, Formik, Form } from 'formik';
import React, { useState } from 'react';
import { useReasonForDisconnectMutation } from '../../../../reducers/allReducer';
import { apiConstants } from '../../../../constants/api';

const MeetingEndModal = ({
  handleModalClose,
  appointmentId,
  handleChangeDoctorStatus,
  userId
}) => {
  const [otherReason, setOtherReason] = useState();
  const [reasonForDisconnect] = useReasonForDisconnectMutation();
  const [isError, setIsError] = useState(false);
  const radioOptions = [
    { label: 'Paramedic did not want to continue with the consult.',
      value: 'Paramedic did not want to continue with the consult.'},
    { label: 'Paramedic disconnected the consult.', value: 'Paramedic disconnected the consult.'},
    { label: 'Paramedic did not join the consult.', value: 'Paramedic did not join the consult.'},
    { label: 'Others.', value: 'Others.'}
  ];

  const handleSubmit = (values) => {
    const reason = values?.reason === 'Others.' ? otherReason : values?.reason;
    if (otherReason?.length < 5) {
      setIsError(true);
    } else {
      setIsError(false);
      reasonForDisconnect({
        endpoint: apiConstants?.reasonForDisconnect,
        method: 'POST',
        data: {
          appointment_id: appointmentId,
          doctor_id: userId,
          reason: reason
        }
      }).then(() => {
        handleModalClose();
        handleChangeDoctorStatus(true);
      });
    }
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[10px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white p-[30px]">
              <div className='flex flex-col gap-[10px]'>
                <p>You are closing the consult without generating prescription. Please select the reason</p>
                <Formik initialValues={{ reason: 'Paramedic did not want to continue with the consult.'}} 
                  onSubmit={handleSubmit}
                >
                  {({ values }) => (
                    <Form>
                      {radioOptions?.map((radio, index) => (
                        <div key={index} className='flex gap-[10px] items-center'>
                          <Field type='radio' value={radio?.value} name='reason' />
                          <span className='whitespace-nowrap'>{radio?.label}</span>
                        </div>
                      ))}
                      {values?.reason === 'Others.' && (
                        <div className='p-[10px]'>
                          <textarea
                            placeholder='Please enter the other reason here*'
                            className='text-[13px] rounded-[12px] border-[1px] border-[#383678] w-full h-[90px] 
                              focus-visible:outline-none p-[10px] resize-none'
                            onChange={(e) => {
                              setOtherReason(e.target.value);
                              // setFieldValue('reason', e?.target?.value);
                            }}
                            rows={2}
                            value={otherReason}
                            name='otherReason'
                          ></textarea>
                          {isError && (
                            <div className='text-center'>
                              <p className='text-[15px] text-red-600'>Please enter appropriate reason.</p>
                            </div>
                          )}
                        </div>
                      )}
                      <div className='flex gap-[10px] justify-center'>
                        <button 
                          className='text-white px-[20px] py-[10px] rounded-[50px] text-[12px] text-nowrap'
                          style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)'}}
                          type='submit'
                        >
                          Submit
                        </button>
                        <button 
                          className='text-white px-[20px] py-[10px] rounded-[50px] text-[12px] text-nowrap'
                          style={{ background: 'linear-gradient(40.66deg,#737575,#737375)'}}
                          onClick={() => handleModalClose()}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingEndModal;
