import React from 'react';

function ScreenshotIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width='24'
      height='24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
      {...props}
    >
      <rect
        x='2'
        y='3'
        width='20'
        height='18'
        rx='2'
        ry='2'
        fill='none'
        strokeDasharray='5'
        strokeDashoffset='0'
      ></rect>
    </svg>
  );
}

export default ScreenshotIcon;
