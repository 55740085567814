import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import Dayz from 'dayz';
import SharedButton from '../common/SharedButton';
import { SlotModal, FailedModal, UpdateEventModal, DeleteModal } from '../Doctor/SlotScheduleModal';
import {
  useLazyGetdoctorscheduleQuery, useAdminaddslotsMutation, useAdminupdateslotsMutation, useAdmindeleteslotsMutation
} from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import moment from 'moment';
import { useParams } from 'react-router-dom';

const DoctorScheduleSlotsModal = ({
  setIsScheduleSots,
  detailsCardData
}) => {
  const { id } = useParams();

  const [getdoctorschedule] = useLazyGetdoctorscheduleQuery();
  const [addSlot] = useAdminaddslotsMutation();
  const [updateSlot] = useAdminupdateslotsMutation();
  const [deleteSlot] = useAdmindeleteslotsMutation();
  const [currentDate, setCurrentDate] = useState(moment().format('YYYY-MM-DD'));
  const [events, setEvents] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(moment().format('MMMM'));
  const [currentYear, setCurrentYear] = useState(moment().format('YYYY'));
  const [currentMonthYear] = useState(moment().format('YYYY-MM'));
  const [addEventModal, setAddEventModal] = useState(false);
  const [radioValue, setRadioValue] = useState('Particular Day.');
  const [particularDate, setParticularDate] = useState(moment().format('YYYY-MM-DD'));
  const [startTime, setStartTime] = useState('10:00');
  const [endTime, setEndTime] = useState('');
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [updateEventModal, setUpdateEventModal] = useState(false);
  const [currentEditData, setCurrentEditData] = useState({});
  const [deleteEventModal, setDeleteEventModal] = useState(false);
  const [deleteEventReason, setDeleteEventReason] = useState('');
  // const [errorData, setErrorData] = useState({});
  const [errorAddData, setErrorAddData] = useState({});
  const [failedMessage, setFailedMessage] = useState('');
  const [failedModal, setFailedModal] = useState(false);
  const [slotsData, setSlotsData] = useState([]);
  const [disableButtons, setDisableButtons] = useState(false);

  const getDoctorSlots = () => {
    getdoctorschedule({
      endpoint: `${apiConstants.getdoctorschedule}/${moment().format('YYYY-MM')}/${id}`,
      method: 'GET'
    }).then((res) => {
      let data = res.data;
      let slotData = data;
      let eventArray = [];
      if (data && data !== undefined && data.length !== 0) {
        data.forEach(element => {
          let obj = {};
          if (element.is_slot_verified === 2) {
            obj.content = moment(element.start_time, ['HH:mm']).format('hh:mm a') +
              '-' + moment(element.end_time, ['HH:mm']).format('hh:mm a') + ' ' + ' ' + '(P)';
          } else {
            obj.content = moment(element.start_time, ['HH:mm']).format('hh:mm a') +
              '-' + moment(element.end_time, ['HH:mm']).format('hh:mm a');
          }

          obj.range = moment.range(moment(element.date).format('YYYY/MM/DD'),
            moment(element.date).format('YYYY/MM/DD'));
          obj.schedule_id = element.id;
          eventArray.push(obj);
        });
      }

      let Events = new Dayz.EventsCollection(eventArray);
      setEvents(Events);
      console.log('slots data', slotData, Events, setCurrentDate, setCurrentMonth, setCurrentYear);

    });
  };

  useEffect(() => {
    getDoctorSlots();
  }, []);
  const changeMonth = (op) => {
    let current_date = currentDate;
    let current_month = currentMonth;
    let current_year = currentYear;
    let current_month_year = currentMonthYear;
    if (op === 'next') {
      current_date = moment(current_date).add(1, 'month').format('YYYY-MM-DD');
      current_month = moment().month(current_month).add(1, 'month').format('MMMM');
      current_year = moment(current_date).format('YYYY');
      getdoctorschedule({
        endpoint: `${apiConstants.getdoctorschedule}/${moment(current_date).format('YYYY-MM')}/${id}`,
        method: 'GET'
      })
        .then((res) => {
          let data = res.data;
          // let slots_data = data;
          let eventArray = [];
          data.forEach(element => {
            let obj = {};
            if (element.is_slot_verified === 2) {
              obj.content = moment(element.start_time, ['HH:mm']).format('hh:mm a') + '-' +
                moment(element.end_time, ['HH:mm']).format('hh:mm a') + ' ' + ' ' + '(P)';
            } else {
              obj.content = moment(element.start_time, ['HH:mm']).format('hh:mm a') + '-' +
                moment(element.end_time, ['HH:mm']).format('hh:mm a');
            }

            obj.range = moment.range(moment(element.date).format('YYYY/MM/DD'),
              moment(element.date).format('YYYY/MM/DD'));
            obj.schedule_id = element.id;
            eventArray.push(obj);
          });
          let EVENTS = new Dayz.EventsCollection(eventArray);
          setCurrentDate(current_date);
          setCurrentMonth(current_month);
          setCurrentYear(current_year);
          setEvents(EVENTS);
          // setSlotsData(slots_data);
          // this.setState({ current_date, current_month, current_year, EVENTS, slots_data });
        });
    } else if (op === 'prev' && current_month_year !== moment(current_date).format('YYYY-MM')) {
      current_date = moment(current_date).subtract(1, 'month').format('YYYY-MM-DD');
      current_month = moment().month(current_month).subtract(1, 'month').format('MMMM');
      current_year = moment(current_date).format('YYYY');
      getdoctorschedule({
        endpoint: `${apiConstants.getdoctorschedule}/${moment(current_date).format('YYYY-MM')}/${id}`,
        method: 'GET'
      })
        .then((res) => {
          let data = res.data;
          // let slots_data = data;
          let eventArray = [];
          if (data && data !== undefined && data.length !== 0) {
            data.forEach(element => {
              let obj = {};
              if (element.is_slot_verified === 2) {
                obj.content = moment(element.start_time,
                  ['HH:mm']).format('hh:mm a') + '-' +
                  moment(element.end_time, ['HH:mm']).format('hh:mm a') + ' ' + ' ' + '(P)';
              } else {
                obj.content = moment(element.start_time,
                  ['HH:mm']).format('hh:mm a') + '-' + moment(element.end_time, ['HH:mm']).format('hh:mm a');
              }
              obj.range = moment.range(moment(element.date).format('YYYY/MM/DD'),
                moment(element.date).format('YYYY/MM/DD'));
              obj.schedule_id = element.id;
              eventArray.push(obj);
            });
          }

          let EVENTS = new Dayz.EventsCollection(eventArray);
          setCurrentDate(current_date);
          setCurrentMonth(current_month);
          setCurrentYear(current_year);
          setEvents(EVENTS);
          // setSlotsData(slots_data);
          // this.setState({ current_date, current_month, current_year, EVENTS, slots_data });
        });
    }
  };

  const modalClose = () => {
    setAddEventModal(false);
    setRadioValue('Particular Day.');
    setParticularDate(moment().format('YYYY-MM-DD'));
    setStartDate(moment().format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));
    setStartTime('');
    setEndTime('');
    setErrorAddData({});
  };
  const handleRadioValue = (e) => {
    setRadioValue(e.target.value);
  };

  const onParticularDate = (e) => {
    setParticularDate(e.target.value);
    // this.setState({ particularDate: e.target.value });
  };

  const onMultipleDate = (e) => {
    e.target.id === 'startDate' ? setStartDate(e.target.value) : setEndDate(e.target.value);
  };

  const onTimeChange = (time, type) => {
    type === 'startTime' ? setStartTime(time) : setEndTime(time);
  };

  const addEvent = () => {
    if (
      endDate && startDate &&
      moment(startDate).diff(endDate, 'seconds') > 0
    ) {
      return;
    }
    let obj = {};
    let add_obj = {};
    let error_add_data = errorAddData;
    // let EVENTS = events;
    if (radioValue === 'Particular Day.') {
      obj = {
        content: startTime + '-' + endTime,
        range: moment.range(moment(particularDate), moment(particularDate))
      };
      if (currentMonth === moment().format('MMMM')) {
        let DateToBeChecked = particularDate === '' ?
          moment(currentDate).startOf('month').format('YYYY-MM-DD') : particularDate;
        if (moment(DateToBeChecked).isSameOrAfter(moment(currentDate))) {
          if (startTime === '') {
            error_add_data['startTime'] = 'Please select start time';
            setErrorAddData(error_add_data);
            // this.setState({ error_add_data });
            return;
          } else {
            error_add_data['startTime'] = '';
            setErrorAddData(error_add_data);
            // this.setState({ error_add_data });
          }
          if (endTime === '') {
            error_add_data['endTime'] = 'Please select end time';
            setErrorAddData(error_add_data);
            // this.setState({ error_add_data });
            return;
          } else {
            error_add_data['endTime'] = '';
            setErrorAddData(error_add_data);
            // this.setState({ error_add_data });
          }

          add_obj = {
            doctor_id: id,
            start_date: particularDate === '' ? moment(currentDate).startOf('month')
              .format('YYYY-MM-DD') : particularDate,
            end_date: particularDate === '' ?
              moment(currentDate).startOf('month').format('YYYY-MM-DD') : particularDate,
            start_time: moment(startTime).format('HH:mm'),
            end_time: moment(endTime).format('HH:mm')
          };

        } else {
          error_add_data['particularDate'] = 'Please select present or future date.';
          setErrorAddData(error_add_data);
          // this.setState({ error_add_data });
          return;
        }
      } else if (startTime === '') {
        error_add_data['startTime'] = 'Please select start time';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
        return;
      } else if (endTime === '') {
        error_add_data['endTime'] = 'Please select end time';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
        return;
      } else {
        add_obj = {
          start_date: particularDate === '' ?
            moment(currentDate).startOf('month').format('YYYY-MM-DD') : particularDate,
          end_date: particularDate === '' ?
            moment(currentDate).startOf('month').format('YYYY-MM-DD') : particularDate,
          start_time: moment(startTime).format('HH:mm'),
          end_time: moment(endTime).format('HH:mm')
        };
      }
    } else {
      obj = {
        content: startTime + '-' + endTime,
        range: moment.range(moment(startDate), moment(endDate))
      };
      if (currentMonth === moment().format('MMMM')) {
        let startDateToBeChecked = startDate === '' ?
          moment(currentDate).startOf('month').format('YYYY-MM-DD') : startDate;
        //         let endDateToBeChecked = endDate === '' ?
        //  moment(currentDate).startOf('month').format('YYYY-MM-DD') : endDate;
        if (moment(startDateToBeChecked).isSameOrAfter(moment(currentDate))) {
          // if (moment(endDateToBeChecked).isAfter(moment(startDateToBeChecked))) {
          if (startTime === '') {
            error_add_data['startTime'] = 'Please select start time';
            setErrorAddData(error_add_data);
            // this.setState({ error_add_data });
            return;
          } else {
            error_add_data['startTime'] = '';
            setErrorAddData(error_add_data);
            // this.setState({ error_add_data });
          }
          if (endTime === '') {
            error_add_data['endTime'] = 'Please select end time';
            setErrorAddData(error_add_data);
            // this.setState({ error_add_data });
            return;
          } else {
            error_add_data['endTime'] = '';
            setErrorAddData(error_add_data);
            // this.setState({ error_add_data });
          }
          add_obj = {
            doctor_id: id,
            start_date: startDate === '' ?
              moment(currentDate).startOf('month').format('YYYY-MM-DD') : startDate,
            end_date: endDate,
            start_time: moment(startTime).format('HH:mm'),
            end_time: moment(endTime).format('HH:mm')

          };
          // } else {
          //   error_add_data['endDate'] = 'Please select present or future date.';
          //   this.setState({ error_add_data });
          //   return;
          // }
        } else {
          error_add_data['startDate'] = 'Please select present or future date.';
          setErrorAddData(error_add_data);
          // this.setState({ error_add_data });
          return;
        }
      } else if (startTime === '') {
        error_add_data['startTime'] = 'Please select start time';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
        return;
      } else if (endTime === '') {
        error_add_data['endTime'] = 'Please select end time';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
        return;
      } else {
        add_obj = {
          doctor_id: id,
          start_date: startDate === '' ?
            moment(currentDate).startOf('month').format('YYYY-MM-DD') : startDate,
          end_date: endDate,
          start_time: moment(startTime).format('HH:mm'),
          end_time: moment(endTime).format('HH:mm')
        };
      }

    }
    console.log('add object', add_obj, obj);
    let st = moment(startTime).format('HHmm');
    let et = moment(endTime).format('HHmm');

    if (st >= et) {
      setFailedModal(true);
      setFailedMessage('Please select appropriate timings');
      // this.setState({ failedModal: true, failedMessage: 'Please select appropriate timings' });
    } else {
      addSlot({
        endpoint: apiConstants.adminAddDoctorSlot,
        method: 'POST',
        data: add_obj
      })
        // this.props.fetchAPI({
        //   fetch: {
        //     url: '/api/doctor/adddoctorschedule',
        //     method: 'POST'
        //   },
        //   payload: add_obj
        // })
        .then(res => {
          if (res.data.success === true) {
            getdoctorschedule({
              endpoint: `${apiConstants.getdoctorschedule}/${moment().format('YYYY-MM')}/${id}`,
              method: 'GET'
            })
              .then((res) => {
                let data = res.data;
                let slots_data = data;
                let eventArray = [];
                if (data && data !== undefined && data.length !== 0) {
                  data.forEach(element => {
                    let obj = {};
                    if (element.is_slot_verified === 2) {
                      obj.content = moment(element.start_time,
                        ['HH:mm']).format('hh:mm a') + '-' +
                        moment(element.end_time, ['HH:mm']).format('hh:mm a') + ' ' + ' ' + '(P)';
                    } else {
                      obj.content = moment(element.start_time,
                        ['HH:mm']).format('hh:mm a') + '-' + moment(element.end_time, ['HH:mm']).format('hh:mm a');
                    }

                    obj.range = moment.range(moment(element.date).format('YYYY/MM/DD'),
                      moment(element.date).format('YYYY/MM/DD'));
                    obj.schedule_id = element.id;
                    eventArray.push(obj);
                  });
                }
                let EVENTS = new Dayz.EventsCollection(eventArray);
                setEvents(EVENTS);
                setAddEventModal(false);
                setParticularDate(moment().format('YYYY-MM-DD'));
                setStartDate(moment().format('YYYY-MM-DD'));
                setEndDate(moment().format('YYYY-MM-DD'));
                setStartTime('');
                setEndTime('');
                setRadioValue('Particular Day.');
                setSlotsData(slots_data);
                //             this.setState({ EVENTS, addEventModal: false, 
                // particularDate: moment().format('YYYY-MM-DD'), startDate: moment().format('YYYY-MM-DD'), 
                //  endDate: moment().format('YYYY-MM-DD'), 
                // startTime: '', endTime: '', radio_value: 'Particular Day.', slots_data });
              });
          } else {
            setFailedModal(true);
            setFailedMessage(res.data.message);
            // this.setState({ failedModal: true, failedMessage: res.message });
          }
        });
    }

  };

  const handleupdateblur = (e) => {
    let error_add_data = errorAddData;
    if (e.target.id === 'startTime' && startTime !== '') {
      error_add_data['editStartTime'] = '';
      setErrorAddData(error_add_data);
      // this.setState({ error_add_data });
    }
    if (e.target.id === 'endTime' && endTime !== '') {
      error_add_data['editEndTime'] = '';
      setErrorAddData(error_add_data);
      // this.setState({ error_add_data });
    }

  };

  const onEventClick = (e, date) => {
    let starttime = new Date();
    let hours = moment(date.attributes.content.split('-')[0], ['hh:mm a']).format('HH:mm').split(':')[0];
    let minutes = date.attributes.content.split('-')[0].split(':')[1].split(' ')[0];
    starttime.setHours(hours, minutes);
    let endtime = new Date();
    let hoursend = moment(date.attributes.content.split('-')[1], ['hh:mm a']).format('HH:mm').split(':')[0];
    let minutesend = date.attributes.content.split('-')[1].split(':')[1].split(' ')[0];
    endtime.setHours(hoursend, minutesend);
    let currentDate = moment(date.attributes.range.start).format('YYYY-MM-DD');
    // disable editing and detelition of previous slots
    let Date1 = new Date(currentDate).setHours(0, 0, 0, 0);
    let Date2 = new Date().setHours(0, 0, 0, 0);
    if (Date2 > Date1) {
      setDisableButtons(true);
      // this.setState({disableButtons:true })
    } else {
      setDisableButtons(false);
      // this.setState({disableButtons:false })
    }

    let currentScheduleId = date.attributes.schedule_id;
    let currenteditData = {
      date: currentDate,
      schedule_id: currentScheduleId
    };
    let toBeDisabled = false;
    slotsData.forEach(slot => {
      if (slot.id === currentScheduleId) {
        if (slot.is_request_for_delete === 1) {
          toBeDisabled = true;
        }
      }
    });
    if (!toBeDisabled) {
      setUpdateEventModal(true);
      setStartTime(starttime);
      setEndTime(endtime);
      setCurrentEditData(currenteditData);
      //       this.setState({ updateEventModal: true, 
      // startTime: startTime, endTime: endTime, currentEditData: currentEditData });
    }
  };

  const updateEvent = () => {
    let obj = {
      schedule_id: currentEditData.schedule_id,
      date: currentEditData.date,
      start_time: moment(startTime).format('HH:mm'),
      end_time: moment(endTime).format('HH:mm'),
      doctor_id: id
    };
    let st = moment(startTime).format('HHmm');
    let et = moment(endTime).format('HHmm');
    if (st >= et) {
      setFailedModal(true);
      setFailedMessage('Please select appropriate timings');
    } else {
      updateSlot({
        endpoint: apiConstants.adminUpdateDoctorSlot,
        method: 'PUT',
        data: obj
      })
        .then(res => {
          if (res.data.success === true) {
            getdoctorschedule({
              endpoint: `${apiConstants.getdoctorschedule}/${moment(currentDate).format('YYYY-MM')}/${id}`,
              method: 'GET'
            })
              .then((res) => {
                let data = res.data;
                let slots_data = data;
                let eventArray = [];
                if (data && data !== undefined && data.length !== 0) {
                  data.forEach(element => {
                    let obj = {};
                    if (element.is_slot_verified === 2) {
                      obj.content = moment(element.start_time,
                        ['HH:mm']).format('hh:mm a') + '-' +
                        moment(element.end_time, ['HH:mm']).format('hh:mm a') + ' ' + ' ' + '(P)';
                    } else {
                      obj.content = moment(element.start_time,
                        ['HH:mm']).format('hh:mm a') + '-' +
                        moment(element.end_time, ['HH:mm']).format('hh:mm a');
                    }

                    obj.range = moment.range(moment(element.date).format('YYYY/MM/DD'),
                      moment(element.date).format('YYYY/MM/DD'));
                    obj.schedule_id = element.id;
                    eventArray.push(obj);
                  });
                }
                let EVENTS = new Dayz.EventsCollection(eventArray);
                setEvents(EVENTS);
                setCurrentEditData({});
                setUpdateEventModal(false);
                setParticularDate(moment().format('YYYY-MM-DD'));
                setStartDate(moment().format('YYYY-MM-DD'));
                setEndDate(moment().format('YYYY-MM-DD'));
                setStartTime('');
                setEndTime('');
                setSlotsData(slots_data);
              });
          } else {
            setFailedMessage(res.data.message);
            setFailedModal(true);
          }

        });
    }
  };

  const onTimeBlur = (e) => {
    let error_add_data = errorAddData;
    // if (e.target.id === 'deleteReason' && deleteEventReason === '') {
    if (e.target.id === 'deleteReason') {
      error_add_data['deleteReason'] = 'Please provide reason for deleting.';
      setErrorAddData(error_add_data);
      // this.setState({ error_add_data });
    } else {
      error_add_data['deleteReason'] = '';
      setErrorAddData(error_add_data);
      // this.setState({ error_add_data })
    }
    if (radioValue === 'Particular Day.') {
      if (e.target.id === 'particularDate' && this.state.particularDate === '') {
        error_add_data['particularDate'] = 'Please select Date';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
      } else {
        error_add_data['particularDate'] = '';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
      }

      if (e.target.id === 'startTime' && startTime !== '') {
        error_add_data['startTime'] = '';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
      }

      if (e.target.id === 'endTime' && endTime !== '') {
        error_add_data['endTime'] = '';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
      }
    } else {
      if (e.target.id === 'startDate' && startDate === '') {
        error_add_data['startDate'] = 'Please select start date';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
      } else {
        error_add_data['startDate'] = '';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
      }

      if (e.target.id === 'endDate' && endDate === '') {
        error_add_data['endDate'] = 'Please select end date';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
      } else {
        error_add_data['endDate'] = '';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
      }

      if (e.target.id === 'startTime' && startTime !== '') {
        error_add_data['startTime'] = '';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
      }

      if (e.target.id === 'endTime' && endTime !== '') {
        error_add_data['endTime'] = '';
        setErrorAddData(error_add_data);
        // this.setState({ error_add_data });
      }

    }
  };
  const openDeleteModal = (type) => {
    if (type === 'openDelete') {
      setUpdateEventModal(false);
      setDeleteEventModal(true);
    }
    else if (type === 'confirmDelete') {
      setStartTime('');
      setEndTime('');
      setDeleteEventReason('');
      setCurrentEditData({});
      setDeleteEventModal(false);
    }
    else if (type === 'closeUpdate') {
      setStartTime('');
      setEndTime('');
      setCurrentEditData({});
      setUpdateEventModal(false);
    }
    else if (type === 'failModal') {
      setFailedMessage('');
      setFailedModal(false);
    }
  };
  const handledeleteReason = (e) => {
    let c = /^\s/.test(e.target.value);
    if (c !== true) {
      setDeleteEventReason(e.target.value);
      // this.setState({ deleteEventReason: e.target.value });
    }
  };

  const deleteEvent = () => {
    let obj = {
      schedule_id: currentEditData.schedule_id,
      void_reason: deleteEventReason
    };
    let error_add_data = errorAddData;
    if (deleteEventReason.length < 1) {
      error_add_data['deleteReason'] = 'Please provide reason for deleting.';
      setErrorAddData(error_add_data);
      return;
    }
    deleteSlot({
      endpoint: apiConstants.adminDeleteDoctorSlot,
      method: 'DELETE',
      data: obj
    })
      .then(res => {
        if (res.data.success === true) {
          getdoctorschedule({
            endpoint: `${apiConstants.getdoctorschedule}/${moment(currentDate).format('YYYY-MM')}/${id}`,
            method: 'GET'
          })
            .then((res) => {
              let data = res.data;
              let eventArray = [];
              let slots_data = data;
              if (data && data !== undefined && data.length !== 0) {
                data.forEach(element => {
                  let obj = {};
                  if (element.is_slot_verified === 2) {
                    obj.content = moment(element.start_time,
                      ['HH:mm']).format('hh:mm a') + '-' +
                      moment(element.end_time, ['HH:mm']).format('hh:mm a') + ' ' + ' ' + '(P)';
                  } else {
                    obj.content = moment(element.start_time,
                      ['HH:mm']).format('hh:mm a') + '-' + moment(element.end_time, ['HH:mm']).format('hh:mm a');
                  }

                  obj.range = moment.range(moment(element.date).format('YYYY/MM/DD'),
                    moment(element.date).format('YYYY/MM/DD'));
                  obj.schedule_id = element.id;
                  eventArray.push(obj);
                });
              }
              let EVENTS = new Dayz.EventsCollection(eventArray);
              setEvents(EVENTS);
              setStartTime('');
              setEndTime('');
              setCurrentEditData({});
              setDeleteEventModal(false);
              setDeleteEventReason('');
              setSlotsData(slots_data);
            });
        }
        else {
          setFailedMessage(res.data.message);
          setFailedModal(true);
        }

      });

  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[35px] bg-white text-left shadow-xl 
            transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-[80%]" // Adjusted width here
          >
            <div className="bg-white">
              <div className="w-full relative">
                <div className='p-[20px] border-b-[1px] border-b-[#808080] flex justify-center'>
                  <span className='text-[#757776] text-[24px] '>
                    Schedule Slot</span>
                </div>
                <FontAwesomeIcon icon={faClose}
                  className='text-[#000] right-[40px] top-[25px] text-[20px] cursor-pointer absolute'
                  onClick={() => setIsScheduleSots(false)}
                />
                <div className="w-full py-[30px] px-[40px] text-[#757776]">
                  <div className="row">

                    <div className="flex justify-between">
                      <div>Dr. {' '}{detailsCardData?.first_name}{' '}{detailsCardData?.first_name}</div>
                      <div className="flex gap-10 justify-center items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                          stroke="currentColor" className="w-6 h-6 cursor-pointer"
                          onClick={() => changeMonth('prev')}
                        // style={{ cursor: leftcursor }}
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                        </svg>
                        {currentMonth !== '' && <h4 className="text-center">{currentMonth} {currentYear}</h4>}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                          stroke="currentColor" className="w-6 h-6 cursor-pointer"
                          onClick={() => changeMonth('next')}
                        >
                          <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                      </div>
                      <div>
                        <SharedButton className="rounded-[20px] bg-[#383678] float-left text-white h-10 w-22 px-[50px] 
                        py-[10px] text-sm font-bold mx-auto flex bg-gradient-to-r from-[#383678] via-[#5f59b3]
                         to-[#7976c9]"
                          onClick={() => setAddEventModal(true)}
                          title="Add Slot" />
                      </div>
                    </div>
                    <div className="mt-5">

                      <Dayz
                        display='month'
                        date={currentDate}
                        events={events}
                        weekStartsOn={1}
                        onEventClick={onEventClick}
                      // highlightDays={[moment()]}
                      />
                    </div>
                    {addEventModal && (
                      <SlotModal
                        title="Add Slot"
                        addEvent={addEvent}
                        onTimeBlur={onTimeBlur}
                        onTimeChange={onTimeChange}
                        onParticularDate={onParticularDate}
                        handleRadioValue={handleRadioValue}
                        startTime={startTime}
                        endTime={endTime}
                        startDate={startDate}
                        endDate={endDate}
                        radioValue={radioValue}
                        particularDate={particularDate}
                        errorAddData={errorAddData}
                        currentDate={currentDate}
                        onMultipleDate={onMultipleDate}
                        modalClose={modalClose}
                      />
                    )

                    }

                    {updateEventModal && (
                      <UpdateEventModal
                        currentEditData={currentEditData}
                        errorAddData={errorAddData}
                        startTime={startTime}
                        endTime={endTime}
                        disableButtons={disableButtons}
                        handleupdateblur={handleupdateblur}
                        updateEvent={updateEvent}
                        onTimeChange={onTimeChange}
                        openDeleteModal={openDeleteModal}
                      />

                    )

                    }
                    {deleteEventModal && (
                      <DeleteModal
                        deleteEventReason={deleteEventReason}
                        handledeleteReason={handledeleteReason}
                        deleteEvent={deleteEvent}
                        openDeleteModal={openDeleteModal}
                      />
                    )

                    }

                    {failedModal && (
                      <FailedModal
                        failedMessage={failedMessage}
                        openDeleteModal={openDeleteModal}
                      />
                    )

                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default DoctorScheduleSlotsModal;
