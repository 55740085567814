import React from 'react';
import PatientDetailBox from '../common/PatientDetailBox';
import SearchComponent from '../common/SearchComponent';
import { apiConstants } from '../../../../constants/api';
import { useLazySearchInvestigationListQuery } from '../../../../reducers/allReducer';

const Investigation = ({ appointmentId }) => {
  const [searchInvestigationList] = useLazySearchInvestigationListQuery();
  return (
    <div className='grid grid-cols-12 gap-[30px]'>
      <div className='col-span-12'>
        <PatientDetailBox title='Tests'>
          <SearchComponent 
            placeholder='Search for tests'
            dropdownName='investigationDropdown'
            appointmentId={appointmentId}
            addAppointmentType='investigation'
            saveNoteType='investigation_add_note'
            endpointForDataSearch={apiConstants.searchInvestigationList}
            hookForApiCall={searchInvestigationList}
            fieldNameForSearchApi='investigationList'
          />
        </PatientDetailBox>
      </div>
    </div>
  );
};

export default Investigation;