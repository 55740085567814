import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Colorcode } from '../../common/utils/Colorcode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useLazyGetHistoryQuestionQuery } from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import MedicalHistoryPreview from './MedicalHistoryPreview';
import CreateMedicalHistory from '../CreateMedicalHistory';

const ViewMedicalHistory = () => {
  const navigate = useNavigate();
  const customerColor = Colorcode();
  const {id} = useParams();
  const [getHistoryQuestion] = useLazyGetHistoryQuestionQuery();
  const [historyData, setHistoryData] = useState();
  const [isPreview, setIsPreview] = useState(true);

  const handleGetHistoryQuestion = () => {
    getHistoryQuestion({
      endpoint: `${apiConstants?.getHistoryQuestion}/${id}`
    }).then((res) => {
      setHistoryData(res?.data);
    });
  };

  useEffect(() => {
    if (id) {
      handleGetHistoryQuestion();
    }
  }, [id]);

  return (
    <div className='flex flex-col gap-[20px] p-[80px]'>
      <div>
        <button
          type='submit'
          className='bg-blue-600 text-white px-[20px] py-[5px] rounded-[50px]'
          style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
      <div className='bg-[#f4f4f4] rounded-[8px] p-[20px] flex flex-col gap-[10px] min-h-[500px]'>
        <div className='flex justify-end cursor-pointer' onClick={() => setIsPreview(!isPreview)}>
          <FontAwesomeIcon icon={isPreview ? faEdit : faClose} className='w-[30px] h-[30px]' />
        </div>
        {isPreview && historyData && (
          <MedicalHistoryPreview historyData={historyData} />
        )}
        {!isPreview && (
          <CreateMedicalHistory 
            isEdit 
            historyData={JSON?.parse(historyData?.questions_json)} 
            setIsPreview={setIsPreview}
            handleGetHistoryQuestion={handleGetHistoryQuestion}
          />
        )}
      </div>
    </div>
  );
};

export default ViewMedicalHistory;