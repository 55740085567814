import React from 'react';
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Colorcode } from '../../common/utils/Colorcode';

const customerColor = Colorcode();
const AdvanceSearchModal = ({
  setIsAdvanceSearch,
  setSearchFilter,
  searchFilter,
  fetchPatientList
}) => {

  const handleSubmit = () => {
    fetchPatientList(1, 'all', searchFilter);
    setIsAdvanceSearch(false);
  };

  const handleClear = () => {
    setSearchFilter({
      patient: '',
      startDate: '',
      phone: '',
      doctor: '',
      endDate: ''
    });
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white">
              <div className="w-full relative">
                <div className='p-[20px] border-b-[1px] border-b-[#808080] flex justify-center'>
                  <span className='text-[#757776] text-[20px] fw-500'>
                    Search</span>
                </div>
                <div className='absolute top-[30px] right-[30px]'>
                  <FontAwesomeIcon icon={faCircleXmark}
                    className='text-[25px] cursor-pointer'
                    style={{ color: customerColor[0] }}
                    onClick={() => setIsAdvanceSearch(false)}
                  />
                </div>
              </div>
              <div className="w-full text-[#757776]">
                <Formik
                  initialValues={{}}
                  // validationSchema={LoginSchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue }) => (
                    <Form className='flex flex-col items-center gap-[50px]'>
                      <div className='flex py-[35px] px-[40px] gap-[10px] justify-between w-[100%]'>
                        <div className='flex flex-col w-[40%] gap-[15px]'>
                          <Field
                            name="patient"
                            type='text'
                            placeholder="Patient"
                            autoComplete="off"
                            className="border-b border-[#A9A9A9] opacity-70
                            outline-none w-full "
                            onChange={(e) => {
                              setSearchFilter({
                                ...searchFilter,
                                patient: e.target.value
                              });
                            }}
                            value={searchFilter?.patient}
                          />
                          <div className='col-span-5 flex flex-col relative'>
                            <Field name="startDate" className='w-full relative'>
                              {({ field }) => (
                                <div className="relative advSearch">
                                  <ReactDatePicker
                                    {...field}
                                    showIcon
                                    maxDate={new Date()}
                                    placeholderText='Start Date'
                                    onChange={(data) => {
                                      setSearchFilter({
                                        ...searchFilter,
                                        startDate: data
                                      });
                                      setFieldValue('startDate', data);
                                    }}
                                    selected={searchFilter?.startDate}
                                    toggleCalendarOnIconClick
                                    showPopperArrow={false}
                                    className='w-full cursor-pointer'
                                    value={searchFilter?.startDate}
                                    icon={''}
                                    dateFormat="dd/MM/yyyy"
                                    showYearDropdown
                                  />
                                  <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    className="text-gray-500 absolute bottom-[15px] right-[20px]" />
                                </div>
                              )}
                            </Field>
                          </div>
                          <Field
                            name="phone"
                            type='number'
                            placeholder="Phone Number"
                            autoComplete="off"
                            className="border-b border-[#A9A9A9] opacity-70
                            outline-none w-full h-[48px]"
                            onChange={(e) => {
                              setSearchFilter({
                                ...searchFilter,
                                phone: e.target.value
                              });
                            }}
                            value={searchFilter?.phone}
                          />
                        </div>
                        <div className='flex flex-col w-[40%] gap-[15px]'>
                          <Field
                            name="doctor"
                            type='text'
                            placeholder="Doctor"
                            autoComplete="off"
                            className="border-b border-[#A9A9A9] opacity-70
                            outline-none w-full"
                            onChange={(e) => {
                              setSearchFilter({
                                ...searchFilter,
                                doctor: e.target.value
                              });
                            }}
                            value={searchFilter?.doctor}
                          />
                          <div className='col-span-5 flex flex-col relative'>
                            <Field name="endDate" className='w-full relative'>
                              {({ field }) => (
                                <div className="relative advSearch">
                                  <ReactDatePicker
                                    {...field}
                                    showIcon
                                    maxDate={new Date()}
                                    placeholderText='End Date'
                                    onChange={(data) => {
                                      setSearchFilter({
                                        ...searchFilter,
                                        endDate: data
                                      });
                                      setFieldValue('endDate', data);
                                    }}
                                    selected={searchFilter?.endDate}
                                    toggleCalendarOnIconClick
                                    showPopperArrow={false}
                                    className='w-full cursor-pointer'
                                    value={searchFilter?.endDate}
                                    icon={''}
                                    dateFormat="dd/MM/yyyy"
                                    showYearDropdown
                                  />
                                  <FontAwesomeIcon
                                    icon={faCalendarAlt}
                                    className="text-gray-500 absolute bottom-[15px] right-[20px]" />
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div className='w-full py-[15px] flex gap-[80px]
                      border-t-[1px] border-t-[#808080]'>
                        <button
                          type='submit'
                          className='bg-[#383678] text-white px-[15px]  w-[100px] h-[35px] leading-[35px]
                          rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center'
                          style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                        >
                          Submit
                        </button>
                        <div
                          className="bg-[#808080] text-white px-[15px]  w-[100px] h-[35px] leading-[35px]
                          rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center cursor-pointer"
                          onClick={handleClear}
                        >
                          Clear
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default AdvanceSearchModal;
