import { genericQueryBuilder, genericQueryMutation } from './middleware/genericBuilder';
import { mainEndPointCreator } from './middleware/mainEndPointCreator';

export default function allReducer(builders) {
  return {
    getFrontDeskList: genericQueryMutation(builders),
    getAdminCount: genericQueryBuilder(builders),
    changeStatus: genericQueryMutation(builders),
    getLoggedIn: genericQueryMutation(builders),
    getRolePrivilegeAccess: genericQueryBuilder(builders),
    getSettings: genericQueryBuilder(builders),
    getUpcomingConsults: genericQueryMutation(builders),
    getDepartmentsList: genericQueryMutation(builders),
    getMedicalHistoryList: genericQueryMutation(builders),
    getAdminFrontDeskList: genericQueryMutation(builders),
    getAdminParamedicList: genericQueryMutation(builders),
    getAdminDoctorsList: genericQueryMutation(builders),
    getAdminPatientsList: genericQueryMutation(builders),
    getAdminKiosksList: genericQueryMutation(builders),
    getAdminSettings: genericQueryBuilder(builders),
    getDoctorDetailsView: genericQueryBuilder(builders),
    getAllClients: genericQueryMutation(builders),
    getAvaialbleDoctors: genericQueryMutation(builders),
    getAdminVerifySlots: genericQueryMutation(builders),
    getDoctorSlotsVerified: genericQueryMutation(builders),
    getAgentVerifySlots: genericQueryMutation(builders),
    createPatient: genericQueryMutation(builders),
    getUpcomingConsultsForDoctor: genericQueryBuilder(builders),
    getCount: genericQueryBuilder(builders),
    getDoctorSlotsForDate: genericQueryMutation(builders),
    schedulekioskappointment: genericQueryMutation(builders),
    getAllAgentDoctor: genericQueryMutation(builders),
    updatekioskappointment: genericQueryMutation(builders),
    getConceptBloodGroup: genericQueryBuilder(builders),
    getallmedicaldepartments: genericQueryBuilder(builders),
    getpatientswithsamephone: genericQueryBuilder(builders),
    getconsultsfortoday: genericQueryMutation(builders),
    getappointmentusers: genericQueryBuilder(builders),
    getpatientdocuments: genericQueryBuilder(builders),
    uploadpatientdocuments: genericQueryMutation(builders),
    deletepatientdocument: genericQueryMutation(builders),
    updatepatientdocument: genericQueryMutation(builders),
    getallpastconsults: genericQueryMutation(builders),
    getAppointmentType: genericQueryBuilder(builders),
    getMedicalCertificateDetails: genericQueryBuilder(builders),
    saveMedicalCertificateDetails: genericQueryMutation(builders),
    sendMedicalCertificate: genericQueryMutation(builders),
    getPatientDetailsByAppointmentId: genericQueryBuilder(builders),
    searchSymtomList: genericQueryBuilder(builders),
    deleteAppointmentData: genericQueryMutation(builders),
    addAppointmentData: genericQueryMutation(builders),
    listAppointmentData: genericQueryBuilder(builders),
    searchInvestigationList: genericQueryBuilder(builders),
    searchDiagnosisList: genericQueryBuilder(builders),
    getPastConsultants: genericQueryMutation(builders),
    uploadPhoto: genericQueryMutation(builders),
    getAllAdmin: genericQueryMutation(builders),
    getAllCustomerList: genericQueryMutation(builders),
    getAllAppointmentList: genericQueryMutation(builders),
    getDrugTypes: genericQueryBuilder(builders),
    getMedicines: genericQueryBuilder(builders),
    getPrescriptionData: genericQueryBuilder(builders),
    sendEmailToPatient: genericQueryMutation(builders),
    getPatientMedicalHistory: genericQueryBuilder(builders),
    addPatientMedicalHistory: genericQueryMutation(builders),
    addCustomer: genericQueryMutation(builders),
    createAdmin: genericQueryMutation(builders),
    adminCreateParamedic: genericQueryMutation(builders),
    adminCreateFrontDesk: genericQueryMutation(builders),
    adminCreateDoctor: genericQueryMutation(builders),
    adminUploadDoctorCertificate: genericQueryMutation(builders),
    adminDoctorCertificateS3Link: genericQueryMutation(builders),
    getcustomers: genericQueryBuilder(builders),
    getAppointmentDetails: genericQueryBuilder(builders),
    getPatientReports: genericQueryBuilder(builders),
    getCoordinates: genericQueryBuilder(builders),
    getPatientPastConsults: genericQueryBuilder(builders),
    getFrontDeskView: genericQueryBuilder(builders),
    getParamedicView: genericQueryBuilder(builders),
    getcustomerView: genericQueryBuilder(builders),
    getadminView: genericQueryBuilder(builders),
    updateagent: genericQueryMutation(builders),
    updateparamedic: genericQueryMutation(builders),
    updatedoctor: genericQueryMutation(builders),
    getSignedUrlForDoctorCertificate: genericQueryMutation(builders),
    updateadmin: genericQueryMutation(builders),
    updatecustomer: genericQueryMutation(builders),
    getPatientVitals: genericQueryBuilder(builders),
    getPatientVitalHistoryInConsult: genericQueryMutation(builders),
    getkiosksforassociation: genericQueryMutation(builders),
    updatekioskforuser: genericQueryMutation(builders),
    getkiosksfordoctorassociation: genericQueryBuilder(builders),
    getDoctorSlots: genericQueryMutation(builders),
    addSlots: genericQueryMutation(builders),
    updateSlots: genericQueryMutation(builders),
    deleteSlots: genericQueryMutation(builders),
    addBodyPainDetails: genericQueryMutation(builders),
    onAttachmentUpdate: genericQueryMutation(builders),
    onAttachmentDelete: genericQueryMutation(builders),
    onAttachmentView: genericQueryMutation(builders),
    getSignedUrlForAttachments: genericQueryMutation(builders),
    saveAttachment: genericQueryMutation(builders),
    doctorsbulkupload: genericQueryMutation(builders),
    getdoctorlistforslots: genericQueryBuilder(builders),
    getsamplecsvdoctor: genericQueryBuilder(builders),
    doctorschedulebulkupload: genericQueryMutation(builders),
    verifydoctor: genericQueryMutation(builders),
    saveLogo: genericQueryMutation(builders),
    getAllKioskUserList: genericQueryBuilder(builders),
    createKiosk: genericQueryMutation(builders), // createKiosk
    updateKiosk: genericQueryMutation(builders), // updateKiosk
    getKioskListById: genericQueryMutation(builders), // getKioskListById
    getUsersAssociation: genericQueryMutation(builders), // getUsersAssociation
    createAssociateUsersWithKiosk: genericQueryMutation(builders), // associateUsersWithKiosk
    getVitalsAnalytics: genericQueryMutation(builders), // getVitalsAnalytics
    getFutureConsults: genericQueryMutation(builders),
    getDoctorDocuments: genericQueryBuilder(builders),
    uploadDoctorDocument: genericQueryMutation(builders),
    getAllAppearance: genericQueryBuilder(builders),
    getDeviceOrderStatusData: genericQueryBuilder(builders),
    getAllSettings: genericQueryBuilder(builders),
    appearance: genericQueryMutation(builders),
    associatedoctorswithkiosks: genericQueryMutation(builders),
    getdashboardanalytics: genericQueryMutation(builders),
    changeAvailableStatus: genericQueryMutation(builders),
    logger: genericQueryMutation(builders),
    reasonForDisconnect: genericQueryMutation(builders),
    getdoctorschedule: genericQueryBuilder(builders),
    adminaddslots: genericQueryMutation(builders),
    adminupdateslots: genericQueryMutation(builders),
    admindeleteslots: genericQueryMutation(builders),
    getAgentScheduleSlots: genericQueryBuilder(builders),
    getSignedUrlForAttachmentsDoctor: genericQueryMutation(builders),
    saveAttachmentDoctor: genericQueryMutation(builders),
    addDoctorVitals: genericQueryMutation(builders),
    updatedeviceorderstatus: genericQueryMutation(builders),
    getOtp: genericQueryMutation(builders),
    verifyOtp: genericQueryMutation(builders),
    resetPassword: genericQueryMutation(builders),
    gettabulardashboardanalytics: genericQueryMutation(builders),
    getvideosdkcreds: genericQueryBuilder(builders),
    updaterecordingdetails: genericQueryMutation(builders),
    addHistoryQuestion: genericQueryMutation(builders),
    getSampleCsvDetails: genericQueryMutation(builders),
    getHistoryQuestion: genericQueryBuilder(builders),
    editHistoryQuestion: genericQueryMutation(builders)
  };
}

export const {
  useGetFrontDeskListMutation,
  useLazyGetAdminCountQuery,
  useChangeStatusMutation,
  useGetLoggedInMutation,
  useLazyGetRolePrivilegeAccessQuery,
  useLazyGetSettingsQuery,
  useGetUpcomingConsultsMutation,
  useGetDepartmentsListMutation,
  useGetMedicalHistoryListMutation,
  useGetAdminFrontDeskListMutation,
  useGetAdminParamedicListMutation,
  useGetAdminDoctorsListMutation,
  useGetAdminPatientsListMutation,
  useGetAdminKiosksListMutation,
  useCreateKioskMutation,
  useGetVitalsAnalyticsMutation,
  useUpdateKioskMutation,
  useGetKioskListByIdMutation,
  useGetUsersAssociationMutation,
  useCreateAssociateUsersWithKioskMutation,
  useLazyGetAdminSettingsQuery,
  useLazyGetDoctorDetailsViewQuery,
  useGetAllClientsMutation,
  useGetAvaialbleDoctorsMutation,
  useGetAdminVerifySlotsMutation,
  useGetAgentVerifySlotsMutation,
  useCreatePatientMutation,
  useGetDoctorSlotsVerifiedMutation,
  useLazyGetUpcomingConsultsForDoctorQuery,
  useLazyGetCountQuery,
  useGetDoctorSlotsMutation,
  useSchedulekioskappointmentMutation,
  useGetAllAgentDoctorMutation,
  useUpdatekioskappointmentMutation,
  useLazyGetConceptBloodGroupQuery,
  useLazyGetallmedicaldepartmentsQuery,
  useLazyGetpatientswithsamephoneQuery,
  useGetconsultsfortodayMutation,
  useLazyGetappointmentusersQuery,
  useLazyGetpatientdocumentsQuery,
  useUploadpatientdocumentsMutation,
  useDeletepatientdocumentMutation,
  useUpdatepatientdocumentMutation,
  useGetallpastconsultsMutation,
  useLazyGetAppointmentTypeQuery,
  useLazyGetMedicalCertificateDetailsQuery,
  useSaveMedicalCertificateDetailsMutation,
  useSendMedicalCertificateMutation,
  useLazyGetPatientDetailsByAppointmentIdQuery,
  useLazySearchSymtomListQuery,
  useDeleteAppointmentDataMutation,
  useAddAppointmentDataMutation,
  useLazyListAppointmentDataQuery,
  useLazySearchInvestigationListQuery,
  useLazySearchDiagnosisListQuery,
  useGetPastConsultantsMutation,
  useUploadPhotoMutation,
  useGetAllAdminMutation,
  useGetAllCustomerListMutation,
  useGetAllAppointmentListMutation,
  useLazyGetDrugTypesQuery,
  useLazyGetMedicinesQuery,
  useLazyGetPrescriptionDataQuery,
  useSendEmailToPatientMutation,
  useLazyGetPatientMedicalHistoryQuery,
  useAddPatientMedicalHistoryMutation,
  useAddCustomerMutation,
  useCreateAdminMutation,
  useAdminCreateParamedicMutation,
  useAdminCreateFrontDeskMutation,
  useAdminCreateDoctorMutation,
  useAdminUploadDoctorCertificateMutation,
  useAdminDoctorCertificateS3LinkMutation,
  useLazyGetcustomersQuery,
  useLazyGetAppointmentDetailsQuery,
  useLazyGetPatientReportsQuery,
  useLazyGetCoordinatesQuery,
  useLazyGetPatientPastConsultsQuery,
  useLazyGetFrontDeskViewQuery,
  useLazyGetParamedicViewQuery,
  useLazyGetcustomerViewQuery,
  useLazyGetadminViewQuery,
  useUpdateagentMutation,
  useUpdateparamedicMutation,
  useUpdatedoctorMutation,
  useGetSignedUrlForDoctorCertificateMutation,
  useUpdateadminMutation,
  useUpdatecustomerMutation,
  useLazyGetPatientVitalsQuery,
  useGetPatientVitalHistoryInConsultMutation,
  useGetkiosksforassociationMutation,
  useUpdatekioskforuserMutation,
  useLazyGetkiosksfordoctorassociationQuery,
  useGetDoctorSlotsForDateMutation,
  useAddSlotsMutation,
  useUpdateSlotsMutation,
  useDeleteSlotsMutation,
  useAddBodyPainDetailsMutation,
  useOnAttachmentUpdateMutation,
  useOnAttachmentDeleteMutation,
  useOnAttachmentViewMutation,
  useGetSignedUrlForAttachmentsMutation,
  useSaveAttachmentMutation,
  useDoctorsbulkuploadMutation,
  useLazyGetdoctorlistforslotsQuery,
  useLazyGetsamplecsvdoctorQuery,
  useDoctorschedulebulkuploadMutation,
  useVerifydoctorMutation,
  useSaveLogoMutation,
  useGetAllKioskUserListMutation,
  useGetFutureConsultsMutation,
  useLazyGetDoctorDocumentsQuery,
  useUploadDoctorDocumentMutation,
  useLazyGetAllAppearanceQuery,
  useLazyGetDeviceOrderStatusDataQuery,
  useLazyGetAllSettingsQuery,
  useAppearanceMutation,
  useGetdashboardanalyticsMutation,
  useChangeAvailableStatusMutation,
  useLoggerMutation,
  useReasonForDisconnectMutation,
  useLazyGetdoctorscheduleQuery,
  useAdminaddslotsMutation,
  useAdminupdateslotsMutation,
  useAdmindeleteslotsMutation,
  useLazyGetAgentScheduleSlotsQuery,
  useGetSignedUrlForAttachmentsDoctorMutation,
  useSaveAttachmentDoctorMutation,
  useAddDoctorVitalsMutation,
  useUpdatedeviceorderstatusMutation,
  useAssociatedoctorswithkiosksMutation,
  useGetOtpMutation,
  useVerifyOtpMutation,
  useResetPasswordMutation,
  useGettabulardashboardanalyticsMutation,
  useLazyGetvideosdkcredsQuery,
  useUpdaterecordingdetailsMutation,
  useAddHistoryQuestionMutation,
  useGetSampleCsvDetailsMutation,
  useLazyGetHistoryQuestionQuery,
  useEditHistoryQuestionMutation
} = mainEndPointCreator;