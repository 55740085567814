import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import ParamedicImage from '../../images/icons/Paramedic_icon.svg';
import * as yup from 'yup';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { useAdminCreateParamedicMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';
import { Colorcode } from '../../components/common/utils/Colorcode';

const Schema = yup.object().shape({
  first_name: yup.string().required('Please Enter First Name'),
  last_name: yup.string().max(10).required('Please Enter Last Name'),
  email: yup.string().email('Must be valid email').required('Please Enter Valid Email')
});

const ParamedicCreate = () => {
  const customerColor = Colorcode();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [createParamedic] = useAdminCreateParamedicMutation();

  const privacypolicy = () => {
    window.open('https://smarthealth.sakhaglobal.com/privacypolicy');
  };

  const termsandconditions = () => {
    window.open('https://smarthealth.sakhaglobal.com/termsandconditions');
  };

  const handleSubmit = (values) => {
    values.phone = phoneNumber;
    createParamedic({
      endpoint: apiConstants.adminCreateParamedic,
      method: 'POST',
      data: {
        data: {
          fname: values.first_name,
          lname: values.last_name,
          phone_number: values.phone,
          email_id: values.email
        }
      }
    }).then((result) => {
      console.log(result);
      navigate('/admin/paramediclist');
    }).catch((error) => {
      console.log(error);
      navigate('/admin/paramediclist');
    });
  };

  return (
    <div className='my-[2%] mx-[5%]'>
      <div className='bg-[#f4f4f4] grid grid-cols-12 mt-28'>
        <div className='col-span-6'>
          <div className='text-[#737575] text-[40px] mt-[25px] ml-48'>Create Paramedic</div>
          <img className="h-auto max-w-lg mx-auto ml-36 mt-10" src={ParamedicImage} alt="image description" />
        </div>
        <div className='col-span-6'>
          {/* form here */}
          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              email: ''
            }}
            validationSchema={Schema}
            onSubmit={handleSubmit}
          >
            <Form className='flex flex-col items-center gap-[30px] mt-[30px]'>
              <div className='relative w-[55%]'>
                <Field
                  name="first_name"
                  type='text'
                  placeholder="First name*"
                  autoComplete="off"
                  className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
                />
                <ErrorMessage name="first_name" component="div" className='text-red-600 absolute' />
              </div>
              {/* Contact number */}
              <div className='relative w-[55%]'>
                <Field
                  name="last_name"
                  type='text'
                  placeholder="Last Name*"
                  autoComplete="off"
                  className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
                />
                <ErrorMessage name="last_name" component="div" className='text-red-600 absolute' />
              </div>
              {/* email field */}
              <div className='relative w-[55%]'>
                <Field
                  name="email"
                  type='text'
                  placeholder="Email*"
                  autoComplete="off"
                  className="border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3"
                />
                <ErrorMessage name="email" component="div" className='text-red-600 absolute' />
              </div>
              {/*  phone number*/}
              <div className="relative w-[55%]"
                style={{ display: 'grid', background: '#f4f4f4' }}>
                <Field name="phone" className='createCustomer border-b border-[#A9A9A9] opacity-70 bg-[#f4f4f4]
                          outline-none w-full py-2 px-3'>
                  {({ field }) => (
                    <PhoneInput
                      {...field}
                      name="phone"
                      type="tel"
                      placeholder="Phone Number"
                      country={'in'}
                      onlyCountries={['in']}
                      autoFormat={false}
                      onChange={(data) => {
                        setPhoneNumber('+' + data);
                      }}
                    />
                  )}
                </Field>
                <ErrorMessage name="phone" component="div" className='text-red-600' />
              </div>
              {/* Buttons */}
              <div className='flex gap-[40px] w-[55%]'>
                <button
                  type='submit'
                  className="bg-[#383678] text-white px-[50px] py-[5px] w-[150px] h-[48px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center"
                  style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                >
                  Submit
                </button>
                <button
                  type='text'
                  className="bg-[#383678] text-white px-[50px] py-[5px] w-[150px] h-[48px] leading-[35px]
                  rounded-[20px] focus:outline-none text-xl mx-auto flex justify-center"
                  style={{ background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})` }}
                  onClick={() => navigate('/admin/paramediclist')}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </Formik>
          <div>
          </div>
          <div className='text-[#737575] ml-32 mt-10 mb-10'>By clicking on Submit you accept our
            <span className='underline cursor-pointer hover:font-semibold'
              onClick={privacypolicy}> Privacy Policy</span> and
            <span className='underline cursor-pointer hover:font-semibold'
              onClick={termsandconditions}> Terms and Conditions.</span>
          </div>
        </div>
      </div>
    </div >
  );
};

export default ParamedicCreate;