import React, { useState, useEffect } from 'react';
import {
  useGetallpastconsultsMutation
} from '../../../reducers/allReducer';
import { apiConstants } from '../../../constants/api';
import moment from 'moment';
import SharedTable from '../../common/SharedTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import AdvanceSearchModal from './AdvanceSearchModal';
import { Colorcode } from '../../common/utils/Colorcode';

const PastConsults = () => {
const customerColor = Colorcode();
  const [getallpastconsults] = useGetallpastconsultsMutation();
const [pageNumber, setPageNumber] = useState('1');
  const [search, setSearch] = useState('');
  const [pastConsults, setPastConsults] = useState([]);
  const [isMoreData, setIsMoreData] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [filter, setFilter] = useState('all');
  const [isAdvanceSearch, setIsAdvanceSearch] = useState(false);
  const [showSearchIcon, setShowSearchIcon] = useState(true);
  const [searchFilter, setSearchFilter] = useState({
    patient: '',
    startDate: '',
    phone: '',
    doctor: '',
    endDate: ''
  });
  const [sort, setSort] = useState({
    sortType: '',
    headerFiled: ''
  });

  const handleOnClickSort = (header) => {
	setPageNumber(1);
    if (header?.field === sort?.headerFiled) {
      setSort({
        ...sort,
        sortType: sort.sortType === 'desc' ? 'asc' : 'desc'
      });
    } else {
      setSort({
        sortType: 'asc',
        headerFiled: header?.field
      });
    }
  };

  const handleSearch = (e) => {
	setPageNumber(1);
    setSearch(e.target.value);
  };

  const clearSearchData = () => {
    setFilter('all');
    setSearch('');
    setSearchFilter({
      patient: '',
      startDate: '',
      phone: '',
      doctor: '',
      endDate: ''
    });
    fetchPatientList(1, 'all');
  };

  const openPrescription = (fileID) => {
    window.open(`https://sakha-health.s3-ap-southeast-1.amazonaws.com/prescriptionfiles/${fileID}.pdf`);
  };

  const openMedicalCertificate = (fileID) => {
    window.open(`https://sakha-health.s3-ap-southeast-1.amazonaws.com/medicalcertificates/${fileID}.pdf`);
  };

  const fetchPatientList = (pageNumber, filter) => {
    const pageNum = pageNumber || 1;
    const type = filter || 'all';
    getallpastconsults({
      endpoint: search ? `${apiConstants.getallpastconsults}/${pageNum}/${type}/${search}` :
        `${apiConstants.getallpastconsults}/${pageNum}/${type}`,
      method: 'POST',
      data: {
        consult_type: '',
        current_time: '',
        data: {
          field: sort.headerFiled,
          type: sort?.sortType,
          patient: searchFilter.patient || '',
          doctor: searchFilter.doctor || '',
          phone_number: searchFilter.phone || '',
          email_id: '',
          start_date: searchFilter.startDate && moment(searchFilter.startDate).format('YYYY-MM-DD') || '',
          end_date: searchFilter.endDate && moment(searchFilter.endDate).format('YYYY-MM-DD') || ''
        },
        field: sort.headerFiled,
        type: sort?.sortType,
        date: '',
        kiosk_id: ''
      }
    }).then((result) => {
      setIsMoreData(result?.data?.moreData);
      setTotalPages(result?.data?.total_pages);
setPageNumber(parseInt(result?.data?.current_page));

      let pastConsultList = result?.data?.list;
      const modifiedData = pastConsultList.map(item => ({
        ...item,
		status: item.status[0].toUpperCase() + item.status.slice(1),
        prescription: item.status === 'successful' ?
          (<button className="box-content text-[12px] ml-0 rounded-[20px] float-right bg-[#383678]
text-white px-[10%] py-[5px]
           mt-1.5 mx-auto flex cursor-pointer"
style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
            onClick={() => openPrescription(item.customer_appointment_id)}
          >Prescription</button>) : '',
        medicalCertificate: item.medical_certificate_generated === 1 ?
          (<button className="box-content text-[10px] ml-0 rounded-[20px] float-right bg-[#383678]
text-white px-[10%] py-[5px]
            mt-1.5 mx-auto flex cursor-pointer"
			style={{background: `linear-gradient(90deg, ${customerColor[0]}, ${customerColor[1]})`}}
            onClick={() => openMedicalCertificate(item.customer_appointment_id)}
          >Medical Certificate</button>) : ''
      }));
      setPastConsults(modifiedData);
    });
  };

  const handleRowData = () => {
  };

  useEffect(() => {
    fetchPatientList(1, filter);
  }, [filter, search, sort]);

  const pastConsultsHeaders = [
    { field: 'appointment_id', headerName: 'Appointment Id' },
    { field: 'patient', headerName: 'Patient' },
    { field: 'phone_number', headerName: 'Phone Number' },
    { field: 'doctor', headerName: 'Doctor' },
    { field: 'appointment_time', headerName: 'Appointment Date' },
    { field: 'status', headerName: 'Status' },
    { field: 'prescription', headerName: 'Prescription' },
    { field: 'medicalCertificate', headerName: 'Medical Certificate' }
  ];

  const handleOnPageChange = (pageNumber) => {
    fetchPatientList(pageNumber, filter);
  };
  return (
    <>
      {isAdvanceSearch &&
        <AdvanceSearchModal
          setIsAdvanceSearch={setIsAdvanceSearch}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
          fetchPatientList={fetchPatientList}
        />}
      <div className='w-full h-full'>
        <div className='grid grid-cols-12 px-[50px]'>
          <div className='col-span-12'>
            <div className="" style={{ float: 'right', marginTop: 10 }}>
              <div className=''>

                <div className="container-list-header">

                  <div className="search relative">
                    <input
                      type="text"
                      placeholder=" "
                      value={search}
                      autoComplete="off"
                      id="patientSearch"
                      name="patientSearch"
                      onChange={(e) => handleSearch(e)}
                      onFocus={() => setShowSearchIcon(false)}
                      onBlur={() => setShowSearchIcon(true)} />
                    <FontAwesomeIcon icon={faSearch}
                      className={`text-gray-500 absolute right-[10px] top-0 text-[20px]
                      search-icon ${showSearchIcon ? 'search-visible' : 'search-hidden'}`} />
                    {/* <div>
                    <svg>
                      <use xlinkHref="#path" />
                    </svg>
                  </div> */}
                  </div>
                  {/* <svg xmlns="axon" style={{ display: 'none' }}>
                  <symbol xmlns="axon" viewBox="0 0 160 28" id="path">
                    <path d="M32.9418651,-20.6880772 C37.9418651,-20.6880772 40.9418651,-16.6880772 40.9418651,
                    -12.6880772 C40.9418651,-8.68807717 37.9418651,-4.68807717 32.9418651,-4.68807717 C27.9418651,
                    -4.68807717 24.9418651,-8.68807717 24.9418651,-12.6880772 C24.9418651,-16.6880772 27.9418651,
                    -20.6880772 32.9418651,-20.6880772 L32.9418651,-29.870624 C32.9418651,-30.3676803 33.3448089,
                    -30.770624 33.8418651,-30.770624 C34.08056,-30.770624 34.3094785,-30.6758029 34.4782612,
                    -30.5070201 L141.371843,76.386562"
                      transform="translate(83.156854, 22.171573) rotate(-225.000000) translate(-83.156854, -22.171573)">
                    </path>
                  </symbol>
                </svg> */}

                </div>
              </div>
              {/* {Consult_Type.includes('1') && */}
              <label style={{ fontFamily: 'Poppins', fontSize: 15, color: '#737575', marginTop: 15, fontWeight: 600 }} >
                Need more specific results?<br />
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => setIsAdvanceSearch(true)}>
                  Try Advanced Search
                </span>
                <br />
                <span
                  style={{ textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={() => clearSearchData()}>Clear Filter</span>
              </label>
              {/* } */}
            </div>
          </div>
        </div>

        <div className='grid grid-cols-12 pl-[6%] pr-[30px] pt-[30px]'>
          <div className='col-span-12 pb-[15px]'>
            <span className='underline-heading' style={{borderColor: customerColor[0]}}>
              Past Consults
            </span>
          </div>
        </div>
        <div className="grid grid-cols-12 px-[6%] pb-[30px]">
          <div className='col-span-12 mt-[20px]'>
            <SharedTable
              tableData={pastConsults}
              tableHeaders={pastConsultsHeaders}
              handleOnPageChange={handleOnPageChange}
              isMoreData={isMoreData}
              totalPages={totalPages}
              onRowSelected={handleRowData}
              handleOnClickSort={handleOnClickSort}
currentPageNumber={pageNumber}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PastConsults;
