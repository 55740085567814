import React, { useEffect, useState } from 'react';
import { useLazyGetdoctorlistforslotsQuery, useGetSampleCsvDetailsMutation } from '../../reducers/allReducer';
import { apiConstants } from '../../constants/api';

const DoctorModalToDownloadSample = ({
  setDoctorModal
}) => {
  const [getdoctorlistforslots] = useLazyGetdoctorlistforslotsQuery();
  const [getSampleCsvApi] = useGetSampleCsvDetailsMutation();
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedDocList, setSelectedDocList] = useState([]);

  // Function to handle the select all checkbox
  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    if (!selectAll) {
      setSelectedItems(doctorsList.map(item => item.doctor_id));
    } else {
      setSelectedItems([]);
    }
  };

  // Function to handle individual checkbox
  const handleCheckboxChange = (item) => {
    if (selectedItems.includes(item.doctor_id)) {
      setSelectedItems(selectedItems.filter(itemId => itemId !== item.doctor_id));
    } else {
      setSelectedItems([...selectedItems, item.doctor_id]);
    }
  };

  useEffect(() => {
    const filteredList = doctorsList?.filter(item => selectedItems?.includes(item.doctor_id));
    setSelectedDocList(filteredList);
  }, [selectedItems]);

  useEffect(() => {
    getdoctorlistforslots({
      endpoint: apiConstants.getdoctorlistforslots
    }).then((result) => {
      setDoctorsList(result?.data);
    }).catch((error) => {
      console.error('error', error);
    });
  }, []);

  const handleSubmit = () => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${day}/${month}/${year}`;
    let payloadSampleData = [];
    selectedDocList.map((doctor) => {
      payloadSampleData.push({
        start_date: currentDate,
        end_date: currentDate,
        start_time: '00:00',
        end_time: '00:00',
        name: doctor.name,
        email: doctor.email_id
      });
    });
    getSampleCsvApi({
      endpoint: apiConstants.getSampleCsv,
      method: 'POST',
      data: {
        doctors: payloadSampleData
      }
    }).then((result) => {
      window.open(result?.data?.csv_url);
    });
    setDoctorModal(false);
  };

  return (
    <div
      className="relative z-50"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div
            className="relative transform overflow-hidden rounded-[40px] bg-white text-left shadow-xl 
              transition-all sm:my-8 sm:mx-4 sm:w-full sm:max-w-xl"
          >
            <div className="bg-white">
              <div className="w-full">
                <div className='p-[20px] border-b-[1px] border-b-[#808080] flex justify-center'>
                  <span className='text-[#757776] text-[24px] '>
                    Select Doctors</span>
                </div>
                <div className="w-full py-[30px] px-[40px] text-[#757776] h-[50vh] overflow-y-scroll">
                  <div className="font-sans">
                    <div className="mb-4">
                      <input type="checkbox"
                        checked={selectAll} onChange={handleSelectAll} className="mr-2 leading-tight" />
                      <span className="text-sm">Select all</span>
                    </div>
                    <table className="w-full table-auto">
                      <thead>
                        <tr>
                          <th className="px-4 py-2 border-b border-gray-400 bg-gray-200 text-left">Select</th>
                          <th className="px-4 py-2 border-b border-gray-400 bg-gray-200 text-left">Kiosk</th>
                        </tr>
                      </thead>
                      <tbody>
                        {doctorsList.map(item => (
                          <tr key={item.doctor_id}>
                            <td className="px-4 py-2 border-b border-gray-400">
                              <input
                                type="checkbox"
                                checked={selectedItems.includes(item.doctor_id)}
                                onChange={() => handleCheckboxChange(item)}
                                className="mr-2 leading-tight"
                              />
                            </td>
                            <td className="px-4 py-2 border-b border-gray-400">{item.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='flex gap-[80px] my-[20px]'>
                <button
                  type='submit'
                  className="bg-[#383678] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                          rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                  style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
                <button
                  type='text'
                  className="bg-[#383678] text-white px-[50px] py-[5px] w-[125px] h-[36px] leading-[25px]
                          rounded-[20px] focus:outline-none text-[15px] mx-auto flex justify-center"
                  style={{ background: 'linear-gradient(40.66deg,#383678,#7976c9)' }}
                  onClick={() => setDoctorModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div >
      </div >
    </div >
  );
};

export default DoctorModalToDownloadSample;
